<template>
  <div style="width: 100%; position: relative">
    <div class="col-md-12 customersWrapper">
      <div
        v-if="hoveredReminderId > 0"
        class="hoverPanel"
        :style="
          last5RemindersHoverPanelPosition +
          ' height:' +
          (last5RemindersHoverPanelHeight + 2) +
          'px;'
        "
      >
        <Last5Reminders
          :panelHeight="last5RemindersHoverPanelHeight"
          :customerId="hoveredReminderId"
        ></Last5Reminders>
      </div>
      <div
        v-if="hoveredDispositionId > 0"
        class="hoverPanel"
        :style="
          last5DispoHoverPanelPosition +
          ' height:' +
          (last5DispoHoverPanelHeight + 2) +
          'px;'
        "
      >
        <Last5Dispositions
          :panelHeight="last5DispoHoverPanelHeight"
          :customerId="hoveredDispositionId"
        ></Last5Dispositions>
      </div>
      <div
        class="col-md-12 noPadding"
        style="float: left; margin-top: -50px; background-color: white"
      >
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="customerPageTitle">Customers</div>
            <div
              v-if="this.view == 'normal' && customersTab"
              class="pagingTotalRecords ml-2"
              style="margin-top: 5px"
            >
              Total Records:
              {{ formatNumberWithoutDecimalPoints(this.totalRecords) }}
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div v-if="this.view == 'normal' && customersTab">
              <div class="pagingPanel float-right">
                <button
                  type="button"
                  :disabled="!checkNext(currentPage + 1)"
                  @click="() => gotoPage(lastPage)"
                  class="pagingIcon float-right"
                >
                  <i class="fe-chevrons-right"></i>
                </button>
                <button
                  type="button"
                  :disabled="!checkNext(currentPage + 1)"
                  @click="() => gotoPage(currentPage + 1)"
                  class="pagingIcon float-right"
                >
                  <i class="fe-chevron-right"></i>
                </button>
                <div class="pagingPanel">
                  <input
                    type="text"
                    id="pageNum"
                    class="form-control pagination-num float-left pagingTextBox"
                    v-model="pageNum"
                    v-on:keydown.enter.prevent="() => gotoPage()"
                  />
                  <span class="pagingText"
                    >of {{ formatNumberWithoutDecimalPoints(lastPage) }}</span
                  >
                </div>

                <button
                  type="button"
                  :disabled="!checkPrev(currentPage - 1)"
                  @click="() => gotoPage(currentPage - 1)"
                  class="pagingIcon float-right"
                >
                  <i class="fe-chevron-left"></i>
                </button>
                <button
                  type="button"
                  :disabled="!checkPrev(currentPage - 1)"
                  @click="() => gotoPage(1)"
                  class="pagingIcon float-right"
                >
                  <i class="fe-chevrons-left"></i>
                </button>
              </div>

              <div class="pagingPanel float-right">
                <input
                  type="text"
                  id="pageSize"
                  class="form-control pagination-num float-left pagingTextBox"
                  v-model="pageSize"
                  v-on:keydown.enter.prevent="handleSearch(false, false)"
                  @change="checkPageSizeCtrl()"
                />
                <span class="pagingText">per page</span>
              </div>
            </div>
            <div v-if="this.view == 'xchange' && customersTab">
              <div class="pagingPanel float-right">
                <button
                  type="button"
                  :disabled="currentPageXchange == lastPageXChange"
                  @click="() => gotoPageXchange(lastPageXChange)"
                  class="pagingIcon float-right"
                >
                  <i class="fe-chevrons-right"></i>
                </button>
                <button
                  type="button"
                  :disabled="currentPageXchange >= lastPageXChange"
                  @click="
                    () => gotoPageXchange(parseInt(currentPageXchange) + 1)
                  "
                  class="pagingIcon float-right"
                >
                  <i class="fe-chevron-right"></i>
                </button>
                <div class="pagingPanel">
                  <input
                    type="text"
                    id="pageNum"
                    class="form-control pagination-num float-left pagingTextBox"
                    v-model="currentPageXchange"
                    v-on:keydown.enter.prevent="
                      () => gotoPageXchange(parseInt(currentPageXchange))
                    "
                  />
                  <span class="pagingText"> of {{ lastPageXChange }}</span>
                </div>

                <button
                  type="button"
                  :disabled="currentPageXchange == 1"
                  @click="
                    () => gotoPageXchange(parseInt(currentPageXchange) - 1)
                  "
                  class="pagingIcon float-right"
                >
                  <i class="fe-chevron-left"></i>
                </button>
                <button
                  type="button"
                  :disabled="currentPageXchange == 1"
                  @click="() => gotoPageXchange(1)"
                  class="pagingIcon float-right"
                >
                  <i class="fe-chevrons-left"></i>
                </button>
              </div>
              <div class="pagingPanel">
                <input
                  type="text"
                  id="pageSize"
                  class="form-control pagination-num float-left pagingTextBox"
                  v-model="pageSizeXChange"
                  v-on:keydown.enter.prevent="getXChangeGrid()"
                  @change="checkXChangePageSizeCtrl()"
                />
                <span class="pagingText">per page</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-md-12"
        :style="'margin-top:' + (isMobile ? 50 : 0) + 'px;'"
        @click="hideRightTabClick()"
      >
        <div
          v-show="showContextMenu"
          id="contextMenu"
          style="
            position: absolute;
            color: white;
            box-shadow: 0 2px 5px #000;
            z-index: 2147483647;
            font-weight: bold;
            cursor: pointer;
          "
          class="closeOtherTabs btn btn-danger btn-sm text-center"
          :style="
            'top:' +
            contextMenuPositionTop +
            'px; left:' +
            contextMenuPositionLeft +
            'px;'
          "
        >
          <span
            style="width: 100%; float: left; display: block; text-align: center"
            @click="closeOtherTabs()"
            >Close Other Tabs</span
          >
        </div>
        <div
          v-if="!isLoading"
          class="col-md-12 noPadding"
          style="margin: 0 0 0 -12px; background-color: white"
        >
          <ul
            class="col-md-12 nav nav-tabs"
            style="border: none; margin-bottom: -5px"
          >
            <li
              key="-1"
              class="nav-item tab-item"
              style="
                width: 120px;
                max-width: 8%;
                float: left;
                position: relative;
                text-align: center;
                z-index: 1;
              "
            >
              <a
                data-toggle="tab"
                class="tab-link customer-tab-link"
                :class="{ activeAllGrid: customersTab }"
                @contextmenu.prevent="rightTabClick($event, -1)"
                @click="handleTabChange(-1, 0)"
              >
                <span class="customer-tab-header-icon">
                  <i class="fas fa-user-friends"></i>
                </span>
                <span class="customer-tab-header">All</span>
                <span
                  v-if="customersTab"
                  style="
                    width: 93%;
                    height: 8px;
                    background-color: white;
                    position: absolute;
                    left: 1px;
                    bottom: -6px;
                    z-index: 800;
                  "
                ></span>
              </a>
            </li>
            <li
              v-show-if-authorized="'1871'"
              key="newCustomer"
              class="nav-item tab-item"
              style="
                width: 160px;
                max-width: 12%;
                float: left;
                text-align: center;
                position: relative;
              "
            >
              <a
                data-toggle="tab"
                class="tab-link customer-tab-link activeGreen"
                @contextmenu.prevent="rightTabClick($event, -1)"
                @click="handleNew()"
              >
                <span class="customer-tab-header-icon">
                  <i
                    style="font-size: 16px; line-height: 18px"
                    class="fe-plus-circle"
                  ></i>
                </span>
                <span class="customer-tab-header">New Customer</span>
              </a>
            </li>

            <li
              v-for="(tab, index) in tabs"
              :key="tab.id"
              class="nav-item tab-item"
              style="
                width: 150px;
                max-width: 10%;
                float: left;
                position: relative;
                z-index: 1;
              "
              :style="
                'float:left; width:200px; max-width:' +
                getTabWidth(tabs.length, 80) +
                '%;'
              "
            >
              <a
                data-toggle="tab"
                :aria-expanded="tab.active"
                @click="handleTabChange(tab.id, index)"
                @contextmenu.prevent="rightTabClick($event, tab.id)"
                class="tab-link customer-tab-link"
                :class="{ customerActiveTab: tab.active }"
              >
                <span class="customer-tab-icons float-left">
                  <i class="fas fa-user iconView"></i>
                </span>
                <span class="tab-item-label customer-tab-text">{{
                  tab.label
                }}</span>
                <span
                  class="customer-tab-icons float-left"
                  v-on:click.stop="handleTabClose(index)"
                >
                  <i
                    style="
                      width: 16px;
                      height: 16px;
                      border-radius: 5px;
                      padding: 2px;
                      background-color: rgba(42, 64, 76, 0.1);
                      line-height: 140%;
                    "
                    :class="tab.closeIcon"
                  ></i>
                </span>
                <span
                  v-if="tab.active"
                  style="
                    width: 94%;
                    height: 8px;
                    background-color: #f4f9ff;
                    position: absolute;
                    left: 1px;
                    bottom: -6px;
                    z-index: 800;
                  "
                ></span>
              </a>
            </li>
          </ul>
        </div>
        <div
          id="searchPanelDiv"
          class="noPadding row"
          v-if="customersTab"
          style="
            border: 1px solid #e4e4e4;
            border-top-left-radius: 0;
            border-top-right-radius: 8px;
          "
        >
          <div class="col-md-12" style="padding: 17px 17px 13px 17px">
            <div class="row">
              <button
                v-if="view == 'priority' || view == 'xchange'"
                @click="handleNormalView()"
                class="btn grayButton float-left ml-2"
              >
                <i
                  style="
                    float: left;
                    margin: 0 2px;
                    color: #6a6c71;
                    font-size: 13px;
                  "
                  class="ri-filter-line"
                ></i>
                Filter Customers
              </button>
              <button
                v-if="view == 'normal' || view == 'xchange'"
                @click="handlePriorityView()"
                class="btn grayButton float-left ml-2"
              >
                <i
                  style="
                    float: left;
                    margin: 3px 0;
                    color: #6a6c71;
                    font-size: 24px;
                  "
                  class="fe-chevron-left"
                ></i>

                <span style="float: left">Priority View</span>
              </button>
              <button
                v-if="view == 'normal'"
                type="button"
                @click="() => (searchToggled = !searchToggled)"
                class="btn grayButton float-left ml-2"
              >
                <i
                  style="
                    float: left;
                    margin: 5px 0;
                    color: #6a6c71;
                    font-size: 20px;
                  "
                  class="fe-filter"
                ></i>
                <span style="float: left; margin: 0 3px">Advanced Search</span>
                <i
                  style="
                    float: left;
                    margin: 6px 0;
                    color: #6a6c71;
                    font-size: 20px;
                  "
                  :class="{
                    'fe-chevron-down': !searchToggled,
                    'fe-chevron-up': searchToggled,
                  }"
                ></i>
              </button>
              <button
                v-if="checkAuth(1094)"
                @click="handleXChangeView()"
                class="btn grayButton float-left ml-2"
              >
                <i
                  style="
                    float: left;
                    margin: 0 5px 0 0;
                    font-size: 13px;
                    color: #6a6c71;
                  "
                  class="ri-shuffle-fill"
                ></i>
                The XChange
              </button>
              <!--                
                <input
                 v-if="view == 'normal'"
                        id="sarchByClientId"
                        autocomplete="off"
                        type="text"
                        style="background-color:white; outline:none; text-align:center "
                        class="grayButton float-left ml-2"
                        v-model="filterParams.CustomerId"
                        placeholder="Search by CustomerId"
                        v-on:keydown.enter.prevent="handleSearch(true, false)"
                        tabindex="1"
                       
                      /> -->

              <button
                v-if="view != 'xchange'"
                @click="refreshGrid()"
                class="btn btn-success float-left ml-2"
                style="border-radius: 8px"
              >
                <i
                  v-if="!refreshingGrid"
                  style="float: left; margin: 1px 3px; font-size: 13px"
                  class="ri-refresh-line"
                ></i>
                <i
                  v-if="refreshingGrid"
                  style="float: left; margin: 1px 3px; font-size: 13px"
                  class="ri-refresh-fill"
                ></i>
                Refresh
              </button>
              <label
                v-if="
                  view == 'xchange' &&
                  xChangeSettings &&
                  xChangeSettings.IsEnabled &&
                  xChangeSettings.IsActive
                "
                class="float-left ml-2 mt-1"
                style="font-size: 16px; padding: 5px"
              >
                Assigned: {{ xChangeSettings.UserAssigned }} /
                {{ xChangeSettings.MaxAssignment }}
              </label>
              <button
                v-if="
                  exportButtonVisible && checkAuth(1093) && view != 'xchange'
                "
                @click="exportGrid()"
                class="btn btn-info float-left ml-2"
                style="border-radius: 8px"
              >
                <i
                  v-if="!refreshingGrid"
                  style="float: left; margin: 1px 3px; font-size: 14px"
                  class="ri-share-box-line"
                ></i>
                Export
              </button>

              <div
                v-if="view == 'normal' && showBulkOperations()"
                style="width: auto"
                class="ml-5 mt-2"
              >
                <label style="font-size: 14px" class="generalLabel">
                  Bulk Operations:</label
                >
              </div>
              <div
                v-if="view == 'normal' && hasAuth(7525)"
                style="width: 150px"
                class="ml-1 mt-1"
              >
                <select
                  id="bulkAssignAgent"
                  v-model="bulkAssingAgentId"
                  class="generalSelectBox"
                  @change="bulkAssignAgent()"
                >
                  <option :value="parseInt('0')">Assign Agent</option>
                  <option :value="null">Unassign</option>
                  <option
                    v-for="item in filteredAgents"
                    :key="item.Id"
                    :value="item.Id"
                    v-enableIfAuthorized="'1071'"
                  >
                    {{ item.Name }}
                  </option>
                </select>
              </div>
              <div
                v-if="view == 'normal' && hasAuth(7535)"
                style="width: 150px"
                class="ml-2 mt-1"
              >
                <select
                  id="bulkAssignStatus"
                  v-model="bulkAssignStatusId"
                  class="generalSelectBox"
                  @change="bulkAssignStatus()"
                >
                  <option :value="null">Change Status</option>
                  <option
                    v-for="item in filteredCustomerStatuses"
                    :key="item.Id"
                    :value="item.Id"
                    v-enableIfAuthorized="'1071'"
                  >
                    {{ item.Name }}
                  </option>
                </select>
              </div>

              <div class="pagination float-left"></div>
            </div>
            <div
              class="row float-left mt-3"
              v-if="activeFilters.length > 0 && view == 'normal'"
            >
              <button
                type="button"
                v-if="activeFilters.length > 0 && view == 'normal'"
                @click="clearFilters(true)"
                class="btn btn-link waves-effect generalFont"
                style="color: #000000; font-size: 13px"
              >
                <u>Clear All Filters</u>
              </button>
              <div
                :key="item.Id"
                v-for="item in activeFilters"
                class="clearFilterButton ml-2"
              >
                {{ item }}
                <span
                  style="cursor: pointer"
                  v-on:click.prevent.stop="removeFilter(item)"
                >
                  <i class="fe-x"></i>
                </span>
              </div>
            </div>
          </div>

          <div
            v-if="searchToggled"
            v-show="view == 'normal'"
            class="col-md-12 noPadding"
            style="margin-bottom: 0 !important"
          >
            <div
              class="col-md-12 filter-box"
              tag="div"
              style="margin-bottom: 0 !important"
            >
              <form class="form" role="form">
                <div class="form-group row" style="margin-bottom: 0 !important">
                  <div v-if="hasAttorney" class="col-md-12 noMargin">
                    <div
                      class="col-md-12 pl-2 form-check form-switch"
                      v-if="1 == 0"
                    >
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="clientRadio"
                          name="clientandCoClientRadio"
                          :value="0"
                          v-model="clientorCoclient"
                        />
                        <label class="form-check-label" for="clientRadio"
                          >Customer</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="clientRadio2"
                          name="clientandCoClientRadio"
                          :value="1"
                          v-model="clientorCoclient"
                        />
                        <label class="form-check-label" for="clientRadio2"
                          >Co-Customer</label
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 col-12">
                    <div class="col-md-12 noMargin">
                      <label class="generalLabel" for="FirstName"
                        >First Name</label
                      >
                    </div>

                    <div class="col-md-12 noMargin">
                      <input
                        id="customerfirstName"
                        autocomplete="nope"
                        type="text"
                        class="generalTextBox"
                        v-model="filterParams.FirstName"
                        placeholder="First Name"
                        v-on:keydown.enter.prevent="handleSearch(true, false)"
                        tabindex="1"
                        style="width: 100%"
                      />
                    </div>
                  </div>

                  <div class="col-md-3 col-12">
                    <div class="col-md-12 noMargin">
                      <label class="generalLabel" for="LastName"
                        >Last Name</label
                      >
                    </div>
                    <div class="col-md-12 noMargin">
                      <input
                        id="customerlastName"
                        autocomplete="nope"
                        type="text"
                        class="generalTextBox"
                        v-model="filterParams.LastName"
                        placeholder="Last Name"
                        v-on:keydown.enter.prevent="handleSearch(true, false)"
                        tabindex="1"
                        style="width: 100%"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 col-12">
                    <div class="col-md-12 noMargin">
                      <label class="generalLabel" for="customerId"
                        >Customer Id</label
                      >
                    </div>
                    <div class="col-md-12 noMargin">
                      <input
                        id="customerCustomerId"
                        autocomplete="nope"
                        type="text"
                        class="generalTextBox"
                        v-model="filterParams.CustomerId"
                        @keypress="keyChange"
                        placeholder="Customer Id"
                        v-on:keydown.enter.prevent="handleSearch(true, false)"
                        tabindex="1"
                        style="width: 100%"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 col-12">
                    <div class="col-md-12 noMargin">
                      <label class="generalLabel" for="SSN">Last 4 SSN</label>
                    </div>
                    <div class="col-md-12 noMargin">
                      <input
                        id="customerLast4ssn"
                        autocomplete="nope"
                        type="text"
                        class="generalTextBox"
                        v-model="filterParams.SSN"
                        v-myMask="{ mask: ['9999'] }"
                        placeholder="SSN"
                        @change="isSSNValid"
                        v-on:keydown.enter.prevent="handleSearch(true, false)"
                        tabindex="1"
                        style="width: 100%"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 col-12">
                    <div class="col-md-12 noMargin">
                      <label class="generalLabel" for="customerFullSsn"
                        >SSN</label
                      >
                    </div>
                    <div class="col-md-12 noMargin">
                      <input
                        id="customerFullSsn"
                        autocomplete="nope"
                        type="text"
                        class="generalTextBox"
                        v-model="filterParams.FullSsn"
                        v-myMask="{ mask: ['999-99-9999'] }"
                        placeholder="SSN"
                        @change="isFullSSNValid"
                        v-on:keydown.enter.prevent="handleSearch(true, false)"
                        tabindex="1"
                        style="width: 100%"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 col-12">
                    <div class="col-md-12 noMargin">
                      <label class="generalLabel" for="customerPhoneInput"
                        >Phone</label
                      >
                    </div>
                    <div class="col-md-12 noMargin">
                      <input
                        id="customerPhoneInput"
                        autocomplete="nope"
                        type="text"
                        class="generalTextBox"
                        v-model="filterParams.Phone"
                        v-myMask="{ mask: ['(999) 999-9999'] }"
                        @change="phoneChanged"
                        placeholder="Phone"
                        v-on:keydown.enter.prevent="handleSearch(true, false)"
                        tabindex="1"
                        style="width: 100%"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 col-12">
                    <div class="col-md-12 noMargin">
                      <label class="generalLabel" for="Email">Email</label>
                    </div>
                    <div class="col-md-12 noMargin">
                      <input
                        autocomplete="nope"
                        type="email"
                        id="customerEmail"
                        class="generalTextBox"
                        v-model="filterParams.Email"
                        placeholder="Email"
                        v-on:keydown.enter.prevent="handleSearch(true, false)"
                        tabindex="1"
                        style="width: 100%"
                      />
                    </div>
                  </div>
                  <div v-if="clientorCoclient == 0" class="col-md-3 col-12">
                    <div class="col-md-12 noMargin">
                      <label class="generalLabel" for="Phone">Timezone</label>
                    </div>
                    <div class="col-md-12 noMargin">
                      <VueMultiselect
                        track-by="Id"
                        label="Name"
                        placeholder="Please Select"
                        v-model="filterParams.Timezone"
                        :options="timezoneList"
                        :close-on-select="false"
                        :multiple="true"
                        :preselect-first="true"
                        tabindex="1"
                        @select="timezoneSelected"
                      >
                      </VueMultiselect>
                    </div>
                  </div>
                  <div class="col-md-3 col-12">
                    <div class="col-md-12 noMargin">
                      <label class="generalLabel" for="AssignedTo"
                        >Sales Users</label
                      >
                    </div>
                    <div class="col-md-12 noMargin">
                      <VueMultiselect
                        track-by="Id"
                        label="Name"
                        placeholder="Please Select"
                        v-model="filterParams.AssignedTo"
                        :options="assignedAgentList"
                        :close-on-select="false"
                        :multiple="true"
                        :disabled="!isMultiselectEnabled()"
                        tabindex="1"
                        @select="assignedAgentSelected"
                      >
                      </VueMultiselect>
                    </div>
                  </div>
                  <div class="col-md-3 col-12">
                    <div class="col-md-12 noMargin">
                      <label class="generalLabel" for="Status">Status</label>
                    </div>
                    <div class="col-md-12 noMargin">
                      <VueMultiselect
                        trackBy="Id"
                        label="Name"
                        style="font-size: 13px !important"
                        placeholder="Please Select"
                        :multiple="true"
                        :close-on-select="false"
                        v-model="filterParams.Status"
                        :preselect-first="true"
                        :options="customerStatusList"
                        @select="statusSelected"
                        tabindex="1"
                      >
                      </VueMultiselect>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="col-md-12 noMargin">
                      <label class="generalLabel" for="Campaign"
                        >Campaign</label
                      >
                    </div>
                    <div class="col-md-12 row noMargin noPadding">
                      <div
                        :class="/*filterParams.CampaignId > 0
                            ? */ 'col-md-10 ' /*
                            : 'col-md-12 '*/"
                      >
                        <select
                          v-model="filterParams.CampaignId"
                          class="form-control"
                          id="clientSearchCampaign"
                          tabindex="1"
                        >
                          <option value="">Select Campaign</option>
                          <option
                            v-for="item in activeCampaigns"
                            :key="item.Id"
                            :value="item.Id"
                          >
                            {{ item.Name }}
                          </option>
                        </select>
                      </div>
                      <div
                        class="col-md-2"
                        v-if="true /*filterParams.CampaignId > 0*/"
                      >
                        <div
                          v-if="true /*filterParams.CampaignId > 0*/"
                          class="campaignLeadButton"
                          v-click-outside="hideCampaignLeadPanel"
                        >
                          <span
                            class="campaignLeadIcon"
                            style="background-color: #3bafda"
                          >
                            <i
                              style="
                                color: #ffffff;
                                font-size: 20px;
                                top: 10px;
                                position: relative;
                              "
                              @click="openCampaignLeadPanel"
                              class="fe-users"
                            ></i>
                          </span>
                          <CampaignLeadPanel
                            :campaignId="filterParams.CampaignId"
                            :fromCustomerSearch="true"
                            v-if="campaignLeadPanelVisible"
                          ></CampaignLeadPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="clientorCoclient == 0" class="col-md-3 col-12">
                    <div class="col-md-12 noMargin">
                      <label class="generalLabel" for="Status">State</label>
                    </div>
                    <div class="col-md-12 noMargin">
                      <VueMultiselect
                        trackBy="Id"
                        label="Name"
                        placeholder="Please Select"
                        :multiple="true"
                        :close-on-select="false"
                        v-model="filterParams.State"
                        :options="StateList"
                        :preselect-first="true"
                        @select="stateSelected"
                        tabindex="1"
                      >
                      </VueMultiselect>
                    </div>
                  </div>

                  <div class="col-md-3 noMargin">
                    <div class="col-md-12 noMargin">
                      <label class="generalLabel">Created Date</label>
                    </div>
                    <div class="col-md-12 row noMargin noPadding">
                      <div class="col-md-12">
                        <select
                          v-model="filterParams.DateOption"
                          class="form-control"
                          style="width: 100% !important"
                        >
                          <option value="null">Select Date</option>
                          <option
                            v-for="item in dateOptions"
                            :key="item.key"
                            :value="item.key"
                          >
                            {{ item.label }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 noMargin">
                    <div class="col-md-12 noMargin">
                      <label class="generalLabel">Office</label>
                    </div>
                    <div class="col-md-12 row noMargin noPadding">
                      <div class="col-md-12 noMargin">
                        <select
                          v-model="filterParams.OfficeId"
                          class="form-control"
                          style="width: 100% !important"
                          :class="
                            hasAccessOtherOffices || hasAccessSubOffices
                              ? ''
                              : 'readonlyInput'
                          "
                          :disabled="
                            !(hasAccessOtherOffices || hasAccessSubOffices)
                          "
                        >
                          <option
                            v-if="hasAccessOtherOffices || hasAccessSubOffices"
                            :value="null"
                          >
                            All
                          </option>
                          <option
                            v-for="item in authorizedOffices"
                            :key="item.Id"
                            :value="item.Id"
                          >
                            {{ item.Name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group orderAndAppyButton">
                      <div>
                        <button
                          type="button"
                          @click="handleSearch(true, false)"
                          :disabled="applyingFilter"
                          class="btn btn-primary"
                          style="border-radius: 8px"
                        >
                          Apply All Filters
                          <i
                            v-if="applyingFilter"
                            style="font-size: 15px"
                            class="spinner-border spinner-border-sm"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 noMargin row">
                    <div
                      class="noMargin"
                      :class="
                        filterParams.DateOption === 'specificDate'
                          ? 'col-md-12 '
                          : 'col-md-6  '
                      "
                      v-if="
                        filterParams.DateOption === 'customDateRange' ||
                        filterParams.DateOption === 'specificDate'
                      "
                    >
                      <label class="generalLabel" for="">Start Date</label>
                      <DatePicker
                        mode="date"
                        :locale="'en-US'"
                        v-model="filterParams.StartDate"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <input
                            autocomplete="off"
                            class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                            :value="inputValue"
                            v-on="inputEvents"
                            placeholder="Start Date"
                          />
                        </template>
                      </DatePicker>
                    </div>
                    <div
                      class="col-md-6 noMargin"
                      v-if="filterParams.DateOption === 'customDateRange'"
                    >
                      <label class="generalLabel" for="">End Date</label>
                      <DatePicker
                        mode="date"
                        :locale="'en-US'"
                        v-model="filterParams.EndDate"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <input
                            autocomplete="off"
                            class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                            :value="inputValue"
                            v-on="inputEvents"
                            placeholder="End Date"
                          />
                        </template>
                      </DatePicker>
                    </div>
                  </div>

                  <!-- <div
                    class="col-md-3 col-12"
                    v-if="filterParams.CampaignId > 0"
                  >
                    <div class="col-md-12">
                      <label class="generalLabel" for="campaignCode"
                        >Reference Code</label
                      >
                    </div>
                    <div class="col-md-12">
                      <input
                        v-model="filterParams.CampaignCode"
                        id="campaignCode"
                        autocomplete="nope"
                        type="text"
                        class="generalTextBox"
                        tabindex="1"
                        style="width: 100%"
                      />
                    </div>
                  </div> -->

                  <div class="col-md-3 col-12" v-if="false">
                    <div class="col-md-12">
                      <label class="generalLabel" for="Status">Order By</label>
                    </div>
                    <div class="col-md-12">
                      <select class="form-control">
                        <i class="fas fa-angle-down" aria-hidden="true"></i>
                        <option value="0">Created Date New > Old</option>
                        <option value="1">Created Date Old > New</option>
                        <option value="2">State A > Z</option>
                        <option value="3">State Z > A</option>
                        <option value="4">Status A > Z</option>
                        <option value="5">Status Z > A</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div v-for="(item, index) in tabs" :key="item.id">
          <KeepAlive :max="8">
            <EditClient
              :id="'customer-' + item.id"
              :customerId="item.id"
              :activeTabId="activeTabId"
              :key="item.id"
              v-show="item.active"
              :ref="'customer-' + item.id"
              @handleSearch="handleSearch"
              @updateTabName="tabs[index].label = $event"
              @updateCustomerId="tabs[index].id = $event"
              @forceTabClose="forceTabClose"
            ></EditClient>
          </KeepAlive>
        </div>

        <div class="row noPadding">
          <div
            v-show="customersTab"
            class="col-md-12 noPadding noMargin table-responsive"
            style="min-height: 400px; position: relative"
          >
            <div
              v-if="view === 'normal' && list.Customers.length > 0"
              style="position: absolute; top: 1em; left: 8px; z-index: 40"
            >
              <input
                @change="selectAllCustomers()"
                :checked="allCustomersChecked"
                type="checkbox"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                  float: left;
                  margin-top: 2px;
                "
              />
            </div>
            <template v-if="view === 'normal'">
              <div
                v-if="refreshingGrid"
                class="policyLoader noPadding"
                style="left: -12px; width: 100%"
              >
                <div class="lds-grid">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div class="col-md-12 noPadding noMargin">
                <table
                  v-if="list.Customers.length"
                  class="datagrid-table table table-striped table-sm mb-0"
                >
                  <thead>
                    <tr>
                      <th
                        :class="{ sortable: item.sortable }"
                        @click="() => handleSorting(item, index, false)"
                        v-for="(item, index) in !isMobile
                          ? Columns
                          : Columns.filter((x) => x.label != 'Timezone')
                              .filter((x) => x.label != 'Phone')
                              .filter((x) => x.label != 'Email')
                              .filter((x) => x.label != 'Created')"
                        :key="item.id"
                      >
                        <span
                          class="title"
                          :class="{ active: item.activeSort }"
                          >{{ item.label }}</span
                        >
                        <template v-if="item.sortable">
                          <i
                            v-if="item.activeSort === 'desc'"
                            class="fas fa-sort-down"
                          ></i>
                          <i
                            v-if="item.activeSort === 'asc'"
                            class="fas fa-sort-up"
                          ></i>
                          <i v-if="!item.activeSort" class="fas fa-sort"></i>
                        </template>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in list.Customers"
                      :key="index"
                      v-on:dblclick.prevent="
                        () => handleEdit(item.Id, item.Name)
                      "
                      style="cursor: pointer"
                    >
                      <td
                        style="
                          width: 10px !important;
                          padding: 0 0 0 8px !important;
                        "
                      >
                        <input
                          type="checkbox"
                          @change="selectCustomer(item.Id)"
                          :checked="isCustomerSelected(item.Id)"
                          class="checkbox-input generalCheckBox"
                          style="
                            border-radius: 4px !important;
                            border: solid 1px #d1d1d1 !important;
                            float: left;
                            margin-top: 2px;
                          "
                        />
                      </td>
                      <td>
                        <span
                          @click.prevent.stop="handleEdit(item.Id, item.Name)"
                          :class="
                            getTagReportHyperLinkClass(item.Id && item.Name)
                          "
                        >
                          {{ addPrefixToCustomerId(item.Id) }}
                        </span>
                      </td>
                      <td>{{ item.Name }}</td>
                      <td>
                        <span
                          :style="{ backgroundColor: item.StatusColor }"
                          class="badge badgeStatusPadding"
                          >{{ item.StatusName }}</span
                        >
                      </td>
                      <td v-if="!isMobile">
                        <button
                          type="button"
                          v-if="item.Phone.length > 0 && checkAuth(6231)"
                          class="btn btn-link waves-effect"
                          @click="openSms(item.Phone, item.Id)"
                          style="background-color: #42cfe7"
                        >
                          <i class="fas fa-sms"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-link waves-effect"
                          style="background-color: #24d15f"
                          v-if="item.Phone.length > 0 && hasPhone == true"
                          @click="makeCall(item.Phone, item.Id)"
                        >
                          <i class="fas fa-phone-alt"></i>
                        </button>
                        {{ formatPhone(item.Phone) }}
                      </td>
                      <td v-if="!isMobile">
                        <button
                          type="button"
                          v-if="false"
                          class="btn btn-link waves-effect"
                          style="background-color: #ccab11"
                        >
                          <i class="fe-mail"></i>
                        </button>
                        {{ item.Email }}
                      </td>
                      <td>{{ this.formatMoney(item.DebtAmount) }}</td>
                      <td>{{ item.AssignedTo }}</td>
                      <td>{{ item.Office }}</td>
                      <td>{{ item.State }}</td>
                      <td v-if="!isMobile">{{ item.TimeZone }}</td>

                      <td v-if="!isMobile">
                        {{
                          item.CreatedDate ? formatDate(item.CreatedDate) : ""
                        }}
                      </td>
                      <td>{{ item.CampaignName }}</td>
                      <td
                        width="170px"
                        class="actions"
                        style="position: relative"
                        @mouseleave="last5Leave()"
                      >
                        <button
                          type="button"
                          title="Edit"
                          class="btn btn-link waves-effect"
                          style="background-color: #24d171"
                          v-on:click.prevent.stop="
                            () => handleEdit(item.Id, item.Name)
                          "
                        >
                          <i class="ri-edit-line"></i>
                        </button>
                        <button
                          type="button"
                          title="Delete"
                          class="btn btn-link waves-effect"
                          style="background-color: #ed3030"
                          v-if="hasDeleteAccess"
                          v-on:click.prevent.stop="
                            () => handleDelete(item.Id, null)
                          "
                        >
                          <i class="fe-trash-2"></i>
                        </button>
                        <button
                          v-if="!isMobile"
                          type="button"
                          title="Reminders"
                          class="btn btn-link waves-effect"
                          style="background-color: #72afe1"
                          @mouseover="last5ReminderHover(item.Id, $event)"
                          v-on:click.prevent.stop="
                            () => openReminderPopup(item.Id, item.Phone)
                          "
                        >
                          <i class="fe-clock"></i>
                        </button>
                        <button
                          v-if="!isMobile"
                          type="button"
                          title="Dispositions"
                          class="btn btn-link waves-effect"
                          style="background-color: #8a9197"
                          v-on:click.prevent.stop="
                            () => openDispositionPopup(item.Id, item.StatusId)
                          "
                          @mouseover="last5DispositionHover(item.Id, $event)"
                        >
                          <i class="ri-sound-module-fill"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="col-md-12 mt-2 noPadding">
                  <div v-if="list.Customers.length">
                    <div class="pagingPanel float-right">
                      <button
                        type="button"
                        :disabled="!checkNext(currentPage + 1)"
                        @click="() => gotoPage(lastPage)"
                        class="pagingIcon float-right"
                      >
                        <i class="fe-chevrons-right"></i>
                      </button>
                      <button
                        type="button"
                        :disabled="!checkNext(currentPage + 1)"
                        @click="() => gotoPage(currentPage + 1)"
                        class="pagingIcon float-right"
                      >
                        <i class="fe-chevron-right"></i>
                      </button>
                      <div class="pagingPanel">
                        <input
                          type="text"
                          id="pageNumBottom"
                          class="form-control pagination-num float-left pagingTextBox"
                          v-model="pageNum"
                          v-on:keydown.enter.prevent="() => gotoPage()"
                        />
                        <span class="pagingText">of {{ lastPage }}</span>
                      </div>

                      <button
                        type="button"
                        :disabled="!checkPrev(currentPage - 1)"
                        @click="() => gotoPage(currentPage - 1)"
                        class="pagingIcon float-right"
                      >
                        <i class="fe-chevron-left"></i>
                      </button>
                      <button
                        type="button"
                        :disabled="!checkPrev(currentPage - 1)"
                        @click="() => gotoPage(1)"
                        class="pagingIcon float-right"
                      >
                        <i class="fe-chevrons-left"></i>
                      </button>
                    </div>

                    <div class="pagingPanel float-right">
                      <input
                        type="text"
                        id="pageSizeBottom"
                        class="form-control pagination-num float-left pagingTextBox"
                        v-model="pageSize"
                        v-on:keydown.enter.prevent="handleSearch(false, false)"
                        @change="checkPageSizeCtrl()"
                      />
                      <span class="pagingText">per page</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="list.Customers.length === 0 && isSearchTriggered"
                class="alert alert-warning mt-3"
                role="alert"
              >
                There is no data to show!
              </div>
            </template>
            <template v-if="view === 'priority'">
              <div
                v-if="refreshingGrid"
                class="policyLoader noPadding"
                style="left: -12px; width: 100%"
              >
                <div class="lds-grid">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div
                v-for="(item, index) in priority"
                :key="index"
                :class="{ expanded: item.Toggle }"
                class="priority-wrapper table-responsive"
              >
                <div
                  @click="() => handleToggle(item, index)"
                  class="priority-header clearfix"
                  style="padding: 12px 8px"
                >
                  <i
                    style="float: left; margin-top: 3px; color: #6a6c71"
                    :class="{
                      'fe-chevron-up': item.Toggle,
                      'fe-chevron-down': !item.Toggle,
                    }"
                  />
                  <span
                    :style="{ backgroundColor: item.StatusColor }"
                    class="float-left badge badgeStatusPadding"
                    >{{ item.StatusName }}</span
                  >
                  <span class="priorityText">{{
                    item.StatusPriorityText
                  }}</span>
                  <!-- <span class="float-left">{{ item.description }}></span> -->
                </div>
                <table class="datagrid-table table table-striped table-sm mb-0">
                  <thead>
                    <tr>
                      <th
                        v-for="(item, index) in !isMobile
                          ? Columns.filter((x) => x.label != 'Status')
                          : Columns.filter((x) => x.label != 'Status')
                              .filter((x) => x.label != 'Timezone')
                              .filter((x) => x.label != 'Phone')
                              .filter((x) => x.label != 'Email')
                              .filter((x) => x.label != 'Created')"
                        :key="index"
                      >
                        {{ item.label }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(itemC, index) in item.Customers"
                      :key="index"
                      v-on:dblclick.prevent="
                        () => handleEdit(itemC.Id, itemC.Name)
                      "
                      style="cursor: pointer"
                    >
                      <td></td>
                      <td>
                        <span
                          @click.prevent.stop="handleEdit(itemC.Id, itemC.Name)"
                          :class="
                            getTagReportHyperLinkClass(itemC.Id && itemC.Name)
                          "
                        >
                          {{ addPrefixToCustomerId(itemC.Id) }}
                        </span>
                      </td>
                      <td>{{ itemC.Name }}</td>

                      <td v-if="!isMobile">
                        <button
                          type="button"
                          class="btn btn-link waves-effect"
                          style="background-color: #3bafda"
                          v-if="itemC.Phone.length > 0 && checkAuth(6231)"
                          @click="openSms(itemC.Phone, itemC.Id)"
                        >
                          <i class="fas fa-sms"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-link waves-effect"
                          style="background-color: #24d15f"
                          v-if="itemC.Phone.length > 0 && hasPhone == true"
                          @click="makeCall(itemC.Phone, itemC.Id)"
                        >
                          <i class="fas fa-phone-alt"></i>
                        </button>
                        {{ formatPhone(itemC.Phone) }}
                      </td>
                      <td v-if="!isMobile">
                        <button
                          v-if="false"
                          type="button"
                          class="btn btn-link waves-effect"
                          style="background-color: #ccab11"
                        >
                          <i class="fe-mail"></i>
                        </button>
                        {{ itemC.Email }}
                      </td>
                      <td>{{ this.formatMoney(itemC.DebtAmount) }}</td>
                      <td>{{ itemC.AssignedTo }}</td>
                      <td>{{ itemC.Office }}</td>
                      <td>{{ itemC.State }}</td>
                      <td v-if="!isMobile">{{ itemC.TimeZone }}</td>

                      <td v-if="!isMobile">
                        {{
                          itemC.CreatedDate ? formatDate(itemC.CreatedDate) : ""
                        }}
                      </td>
                      <td>{{ itemC.CampaignName }}</td>
                      <td
                        width="170px"
                        class="actions"
                        style="position: relative"
                        @mouseleave="last5Leave()"
                      >
                        <button
                          type="button"
                          title="Edit"
                          class="btn btn-link waves-effect"
                          style="background-color: #24d171"
                          v-on:click.prevent.stop="
                            () => handleEdit(itemC.Id, itemC.Name)
                          "
                        >
                          <i class="ri-edit-line"></i>
                        </button>
                        <button
                          type="button"
                          title="Delete"
                          class="btn btn-link waves-effect"
                          style="background-color: #ed3030"
                          v-if="hasDeleteAccess"
                          v-on:click.prevent.stop="
                            () => handleDelete(itemC.Id, item)
                          "
                        >
                          <i class="fe-trash-2"></i>
                        </button>
                        <button
                          v-if="!isMobile"
                          type="button"
                          title="Reminders"
                          class="btn btn-link waves-effect"
                          style="background-color: #72afe1"
                          @mouseover="last5ReminderHover(itemC.Id, $event)"
                          v-on:click.prevent.stop="
                            () => openReminderPopup(itemC.Id, itemC.Phone)
                          "
                        >
                          <i class="fe-clock"></i>
                        </button>
                        <button
                          v-if="!isMobile"
                          type="button"
                          title="Dispositions"
                          class="btn btn-link waves-effect"
                          style="background-color: #8a9197"
                          v-on:click.prevent.stop="
                            () => openDispositionPopup(itemC.Id, itemC.StatusId)
                          "
                          @mouseover="last5DispositionHover(itemC.Id, $event)"
                        >
                          <i class="ri-sound-module-fill"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
            <template v-if="view === 'xchange'">
              <div
                v-if="refreshingGrid"
                class="policyLoader noPadding"
                style="left: -12px; width: 100%"
              >
                <div class="lds-grid">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div
                v-if="
                  xChangeSettings &&
                  xChangeSettings.IsEnabled == true &&
                  xChangeSettings.IsActive == true
                "
                class="alert alert-info mt-1 mb-0"
                role="alert"
                style="
                  border-bottom-left-radius: 0;
                  border-bottom-right-radius: 0;
                "
              >
                <div id="xChangeFilters" class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                    <div style="width: 50px; float: left; margin-top: 8px">
                      <label class="generalLabel" for="xChangeStatus"
                        >Status:</label
                      >
                    </div>
                    <div style="width: 160px; float: left">
                      <select
                        v-model="xChangeFilters.StatusId"
                        id="xChangeStatus"
                        class="generalSelectBox ml-2"
                      >
                        <option :value="0">Please select</option>
                        <option
                          v-for="item in filteredCustomerStatuses"
                          :key="item.Id"
                          :value="item.Id"
                        >
                          {{ item.Name }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="ml-4"
                      style="width: 50px; float: left; margin-top: 8px"
                    >
                      <label class="generalLabel" for="xChangeState"
                        >State:</label
                      >
                    </div>
                    <div style="width: 150px; float: left">
                      <select
                        v-model="xChangeFilters.StateId"
                        id="xChangeState"
                        class="generalSelectBox ml-2"
                      >
                        <option :value="0">Please select</option>
                        <option
                          v-for="item in states"
                          :key="item.Id"
                          :value="item.Id"
                        >
                          {{ item.Name }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="ml-4"
                      style="width: 160px; float: left; margin-top: 8px"
                    >
                      <label class="generalLabel" for="xChangeState"
                        >Created Date between:</label
                      >
                    </div>
                    <div style="width: 120px; float: left">
                      <DatePicker
                        mode="date"
                        :locale="'en-US'"
                        v-model="xChangeFilters.DtStart"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <input
                            autocomplete="off"
                            class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                            :value="inputValue"
                            v-on="inputEvents"
                            placeholder="Start Date"
                          />
                        </template>
                      </DatePicker>
                    </div>
                    <div style="width: 40px; float: left; margin-top: 8px">
                      <label class="generalLabel ml-1 mr-1" for="xChangeState"
                        >and</label
                      >
                    </div>
                    <div style="width: 120px; float: left">
                      <DatePicker
                        mode="date"
                        :locale="'en-US'"
                        v-model="xChangeFilters.DtEnd"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <input
                            autocomplete="off"
                            class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                            :value="inputValue"
                            v-on="inputEvents"
                            placeholder="End Date"
                          />
                        </template>
                      </DatePicker>
                    </div>
                    <div style="width: 130px; float: left; margin-top: 3px">
                      <button
                        @click="clearXchangeFilters()"
                        type="button"
                        class="btn btn-link waves-effect generalFont"
                        style="color: #000000; font-size: 13px"
                      >
                        <u>Clear All Filters</u>
                      </button>
                    </div>
                    <div style="width: 130px; float: left">
                      <button
                        @click="refreshXchangeGrid"
                        class="btn btn-success float-left ml-2"
                        style="border-radius: 8px"
                      >
                        <i
                          v-if="!refreshingGrid"
                          style="float: left; margin: 1px 3px; font-size: 13px"
                          class="ri-refresh-line"
                        ></i>
                        <i
                          v-if="refreshingGrid"
                          style="float: left; margin: 1px 3px; font-size: 13px"
                          class="ri-refresh-fill"
                        ></i>
                        Refresh
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 noPadding noMargin">
                <table
                  v-if="xChangeCustomers && xChangeCustomers.length > 0"
                  class="datagrid-table table table-striped table-sm mb-0"
                >
                  <thead>
                    <tr>
                      <th
                        :class="{ sortable: item.sortable }"
                        @click="() => handleSorting(item, index, true)"
                        v-for="(item, index) in xChangeColumns"
                        :key="item.id"
                      >
                        <span
                          class="title"
                          :class="{ active: item.activeSort }"
                          >{{ item.label }}</span
                        >
                        <template v-if="item.sortable">
                          <i
                            v-if="
                              item.activeSort === 'desc' &&
                              item.name == xChangeDefaultSorting
                            "
                            class="fas fa-sort-down"
                          ></i>
                          <i
                            v-if="
                              item.activeSort === 'asc' &&
                              item.name == xChangeDefaultSorting
                            "
                            class="fas fa-sort-up"
                          ></i>
                          <i
                            v-if="item.name != xChangeDefaultSorting"
                            class="fas fa-sort"
                          ></i>
                        </template>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in xChangeCustomers"
                      :key="index"
                      style="cursor: pointer"
                    >
                      <td>
                        <span
                          @click.prevent.stop="
                            handleEdit(
                              item.CustomerId,
                              item.FirstName + ' ' + item.LastName
                            )
                          "
                          :class="
                            getTagReportHyperLinkClass(
                              item.CustomerId && item.FirstName && item.LastName
                            )
                          "
                        >
                          {{ addPrefixToCustomerId(item.CustomerId) }}
                        </span>
                      </td>
                      <td>
                        <span
                          :style="{ backgroundColor: item.PrevStatusColor }"
                          class="badge badgeStatusPadding"
                          >{{ item.PrevStatusName }}</span
                        >
                      </td>
                      <td>
                        {{ item.FirstName }}
                      </td>
                      <td>
                        {{ item.LastName }}
                      </td>
                      <td>
                        {{ item.OfficeName }}
                      </td>
                      <td>
                        {{ item.DebtAmount }}
                      </td>
                      <td>
                        {{ item.State }}
                      </td>
                      <td>
                        {{ item.CreatedDate }}
                      </td>
                      <td>
                        <button
                          type="button"
                          title="Assign Customer"
                          class="btn btn-link waves-effect"
                          v-on:click.prevent.stop="
                            () => assignXchangeCustomer(item)
                          "
                          style="background-color: #42cfe7"
                        >
                          <i class="ri-shuffle-fill"></i>
                        </button>
                      </td>
                      <td
                        width="100px"
                        class="actions"
                        style="position: relative"
                        @mouseleave="last5Leave()"
                      >
                        <button
                          v-if="!isMobile"
                          type="button"
                          title="Reminders"
                          class="btn btn-link waves-effect"
                          style="background-color: #72afe1"
                          @mouseover="
                            last5ReminderHover(item.CustomerId, $event)
                          "
                        >
                          <i class="fe-clock"></i>
                        </button>
                        <button
                          v-if="!isMobile"
                          type="button"
                          title="Dispositions"
                          class="btn btn-link waves-effect"
                          style="background-color: #8a9197"
                          @mouseover="
                            last5DispositionHover(item.CustomerId, $event)
                          "
                        >
                          <i class="ri-sound-module-fill"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <!-- <div class="col-md-12 mt-2 noPadding">
                  <div v-if="list.Customers.length">
                    <div class="pagingPanel float-right">
                      <button
                        type="button"
                        :disabled="!checkNext(currentPage + 1)"
                        @click="() => gotoPage(lastPage)"
                        class="pagingIcon float-right"
                      >
                        <i class="fe-chevrons-right"></i>
                      </button>
                      <button
                        type="button"
                        :disabled="!checkNext(currentPage + 1)"
                        @click="() => gotoPage(currentPage + 1)"
                        class="pagingIcon float-right"
                      >
                        <i class="fe-chevron-right"></i>
                      </button>
                      <div class="pagingPanel">
                        <input
                          type="text"
                          id="pageNumBottom"
                          class="
                            form-control
                            pagination-num
                            float-left
                            pagingTextBox
                          "
                          v-model="pageNum"
                          v-on:keydown.enter.prevent="() => gotoPage()"
                        />
                        <span class="pagingText">of {{ lastPage }}</span>
                      </div>

                      <button
                        type="button"
                        :disabled="!checkPrev(currentPage - 1)"
                        @click="() => gotoPage(currentPage - 1)"
                        class="pagingIcon float-right"
                      >
                        <i class="fe-chevron-left"></i>
                      </button>
                      <button
                        type="button"
                        :disabled="!checkPrev(currentPage - 1)"
                        @click="() => gotoPage(1)"
                        class="pagingIcon float-right"
                      >
                        <i class="fe-chevrons-left"></i>
                      </button>
                    </div>

                    <div class="pagingPanel float-right">
                      <input
                        type="text"
                        id="pageSizeBottom"
                        class="
                          form-control
                          pagination-num
                          float-left
                          pagingTextBox
                        "
                        v-model="pageSize"
                        v-on:keydown.enter.prevent="handleSearch(false, false)"
                        @change="checkPageSizeCtrl()"
                      />
                      <span class="pagingText">per page</span>
                    </div>
                  </div>
                </div> -->
              </div>
              <div
                v-if="
                  xChangeSettings &&
                  xChangeSettings.IsActive &&
                  xChangeCustomers &&
                  xChangeCustomers.length == 0
                "
                class="alert alert-warning mt-1"
                role="alert"
              >
                There is no data to show!
              </div>
              <div
                v-if="
                  xChangeSettings &&
                  (xChangeSettings.IsEnabled == false ||
                    (xChangeSettings.IsEnabled == true &&
                      xChangeSettings.IsActive == false))
                "
                class="alert alert-danger mt-1"
                role="alert"
              >
                <span v-if="xChangeSettings.IsEnabled == false">
                  The XChange is closed today, come back later.
                </span>
                <span
                  v-if="
                    xChangeSettings.IsEnabled == true &&
                    xChangeSettings.IsActive == false
                  "
                >
                  The XChange is open today between
                  {{ this.formatTime12Hours(xChangeSettings.StartTime) }} and
                  {{ this.formatTime12Hours(xChangeSettings.EndTime) }}
                  {{ xChangeSettings.TimeZone }}
                </span>
              </div>

              <div
                v-if="!xChangeSettings"
                class="alert alert-danger mt-1"
                role="alert"
              >
                There is no xChange setting for your office.
              </div>
            </template>
          </div>
        </div>
        <div v-if="dispositionCustomerId > 0" class="backgroundLock">
          <div
            style="position: relative; margin: 10% auto"
            class="col-xl-5 col-lg-8 col-md-12 col-sm-12"
          >
            <div class="reminderClose" @click="closeDispositionPopup()">
              <i class="fas fa-window-close"></i>
            </div>
            <DispositionPanel
              :customerId="dispositionCustomerId"
              :statusId="dispositionStatusId"
              :fromEdit="0"
            ></DispositionPanel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import deepCopy from "@/helpers/deepCopy";
import globalTypes from "@/store/types";
import types from "./types";
import clientTypes from "@/views/agent/EditClient/types";
import { mapState } from "vuex";
import VueMultiselect from "vue-multiselect";
import DispositionPanel from "@/views/agent/Disposition/DispositionPanel";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import Last5Dispositions from "@/components/Last5Dispositions";
import Last5Reminders from "@/components/Last5Reminders";
import _orderby from "lodash";
import { DatePicker } from "v-calendar";
import EditClient from "@/views/agent/EditClient/EditClient";
import CampaignLeadPanel from "@/components/PlivoParts/Popups/CampaignLeadPanel";

const filterParams = {
  FirstName: "",
  LastName: "",
  SSN: "",
  Status: [],
  State: [],
  Email: "",
  Phone: "",
  Timezone: [],
  AssignedTo: [],
  CustomerId: "",
  CampaignId: "",
  DateOption: null,
  StartDate: null,
  EndDate: null,
  OfficeId: null,
  FullSsn: "",
  //CampaignCode: "",
};
const tabObj = {
  id: 0,
  label: "",
  iconName: "fas fa-user",
  active: true,
  closeIcon: "fe-x",
  isDataChanged: false,
};
const allagentsObj = { Id: "", Name: "All Agents" };
const allStatusObj = { Id: "", Name: "All Statuses" };
const allTimeZonesObj = {
  Id: "",
  Name: "All Timezones",
  ShortName: "All Timezones",
  UiOrder: 0,
};
const allStatesObj = { Id: "", Name: "All States", ShortCode: "" };
let stopTimer = false;
let customerGridTimer = null;
export default {
  name: "Clients-c",
  components: {
    EditClient,
    DispositionPanel,
    Last5Dispositions,
    Last5Reminders,
    DatePicker,
    VueMultiselect,
    CampaignLeadPanel,
  },
  mixins: [formatMixin, utilitiesMixin],

  data() {
    return {
      hasAttorney: false,
      clientorCoclient: 0,
      hasPhone: false,
      customerPrefix: null,
      view: "priority",
      pageNum: 1,
      currentPage: 1,
      lastPage: 100,
      pageSize: 20,
      allowedKeyCodes: [48, 49, 50, 51, 52, 53, 54, 55, 56, 57],
      allowedKeyCodesAll: [
        48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 65, 66, 67, 68, 69, 70, 71, 72,
        73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90,
        97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111,
        112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122,
      ],
      assignedAgentList: [],
      isSearchTriggered: false,
      customerStatusList: [],
      StateList: [],
      timezoneList: [],
      currentSort: {
        col: "",
        sort: "",
      },
      query: {},
      totalRecords: 0,
      assignedAgendDisplayText: "",
      statusDisplayText: "",
      timezoneDisplayText: "",
      searchToggled: false,
      activeFilters: [],
      filterParams: deepCopy(filterParams),
      hasDeleteAccess: true,
      activeTabId: -1,
      PriorityList: [
        {
          id: 1,
          name: "newLead",
          title: "New Lead",
          description: "Call ASAP and leave VM & SMS",
          StatusColor: "#0c9463",
        },
        {
          id: 2,
          name: "callbackScheduled",
          title: "Callback Scheduled",
          description: "Call to follow up, check up notes before calling",
          StatusColor: "#1b79c7",
        },
        {
          id: 3,
          name: "attemting",
          title: "Attemting 2nd",
          description: "Send introduction and good reviews email",
          StatusColor: "#fe7f08",
        },
        {
          id: 4,
          name: "contacted",
          title: "Contacted 3nd",
          description: "Call & 3rd attempt SMS",
          StatusColor: "#f65c78",
        },
      ],
      Columns: [
        { id: 1, name: "name", label: "", sortable: false },
        { id: 2, name: "id", label: "Id", sortable: false },
        { id: 3, name: "name", label: "Name", sortable: true },
        { id: 4, name: "status", label: "Status", sortable: true },
        { id: 5, name: "phone", label: "Phone", sortable: false },
        { id: 6, name: "email", label: "Email", sortable: false },
        { id: 7, name: "debtamount", label: "Debt Amount", sortable: true },
        {
          id: 8,
          name: "assignedtoname",
          label: "Assigned To",
          sortable: false,
        },
        { id: 9, name: "office", label: "Office", sortable: false },
        { id: 10, name: "state", label: "State", sortable: false },
        { id: 11, name: "timezonename", label: "Timezone", sortable: false },
        { id: 12, name: "created", label: "Created", sortable: true },
        { id: 13, name: "campaignname", label: "Campaign", sortable: false },
        { id: 14, name: "actions", label: "Actions", sortable: false },
      ],
      customersTab: true,
      Customers: [],
      tabs: [],
      showContextMenu: false,
      contextMenuPositionLeft: 0,
      contextMenuPositionTop: 45,
      closeTabSelection: -1,
      dispositionCustomerId: 0,
      dispositionStatusId: 0,
      hoveredDispositionId: 0,
      hoveredReminderId: 0,
      last5Dispositions: [],
      last5DispoHoverPanelPosition: "top:45px; right:45px;",
      last5DispoHoverPanelHeight: 320,
      last5Reminders: [],
      last5RemindersHoverPanelPosition: "top:45px; right:30px;",
      last5RemindersHoverPanelHeight: 320,
      windowHeight: 0,
      isStriped: true,
      isHoverable: true,
      isLoading: false,
      isEmpty: false,
      isMobile: false,
      applyingFilter: false,
      checkedCustomerIds: [],
      bulkAssingAgentId: 0,
      bulkAssignStatusId: null,
      refreshingGrid: false,
      arrPermission: [],
      campaignFilter: 0,
      hasAccessOtherOffices: false,
      hasAccessSubOffices: false,
      userOfficeId: 0,
      campaignLeadPanelVisible: false,
      dateOptions: [
        {
          key: "today",
          label: "Today",
        },
        {
          key: "yesterday",
          label: "Yesterday",
        },
        {
          key: "thisWeek",
          label: "This Week",
        },
        {
          key: "thisMonth",
          label: "This Month",
        },
        {
          key: "lastMonth",
          label: "Last Month",
        },
        {
          key: "thisYear",
          label: "This Year",
        },
        {
          key: "specificDate",
          label: "Specific Date",
        },
        {
          key: "customDateRange",
          label: "Custom Date Range",
        },
      ],
      exportButtonVisible: false,
      allCustomersChecked: false,
      xChangeCustomers: [],
      xChangeSettings: null,
      xChangeColumns: [
        {
          id: 1,
          name: "id",
          label: "Customer Id",
          sortable: true,
          activeSort: "desc",
        },
        {
          id: 2,
          name: "prevstatus",
          label: "Prev. Status",
          sortable: true,
          activeSort: "asc",
        },
        { id: 3, name: "firstname", label: "First Name", sortable: false },
        { id: 4, name: "lastname", label: "Last Name", sortable: false },
        { id: 8, name: "officename", label: "Office", sortable: true },
        { id: 5, name: "debtamount", label: "Debt Amount", sortable: false },
        {
          id: 6,
          name: "state",
          label: "State",
          sortable: true,
          activeSort: "asc",
        },
        {
          id: 7,
          name: "created",
          label: "Created Date",
          sortable: true,
          activeSort: "desc",
        },
      ],
      xChangeDefaultSorting: "created",
      currentPageXchange: 1,
      xChangeTotalRecords: 0,
      pageSizeXChange: 20,
      lastPageXChange: 100,
      xChangeFilters: {
        PageNumber: 1,
        PageCount: 100,
        OrderBy: "createdDesc",
        StateId: 0,
        StatusId: 0,
        DtStart: null,
        DtEnd: null,
      }
    };
  },
  computed: mapState({
    offices: (state) => state.globals.offices,
    authorizedOffices() {
      return this.filterOfficesByAccessRole(this.offices);
    },
    timezones: (state) => state.globals.timezones,
    list: (state) => state.customers.list,
    priority: (state) => state.customers.priority,
    customerStatuses: (state) => state.globals.customerStatuses,
    filteredCustomerStatuses() {
      return this.customerStatuses.length > 0
        ? this.customerStatuses.filter(
            (x) =>
              x.Name != null && !x.Name.toLowerCase().startsWith("not in use")
          )
        : [];
    },
    favorites: (state) => state.globals.favorites,
    salesAgents: (state) => state.globals.salesAgents,
    filteredAgents() {
      return this.filterAgentsByAccessRole(this.salesAgents);
    },
    states: (state) => state.globals.states,
    campaignList: (state) => state.globals.campaignList,
    activeCampaigns() {
      return this.campaignList.filter((x) => x.IsActive);
    },
  }),

  async mounted() {
    this.isMobile = this.isMobileDevice();

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    let userRoles = "";
    if (userInfo != null) {
      if (
        userInfo.customerIdPrefix != null &&
        userInfo.customerIdPrefix.length > 0
      ) {
        this.customerPrefix = userInfo.customerIdPrefix;
      }

      if (userInfo.hasAttorneyConfig == false) {
        this.hasAttorney = true;
      } else {
        this.hasAttorney = false;
      }
    }

    let phoneSettings = JSON.parse(sessionStorage.getItem("userPhoneSettings"));

    this.hasPhone = phoneSettings && phoneSettings.endPointName != null;

    await Promise.all([
      this.$store.dispatch(globalTypes.GET_OFFICE_NAMES),
      this.$store.dispatch(globalTypes.GET_TIMEZONES),
      this.$store.dispatch(globalTypes.GET_STATUS_NAMES),
      this.$store.dispatch(globalTypes.GET_SALES_AGENTS),
      //this.$store.dispatch(types.GET_CUSTOMERS, { View: 1 }),
      this.$store.dispatch(globalTypes.GET_STATES),
      this.$store.dispatch(globalTypes.GET_CAMPAIGNLIST, {
        Roles: userInfo.roles,
      }),
    ]);

    this.arrPermission = userInfo.moduleFunctions
      .split(",")
      .map(function (item) {
        return parseInt(item, 10);
      });
    if (userInfo != null) {
      this.userOfficeId = userInfo.officeId;
    }
    this.hasAccessOtherOffices = this.canSeeAllOffices();
    this.hasAccessSubOffices = this.canSeeSubOffices();
    if (!this.arrPermission.includes(400)) {
      this.hasDeleteAccess = false;
    }
    if (this.filteredCustomerStatuses) {
      this.customerStatusList = _orderby.orderBy(
        deepCopy(this.filteredCustomerStatuses),
        "Name"
      );
    }

    if (!(this.hasAccessOtherOffices || this.hasAccessSubOffices)) {
      this.filterParams.OfficeId = this.userOfficeId;
    }

    let routeName = this.$router.currentRoute.value.name;
    if (routeName == "customers") {
      if (this.customersTab) {
        this.startTimer();
      }
    }

    this.$Emitter.on("stopCustomerGridTimer", () => {
      this.clearTimer();
    });

    this.$Emitter.on("openGrid", (data) => {
      if (data.HandleNew) {
        this.handleNew();
      } else {
        this.handleTabChange(-1, 0);
      }
    });

    this.$Emitter.on("refreshCustomerGrid", () => {
      this.handleSearch(false, false);
    });

    this.$Emitter.on("refreshXChangeGrid", async (data) => {
      if (data && data.CustomerId > 0) {
        this.xChangeCustomers = this.xChangeCustomers.filter(
          (x) => x.CustomerId != data.CustomerId
        );
      }
    });

    if (this.customerStatusList.length >= 1) {
      this.customerStatusList.unshift(allStatusObj);
    }
    if (this.states) this.StateList = deepCopy(this.states);
    if (this.StateList.length >= 1) {
      this.StateList.unshift(allStatesObj);
    }
    if (this.timezones) this.timezoneList = deepCopy(this.timezones);
    if (this.timezoneList.length >= 1) {
      this.timezoneList.unshift(allTimeZonesObj);
    }
    this.StatusDisplay(this.filterParams.Status);
    let res = await this.$store.dispatch(clientTypes.GETASSIGNEDAGENTS, 0);
    if (res && res.Data) {
      let agents = [];

      agents = this.filterAgentsByAccessRole(res.Data);

      this.assignedAgentList = _orderby.orderBy(agents, "Name");
      if (this.assignedAgentList.length > 1) {
        this.assignedAgentList.unshift({ Id: "0", Name: "Unassigned" });
        this.assignedAgentList.unshift(allagentsObj);
      }
      if (this.assignedAgentList)
        this.filterParams.AssignedTo.push(this.assignedAgentList[0]);
      this.assignedAgentDisplay(this.filterParams.AssignedTo);
      this.handleSearch(false, false);
    }
 

    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  beforeMount() {
    window.removeEventListener("resize", this.onResize);
  },
  unmounted() {
    this.$Emitter.off("refreshCustomerGrid");
    this.$Emitter.off("stopCustomerGridTimer");
  },

  watch: {
    clientorCoclient(newVal) {
      if (newVal == 1) {
        this.filterParams.State = [];
        this.filterParams.Timezone = [];
      }
    },
  },
  methods: {
    forceTabClose(customerId) {
      let indx = this.tabs.findIndex((x) => x.id == customerId);

      if (indx > -1) {
        this.$Emitter.emit("closeActiveCustomer", {
          CustomerId: this.tabs[indx].id,
        });
        this.tabs.splice(indx, 1);
        this.handleTabChange(-1, 0);
      }
    },
    filterOfficesByAccessRole(offices) {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      let subOfficeIds = [];

      if (userInfo != null) {
        subOfficeIds = userInfo.subOffices;
      }

      if (this.hasAccessOtherOffices) {
        return offices;
      } else if (
        this.hasAccessSubOffices &&
        subOfficeIds &&
        subOfficeIds.length > 0
      ) {
        subOfficeIds.push(userInfo.officeId);

        return offices.filter((x) => subOfficeIds.includes(x.Id));
      } else {
        return offices.filter((x) => x.Id == this.userOfficeId);
      }
    },
    filterAgentsByAccessRole(agents) {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      let subOfficeIds = [];

      if (userInfo != null) {
        subOfficeIds = userInfo.subOffices;
      }

      if (this.hasAccessOtherOffices) {
        return agents;
      } else if (
        this.hasAccessSubOffices &&
        subOfficeIds &&
        subOfficeIds.length > 0
      ) {
        subOfficeIds.push(userInfo.officeId);
        return agents.filter((x) => subOfficeIds.includes(x.OfficeId));
      } else {
        return agents.filter((x) => x.OfficeId == this.userOfficeId);
      }
    },

    getActiveTabIds() {
      return this.tabs.filter((x) => x.id > 0).map((x) => x.id);
    },
    async refreshXchangeGrid() {
      await this.getXChangeGrid();
    },
    async clearXchangeFilters() {
      this.xChangeFilters.StatusId = 0;
      this.xChangeFilters.StateId = 0;
      this.xChangeFilters.DtStart = null;
      this.xChangeFilters.DtEnd = null;
      await this.getXChangeGrid();
    },
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    async assignXchangeCustomer(item) {
      await this.$swal({
        title: "The XChange",
        text: "Are you sure to XChange this customer?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (dialog) => {
        if (dialog.value) {
          let err, result;
          [err, result] = await this.$store.dispatch(
            types.ASSIGN_XCHANGE_CUSTOMER,
            { CustomerId: item.CustomerId }
          );
          if (result) {
            if (result.Data) {
              if (result.Data.IsSuccessful) {
                this.$swal("Success", "XChanged successfully.", "success");
                this.xChangeSettings.UserAssigned += 1;
                this.handleEdit(
                  item.CustomerId,
                  item.FirstName + " " + item.LastName
                );
              } else {
                this.$swal("Error", result.Data.Message, "error");
              }
            }
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        }
      });
    },
    async refreshGrid() {
      await this.handleSearch(false, false);
    },
    formatTime12Hours(value) {
      let rtn = "";
      if (value.includes(":")) {
        let arr = value.split(":");
        if (arr.length > 0) {
          rtn = this.getTime12Hours(parseInt(arr[0]), parseInt(arr[1]));
        }
      }
      return rtn;
    },
    async getXChangeGrid(sortDirection = null) {
      let err, result;
      this.refreshingGrid = true;
      this.xChangeFilters.PageNumber = this.currentPageXchange;
      if (sortDirection) {
        this.xChangeFilters.OrderBy =
          this.xChangeDefaultSorting + sortDirection;
      } else {
        this.xChangeDefaultSorting = "created";
        this.xChangeFilters.OrderBy = "createdDesc";
      }

      this.xChangeFilters.PageCount = this.pageSizeXChange;

      [err, result] = await this.$store.dispatch(
        types.GET_XCHANGE_CUSTOMERS,
        this.xChangeFilters
      );
      if (result) {
        if (result.Data && result.Data.Setting) {
          this.xChangeSettings = deepCopy(result.Data.Setting);
        }
        if (result.Data && result.Data.Customers) {
          this.xChangeCustomers = deepCopy(result.Data.Customers);
          if (result.Data.Customers.length > 0) {
            this.xChangeTotalRecords = parseInt(result.Data.Customers[0].Total);
          } else {
            this.xChangeTotalRecords = 0;
          }

          this.lastPageXChange = Math.ceil(
            this.xChangeTotalRecords / this.pageSizeXChange
          );
        }
      }
      this.refreshingGrid = false;
    },
    isAuthorized(authId) {
      let rtn = false;
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

      if (userInfo != null && userInfo.moduleFunctions != null) {
        let arrPermission = userInfo.moduleFunctions
          .split(",")
          .map(function (item) {
            return parseInt(item, 10);
          });

        if (arrPermission.includes(parseInt(authId))) {
          rtn = true;
        }
      }
      return rtn;
    },
    openCampaignLeadPanel() {
      let temp = this.campaignLeadPanelVisible;
      this.hideCampaignLeadPanel();
      this.campaignLeadPanelVisible = !temp;
    },
    hideCampaignLeadPanel() {
      this.campaignLeadPanelVisible = false;
    },
    handleDataSync(data) {
      let customerId =
        data != null && data.CustomerId && data.CustomerId.length > 0
          ? data.CustomerId[0]
          : -1;

      if (customerId > -1) {
        let customerTab = this.$refs["customer-" + data.CustomerId];
        if (typeof customerTab != "undefined" && customerTab) {
          customerTab.dataSyncProcess(
            customerId,
            data.UserId,
            data.UserName,
            data.ObjectType
          );
        }
      }
    },
    hasAuth(mfid) {
      return this.arrPermission.includes(mfid);
    },
    showBulkOperations() {
      let rtn = false;
      if (this.arrPermission.includes(7535)) rtn = true;
      else if (this.arrPermission.includes(7525)) rtn = true;
      return rtn;
    },
    clearTimer() {
      if (customerGridTimer) {
        window.clearInterval(customerGridTimer);
        customerGridTimer = null;
      }
    },
    startTimer() {
      if (customerGridTimer == null && this.isAuthorized(7520)) {
        customerGridTimer = window.setInterval(async () => {
          if (this.view != "xchange") {
            await this.handleSearch(true, true);
          }
        }, 90000);
      }
    },
    keyChange(event) {
      if (!this.allowedKeyCodesAll.includes(event.keyCode)) {
        event.preventDefault();
      }
    },
    async bulkAssignStatus() {
      if (this.bulkAssignStatusId > 0) {
        let statusIndex = this.customerStatuses.findIndex(
          (x) => x.Id == this.bulkAssignStatusId
        );
        let statusName =
          statusIndex > -1 ? this.customerStatuses[statusIndex].Name : "";
        let message =
          this.checkedCustomerIds.length > 0
            ? "Are you sure to change status of " +
              this.checkedCustomerIds.length +
              " client(s) to " +
              statusName +
              "?"
            : "No customer selected!";
        this.$swal
          .fire({
            title: "Confirm",
            text: message,
            type: "warning",
            showCancelButton: this.checkedCustomerIds.length > 0 ? true : false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              if (
                this.checkedCustomerIds.length > 0 &&
                this.bulkAssignStatusId > 0
              ) {
                let payload = {
                  CustomerIds: this.checkedCustomerIds.join(","),
                  StatusId: this.bulkAssignStatusId,
                };

                this.refreshingGrid = true;
                let err,
                  result = await this.$store.dispatch(
                    types.BULK_UPDATE_STATUS,
                    payload
                  );
                this.refreshingGrid = false;
                if (result) {
                  this.$swal("Success", "", "success");
                  this.checkedCustomerIds = [];
                  this.allCustomersChecked = false;
                  this.handleSearch(false, false);
                } else {
                  let errMsg = this.getApiErrorMessage(err);
                  this.$swal("Error!", errMsg, "error");
                }
                this.resetBulkAssignOperations();
              } else {
                this.bulkAssignStatusId = null;
              }
            } else {
              this.bulkAssignStatusId = null;
            }
          });
      }
    },

    async bulkAssignAgent() {
      if (this.bulkAssingAgentId > 0 || this.bulkAssingAgentId == null) {
        let agentIndex = this.salesAgents.findIndex(
          (x) => x.Id == this.bulkAssingAgentId
        );
        let agentName =
          agentIndex > -1 ? this.salesAgents[agentIndex].Name : "";
        let message =
          this.checkedCustomerIds.length > 0
            ? "Are you sure to change the assigned agent of " +
              this.checkedCustomerIds.length +
              " client(s) to " +
              agentName +
              "?"
            : "No customer selected!";
        this.$swal
          .fire({
            title: "Confirm",
            text: message,
            type: "warning",
            showCancelButton: this.checkedCustomerIds.length > 0 ? true : false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              if (
                this.checkedCustomerIds.length > 0 &&
                (this.bulkAssingAgentId > 0 || this.bulkAssingAgentId == null)
              ) {
                let payload = {
                  CustomerIds: this.checkedCustomerIds.join(","),
                  UserId: this.bulkAssingAgentId,
                };

                let err,
                  result = await this.$store.dispatch(
                    types.BULK_UPDATE_AGENT,
                    payload
                  );

                if (result) {
                  this.$swal("Success", "", "success");
                  this.checkedCustomerIds = [];
                  this.allCustomersChecked = false;
                  this.handleSearch(false, false);
                } else {
                  let errMsg = this.getApiErrorMessage(err);
                  this.$swal("Error!", errMsg, "error");
                }

                this.resetBulkAssignOperations();
              } else {
                this.bulkAssingAgentId = 0;
              }
            } else {
              this.bulkAssingAgentId = 0;
            }
          });
      }
    },
    selectCustomer(customerId) {
      let indx = this.checkedCustomerIds.findIndex((x) => x == customerId);
      if (indx > -1) {
        this.checkedCustomerIds.splice(indx, 1);
      } else {
        this.checkedCustomerIds.push(customerId);
      }
    },
    selectAllCustomers() {
      let customerIds = this.list.Customers.map((x) => x.Id);
      this.allCustomersChecked = !this.allCustomersChecked;
      if (customerIds.length == this.checkedCustomerIds.length) {
        this.checkedCustomerIds = [];
      } else {
        this.checkedCustomerIds = [];
        for (let index = 0; index < customerIds.length; index++) {
          this.checkedCustomerIds.push(customerIds[index]);
        }
      }
    },
    isCustomerSelected(customerId) {
      return this.checkedCustomerIds.findIndex((x) => x == customerId) > -1;
    },
    resetBulkAssignOperations() {
      this.bulkAssingAgentId = 0;
      this.bulkAssignStatusId = null;
      this.checkedCustomerIds = [];
      this.allCustomersChecked = false;
    },

    async removeFilter(item) {
      let indx = this.activeFilters.indexOf(item);
      if (indx > -1) {
        let arr = item.split(":");
        if (arr.length > 0) {
          let paramName = arr[0];
          paramName = paramName.replace(/\s/g, "");

          if (paramName == "FirstName") this.filterParams.FirstName = "";
          else if (paramName == "LastName") this.filterParams.LastName = "";
          else if (paramName == "Last4SSN") this.filterParams.SSN = "";
          else if (paramName == "SSN") this.filterParams.FullSsn = "";
          else if (paramName == "CustomerId") this.filterParams.CustomerId = "";
          else if (paramName == "Phone") this.filterParams.Phone = "";
          else if (paramName == "Email") this.filterParams.Email = "";
          else if (paramName == "Co-Customer") this.clientorCoclient = 0;
          else if (paramName == "Timezone") {
            this.filterParams.Timezone = [];
            if (this.timezoneList) {
              this.filterParams.Timezone.push(this.timezoneList[0]);
            }
          } else if (paramName == "AssignedTo") {
            this.filterParams.AssignedTo = [];
            if (this.assignedAgentList) {
              this.filterParams.AssignedTo.push(this.assignedAgentList[0]);
            }
          } else if (paramName == "Status") {
            this.filterParams.Status = [];
            if (this.customerStatusList) {
              this.filterParams.Status.push(this.customerStatusList[0]);
            }
          } else if (paramName == "State") {
            this.filterParams.State = [];
            if (this.StateList) {
              this.filterParams.State.push(this.StateList[0]);
            }
          } else if (paramName == "Campaign") {
            this.filterParams.CampaignId = "";
          } else if (paramName == "DateOption") {
            this.filterParams.DateOption = null;
          } else if (paramName == "StartDate") {
            this.filterParams.StartDate = null;
          } else if (paramName == "EndDate") {
            this.filterParams.EndDate = null;
          } else if (
            paramName == "Office" &&
            (this.hasAccessOtherOffices || this.hasAccessSubOffices)
          ) {
            this.filterParams.OfficeId = null;
          }
        }

        this.activeFilters.splice(indx, 1);
        this.handleSearch(false, false);
      }
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    formatDate(dateStr) {
      let dt = new Date(dateStr);
      let rtn = "";

      let MM = dt.getMonth() + 1;
      rtn += (MM < 10 ? "0" + MM : MM) + "/";

      let DD = dt.getDate();
      rtn += (DD < 10 ? "0" + DD : DD) + "/";

      rtn += dt.getFullYear().toString().substr(-2) + " ";

      let hrs = dt.getHours();
      let tt = "AM";
      if (hrs >= 12) tt = "PM";
      if (hrs > 12) hrs -= 12;
      rtn += hrs + ":";

      let mm = dt.getMinutes();
      rtn += (mm < 10 ? "0" + mm : mm) + tt;

      return rtn;
    },
    makeCall(phone, customerId) {
      if (phone.length >= 10) {
        if (phone.length == 11 && phone.startsWith("1")) {
          phone = phone.slice(1, phone.length);
        }
        this.$Emitter.emit("makeCall", {
          phone: phone,
          customerId: customerId,
        });
      }
    },
    openSms(phone, id) {
      if (phone.length >= 10) {
        this.$Emitter.emit("openSms", { Phones: phone, CustomerId: id });
      }
    },
    closeOtherTabs() {
      if (this.closeTabSelection == -1) {
        this.tabs = [];
        this.tabs.push();
        this.handleTabChange(-1, 0);
      } else {
        let newTabs = this.tabs.filter((x) => x.id == this.closeTabSelection);
        this.tabs = newTabs;
        this.tabs.push();
        this.handleTabChange(this.closeTabSelection, 0);
      }
    },
    rightTabClick(e, customerId) {
      if (customerId == -1) {
        if (this.tabs.length == 0) return;
      } else if (customerId > -1) {
        if (this.tabs.length == 1) return;
      }
      //test
      this.showContextMenu = true;
      this.closeTabSelection = customerId;
      let menu = document.getElementById("contextMenu");

      //menu.style.left = e.target.offsetLeft + "px";
      //let topVal = 50; //e.target.offsetTop + 50;
      //if(topVal > 60) topVal = 60;
      //menu.style.top = topVal + "px";

      if (e.target.offsetParent.offsetLeft)
        this.contextMenuPositionLeft = e.target.offsetParent.offsetLeft;
      else this.contextMenuPositionLeft = 0;

      menu.style.display = "block";
    },
    hideRightTabClick() {
      if (this.showContextMenu) {
        this.showContextMenu = false;
        this.closeTabSelection = -1;
      }
    },
    async last5ReminderHover(itemId) {
      let positioning =
        this.windowHeight - event.clientY > this.last5RemindersHoverPanelHeight
          ? "top:" + event.clientY + "px; "
          : "bottom:" + (this.windowHeight - event.clientY) + "px;";

      this.last5RemindersHoverPanelPosition = positioning + " right:185px;";
      this.hoveredDispositionId = 0;
      this.hoveredReminderId = itemId;
    },
    last5Leave() {
      this.hoveredReminderId = 0;
      this.hoveredDispositionId = 0;
      this.last5Reminders = [];
      this.last5Dispositions = [];
    },
    async last5DispositionHover(itemId, event) {
      let positioning =
        this.windowHeight - event.clientY > this.last5DispoHoverPanelHeight
          ? "top:" + event.clientY + "px; "
          : "bottom:" + (this.windowHeight - event.clientY) + "px;";

      this.last5DispoHoverPanelPosition = positioning + " right:185px;";

      this.hoveredReminderId = 0;
      this.hoveredDispositionId = itemId;
    },
    openDispositionPopup(customerId, statusId) {
      this.dispositionCustomerId = customerId;
      this.dispositionStatusId = statusId;
    },
    closeDispositionPopup() {
      this.dispositionCustomerId = 0;
      this.dispositionStatusId = 0;
    },
    openReminderPopup(customerId, phone) {
      this.$Emitter.emit("openReminder", {
        CustomerId: customerId,
        IsAutoClose: false,
        CustomerPrimaryPhone: phone,
      });
    },
    async handleTabClose(indx) {
      if (indx > -1) {
        this.$Emitter.emit("closeActiveCustomer", {
          CustomerId: this.tabs[indx].id,
        });
      }
      let iscancelled = false;

      if (this.tabs[indx].active) {
        this.tabDataChangeChecker();
      }

      if (this.tabs[indx].isDataChanged) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you close the tab",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            iscancelled = false;
          } else {
            iscancelled = true;
          }
        });
        if (iscancelled) return;
      }

      if (indx > -1) {
        this.tabs.splice(indx, 1);
      }
      this.handleTabChange(-1, 0);
    },
    handleNew() {
      if (this.customerStatusList.length == 0) {
        this.$swal(
          "Warning!",
          "There is no customer status for this company.",
          "warning"
        );
        return;
      }
      this.activeTabId = 0;
      let indx = this.tabs.findIndex((x) => x.id == 0);
      if (indx > -1) {
        this.handleTabChange(0, indx);
        return;
      }
      let tabItem = deepCopy(tabObj);
      tabItem.label = "New Customer";
      this.resetTabChange();

      this.createTab(tabItem);
    },
    handleEdit(customerId, clientName, fromOtherComponent = false) {
      if (this.customerStatusList.length == 0) {
        this.$swal(
          "Warning!",
          "There is no customer status for this company.",
          "warning"
        );
        return;
      }
      let indx = this.tabs.findIndex((x) => x.id == customerId);

      if (fromOtherComponent) {
        stopTimer = true;
      }

      if (indx > -1) {
        this.handleTabChange(customerId, indx);
        return;
      }

      if (clientName.toLowerCase().trim() != "hot lead") {
        this.$store.dispatch(globalTypes.GET_HISTORY, {
          Id: customerId,
          Name: clientName,
        });
      }

      let tabItem = deepCopy(tabObj);
      tabItem.id = customerId;
      tabItem.label = clientName;
      this.resetTabChange();
      this.createTab(tabItem);
    },
    createTab(tabItem) {
      if (this.tabs.length < 8) {
        this.tabs.push(tabItem);

        this.clearTimer();
      } else {
        this.$swal(
          "Warning!",
          "You have more than 8 open tabs. Please close the redundant ones and try again",
          "warning"
        );

        this.handleTabChange(-1, 0);
      }
    },
    resetTabChange() {
      this.customersTab = false;
      this.tabs.map((t) => {
        t.active = false;
      });
    },

    tabDataChangeChecker() {
      let activeTabIndex = this.tabs.findIndex((x) => x.active);

      if (activeTabIndex > -1) {
        let currentTab = this.$refs["customer-" + this.tabs[activeTabIndex].id];

        if (typeof currentTab != "undefined" && currentTab) {
          this.tabs[activeTabIndex].isDataChanged = currentTab.isDataChanged();
        }
      }
    },
    async handleTabChange(id, index) {
      this.hideRightTabClick();
      this.customersTab = false;

      if (id >= 0) {
        this.tabDataChangeChecker();
      }

      this.tabs.map((t) => {
        t.active = false;
      });

      if (id > -1) {
        stopTimer = true;
        this.clearTimer();
      } else {
        stopTimer = false;
        this.startTimer();
      }

      if (id == -1) {
        this.customersTab = true;
        return;
      }

      if (index < 0) return;

      this.tabs[index].active = true;
      if (this.activeTabId != this.tabs[index].id && this.tabs[index].id > 0) {
        //guncelle
        // await Promise.all([
        //     //this.$store.dispatch(notificationTypes.GET_CUSTOMERREMINDERS,this.tabs[index].id),
        //     this.$store.dispatch(dispositionTypes.GET_DISPOSITIONLOGS, this.tabs[index].id)
        // ]);
      }

      if (this.tabs[index].id >= 0) {
        this.activeTabId = this.tabs[index].id;
      }
    },
    isSSNValid(event) {
      if (event.target.value) {
        this.filterParams.SSN = event.target.value;
      }
      if (this.filterParams.SSN.length != 4) this.filterParams.SSN = "";
    },
    isFullSSNValid(event) {
      if (event.target.value) {
        this.filterParams.FullSsn = event.target.value;
      }
      if (this.filterParams.FullSsn.length != 9) this.filterParams.FullSsn = "";
    },
    checkPageSizeCtrl() {
      if (
        !Number.isInteger(+this.pageSize) ||
        +this.pageSize < 1 ||
        +this.pageSize > 200
      )
        this.pageSize = 50;
    },
    checkXChangePageSizeCtrl() {
      if (
        !Number.isInteger(+this.pageSizeXChange) ||
        +this.pageSizeXChange < 1 ||
        +this.pageSizeXChange > 100
      )
        this.pageSizeXChange = 50;
    },

    assignedAgentSelected(selected) {
      if (selected.Id == "") {
        this.filterParams.AssignedTo.splice(
          0,
          this.filterParams.AssignedTo.length
        );
        this.filterParams.AssignedTo = [];
        this.filterParams.AssignedTo.push(allagentsObj);
      } else {
        let indx = this.filterParams.AssignedTo.findIndex((x) => x.Id == "");
        if (indx >= 0) {
          this.filterParams.AssignedTo.splice(indx, 1);
        }
      }
    },
    assignedAgentDisplay(values) {
      if (values.length == 0) this.assignedAgendDisplayText = "Select";
      else if (values.length <= 2) {
        this.assignedAgendDisplayText = values.map((x) => x.Name).join(",");
      } else {
        this.assignedAgendDisplayText =
          values[0].Name +
          "," +
          values[1].Name +
          " and " +
          (values.length - 2).toString() +
          " more";
      }
    },

    statusSelected(selected) {
      if (selected.Id == "") {
        this.filterParams.Status.splice(0, this.filterParams.Status.length);
        this.filterParams.Status = [];
        this.filterParams.Status.push(allStatusObj);
      } else {
        let indx = this.filterParams.Status.findIndex((x) => x.Id == "");
        if (indx >= 0) {
          this.filterParams.Status.splice(indx, 1);
        }
      }
    },
    StatusDisplay(values) {
      if (values.length == 0) this.statusDisplayText = "Select";
      else if (values.length <= 2) {
        this.statusDisplayText = values.map((x) => x.StatusName).join(",");
      } else {
        this.statusDisplayText =
          values[0].StatusName +
          "," +
          values[1].StatusName +
          " and " +
          (values.length - 2).toString() +
          " more";
      }
    },
    stateSelected(selected) {
      if (selected.Id == "") {
        this.filterParams.State.splice(0, this.filterParams.State.length);
        this.filterParams.State = [];
        this.filterParams.State.push(allTimeZonesObj);
      } else {
        let indx = this.filterParams.State.findIndex((x) => x.Id == "");
        if (indx >= 0) {
          this.filterParams.State.splice(indx, 1);
        }
      }
    },
    timezoneSelected(selected) {
      if (selected.Id == "") {
        this.filterParams.Timezone.splice(0, this.filterParams.Timezone.length);
        this.filterParams.Timezone = [];
        this.filterParams.Timezone.push(allTimeZonesObj);
      } else {
        let indx = this.filterParams.Timezone.findIndex((x) => x.Id == "");
        if (indx >= 0) {
          this.filterParams.Timezone.splice(indx, 1);
        }
      }
    },
    timezoneDisplay(values) {
      if (values.length == 0) this.timezoneDisplayText = "Select";
      else if (values.length <= 2) {
        this.timezoneDisplayText = values.map((x) => x.ShortName).join(",");
      } else {
        this.timezoneDisplayText =
          values[0].ShortName +
          "," +
          values[1].ShortName +
          " and " +
          (values.length - 2).toString() +
          " more";
      }
    },
    isMultiselectEnabled() {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      return arrPermission.includes(1071);
    },
    handleDelete(customerId, priorityview) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let result;
            result = await this.$store.dispatch(
              types.DELETE_CUSTOMER,
              customerId
            );

            if (result) {
              //close tab if exist

              let indxTab = this.tabs.findIndex((t) => t.id == customerId);

              if (indxTab > -1) {
                this.tabs.splice(indxTab, 1);
              }
              let indx = -1;
              if (priorityview == null) {
                indx = this.list.Customers.findIndex((x) => x.Id == customerId);
                if (indx > -1) this.list.Customers.splice(indx, 1);
                this.totalRecords--;
              } else {
                indx = priorityview.Customers.findIndex(
                  (x) => x.Id == customerId
                );
                if (indx > -1) priorityview.Customers.splice(indx, 1);
              }
              this.$store.dispatch(globalTypes.DELETE_HISTORY, {
                id: customerId,
                name: "",
              });

              indx = -1;
              indx = this.favorites.findIndex(
                (x) => x.CustomerId == customerId
              );
              if (indx > -1) {
                // delete from fav
                this.$store.dispatch(globalTypes.DELETEFAV, {
                  CustomerId: customerId,
                });
              }
              this.$swal("Deleted!", result.Message, "success");
            } else {
              let errMsg = "An Error Occured!";

              this.$swal("Error!", errMsg, "error");
            }
          }
        });
    },
    async handleSorting(item, index, isXChange) {
      if (!item.sortable) return;

      if (isXChange) {
        item.activeSort = item.activeSort === "asc" ? "desc" : "asc";
        this.xChangeDefaultSorting = item.name;
        await this.getXChangeGrid(item.activeSort);
      } else {
        let temp = deepCopy(this.Columns);
        temp = temp.map((i) => {
          i.activeSort = false;
          return i;
        });
        temp[index].activeSort = item.activeSort === "asc" ? "desc" : "asc";
        this.Columns = deepCopy(temp);
        this.currentSort =
          item.activeSort !== false
            ? { col: item.name, sort: temp[index].activeSort }
            : { col: "", sort: "" };
        this.handleSearch(false, false);
      }
    },
    async handleNormalView() {
      this.view = "normal";
      await this.handleSearch(false, false);
 
    },
    async handleXChangeView() {
      this.view = "xchange";
      await this.getXChangeGrid();

    },
    async handlePriorityView() {
      this.view = "priority";
      await this.handleSearch(false, false);
 
    },

    handleToggle(item, index) {
      let temp = deepCopy(this.priority);

      temp[index].Toggle = !item.Toggle;

      this.$store.commit(types.SET_PRIORITY, temp);
    },

    clearFilters(canSearch) {
      this.clientorCoclient = 0;
      this.filterParams = deepCopy(filterParams);
      if (this.assignedAgentList) {
        this.filterParams.AssignedTo.push(this.assignedAgentList[0]);
        //this.assignedAgentDisplay(this.filterParams.AssignedTo);
      }
      if (this.StateList) {
        this.filterParams.State.push(this.StateList[0]);
      }
      if (this.customerStatusList) {
        this.filterParams.Status.push(this.customerStatusList[0]);
      }
      if (this.timezoneList) {
        this.filterParams.Timezone.push(this.timezoneList[0]);
      }

      if (!(this.hasAccessOtherOffices || this.hasAccessSubOffices)) {
        this.filterParams.OfficeId = this.userOfficeId;
      }

      this.resetpages();
      if (canSearch) this.handleSearch(false, false);
    },
    formatPhone(number) {
      //Filter only numbers from the input
      let cleaned = ("" + number).replace(/\D/g, "");

      //Check if the input is of correct
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        //let intlCode = match[1] ? "+1 " : "";
        return ["(", match[2], ") ", match[3], "-", match[4]].join("");
      }
    },
    resetpages() {
      this.pageNum = 1;
      this.currentPage = 1;
    },
    async gotoPage(page) {
      this.refreshingGrid = true;
      if (!this.pageNum) this.pageNum = 1;
      if (page > this.lastPage) return false;
      if (page !== undefined && page !== this.currentPage) {
        this.currentPage = page;
        this.pageNum = page;
      } else if (parseInt(this.pageNum) !== this.currentPage) {
        this.currentPage = parseInt(this.pageNum);
      }

      if (this.currentSort.col) {
        this.query.SortBy = this.currentSort.col;
        this.query.SortAsc = this.currentSort.sort === "asc";
      }
      if (this.pageNum > this.lastPage) this.pageNum = this.lastPage;
      if (this.pageNum != this.currentPage) {
        this.currentPage = this.pageNum;
      }
      this.query.Page = this.pageNum;
      await this.$store.dispatch(types.GET_CUSTOMERS, this.query);
      this.refreshingGrid = false;
    },
    async gotoPageXchange(page) {
      if (page < 1) page = 1;

      if (page > this.lastPageXChange) page = this.lastPageXChange;

      this.currentPageXchange = page;
      await this.getXChangeGrid();
    },

    checkNext(page) {
      if (page > this.lastPage) return false;
      return true;
    },
    checkPrev(page) {
      if (page < 1) return false;
      return true;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async handleSearch(resetPageNumber = false, isAutoRefresh = false) {
      if (stopTimer) return;

      if (isAutoRefresh == false) {
        this.checkedCustomerIds = [];
        this.allCustomersChecked = false;
      }

      this.refreshingGrid = true;
      this.applyingFilter = true;

      await this.sleep(500);
      if (resetPageNumber == true) {
        this.resetpages();
      }

      let customerId = 0;
      let payload = {};

      if (this.filterParams.SSN.length != 4) this.filterParams.SSN = "";
      if (this.filterParams.FullSsn.length != 9) this.filterParams.FullSsn = "";
      if (this.filterParams.CustomerId == "") {
        customerId = 0;
      } else {
        // if (
        //   this.customerPrefix != null &&
        //   this.filterParams.CustomerId.toUpperCase().startsWith(this.customerPrefix.toUpperCase())
        // ) {
        //   let removeChar = this.customerPrefix.length;
        //   let newCustomerId =
        //     this.filterParams.CustomerId.substring(removeChar);
        //   customerId = parseInt(newCustomerId);
        // } else {
        //   customerId = parseInt(this.filterParams.CustomerId);
        // }
        let testString = "";
        let testStringArr = this.filterParams.CustomerId.match(/\d/g);
        if (testStringArr != null && testStringArr.length > 0) {
          testString = testStringArr.join("");
        } else {
          testString = "0";
        }
        customerId = parseInt(testString, 0);
      }

      if (customerId == null) {
        customerId = 0;
      }
      if (this.view === "normal") {
        payload = {
          View: 1,
          FirstName: this.filterParams.FirstName,
          LastName: this.filterParams.LastName,
          CustomerId: customerId,
          Last4Ssn: this.filterParams.SSN,
          FullSsn: this.filterParams.FullSsn,
          Email: this.filterParams.Email,
          Phone: this.filterParams.Phone,
          TimeZoneIds: this.filterParams.Timezone.map((x) => x.Id).join(","),
          SalesUserIds: this.filterParams.AssignedTo.map((x) => x.Id).join(","),
          StateIds: this.filterParams.State.map((x) => x.Id).join(","),
          StatusIds: this.filterParams.Status.map((x) => x.Id).join(","),
          CampaignId: this.filterParams.CampaignId,
          Option:
            this.filterParams.DateOption != null
              ? this.filterParams.DateOption.toLowerCase()
              : null,
          StartDate:
            this.filterParams.StartDate != null
              ? this.formatShortDate(this.filterParams.StartDate)
              : null,
          EndDate:
            this.filterParams.EndDate != null
              ? this.formatShortDate(this.filterParams.EndDate)
              : null,
          OfficeId: this.filterParams.OfficeId,

          //CampaignCode: this.filterParams.CampaignCode,
          PageRows: this.pageSize,
          Page: this.pageNum,
          IsCoCustomer: this.clientorCoclient == 1,
        };
        if (
          typeof payload.CustomerId == "undefined" ||
          isNaN(payload.CustomerId)
        ) {
          payload.CustomerId = 0;
        }

        if (this.currentSort.col) {
          payload.SortBy = this.currentSort.col;
          payload.IsAsc = this.currentSort.sort === "asc";
        } else {
          payload.SortBy = "created";
          payload.SortAsc = false;
        }
      } else {
        payload.View = 2;
        this.exportButtonVisible = false;
      }

      this.query = Object.assign({}, payload);

      await this.$store.dispatch(types.GET_CUSTOMERS, payload).then((res) => {
        if (this.view != "priority") {
          if (res && res.data) {
            this.lastPage = res.data.Data.PageCount;
            this.isSearchTriggered = true;
            this.totalRecords = res.data.Data.TotalRecords;
            if (this.totalRecords > 0) {
              this.exportButtonVisible = true;
            } else {
              this.exportButtonVisible = false;
            }
          }
        }
      });

      this.activeFilters = [];
      if (this.filterParams.FirstName != "") {
        this.activeFilters.push("First Name: " + this.filterParams.FirstName);
      }
      if (this.clientorCoclient == 1) {
        this.activeFilters.push("Co-Customer");
      }
      if (this.filterParams.LastName != "") {
        this.activeFilters.push("Last Name:" + this.filterParams.LastName);
      }
      if (this.filterParams.CustomerId != "") {
        this.activeFilters.push("CustomerId:" + this.filterParams.CustomerId);
      }
      if (this.filterParams.Status.length > 0) {
        if (
          this.filterParams.Status.length == 1 &&
          this.filterParams.Status[0].Id != ""
        ) {
          this.activeFilters.push(
            "Status: " + this.filterParams.Status[0].Name
          );
        } else if (this.filterParams.Status.length > 1) {
          this.activeFilters.push("Status: Multiple Selected");
        }
      }

      if (this.filterParams.Timezone.length > 0) {
        if (
          this.filterParams.Timezone.length == 1 &&
          this.filterParams.Timezone[0].Id != ""
        ) {
          this.activeFilters.push(
            " Timezone: " + this.filterParams.Timezone[0].ShortName
          );
        } else if (this.filterParams.Timezone.length > 1) {
          this.activeFilters.push(" Timezone: Multiple Selected");
        }
      }

      if (this.filterParams.SSN != "") {
        this.activeFilters.push("Last 4 SSN: " + this.filterParams.SSN);
      }
      if (this.filterParams.FullSsn != "") {
        this.activeFilters.push("SSN: " + this.filterParams.FullSsn);
      }
      if (this.filterParams.Email != "") {
        this.activeFilters.push("Email: " + this.filterParams.Email);
      }
      if (this.filterParams.Phone != "") {
        let phone =
          this.filterParams.Phone.length == 10
            ? this.formatPhone(this.filterParams.Phone)
            : this.filterParams.Phone;
        this.activeFilters.push("Phone: " + phone);
      }
      if (this.filterParams.AssignedTo.length > 0) {
        if (
          this.filterParams.AssignedTo.length == 1 &&
          this.filterParams.AssignedTo[0].Id != ""
        ) {
          this.activeFilters.push(
            "Assigned To: " + this.filterParams.AssignedTo[0].Name
          );
        } else if (this.filterParams.AssignedTo.length > 1) {
          this.activeFilters.push("Assigned To: Multiple Selected");
        }
      }
      if (this.filterParams.State.length > 0) {
        if (
          this.filterParams.State.length == 1 &&
          this.filterParams.State[0].Id != ""
        ) {
          this.activeFilters.push(
            "State: " + this.filterParams.State[0].ShortCode
          );
        } else if (this.filterParams.State.length > 1) {
          this.activeFilters.push("State: Multiple Selected");
        }
      }
      if (this.filterParams.DateOption != null) {
        this.activeFilters.push("DateOption: " + this.filterParams.DateOption);
      }

      if (this.filterParams.CampaignId > 0) {
        let campaignIndex = this.activeCampaigns.findIndex(
          (x) => x.Id == parseInt(this.filterParams.CampaignId)
        );
        if (campaignIndex > -1) {
          this.activeFilters.push(
            "Campaign: " + this.activeCampaigns[campaignIndex].Name
          );
        }
      }
      if (this.filterParams.OfficeId > 0) {
        let indx = this.authorizedOffices.findIndex(
          (x) => x.Id == parseInt(this.filterParams.OfficeId)
        );
        if (indx > -1) {
          this.activeFilters.push(
            "Office: " + this.authorizedOffices[indx].Name
          );
        }
      }
      // if (this.filterParams.CampaignCode != "") {
      //   this.activeFilters.push(
      //     "Reference Code: " + this.filterParams.CampaignCode
      //   );
      // }
      this.applyingFilter = false;
      this.refreshingGrid = false;
    },
    async exportGrid() {
      let payload = {
        View: 1,
        FirstName: this.filterParams.FirstName,
        LastName: this.filterParams.LastName,
        CustomerId: this.filterParams.CustomerId,
        Last4Ssn: this.filterParams.SSN,
        Email: this.filterParams.Email,
        Phone: this.filterParams.Phone,
        TimeZoneIds: this.filterParams.Timezone.map((x) => x.Id).join(","),
        SalesUserIds: this.filterParams.AssignedTo.map((x) => x.Id).join(","),
        StateIds: this.filterParams.State.map((x) => x.Id).join(","),
        StatusIds: this.filterParams.Status.map((x) => x.Id).join(","),
        CampaignId: this.filterParams.CampaignId,
        Option:
          this.filterParams.DateOption != null
            ? this.filterParams.DateOption.toLowerCase()
            : null,
        StartDate:
          this.filterParams.StartDate != null
            ? this.formatShortDate(this.filterParams.StartDate)
            : null,
        EndDate:
          this.filterParams.EndDate != null
            ? this.formatShortDate(this.filterParams.EndDate)
            : null,
        OfficeId: this.filterParams.OfficeId,
      };

      if (this.totalRecords > 10000) {
        this.$swal(
          "WARNING",
          "Please narrow down your search parameters. Maximum allowed export count: 10,000 records.",
          "warning"
        );
      } else {
        let err, result;
        [err, result] = await this.$store.dispatch(types.EXPORT_GRID, payload);

        if (result) {
          this.saveByteArray(
            "Customers-" + new Date().getTime() + ".xls",
            result
          );
        }
      }
    },
    saveByteArray(name, byte) {
      let blob = new Blob([byte], { type: "application/xlsx" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      let fileName = name;
      link.download = fileName;
      link.click();
    },
    getTabWidth(tabCount, totalWidth) {
      let rtn =
        tabCount > 4
          ? Math.floor(totalWidth / tabCount)
          : Math.floor(totalWidth / 4);

      return rtn;
    },
    phoneChanged(event) {
      if (event.target.value) {
        this.filterParams.Phone = event.target.value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/custom.scss";

.orderAndAppyButton {
  display: flex;
  justify-content: center;
}

.reminderControl {
  margin: 20% auto;
  display: inline-block;
  padding: 0 10px 0 10px;
  z-index: 1000;
  background: #efefef;
  border: 3px solid #555;
  box-shadow: 0 2px 5px #000;
  text-align: left;
}
.reminderClose {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.reminderClose:hover {
  color: #ff3860;
}
.hoverPanel {
  width: 480px;
  /*height:  dynamically set by last5DispoHoverPanelHeight  */
  position: fixed;
  /*top,right:  dynamically set by last5DispoHoverPanelPosition */
  z-index: 999;
  background: #ffffff;
  border: 1px solid #3bafda;
}
.hoverPanelHeader {
  width: 98%;
  float: left;
  margin: 5px 1% 0 1%;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: #343a40;
  border-bottom: 1px solid #3bafda;
}
.hoverPanelRow {
  width: 100%;
  float: left;
  margin: 0;
}
.campaignLeadButton {
  float: left;
  margin: 0;
  position: relative;
  cursor: pointer;
}
.campaignLeadIcon {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #24d15f;
  padding: 0 !important;
  text-align: center;
}

@media only screen and (min-width: 1030px) {
  .iconView {
    color: rgba(255, 255, 255, 0);
  }
}

// .movWrapper {
//   width: 100%;
//   height: 1px;
//   box-sizing: border-box;

//   position: absolute;

//   z-index: 1900;
// }
// .movContainer {
//   position: absolute;
//   top: 50%;
//   right: 50%;
//   transform: translate(-50%, -50%);
// }
// .moveable {
//   width: 480px;
//   height: auto;
//   margin: 0 auto;
//   padding: 0px;
//   border-radius: 8px;
//   box-shadow: 0 0 5px 2px #b7b3b3;
//   background-color: #ffffff;
//   cursor: pointer;
// }
// .moveable-control-box {
//   display: none !important;
// }
</style>
