<template>
  <div
    class="col-md-12 newPanelWrapper noMargin"
    style="background-color: #ffffff; z-index: 1000"
  >
    <div v-if="isLoading" class="newLoading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div
      style="display: flex; justify-content: space-between"
      v-on:click.prevent.stop="closePanel"
    >
    <div class="align-items-center" style="height:25px; display: flex; justify-content: left;">
      <label>Bulk Draft Changes</label>
      <button
              type="button"
              class="btn btn-xs btn-danger waves-effect waves-light ml-2"
              v-if="isCashFlowNegative"
              v-on:click.prevent.stop="openCashFlowPanel"
            >
              Negative Cash Flow!
            </button>
    </div>
      <i class="fas fa-window-close closeButtonBulk"></i>
    </div>
    <hr />
    <perfect-scrollbar style="max-height: 600px; margin-top: -20px">
      <div class="col-md-12 admin-page">
        <div
          class="mt-1"
          style="
            display: flex;
            border-bottom: 1px dashed gray;
            align-items: baseline;
          "
        >
          <div>
            <input
              type="checkbox"
              :checked="editDraftDaysChecked"
              @change="changeEditDraftDays"
              class="checkbox-input generalCheckBox"
            />
          </div>
          <label class="generalLabel" style="margin-right: 10px"
            >Edit Draft Days</label
          >
        </div>
        <div class="mt-1" style="display: flex; align-items: baseline">
          <div>
            <input
              name="draftOption"
              :checked="draftDayOption == 1"
              @change="changeDraftDayOption(1)"
              type="radio"
              class="checkbox-input generalCheckBox"
            />
          </div>
          <label for="pushDraftFoward" class="generalLabel"
            >Push drafts forward (days):</label
          >
          <input
            type="text"
            :disabled="draftDayOption != 1"
            v-model="draftForwardDays"
            class="generalTextBox miniTextBox ml-1"
            :class="draftDayOption != 1 ? 'readonlyInput' : ''"
            style="width: 40px"
            v-myMask="{
              alias: 'numeric',
              min: 0,
              digits: 0,
              allowMinus: false,
              rightAlign: false,
            }"
          />
        </div>
        <div class="mt-1" style="display: flex; align-items: baseline">
          <div>
            <input
              name="draftOption"
              :checked="draftDayOption == 2"
              @change="changeDraftDayOption(2)"
              type="radio"
              class="checkbox-input generalCheckBox"
            />
          </div>
          <label for="pullDraftsBackward" class="generalLabel"
            >Pull drafts backward (days):</label
          >
          <input
            type="text"
            :disabled="draftDayOption != 2"
            v-model="draftBackwardDays"
            class="generalTextBox miniTextBox ml-1"
            :class="draftDayOption != 2 ? 'readonlyInput' : ''"
            style="width: 40px"
            v-myMask="{
              alias: 'numeric',
              min: -29,
              max: 0,
              rightAlign: false,
            }"
          />
        </div>
        <div class="mt-2" style="display: flex; align-items: baseline">
          <div>
            <input
              name="draftOption"
              type="radio"
              :checked="draftDayOption == 3"
              @change="changeDraftDayOption(3)"
              class="checkbox-input generalCheckBox"
            />
          </div>
          <label for="pullDraftsForward" class="generalLabel"
            >Change draft day from:</label
          >
        </div>
        <div
          class="mt-1"
          style="margin-left: 31px; width: 320px"
          v-if="draftDayOption == 3"
        >
          <perfect-scrollbar style="min-width: 320px">
            <table class="datagrid-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Day From</th>
                  <th>Day To</th>
                  <th></th>
                </tr>
                <tr v-if="isDayGridNewRowActive">
                  <th>
                    <span
                      style="cursor: pointer; margin: 5px; padding: 5px"
                      class="badge badge-danger"
                      @click="dayGridCancelRow"
                      >Cancel</span
                    >
                  </th>
                  <th>
                    <input
                      v-myMask="{
                        alias: 'numeric',
                        max: 31,
                        digits: 0,
                        allowMinus: false,
                        rightAlign: false,
                      }"
                      class="generalTextBox miniTextBox"
                      type="text"
                      v-model="dayGridFrom"
                      v-on:keydown.enter.prevent="dayGridSave"
                      v-on:keydown.esc.prevent="dayGridCancelRow"
                      autocomplete="off"
                    />
                  </th>
                  <th>
                    <input
                      v-myMask="{
                        alias: 'numeric',
                        max: 31,
                        digits: 0,
                        allowMinus: false,
                        rightAlign: false,
                      }"
                      class="generalTextBox miniTextBox"
                      type="text"
                      v-model="dayGridTo"
                      v-on:keydown.enter.prevent="dayGridSave"
                      v-on:keydown.esc.prevent="dayGridCancelRow"
                      autocomplete="off"
                    />
                  </th>
                  <th>
                    <i
                      style="color: #1abc9c; font-size: 17px; cursor: pointer"
                      class="ri-checkbox-circle-line"
                      @click="dayGridSave"
                    ></i>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <span
                    v-if="!isDayGridNewRowActive"
                    style="cursor: pointer; margin: 5px; padding: 5px"
                    class="badge badge-success badgeStatusPadding"
                    @click="addNewDayGridData"
                    >Add</span
                  >
                </tr>
                <tr v-for="(item, index) in dayGridData" :key="item.Id">
                  <th class="text-center" style="padding: 5px">
                    <label class="generalLabel">{{ index + 1 }}</label>
                  </th>
                  <th class="text-center" style="padding: 5px">
                    <label class="generalLabel">{{ item.DayFrom }}</label>
                  </th>
                  <th class="text-center" style="padding: 5px">
                    <label class="generalLabel">{{ item.DayTo }}</label>
                  </th>
                  <th class="text-center">
                    <i
                      style="color: red; font-size: 17px; cursor: pointer"
                      class="ri-close-circle-line"
                      @click="removeDayGridItem(item.Id)"
                    ></i>
                  </th>
                </tr>
              </tbody>
            </table>
          </perfect-scrollbar>
        </div>
        <div class="mt-2" style="display: flex; align-items: baseline">
          <div>
            <input
              name="draftOption"
              type="radio"
              :checked="draftDayOption == 4"
              @change="changeDraftDayOption(4)"
              class="checkbox-input generalCheckBox"
            />
          </div>
          <label for="pullDraftsForward" class="generalLabel"
            >Change to the next day of week:</label
          >
        </div>
        <div
          class="mt-2"
          style="display: flex; align-items: baseline"
          v-if="draftDayOption == 4"
        >
          <label class="generalLabel">Day of Week:</label>

          <select
            style="width: 200px"
            class="form-control ml-2"
            v-model="nextDayOfWeek"
          >
            <option :value="null">Please Select</option>
            <option
              v-for="item in daysOfWeekList"
              :value="item.Id"
              :key="item.Id"
            >
              {{ item.Name }}
            </option>
          </select>
        </div>
        <div class="mt-2" style="display: flex; align-items: baseline">
          <div>
            <input
              name="draftOption"
              type="radio"
              :checked="draftDayOption == 5"
              @change="changeDraftDayOption(5)"
              class="checkbox-input generalCheckBox"
            />
          </div>
          <label for="pullDraftsForward" class="generalLabel"
            >Change to the previous day of week:</label
          >
        </div>
        <div
          class="mt-2"
          style="display: flex; align-items: baseline"
          v-if="draftDayOption == 5"
        >
          <label class="generalLabel">Day of Week:</label>

          <select
            style="width: 200px"
            class="form-control ml-2"
            v-model="prevDayOfWeek"
          >
            <option :value="null">Please Select</option>
            <option
              v-for="item in daysOfWeekList"
              :value="item.Id"
              :key="item.Id"
            >
              {{ item.Name }}
            </option>
          </select>
        </div>
        <div
          class="mt-2"
          style="
            display: flex;
            border-top: 1px dashed gray;
            padding-top: 20px;
            align-items: baseline;
          "
        >
          <div>
            <input
              id="pullDraftsForward"
              type="checkbox"
              :checked="draftMonthSelected"
              @change="changeMonthsSelect()"
              class="checkbox-input generalCheckBox"
            />
          </div>
          <label for="pullDraftsForward" class="generalLabel"
            >Push drafts forward (months):</label
          >
          <input
            type="text"
            class="generalTextBox miniTextBox ml-1"
            :disabled="draftMonthSelected == false"
            :class="draftMonthSelected == false ? 'readonlyInput' : ''"
            style="width: 40px"
            v-model="draftMonthCount"
            v-myMask="{
              alias: 'numeric',
              digits: 0,
              allowMinus: false,
              rightAlign: false,
            }"
          />
        </div>
        <div class="mt-1" style="display: flex; align-items: baseline">
          <div>
            <input
              type="checkbox"
              :checked="draftAmountSelected"
              @change="changeDraftAmountSelect()"
              class="checkbox-input generalCheckBox"
            />
          </div>
          <label for="pullDraftsForward" class="generalLabel"
            >Change draft amount:</label
          >
          <input
            type="text"
            :disabled="draftAmountSelected == false"
            :class="draftAmountSelected == false ? 'readonlyInput' : ''"
            class="generalTextBox miniTextBox ml-1"
            style="width: 100px"
            v-model="draftAmount"
            v-myMask="{
              alias: 'currency',
              prefix: '$',
              min: 0,
              allowMinus: false,
              rightAlign: false,
            }"
          />
        </div>
        <div
          class="mt-4"
          v-if="
            editDraftDaysChecked || draftMonthSelected || draftAmountSelected
          "
        >
          <button @click="applyChanges()" class="btn btn-primary">
            Apply Changes and Show Preview
          </button>
        </div>

        <!-- Ikinci alan -->

        <div class="mt-4">
          <label class="generalLabel" style="text-decoration: underline"
            >Preview</label
          >
          <div class="mt-2" style="width: 100%">
            <perfect-scrollbar>
              <table class="datagrid-table">
                <thead>
                  <tr>
                    <th>
                      <input
                        id="pullDraftsForward"
                        type="checkbox"
                        class="checkbox-input"
                        :checked="selectAllDrafts"
                        @change="selectAllDraftChanges()"
                      />
                    </th>
                    <th class="text-left" style="padding: 10px">
                      Effective Date
                    </th>
                    <th class="text-left" style="padding: 10px">
                      Debit Amount
                    </th>
                    <th class="text-left" style="padding: 10px">
                      New Effective Date
                    </th>
                    <th class="text-left" style="padding: 10px">
                      New Debit Amount
                    </th>
                    <th class="text-left" style="padding: 10px">Delete</th>
                  </tr>
                  <tr v-if="isDraftGridNewRowActive">
                    <th></th>
                    <th></th>

                    <th>
                      <span
                        style="cursor: pointer; margin: 5px; padding: 5px"
                        class="badge badge-danger"
                        @click="draftGridCancelRow"
                        >Cancel</span
                      >
                    </th>
                    <th>
                      <DatePicker
                        mode="date"
                        :locale="'en-US'"
                        v-model="newDraft.NewDate"
                        :model-config="modelConfig"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <input
                            class="form-control border rounded focus:outline-none focus:border-blue-300"
                            :value="inputValue"
                            v-on="inputEvents"
                            v-on:keydown.enter.prevent="draftGridSave"
                            v-on:keydown.esc.prevent="draftGridCancelRow"
                            placeholder="Select Date"
                          />
                        </template>
                      </DatePicker>
                    </th>
                    <th>
                      <input
                        v-myMask="{
                          alias: 'currency',
                          allowMinus: false,
                          rightAlign: false,
                        }"
                        class="generalTextBox miniTextBox"
                        type="text"
                        v-model="newDraft.Amount"
                        v-on:keydown.enter.prevent="draftGridSave"
                        v-on:keydown.esc.prevent="draftGridCancelRow"
                        autocomplete="off"
                      />
                    </th>
                    <th>
                      <span
                        title="Add new draft"
                        style="cursor: pointer; margin: 5px 10px; padding: 5px"
                        class="badge badge-success badgeStatusPadding"
                        @click="draftGridSave"
                        >Save</span
                      >
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td style="padding: 5px 0 !important">
                      <span
                        title="Add new draft"
                        style="cursor: pointer; margin: 5px 10px; padding: 5px"
                        class="badge badge-success badgeStatusPadding"
                        @click="addNewDraft"
                        >Add</span
                      >
                    </td>
                    <td
                      v-if="addedDraftList.length > 0"
                      style="padding: 5px 0 !important"
                    >
                      <span
                        title="Cancel added drafts"
                        style="cursor: pointer; float: left"
                        class="badge badge-danger badgeStatusPadding"
                        @click="cancelBulkDrafts"
                        >Cancel added drafts</span
                      >
                    </td>
                    <td
                      :colspan="addedDraftList.length > 0 ? 3 : 4"
                      style="text-align: right"
                    ></td>
                    <td style="text-align: center">
                      <input
                        type="checkbox"
                        class="checkbox-input"
                        v-model="isAllDeleted"
                        @change="deleteSelected"
                      />
                    </td>
                  </tr>
                  <tr v-for="item in draftList" :key="item.DebitId">
                    <td class="text-center" style="padding: 5px">
                      <input
                        id="pullDraftsForward"
                        type="checkbox"
                        class="checkbox-input"
                        :checked="item.isEditing"
                        @change="selectDraft(item)"
                      />
                    </td>
                    <td class="text-left" style="padding: 5px 10px">
                      <label class="generalLabel">{{
                        formatShortDate(item.EffectiveDate)
                      }}</label>
                    </td>
                    <td class="text-left" style="padding: 5px 10px">
                      <label class="generalLabel">{{
                        formatMoney(item.Amount)
                      }}</label>
                    </td>
                    <td class="text-left" style="padding: 5px 10px">
                      <label
                        class="generalLabel"
                        :class="
                          getDraftDateColorClass(
                            item.NewDate,
                            item.EffectiveDate,
                            item.isEditing
                          )
                        "
                        >{{ formatShortDate(item.NewDate) }}</label
                      >
                    </td>
                    <td class="text-left" style="padding: 5px 10px">
                      <label
                        class="generalLabel"
                        :class="
                          getDraftColorClass(
                            item.NewAmount,
                            item.Amount,
                            item.isEditing
                          )
                        "
                        >{{ formatMoney(item.NewAmount) }}</label
                      >
                    </td>
                    <td class="text-center" style="padding: 5px">
                      <input
                        type="checkbox"
                        :disabled="!item.isEditing"
                        class="checkbox-input"
                        :checked="item.isDeleting"
                        @change="deleteDraft(item)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </perfect-scrollbar>
          </div>

          <div class="col-md-6 noPadding mt-3">
            <label class="generalLabel" style="text-decoration: underline"
              >Notes:</label
            >
            <textarea
              v-model="draftNotes"
              placeholder="Notes here..."
              class="form-control textareaInput generalTextBox"
              rows="4"
            ></textarea>
          </div>
          <div v-if="checkAuth(54983) && isDpg == false" class="col-md-12 mt-4">
            <div class="row">
              <label class="generalLabel" style="text-decoration: underline"
                >Related Fees:</label
              >
            </div>
            <div class="row">
              <div class="col-md-4 noPadding">
                <label class="generalLabel">Fee Type</label>
                <select
                  class="form-control"
                  v-model="draftChangesExtraFees.GlobalFeeType"
                >
                  <option :value="null">Please Select</option>
                  <option
                    v-for="item in feesTypesList"
                    :value="item.FeeType"
                    :key="item.FeeType"
                  >
                    {{ item.Description }}
                  </option>
                </select>
              </div>
              <div class="col-md-3">
                <label for="serviceFee" class="generalLabel">Amount</label>
                <input
                  v-model="draftChangesExtraFees.FeeAmount"
                  class="form-control"
                  id="DCP-feeAmount"
                  v-myMask="{
                    alias: 'currency',
                    prefix: '$',
                    rightAlign: false,
                  }"
                  placeholder="$0.00"
                />
              </div>
              <div class="col-md-3">
                <label for="serviceFee" class="generalLabel">Date</label>

                <DatePicker
                  mode="date"
                  :locale="'en-US'"
                  v-model="draftChangesExtraFees.FeeDate"
                  :model-config="modelConfig"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      id="DCP-feeDate"
                      class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="Select Date"
                    />
                  </template>
                </DatePicker>
              </div>
              <div
                v-showIfAuthorized="54983"
                class="col-md-1"
                style="padding-top: 28px"
              >
                <button
                  @click="addFees()"
                  type="button"
                  class="btn btn-success waves-effect waves-light"
                >
                  <i class="fas fa-plus-circle"></i>
                </button>
              </div>
            </div>

            <div class="row pt-3">
              <label v-for="(item, index) in applicableFees" :key="index">
                <div style="display: flex; align-items: stretch">
                  <label class="generalLabel pr-2">{{ index + 1 }}.</label>
                  <label class="generalLabel pr-2"
                    >Fee Type:<label class="ml-1" style="color: #6c757d">{{
                      item.GlobalFeeType
                    }}</label></label
                  >
                  <label class="generalLabel pr-2"
                    >Amount:<label class="ml-1" style="color: #6c757d">{{
                      formatMoney(item.FeeAmount)
                    }}</label></label
                  >
                  <label class="generalLabel pr-1"
                    >Amount Date:<label class="ml-1" style="color: #6c757d">{{
                      item.FeeDate
                    }}</label></label
                  >

                  <span
                    style="padding: 5px; cursor: pointer; height: 20px"
                    class="badge badge-danger"
                    @click="deleteFee(index)"
                  >
                    Cancel</span
                  >
                </div>
              </label>
            </div>
          </div>
          <div class="col-md-12 noPadding mt-3">
            <div>
              <input
                type="checkbox"
                :checked="isEmergency"
                @change="changeEmergency()"
                class="checkbox-input generalCheckBox"
              />
              <label class="generalLabel" style="margin-right: 27px"
                >Emergency (2 B.days):</label
              >
            </div>
          </div>
 
          <div
            class="col-md-12"
            style="display: flex; justify-content: flex-end"
          >
            <button
              :disabled="isLoading"
              @click="requestDraftChanges()"
              class="btn btn-primary"
            >
              {{ checkAuth(12411) ? "Request and Approve" : "Request Changes" }}
            </button>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <div
      class="backgroundLock"
      v-if="isBulkAddPopupOpen"
      style="-webkit-box-align: center; align-items: center; display: flex"
    >
      <div
        style="position: relative; margin: 10% auto"
        class="offset-lg-12 col-xl-5 offset-lg-2 col-lg-8 col-md-12 col-sm-12"
      >
        <BulkAddDraftPopup
          @closeBulkAddDraftPopup="closeBulkAddDraftPopup"
          @addBulkDrafts="addBulkDrafts"
        />
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
import _orderby from "lodash";
import { DatePicker } from "v-calendar";
import BulkAddDraftPopup from "./BulkAddDraftPopup";
export default {
  name: "BulkRequestPopUp",
  components: { DatePicker, BulkAddDraftPopup },
  mixins: [formatMixin, utilitiesMixin],
  props: {
    CustomerId: {
      Type: Number,
      default: 0,
    },
    OfficeId: {
      Type: Number,
      default: 0,
    },
    isDpg: {
      Type: Boolean,
      default: false,
    },
    drafts: {
      Type: Array,
      default: [],
    },
    feesTypesList: {
      Type: Array,
      default: [],
    },
    cashFlowList: {
      Type: Array,
      default: [],
    },
  },
  data() {
    return {
      isCashFlowNegative: false,
      isLoading: false,
      isBulkAddPopupOpen: false,
      draftList: [],
      draftListBackup: [],
      appliedList: [],
      addedDraftList: [],
      editDraftDaysChecked: false,
      draftDayOption: 0,
      draftForwardDays: 3,
      draftBackwardDays: -3,
      draftMonthSelected: false,
      draftMonthCount: 1,
      draftAmountSelected: false,
      draftAmount: 0,
      isDayGridNewRowActive: false,
      dayGridFrom: null,
      dayGridTo: null,
      dayGridData: [],
      changesApplied: false,
      selectAllDrafts: true,
      isEmergency: false,
      draftNotes: "",
      draftChangesExtraFees: {
        GlobalFeeType: null,
        FeeAmount: 0,
        FeeDate: "",
      },
      applicableFees: [],
      draftListSelection: [],
      isDraftGridNewRowActive: false,
      newDraft: {
        NewDate: "",
        Amount: 0,
      },
      modelConfig: {
        type: "string",
        mask: "MM/DD/YYYY",
      },
      nextDayOfWeek: null,
      prevDayOfWeek: null,
      daysOfWeekList: [
        { Id: 0, Name: "Sunday" },
        { Id: 1, Name: "Monday" },
        { Id: 2, Name: "Tuesday" },
        { Id: 3, Name: "Wednesday" },
        { Id: 4, Name: "Thursday" },
        { Id: 5, Name: "Friday" },
        { Id: 6, Name: "Saturday" },
      ],
      isAllDeleted: false,
    };
  },
  unmounted() {},
  async mounted() {
    await this.bindDrafts();
  },

  methods: {
    getFeeTypeDescription(value){
      let rtn = "";
      if (this.feesTypesList.length > 0 && value != "") {
        let index = this.feesTypesList.findIndex((x) => x.FeeType == value);
        if (index > -1) {
          rtn = this.feesTypesList[index].Description;
        }
      }
      return rtn;
    },
    calculateCashFlowForBulkDraft() {
      if (this.checkAuth(956782)) {
        if (this.cashFlowList && this.cashFlowList.length > 0) {
          let list = deepCopy(this.cashFlowList);
          for(let item of this.draftList)
          {  
            let debitId = parseInt(item.DebitId);
    
             if(item.isDeleting == true)
             {
                  //for deleting
                  let deletedIndx = list.findIndex(x=> parseInt(x.ReferenceId) == debitId);
                  if(deletedIndx > -1)
                  {
                    list[deletedIndx].IsDeletedDraft=true;
                  }
             }
             else if(item.isEditing == true && debitId> 0)
             {
                  //for updating
                  let updatedIndx = list.findIndex(x=> parseInt(x.ReferenceId) == debitId);
                  if(updatedIndx > -1)
                  {
                    list[updatedIndx].IsDeletedDraft=true;
                  }
                  list.push({
                      Id: "X",
                      ReferenceId: debitId,
                      IsDraft: true,
                      TransactionType: list[updatedIndx].TransactionType,
                      TransactionTypeDesc:list[updatedIndx].TransactionTypeDesc,
                      Date: item.NewDate == item.OriginalDate ? list[updatedIndx].Date : item.NewDate,
                      Amount: parseFloat(item.NewAmount),
                      Memo: list[updatedIndx].Memo,
                      Balance: 0,
                    
                    });
             }
             else if (item.isEditing == true){
               //for inserting
               list.push({
                  Id: "X",
                  ReferenceId: debitId,
                  IsDraft: true,
                  TransactionType: "A",
                  TransactionTypeDesc:"Monthly Draft",
                  Date: item.NewDate,
                  Amount: parseFloat(item.NewAmount),
                  Memo: "Monthly Draft",
                  Balance: 0,
                 
                });
             }
          }

        
          let fees = this.applicableFees;
          if (fees && fees.length > 0) {
            for (let item of fees) {
              let feeAmount = parseFloat(item.FeeAmount);
              let feeDate = new Date(item.FeeDate);
              list.push({
                Id: "X",
                IsPay: true,
                TransactionType: item.GlobalFeeType,
                TransactionTypeDesc: this.getFeeTypeDescription(
                  item.GlobalFeeType
                ),
                Date: feeDate.toString("MM/dd/yyyy"),
                Amount: feeAmount,
                Memo: this.getFeeTypeDescription(item.GlobalFeeType),
                Balance: 0,
              });
            }
          }
          this.$emit("calculateCashFlowForBulkDraft", list);
          this.isCashFlowNegative = this.checkCashFlowNegative();
        }
      }
    },
    openCashFlowPanel() {
      this.$emit("openCashFlowPanel");
    },
    checkCashFlowNegative() {
      let rtn = false;
      if (this.$parent) {
        rtn = this.$parent.checkCashFlowNegativeForPopups();
      }
      return rtn;
    },
    deleteSelected(event) {
      if (event && event.currentTarget) {
        let checked = event.currentTarget.checked;

        for (let item of this.draftList) {
          if (item.isEditing == true) {
            item.isDeleting = checked;
          }
        }
      }
    },
    addBulkDrafts(list) {
      for (let item of list) {
        this.draftList.unshift(item);
        this.addedDraftList.push(item);
        this.calculateCashFlowForBulkDraft();
      }
      this.isBulkAddPopupOpen = false;
    },
    closeBulkAddDraftPopup() {
      this.isBulkAddPopupOpen = false;
    },
    async bindDrafts() {
      this.isLoading = true;
      let err, result;
      [err, result] = await this.$store.dispatch(types.GET_DRAFT_LIST, {
        CustomerId: this.CustomerId,
        OfficeId: this.OfficeId,
      });
      this.isLoading = false;

      if (result && result.Data) {
        this.draftList =
          result.Data.length > 0
            ? _orderby.orderBy(
                result.Data.filter((x) => x.Active == "Y").map((x) => {
                  return {
                    EffectiveDate: x.EffectiveDate,
                    DebitId: x.DebitId,
                    Amount: x.Amount,
                    Memo: x.Memo,
                    isEditing: true,
                    isDeleting: false,
                    NewDate: x.EffectiveDate,
                    NewAmount: x.Amount,
                  };
                }),
                "EffectiveDate",
                "asc"
              )
            : [];
        this.draftListBackup = deepCopy(this.draftList);
      }
    },
    async requestDraftChanges() {
      if (this.CustomerId == 0) {
        this.$swal("Warning!", "Invalid customer id", "warning");
        return;
      }

      let selectedDrafts = this.draftList.filter((x) => x.isEditing == true);

      if (selectedDrafts.length == 0) {
        this.$swal("Warning!", "No drafts selected.", "warning");
        return;
      }

      if (this.draftNotes.trim().length == 0) {
        this.$swal("Warning!", "Notes field is mandatory", "warning");
        return;
      }

      let changeDetails = [];
      for (let i = 0; i < selectedDrafts.length; i++) {
        if (
          selectedDrafts[i].EffectiveDate != selectedDrafts[i].NewDate ||
          selectedDrafts[i].Amount != selectedDrafts[i].NewAmount ||
          selectedDrafts[i].isDeleting
        ) {
          let amount = parseFloat(selectedDrafts[i].NewAmount);
          if (selectedDrafts[i].isDeleting) {
            amount = 0;
          }

          changeDetails.push({
            OriginalDate: this.formatShortDate(selectedDrafts[i].EffectiveDate),
            NewDate: this.formatShortDate(selectedDrafts[i].NewDate),
            GlobalDebitId:
              selectedDrafts[i].DebitId > 0 ? selectedDrafts[i].DebitId : 0,
            Amount: amount,
          });
        }
      }

      if (changeDetails.length == 0) {
        this.$swal("Warning!", "Nothing to change", "warning");
        return;
      }
      this.$swal
        .fire({
          title: "Confirm",
          text: "Are you sure to submit this request?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then(async (dialog) => {
          this.isLoading = true;
          if (dialog.value) {
            let err, result;
            [err, result] = await this.$store.dispatch(types.CREATE_DRAFT, {
              CustomerId: this.CustomerId,
              ApplicableFees: this.applicableFees,
              Notes: this.draftNotes,
              IsEmergency: this.isEmergency,
              ChangeDetails: changeDetails,
            });

            if (result) {
              let successMessage = this.checkAuth(12411)
                ? "The requested changes are approved."
                : "Draft changes requested.";
              this.$swal("Success!", successMessage, "success");
              this.$emit("closeBulkRequestPopup", this.checkAuth(12411));
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
          this.isLoading = false;
        });
    },
    addNewDraft() {
      //this.isDraftGridNewRowActive = true;
      this.isBulkAddPopupOpen = true;
    },
    cancelBulkDrafts() {
      this.addedDraftList = [];
      this.draftList = this.draftList.filter((x) => x.DebitId > 0);
      this.calculateCashFlowForBulkDraft();
    },

    addFees() {
      if (
        this.draftChangesExtraFees.GlobalFeeType != null &&
        this.draftChangesExtraFees.FeeAmount > 0 &&
        this.draftChangesExtraFees.FeeDate != ""
      ) {
        let item = deepCopy(this.draftChangesExtraFees);
        item.FeeDate = new Date(item.FeeDate).toString("M/d/yyyy");
        item.FeeAmount = parseFloat(item.FeeAmount);

        this.applicableFees.push(item);
        this.draftChangesExtraFees.GlobalFeeType = null;
        this.draftChangesExtraFees.FeeAmount = 0;
        this.draftChangesExtraFees.FeeDate = "";
        this.calculateCashFlowForBulkDraft();
      } else {
        this.$swal(
          "Warning!",
          "Fee type, amount and date fields are required",
          "warning"
        );
      }
    },
    deleteFee(indx) {
      if (indx > -1) {
        this.applicableFees.splice(indx, 1);
        this.calculateCashFlowForBulkDraft();
      }
    },
    changeEmergency() {
      this.isEmergency = !this.isEmergency;
    },
    selectAllDraftChanges() {
      this.selectAllDrafts = !this.selectAllDrafts;
      for (let item of this.draftList) {
        item.isEditing = this.selectAllDrafts;
        item.isDeleting = this.isAllDeleted;
        this.updateDraftData(item);
        this.calculateCashFlowForBulkDraft();
      }
    },
    selectDraft(item) {
      item.isEditing = !item.isEditing;
      item.isDeleting = this.isAllDeleted;
      this.updateDraftData(item);
      this.calculateCashFlowForBulkDraft();
    },
    updateDraftData(item) {
      if (item.isEditing == false) {
        let indx = this.draftListBackup.findIndex(
          (x) => x.DebitId == item.DebitId
        );
        if (indx > -1) {
          item.NewDate = this.draftListBackup[indx].EffectiveDate;
          item.NewAmount = this.draftListBackup[indx].Amount;
        }
      } else {
        let indx = this.appliedList.findIndex((x) => x.DebitId == item.DebitId);
        if (indx > -1) {
          item.NewDate = this.appliedList[indx].NewDate;
          item.NewAmount = this.appliedList[indx].NewAmount;
        }
      }
    },
    deleteDraft(item) {
      item.isDeleting = !item.isDeleting;
      this.calculateCashFlowForBulkDraft();
    },
    getDraftDateColorClass(item1, item2, isEditing) {
      let rtn = "";
      if (
        this.formatShortDate(item1) != this.formatShortDate(item2) &&
        isEditing
      ) {
        rtn = "selectedDraft";
      }

      return rtn;
    },
    getDraftColorClass(item1, item2, isEditing) {
      let rtn = "";
      if (item1 != item2 && isEditing) {
        rtn = "selectedDraft";
      }

      return rtn;
    },
    getSelection(draftItem, index) {
      let indx = this.draftListSelection.findIndex(
        (x) => x.DebitId == draftItem.DebitId
      );
      if (indx > -1) {
        if (index > -1) {
          this.draftList[index].isEditing =
            this.draftListSelection[indx].isEditing;
          this.draftList[index].isDeleting =
            this.draftListSelection[indx].isDeleting;
        }
      }
    },
    applyChanges() {
      this.draftListSelection = this.draftList.map((x) => {
        return {
          DebitId: x.DebitId,
          isEditing: x.isEditing,
          isDeleting: x.isDeleting,
        };
      });
      this.draftList = deepCopy(this.draftListBackup);
      for (let item of this.draftList) {
        this.getSelection(item, this.draftList.indexOf(item));
      }
      // for (let index in this.draftList) {
      //   this.draftList[index].isEditing =
      //     this.draftListSelection[index].isEditing;
      //   this.draftList[index].isDeleting =
      //     this.draftListSelection[index].isDeleting;
      // }

      for (let draftItem of this.draftList) {
        let forwardDays = parseInt(this.draftForwardDays);
        let backwardDays = parseInt(this.draftBackwardDays);
        if (this.draftDayOption == 1 && forwardDays > 0) {
          draftItem.NewDate = this.formatShortDate(
            this.addDays(draftItem.NewDate, forwardDays)
          );
        } else if (this.draftDayOption == 2 && backwardDays < 0) {
          draftItem.NewDate = this.formatShortDate(
            this.addDays(draftItem.NewDate, backwardDays)
          );
        } else if (this.draftDayOption == 3 && this.dayGridData.length > 0) {
          let draftDay = new Date(draftItem.NewDate).getDate();

          for (let dayItem of this.dayGridData) {
            if (parseInt(dayItem.DayFrom) == parseInt(draftDay)) {
              let draftDate = new Date(draftItem.NewDate);

              let maxDayCount = new Date(
                draftDate.getFullYear(),
                draftDate.getMonth() + 1,
                0
              ).getDate();

              let day = parseInt(dayItem.DayTo);
              if (day > maxDayCount) {
                day = maxDayCount;
              }

              let newDate = new Date(
                draftDate.getFullYear(),
                draftDate.getMonth(),
                day
              );
              while (newDate.getMonth() != draftDate.getMonth()) {
                newDate = this.addDays(newDate, -1);
              }

              draftItem.NewDate = this.formatShortDate(newDate);
            }
          }
        } else if (this.draftDayOption == 4) {
          if (this.nextDayOfWeek != null) {
            let currentDay = new Date(draftItem.NewDate).getDay();

            if (currentDay == this.nextDayOfWeek) {
              draftItem.NewDate = this.addDays(draftItem.NewDate, 7);
            } else if (currentDay < this.nextDayOfWeek) {
              draftItem.NewDate = this.addDays(
                draftItem.NewDate,
                parseInt(this.nextDayOfWeek - currentDay)
              );
            } else {
              draftItem.NewDate = this.addDays(
                draftItem.NewDate,
                7 - parseInt(currentDay - this.nextDayOfWeek)
              );
            }
            draftItem.NewDate = this.formatShortDate(draftItem.NewDate);
          } else {
            this.$swal("Warning!", "Please select a day.", "warning");
          }
        } else if (this.draftDayOption == 5) {
          if (this.prevDayOfWeek != null) {
            let currentDay = new Date(draftItem.NewDate).getDay();

            if (currentDay == this.prevDayOfWeek) {
              draftItem.NewDate = this.addDays(draftItem.NewDate, -7);
            } else if (currentDay < this.prevDayOfWeek) {
              let diff = parseInt(this.prevDayOfWeek - currentDay);
              draftItem.NewDate = this.addDays(
                draftItem.NewDate,
                parseInt(7 - diff) * -1
              );
            } else {
              draftItem.NewDate = this.addDays(
                draftItem.NewDate,
                parseInt(currentDay - this.prevDayOfWeek) * -1
              );
            }
            draftItem.NewDate = this.formatShortDate(draftItem.NewDate);
          } else {
            this.$swal("Warning!", "Please select a day.", "warning");
          }
        }

        let draftMonths = parseInt(this.draftMonthCount);
        if (this.draftMonthSelected == true && draftMonths > 0) {
          draftItem.NewDate = this.formatShortDate(
            this.addMonthsFix(new Date(draftItem.NewDate), draftMonths)
          );
        }

        if (this.draftAmount > 0 && this.draftAmount != draftItem.Amount) {
          draftItem.NewAmount = this.draftAmount;
        }
      }

      this.changesApplied = true;
      this.appliedList = deepCopy(this.draftList);
      for (let item of this.draftList.filter((x) => x.isEditing == false)) {
        this.updateDraftData(item);
      }
      for (let item of this.addedDraftList) {
        this.draftList.unshift(item);
      }

      this.calculateCashFlowForBulkDraft();
    },
    draftGridCancelRow() {
      this.isDraftGridNewRowActive = false;
    },
    draftGridSave() {
      if (this.newDraft) {
        if (this.newDraft.Amount <= 0) {
          this.$swal("Warning!", "Draft Amount required.", "warning");
          return;
        }

        if (this.newDraft.NewDate == "") {
          this.$swal("Warning!", "New Date invalid.", "warning");
          return;
        }

        let indx = this.draftList.findIndex(
          (x) =>
            x.NewDate == this.newDraft.NewDate &&
            parseFloat(x.NewAmount) == parseFloat(this.newDraft.Amount)
        );
        if (indx > -1) {
          this.$swal(
            "Warning!",
            "This values already exists in grid.",
            "warning"
          );
          return;
        }

        let draftItem = {
          EffectiveDate: "",
          DebitId: 0,
          Amount: 0,
          isEditing: true,
          isDeleting: false,
          NewDate: this.newDraft.NewDate,
          NewAmount: this.newDraft.Amount,
        };

        this.draftList.unshift(draftItem);

        this.addedDraftList.push(draftItem);
        this.newDraft.NewDate = "";
        this.newDraft.Amount = 0;
        this.isDraftGridNewRowActive = false;
      }
    },
    dayGridCancelRow() {
      this.dayGridFrom = null;
      this.dayGridTo = null;
      this.isDayGridNewRowActive = false;
    },
    addNewDayGridData() {
      this.isDayGridNewRowActive = true;
    },
    dayGridSave() {
      this.dayGridFrom = parseInt(this.dayGridFrom);
      this.dayGridTo = parseInt(this.dayGridTo);

      if (this.dayGridFrom > 0 && this.dayGridTo > 0) {
        let indx = this.dayGridData.findIndex(
          (x) => x.DayFrom == this.dayGridFrom
        );
        if (indx == -1) {
          let latestItem = _orderby.orderBy(this.dayGridData, "Id", "desc");
          let latestId =
            latestItem && latestItem.length > 0 ? latestItem[0].Id + 1 : 0;

          this.dayGridData.push({
            Id: latestId,
            DayFrom: this.dayGridFrom,
            DayTo: this.dayGridTo,
          });
          this.dayGridCancelRow();
        } else {
          this.$swal(
            "Warning!",
            "Another setting already exists in grid for this day.",
            "warning"
          );
        }
      } else {
        this.$swal("Warning!", "Day parameters invalid.", "warning");
      }
    },
    removeDayGridItem(id) {
      let indx = this.dayGridData.findIndex((x) => x.Id == id);
      if (indx > -1) {
        this.dayGridData.splice(indx, 1);
      }
    },
    changeDraftDayOption(value) {
      this.draftDayOption = parseInt(value);
      this.editDraftDaysChecked = true;
      if (this.draftDayOption != 4) {
        this.nextDayOfWeek = null;
      }
      if (this.draftDayOption != 5) {
        this.prevDayOfWeek = null;
      }
    },
    changeEditDraftDays() {
      this.editDraftDaysChecked = !this.editDraftDaysChecked;
      if (this.editDraftDaysChecked == false) {
        this.draftDayOption = 0;
        this.dayGridData = [];
      }
    },
    changeMonthsSelect() {
      this.draftMonthSelected = !this.draftMonthSelected;
      if (this.draftMonthSelected == false) {
        this.draftMonthCount = 1;
      }
    },
    changeDraftAmountSelect() {
      this.draftAmountSelected = !this.draftAmountSelected;
      if (this.draftAmountSelected == false) {
        this.draftAmount = 0;
      }
    },
    closePanel() {
      this.$emit("closeBulkRequestPopup", false);
    },
  },
};
</script>

<style>
.closeButtonBulk {
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButtonBulk:hover {
  color: #ff3860;
}
.selectedDraft {
  color: #24d15f !important;
}
.editedDraft {
  color: #000 !important;
}
.miniTextBox {
  padding: 2px 4px !important;
  line-height: 13px !important;
  height: 28px !important;
}
</style>
