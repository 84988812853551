export default {
  GET_CHARGES:"GET_CHARGES",
  GET_DRAFTS: "GET_DRAFTS",
  GET_TRANSACTIONS: "GET_TRANSACTIONS",
  GET_FEES: "GET_FEES",
  GET_DEPOSITS: "GET_DEPOSITS",
  GET_DOCUMENTS: "GET_DOCUMENTS",
  GET_PAYMENTS: "GET_PAYMENTS",
  GET_GCS_NOTES: "GET_GCS_NOTES",
  GET_CASHFLOW: "GET_CASHFLOW",
  GET_CASHFLOW_DATA: "GET_CASHFLOW_DATA",
  GET_STATEMENT: "GET_STATEMENT",
  GET_INFO: "GET_INFO",
  GET_GLOBAL_INFO_DATA: "GET_GLOBAL_INFO_DATA",
  PHONE_PAY_INFO: "PHONE_PAY_INFO",
  GET_NEW_CASHFLOW: "GET_NEW_CASHFLOW",
};
