<template>

    <div style="position: relative;" class="noPadding">
        <div v-if="isLoading" class="loading">
            <div class="text-center" style="margin-top:100px;">
                <i class="spinner-grow text-info m-2" style="color:#800080 !important;"></i>
            </div>
        </div>
        <div v-if="!isLoading" style="width:35px; height: 50px; border-left:1px solid #fff; background-color: #800080; position: absolute; top:0; right:0; z-index:1000;"></div>
        <iframe
                id="kowledgeOwlControl"
                v-if="showIframe"
                :style="'border: none !important; height:' + iframeHeight + 'px;'"
                width="100%"
                :src="iframeSrc"
                @load="onLoad"
        ></iframe>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import utilitiesMixin from "@/mixins/utilitiesMixin";
    export default {
        name: "Documentation",
        mixins: [utilitiesMixin],
        data() {
            return {
                isLoading:true,
                iframeSrc: "knowledgeowlwidget.html",
                iframeHeight: 600,
                accessToken:"",
                initialize:false,
                showIframe:false,

            };
        },
       
        mounted(){
            window.addEventListener("resize", this.handleResize);
            this.handleResize();

            

        },
        unmounted(){
            window.removeEventListener("resize", this.handleResize);
            

        },
        methods:{
            async authenticateWidget(token){
                this.accessToken = token;
                this.initialize = true;
                this.showIframe=true;
            },
           async openWidget(){
                this.isLoading=true;
               this.initialize = false;
                this.showIframe = true;
                let ctrl = document.getElementById("kowledgeOwlControl");
                if(typeof ctrl != "undefined" && ctrl != null && ctrl.contentWindow != null)
                {

                    let hrf  = window.location.href;
                    let route = this.cleanRoute(hrf);
                    ctrl.contentWindow.open({route:route});
                }
               this.isLoading=false;
               /* await this.sleep(100);
               this.showIframe = true;*/

            },
            cleanRoute(str) {
                return str.substring(str.lastIndexOf('/') + 1);
            },

            async onLoad(frame) {
                let route = "";
                let hrf  = window.location.href;
                route = this.cleanRoute(hrf);

               // if(this.accessToken != "" && this.initialize==true)
                //{

                    frame.srcElement.contentWindow.initialize({token: this.accessToken,route:route});
                    this.isLoading=false;

               // }
               /* else
                {

                    let route = "";
                    let hrf  = window.location.href;
                    route = this.cleanRoute(hrf);
                    frame.srcElement.contentWindow.open({route:route});
                    this.isLoading=false;
                }*/

            },
            handleResize() {
                this.getIFrameHeight();
            },
            getIFrameHeight() {
                this.iframeHeight = window.innerHeight - 72;
            },
        },

    };
</script>

<style>
</style>
