<template>
  <div class="row admin-page officePage">
    <div v-if="saving" class="loading">
      <div class="text-center" style="margin-top: 350px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
</div>
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12">
      <button
        class="col-xl-12 btn btn-primary waves-effect waves-light"
        @click="handleAddNew()"
      >
        Add New Type
      </button>
      <div class="col-md-12 mt-2 noPadding">
        <input
          autocomplete="off"
          id="txtShowInactive"
          type="checkbox"
          class="checkbox-input generalCheckBox"
          style="
            border-radius: 4px !important;
            border: solid 1px #d1d1d1 !important;
            vertical-align: text-top;
          "
          v-model="showInactiveItems"
        />

        <label class="generalLabel" style="font-weight: normal"
          >Show Inactive Template Types</label
        >
      </div>
      <div class="filter form-group row">
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            v-model="typeFilter"
            placeholder="Filter Type"
          />
        </div>
        <div class="list col-md-12">
          <div
            @click="handleSelection(item)"
            class="office item row"
            v-for="(item, index) in filteredTypes"
            :key="item.Id"
            :class="{ active: activeItem === item.Id }"
            :disabled="saving || deleting"
            :style="[
              index % 2 === 0
                ? { 'background-color': '#F1EFEF' }
                : { 'background-color': '#FFFFFF' },
            ]"
          >
            <span :class="{ adminActiveMenuItem: activeItem === item.Id }">
              <i class="ri-table-alt-fill"></i>
              <span style="margin-left: 8px">{{ item.Name }}</span>
            </span>
          </div>
        </div>
        <div
          v-if="isMobileDevice()"
          style="
            border-top: 1px solid rgb(0, 0, 0, 0);
            margin: 20px 0 0 9px;
            padding-bottom: 20px;
          "
          class="col-md-12 scrollToSelectedItem"
        ></div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-8 col-md-12 col-sm-12" v-if="isEditing">
      <div class="panelContent" style="padding-top: 0">
        <div class="form-group row">
          <div class="col-md-12">
            <label for="DTMname">Name: *</label>
            <input
              autocomplete="off"
              id="DTMname"
              class="form-control"
              type="text"
              placeholder="Enter Name"
              v-model="selectedRow.Name"
              :class="v$.selectedRow.Name.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.Name.$touch"
            />
          </div>
          <div class="col-md-12 pt-4" v-if="selectedRow.Id > 0">
            <input
              id="txtIsActive"
              type="checkbox"
              class="checkbox-input generalCheckBox"
              v-model="selectedRow.IsActive"
              style="
                border-radius: 4px !important;
                border: solid 1px #d1d1d1 !important;
              "
            />

            <label
              class="generalLabel"
              style="font-weight: normal"
              for="txtIsActive"
              >Is Active</label
            >
          </div>

          <div class="col-md-12">
            <div
              class="col-md-10 col-xs-12 legend mt-4 form-group"
              style="border: 1px solid #b4b5b7"
            >
              <h4 style="color: #6c757d">Auto Disposition</h4>

              <div class="col-md-12 d-flex" style="align-items: center">
                <div class="col-md-3">
                  <label for="dttm-whenSend">When Sent:</label>
                </div>
                <div class="col-xl-6 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                  <select
                    v-model="selectedRow.DispositionIdWhenSent"
                    class="form-control"
                    id="dttm-whenSend"
                  >
                    <option value="null">Select</option>
                    <option
                      v-for="item in sortedDispositionTypes"
                      :key="item.Id"
                      :value="item.Id"
                    >
                      {{ item.Name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-12 d-flex" style="align-items: center">
                <div class="col-md-3">
                  <label for="dttm-whenVoided">When Voided:</label>
                </div>
                <div class="col-xl-6 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                  <select
                    v-model="selectedRow.DispositionIdWhenVoided"
                    class="form-control"
                    id="dttm-whenVoided"
                  >
                    <option value="null">Select</option>
                    <option
                      v-for="item in sortedDispositionTypes"
                      :key="item.Id"
                      :value="item.Id"
                    >
                      {{ item.Name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-12 d-flex mb-3" style="align-items: center">
                <div class="col-md-3">
                  <label for="dttm-whenCompleted">When Completed:</label>
                </div>
                <div class="col-xl-6 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                  <select
                    v-model="selectedRow.DispositionIdWhenCompleted"
                    class="form-control"
                    id="dttm-whenCompleted"
                  >
                    <option value="null">Select</option>
                    <option
                      v-for="item in sortedDispositionTypes"
                      :key="item.Id"
                      :value="item.Id"
                    >
                      {{ item.Name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div
            class="offset-xl-9 offset-lg-4 col-xl-3 col-lg-4 col-md-6 col-xs-12 pt-4"
          >
            <button
              class="btn btn-success form-control"
              id="txtsave"
              :disabled="v$.$invalid || saving"
              @click="handleSave()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import _orderby from "lodash";
import deepCopy from "@/helpers/deepCopy";
import types from "./types";
import globalTypes from "@/store/types";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";

const init = {
  Id: 0,
  Name: "",
  IsActive: false,
  DispositionIdWhenSent: null,
  DispositionIdWhenVoided: null,
  DispositionIdWhenCompleted: null,
};
export default {
  name: "DocusignTemplateTypeManagement",
  components: {},

  data() {
    return {
      saving: false,
      deleting: false,
      isEditing: false,
      activeItem: null,
      typeFilter: "",
      oldValue: JSON.stringify(deepCopy(init)),
      v$: useValidate(),
      selectedRow: Object.assign({}, init),
      isCancelledBeforeSave: false,
      showInactiveItems: false,
    };
  },
  mixins: [formatMixin, utilitiesMixin],
  computed: mapState({
    dispositionTypes: (state) => state.globals.dispositionOptionList,
    sortedDispositionTypes() {
      return _orderby.orderBy(this.dispositionTypes, "Name", "asc");
    },
    templateTypeList: (state) => state.docusignManagement.templateTypeList,
    filteredTypes() {
      return _orderby.orderBy(
        this.templateTypeList.filter((item) => {
          let rtn =
            item.Name.toLowerCase().indexOf(this.typeFilter.toLowerCase()) >
              -1 &&
            ((!this.showInactiveItems && item.IsActive) ||
              this.showInactiveItems);
          return rtn;
        }),
        "Name"
      );
    },
  }),
  async mounted() {
    await Promise.all([
      this.$store.dispatch(globalTypes.GET_DISPOSITION_OPTIONS_FOR_ADMIN),
      this.$store.dispatch(types.GET_DOCUSIGN_TEMPLATE_TYPE),
    ]);
  },
  methods: {
    async handleSelection(item) {
      if (
        this.oldValue != JSON.stringify(this.selectedRow) &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (!dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      }
      if (!this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(item);
        this.activeItem = item.Id;
        this.oldValue = JSON.stringify(this.selectedRow);
      } else {
        this.activeItem = this.selectedRow.Id;
      }
      this.isCancelledBeforeSave = false;
      this.isEditing = true;

      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    async handleAddNew() {
      if (this.oldValue != JSON.stringify(this.selectedRow)) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (!dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      }

      if (!this.isCancelledBeforeSave) {
        this.activeItem = null;
        this.selectedRow = Object.assign({}, init);
        this.oldValue = JSON.stringify(init);
      }
      this.isCancelledBeforeSave = false;
      this.isEditing = true;
    },
    async handleSave() {
      if (this.oldValue == JSON.stringify(this.selectedRow)) {
        this.$swal("Warning!", "No Changes Detected!", "warning");

        return;
      }
      this.saving = true;
      let err, result, type, payload;
      if (this.selectedRow.Id == 0) {
        type = types.INSERT_DOCUSIGN_TEMPLATE_TYPE;
        payload = {
          Name: this.selectedRow.Name,
          DispositionIdWhenSent: this.selectedRow.DispositionIdWhenSent,
          DispositionIdWhenVoided: this.selectedRow.DispositionIdWhenVoided,
          DispositionIdWhenCompleted:
            this.selectedRow.DispositionIdWhenCompleted,
        };
      } else {
        type = types.UPDATE_DOCUSIGN_TEMPLATE_TYPE;
        payload = {
          Id: this.selectedRow.Id,
          Name: this.selectedRow.Name,
          IsActive: this.selectedRow.IsActive,
          DispositionIdWhenSent: this.selectedRow.DispositionIdWhenSent,
          DispositionIdWhenVoided: this.selectedRow.DispositionIdWhenVoided,
          DispositionIdWhenCompleted:
            this.selectedRow.DispositionIdWhenCompleted,
        };
      }

      [err, result] = await this.$store.dispatch(type, payload);
      if (result) {
        this.$swal("Success", result.Message, "success");
        await this.$store.dispatch(types.GET_DOCUSIGN_TEMPLATE_TYPE);

        if (this.selectedRow.Id == 0) {
          this.reset();
        } else {
          let indx = this.templateTypeList.findIndex(
            (x) => x.Id == this.selectedRow.Id
          );
          this.selectedRow = deepCopy(this.templateTypeList[indx]);
          this.oldValue = JSON.stringify(this.selectedRow);
          this.activeItem = this.selectedRow.Id;
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.saving = false;
    },
    reset() {
      this.selectedRow = Object.assign({}, init);
      this.oldValue = JSON.stringify(init);
      this.isEditing = false;
      this.activeItem = null;
    },
  },
  validations() {
    return {
      selectedRow: {
        Name: { required },
      },
    };
  },
};
</script>

<style></style>
