<template>
  <div
    class="col-md-12 newPanelWrapper noMargin dcp-mediaScreen"
    style="background-color: #ffffff; z-index: 1000"
  >
    <div v-if="isLoading" class="newLoading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="closeButton2" v-on:click.prevent.stop="closePanel">
      <i class="fas fa-window-close"></i>
    </div>
    <div class="col-md-12 row" v-if="checkAuth(54983) && isDpg == false">
      <div class="col-md-4">
        <label class="generalLabel">Fee Type</label>
        <select class="form-control" v-model="extraFees.GlobalFeeType">
          <option :value="null">Please Select</option>
          <option
            v-for="item in feesTypesList"
            :value="item.FeeType"
            :key="item.FeeType"
          >
            {{ item.Description }}
          </option>
        </select>
      </div>
      <div class="col-md-3">
        <label for="serviceFee" class="generalLabel">Amount</label>
        <input
          v-model="extraFees.FeeAmount"
          class="form-control"
          id="DCP-feeAmount"
          v-myMask="{
            alias: 'currency',
            prefix: '$',
            rightAlign: false,
          }"
          placeholder="$0.00"
        />
      </div>
      <div class="col-md-3">
        <label for="serviceFee" class="generalLabel">Date</label>

        <DatePicker
          mode="date"
          :locale="'en-US'"
          v-model="extraFees.FeeDate"
          :model-config="modelConfig"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
              :value="inputValue"
              v-on="inputEvents"
              placeholder="Select Date"
            />
          </template>
        </DatePicker>
      </div>
      <div
        v-showIfAuthorized="54983"
        class="col-md-1"
        style="padding-top: 28px"
      >
        <button
          @click="addExtraFee()"
          type="button"
          class="btn btn-success waves-effect waves-light"
        >
          <i class="fas fa-plus-circle"></i>
        </button>
      </div>

      <perfect-scrollbar
        v-showIfAuthorized="54983"
        style="width: 100%; max-height: 150px; overflow-x: hidden !important"
      >
        <div class="col-md-12 pt-3">
          <label
            v-for="(item, index) in chargeChangeData.ApplicableFees"
            :key="index"
          >
            <div style="display: flex; align-items: stretch">
              <label class="generalLabel pr-2"
                >Fee Type:<label class="ml-1" style="color: #6c757d">{{
                  item.GlobalFeeType
                }}</label></label
              >
              <label class="generalLabel pr-2"
                >Amount:<label class="ml-1" style="color: #6c757d">{{
                  formatMoney(item.FeeAmount)
                }}</label></label
              >
              <label class="generalLabel pr-1"
                >Amount Date:<label class="ml-1" style="color: #6c757d">{{
                  item.FeeDate
                }}</label></label
              >

              <span
                style="padding: 5px; cursor: pointer; height: 20px"
                class="badge badge-danger"
                @click="deleteExtraFee(index)"
              >
                Cancel</span
              >
            </div>
          </label>
        </div>
      </perfect-scrollbar>
    </div>

    <div class="col-md-12">
      <label for="serviceFee" class="generalLabel">Notes</label>

      <textarea
        class="form-control textareaInput generalTextBox"
        rows="6"
        placeholder="Notes"
        v-model="chargeChangeData.Notes"
      ></textarea>
    </div>
    <div class="col-md-12 text-right mt-2" >
      <button
        type="button"
        class="btn btn-danger waves-effect waves-light ml-2"
        @click="openCashFlowPanel"
        v-if="isCashFlowNegative"
      >
        Negative Cash Flow!
      </button>
    </div>
    <div
      class="col-md-12 mt-3"
      style="display: flex; justify-content: flex-end; align-items: center"
    >
      <input
        type="checkbox"
        v-model="chargeChangeData.IsEmergency"
        class="checkbox-input generalCheckBox"
        style="
          border-radius: 4px !important;
          border: solid 1px #d1d1d1 !important;
        "
      />
      <label class="generalLabel mt-1">Emergency (2 B.days)</label>
      <button
        @click="submitChargeChanges()"
        type="button"
        class="btn btn-success waves-effect waves-light ml-2"
        :class="checkAuth(12411) ? 'col-md-6' : 'col-md-3'"
        :disabled="isLoading"
      >
        {{ checkAuth(12411) ? "Request and Approve" : "Submit" }}
      </button>
    </div>
  </div>
</template>

<script>
import types from "./types";
import globalTypes from "@/store/types";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
import { DatePicker } from "v-calendar";
export default {
  name: "ChargeChangesPopup",
  components: { DatePicker },
  props: {
    customerId: {
      Type: Number,
      default: 0,
    },
    changeDetails: {
      Type: Array,
      default: [],
    },
    feesTypesList: {
      Type: Array,
      default: [],
    },
    isDpg: {
      Type: Boolean,
      default: false,
    },
    cashFlowList: {
      Type: Array,
      default: [],
    },
  },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      isCashFlowNegative:false,
      isLoading: false,
      chargeChangeData: {
        Notes: "",
        IsEmergency: false,
        ApplicableFees: [],
      },
      extraFees: {
        GlobalFeeType: null,
        FeeAmount: 0,
        FeeDate: "",
      },
      modelConfig: {
        type: "string",
        mask: "MM/DD/YYYY",
      },
      creditorList: [],
    };
  },

  async mounted() {
    if (this.changeDetails.length == 0) {
      this.$swal("Warning!", "Nothing to change", "warning");
      return;
    }
    this.calculateCashFlowForChargeChanges();
  },
  methods: {
    openCashFlowPanel() {
      this.$emit("openCashFlowPanel");
    },
    calculateCashFlowForChargeChanges() {
      if(this.checkAuth(956782)){
        if (this.cashFlowList && this.cashFlowList.length > 0) {
          let list = deepCopy(this.cashFlowList);
          for (let item of this.changeDetails) {
            let indx = this.cashFlowList.findIndex(
              (x) => parseInt(x.ReferenceId) == parseInt(item.ChargeId)
            );
            if (indx > -1) {

              list[indx].IsDeletedPay = true;
              list.push({
                  Id: "X",
                  ReferenceId: parseInt(item.ChargeId),
                  IsPay: true,
                  TransactionType:  list[indx].TransactionType,
                  TransactionTypeDesc:list[indx].TransactionTypeDesc,
                  Date: item.ChargeDate == item.OriginalDate ? list[indx].Date : item.ChargeDate,
                  Amount: parseFloat(item.ChargeAmount),
                  Memo: list[indx].Memo,
                  Balance: 0,
                 
                });

          
            }
          
          }

          let fees = this.chargeChangeData.ApplicableFees;
          if (fees && fees.length > 0) {
            for (let item of fees) {
              let feeAmount = parseFloat(item.FeeAmount);
              let feeDate = new Date(item.FeeDate);
              list.push({
                Id: "X",
                IsPay: true,
                TransactionType: item.GlobalFeeType,
                TransactionTypeDesc: this.getFeeTypeDescription(
                  item.GlobalFeeType
                ),
                Date: feeDate.toString("MM/dd/yyyy"),
                Amount: feeAmount,
                Memo:this.getFeeTypeDescription(
                  item.GlobalFeeType
                ),
                Balance: 0,
              });
            }
          }
          this.$emit("calculateCashFlowForChargeChanges", list);
          this.isCashFlowNegative = this.checkCashFlowNegative();
        }
      }
    },
    getFeeTypeDescription(value){
      let rtn = "";
      if (this.feesTypesList.length > 0 && value != "") {
        let index = this.feesTypesList.findIndex((x) => x.FeeType == value);
        if (index > -1) {
          rtn = this.feesTypesList[index].Description;
        }
      }
      return rtn;
    },
    checkCashFlowNegative() {
      let rtn = false;
      if(this.$parent){
      rtn = this.$parent.checkCashFlowNegativeForPopups();
      }
      return rtn;
    },
    closePanel() {
      this.$emit("closeChargeChangesPopup");
    },

    addExtraFee() {
      if (
        this.extraFees.GlobalFeeType != null &&
        this.extraFees.FeeAmount > 0 &&
        this.extraFees.FeeDate != ""
      ) {
        let item = deepCopy(this.extraFees);
        item.FeeDate = new Date(item.FeeDate).toString("M/d/yyyy");
        item.FeeAmount = parseFloat(item.FeeAmount);

        this.chargeChangeData.ApplicableFees.push(item);
        this.extraFees.GlobalFeeType = null;
        this.extraFees.FeeAmount = 0;
        this.extraFees.FeeDate = "";
        this.calculateCashFlowForChargeChanges();
      } else {
        this.$swal(
          "Warning!",
          "Fee type, amount and date fields are required.",
          "warning"
        );
      }
    },
    deleteExtraFee(indx) {
      if (indx > -1) {
        this.chargeChangeData.ApplicableFees.splice(indx, 1);
        this.calculateCashFlowForChargeChanges();
      }
    },
    async submitChargeChanges() {
      if (this.changeDetails.length == 0) {
        this.$swal("Warning!", "Nothing to change", "warning");
        return;
      }

      if (this.customerId == 0) {
        this.$swal("Warning!", "Invalid customer id", "warning");
        return;
      }

      this.$swal
        .fire({
          title: "Confirm",
          text: "Are you sure to submit this request?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then(async (dialog) => {
          this.isLoading = true;
          if (dialog.value) {
            let err, result;
            [err, result] = await this.$store.dispatch(
              types.CREATE_CHARGE_CHANGES,
              {
                CustomerId: this.customerId,
                Notes: this.chargeChangeData.Notes,
                IsEmergency: this.chargeChangeData.IsEmergency,
                ChangeDetails: this.changeDetails,
                ApplicableFees: this.chargeChangeData.ApplicableFees,
              }
            );

            if (result) {
              let successMessage = this.checkAuth(12411)
                ? "The requested changes are approved."
                : "Charge changes requested.";
              this.$swal("Success!", successMessage, "success");
              this.chargeChangeData.Notes = "";
              this.chargeChangeData.IsEmergency = false;
              this.chargeChangeData.ApplicableFees = [];
              this.$emit("submitChargeChanges", this.checkAuth(12411));
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
          this.isLoading = false;
        });
    },
  },
};
</script>

<style>
.closeButton2 {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 999;
  font-size: 30px;
  cursor: pointer;
}
.closeButton2:hover {
  color: #ff3860;
}
@media screen and (max-height: 390px) {
  .dcp-mediaScreen {
    max-height: 200px !important;
    overflow-y: scroll;
  }
}
</style>
