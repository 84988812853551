<template>
  <div class="col-md-12 liveCallReportWrapper">
    <div style="margin-top: -48px; margin-left: 150px">
      <span
        style="padding: 8px; cursor: pointer"
        class="badge btn-success"
        @click="refreshData"
        >Refresh Now</span
      >
    </div>
    <div class="col-md-3 noPadding mt-3">
      <div>
        <label class="generalLabel">Roles:</label>
        <div
          class="col-md-12 noPadding"
          style="display: flex; justify-content: flex-end; margin-left: -56px"
        >
          <span
            class="badge badge-primary"
            style="width: 20px; cursor: pointer"
            @click="addAllRoles"
          >
            <span style="font-weight: 600">+</span>
          </span>

          <span
            class="badge badge-light ml-1"
            style="width: 20px; cursor: pointer"
            @click="clearRoles"
          >
            <span class="" style="font-weight: 600">-</span>
          </span>
        </div>
      </div>
      <div style="display: flex">
        <VueMultiselect
          v-model="roles"
          :options="sortedRoles"
          :multiple="true"
          :close-on-select="false"
          label="Name"
          track-by="Id"
          @click="openRoles()"
          v-click-outside="startTimer"
        />
        <div class="pl-1">
          <button
            @click="filterReport()"
            class="multiselect__tags btn btn-primary"
          >
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </div>

    <button @click="changeState" class="btn btn-primary mt-3" ref="btnToggle">
      Show Inbound Statistics
    </button>

    <div v-if="InboundStatistics">
      <div
        class="alert alert-warning mt-2"
        style="max-width: 320px"
        v-if="
          !inboundCallStatistics ||
          (inboundCallStatistics &&
            inboundCallStatistics.CampaignReport &&
            inboundCallStatistics.CampaignReport.length == 0 &&
            inboundCallStatistics.UserReport &&
            inboundCallStatistics.UserReport.length == 0)
        "
      >
        <i class="mdi mdi-alert-outline mr-2"></i>
        There is no report data for today
      </div>
      <div
        class="row mt-2"
        style="padding: 0 10px"
        v-if="
          InboundStatistics &&
          inboundCallStatistics &&
          inboundCallStatistics.CampaignReport &&
          inboundCallStatistics.CampaignReport.length > 0
        "
      >
        <div
          class="col-xl-6 col-lg-8 col-md-12 col-sm-12 noPadding"
          style="margin: 0 !important"
        >
          <div
            class="buefyWrapper datagrid-table inbound-call-table"
            style="
              height: auto;
              overflow-y: auto;
              overflow-x: auto;
              font-size: 12px !important;
            "
          >
            <table
              style="
                width: 100%;
                margin: 0 !important;
                min-width: 480px;
                overflow-y: auto;
                overflow-x: auto;
                background-color: #fffff;
                border: none !important;
                text-align: center;
              "
            >
              <thead>
                <tr>
                  <th>Campaign</th>
                  <th>User</th>
                  <th class="text-center">Inbound Count</th>
                  <th class="text-center">Missed Call Count</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in inboundCallStatistics.CampaignReport"
                  :key="item.Id"
                  style="background-color: #fffff"
                >
                  <td>
                    {{ item.Campaign == null ? "No Campaign" : item.Campaign }}
                  </td>
                  <td>{{ item.User }}</td>
                  <td class="text-center">{{ item.InboundCallCount }}</td>
                  <td class="text-center">
                    {{
                      item.MissedCallCount != null ? item.MissedCallCount : ""
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        class="row mt-2"
        style="padding: 0 10px"
        v-if="
          InboundStatistics &&
          inboundCallStatistics &&
          inboundCallStatistics.UserReport &&
          inboundCallStatistics.UserReport.length > 0
        "
      >
        <div
          class="col-xl-6 col-lg-8 col-md-12 col-sm-12 noPadding"
          style="margin: 0 !important"
        >
          <div
            class="buefyWrapper datagrid-table inbound-call-table"
            style="
              height: auto;
              overflow-y: auto;
              overflow-x: auto;
              font-size: 12px !important;
            "
          >
            <table
              style="
                width: 100%;
                margin: 0 !important;
                min-width: 480px;
                overflow-y: auto;
                overflow-x: auto;
                background-color: #37cde6;
                border: none !important;
                text-align: center;
              "
            >
              <thead>
                <tr>
                  <th>User</th>
                  <th class="text-center">Inbound Count</th>
                  <th class="text-center">Missed Call Count</th>
                  <th class="text-center">Outbound Count</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in inboundCallStatistics.UserReport"
                  :key="item.Id"
                  style="background-color: #ffffff"
                >
                  <td>{{ item.User }}</td>
                  <td class="text-center">{{ item.InboundCallCount }}</td>
                  <td class="text-center">{{ item.MissedCallCount }}</td>
                  <td class="text-center">{{ item.OutboundCallCount }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-md-6 noPadding mt-4"
      style="display: flex; justify-content: space-between"
    >
      <label for="availableAgents" class="generalLabel"
        >Available Agents: {{ agentList.length.toString() }}</label
      >
      <label for="activeAgents" class="generalLabel"
        >Active Agents: {{ getActiveAgentCount() }}</label
      >
      <label for="idleAgents" class="generalLabel"
        >Idle Agents: {{ getIdleAgentCount() }}</label
      >
    </div>
    <div class="mt-3">
      <label class="generalLabel" style="font-size: 18px">Inbound Calls</label>
      <div class="row" style="padding: 0 10px">
        <div
          class="col-xl-6 col-lg-8 col-md-12 col-sm-12 newPanelWrapper noPadding"
        >
          <div
            v-if="inboundCallList.length > 0"
            class="buefyWrapper datagrid-table"
            :style="' height:auto; max-height:320px; padding:3px !importnant;  overflow-y:auto; overflow-x:auto;  font-size: 12px !important;'"
          >
            <table
              style="
                width: 100%;
                min-width: 320px;
                overflow-y: auto;
                overflow-x: auto;
              "
            >
              <thead>
                <tr>
                  <th>From</th>
                  <th>To</th>
                  <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in inboundCallList"
                  :key="item.From"
                  :style="{ backgroundColor: item.Color }"
                >
                  <td>{{ formatIfPhone(item.From) }}</td>
                  <td>{{ formatIfPhone(item.To) }}</td>
                  <td>{{ formatSecond(item.Duration) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-if="inboundCallList.length == 0"
            class="buefyWrapper datagrid-table"
            :style="' height:auto; max-height:320px; padding:3px !importnant;  overflow-y:auto; overflow-x:auto;  font-size: 12px !important;'"
          >
            <table
              style="
                width: 100%;
                min-width: 320px;
                overflow-y: auto;
                overflow-x: auto;
              "
            >
              <thead>
                <tr>
                  <th>From</th>
                  <th>To</th>
                  <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div v-if="roles != null && roles.length > 0" class="mt-3">
      <label class="generalLabel" style="font-size: 18px">Agents</label>
      <div class="row" style="padding: 0 10px">
        <div
          class="col-xl-8 col-lg-12 col-md-12 col-sm-12 newPanelWrapper noPadding"
        >
          <div
            class="buefyWrapper datagrid-table"
            :style="'height:auto; overflow-y:auto; overflow-x:auto;  font-size: 12px !important;'"
          >
            <table
              style="
                width: 100%;
                min-width: 480px;
                overflow-y: auto;
                overflow-x: auto;
              "
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Role</th>
                  <th>Direction</th>
                  <th>Phone Number</th>
                  <th>Duration</th>
                  <th>Office</th>
                  <th>To</th>
                  <th v-if="phoneReady"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in checkDuplicateCalls()"
                  :key="
                    item.EndpointId +
                    '-' +
                    item.PhoneNumber +
                    '-' +
                    item.ConferenceName
                  "
                  :style="{ backgroundColor: item.Color }"
                  :class="item.Status == 'In Call' ? 'activeCallRow' : ''"
                >
                  <td>
                    {{ item.Name }}
                    <span class="ml-1" v-if="item.ConferenceName != ''">
                      <i class="fas fa-users"></i>
                    </span>
                  </td>
                  <td>{{ item.Role }}</td>
                  <td>{{ item.Direction }}</td>
                  <td>
                    {{
                      item.PhoneNumber.length > 0
                        ? formatIfPhone(item.PhoneNumber)
                        : item.PauseReason
                    }}
                  </td>
                  <td>{{ formatSecond(item.Duration) }}</td>
                  <td>{{ item.Office }}</td>
                  <td>{{ formatIfPhone(item.Campaign) }}</td>
                  <td
                    v-if="phoneReady"
                    style="
                      min-width: 90px !important;
                      max-width: 90px !important;
                    "
                  >
                    <button
                      v-if="
                        this.checkAuth(3824) &&
                        item.Status == 'In Call' &&
                        item.IsConnected == true &&
                        !item.IsInternal &&
                        item.EndpointId != userPhoneSettings.endPointId
                      "
                      :disabled="!isAvailableForListenWhisper(item.CallUuid)"
                      v-on:click.prevent.stop="listenCall(item)"
                      type="button"
                      class="btn btn-link waves-effect"
                      style="background-color: #fefefe"
                    >
                      <i
                        :class="
                          isListenActive(item.CallUuid)
                            ? 'activeButton'
                            : 'passiveButton'
                        "
                        class="ri-headphone-line"
                      ></i>
                    </button>
                    <button
                      v-if="
                        this.checkAuth(3824) &&
                        this.checkAuth(3825) &&
                        item.Status == 'In Call' &&
                        item.IsConnected == true &&
                        !item.IsInternal &&
                        item.EndpointId != userPhoneSettings.endPointId
                      "
                      :disabled="!isAvailableForListenWhisper(item.CallUuid)"
                      v-on:click.prevent.stop="
                        whisperCall(item.CallUuid, item.PeerId)
                      "
                      type="button"
                      class="btn btn-link waves-effect"
                      style="background-color: #fefefe"
                    >
                      <i
                        :class="
                          isWhisperActive(item.CallUuid)
                            ? 'activeButton'
                            : 'passiveButton'
                        "
                        class="ri-mic-line"
                      ></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-if="roles != null && roles.length > 0" class="form-group row mt-2">
      <div class="col-md-8">
        <div class="float-left mr-2">
          <i class="mdi mdi-square" style="color: rgb(122, 206, 2)"></i
          ><label class="generalLabel">In call 1min</label>
        </div>
        <div class="float-left mr-2">
          <i class="mdi mdi-square" style="color: rgb(18, 173, 42)"></i
          ><label class="generalLabel">5min</label>
        </div>
        <div class="float-left mr-2">
          <i class="mdi mdi-square" style="color: rgb(14, 134, 28)"></i
          ><label class="generalLabel">5min+</label>
        </div>
        <div class="float-right">
          <i class="mdi mdi-square" style="color: rgb(51, 51, 50)"></i
          ><label class="generalLabel">Paused</label>
        </div>
        <div class="float-right mr-2">
          <i class="mdi mdi-square" style="color: rgb(13, 59, 103)"></i
          ><label class="generalLabel">5min+</label>
        </div>
        <div class="float-right mr-2">
          <i class="mdi mdi-square" style="color: rgb(35, 90, 159)"></i
          ><label class="generalLabel">5min</label>
        </div>
        <div class="float-right mr-2">
          <i class="mdi mdi-square" style="color: rgb(0, 120, 212)"></i
          ><label class="generalLabel">1min</label>
        </div>
        <div class="float-right mr-2">
          <i class="mdi mdi-square" style="color: rgb(85, 156, 228)"></i
          ><label class="generalLabel">Idle 15s</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import globalTypes from "@/store/types";
import VueMultiselect from "vue-multiselect";
import _orderby from "lodash";

let liveCallReportTimer = null;
export default {
  name: "LiveCallsReport",
  components: { VueMultiselect },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      roles: null,
      userPhoneSettings: null,
      isFavorite: false,
      InboundStatistics: false,
      show: true,
      isLoading: false,
      phoneReady: false,
      pauseTimer: true,
      userOfficeId: 0,
      userSubOffices: [],
    };
  },
  computed: mapState({
    inboundCallList: (state) => state.reports.inboundCallList,
    agentList: (state) => state.reports.agentList,
    listenWhisperList: (state) => state.reports.listenWhisperList,
    inboundCallStatistics: (state) => state.reports.inboundStatisticsList,
    roleList: (state) => state.globals.roles,
    liveCallRoleList: (state) => state.reports.liveCallRoleList,

    sortedRoles() {
      let rtn = [];
      let userOfficeId = 0;
      let userSubOffices = [];

      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      if (userInfo != null && userInfo.moduleFunctions != null) {
        userOfficeId = userInfo.officeId;
        userSubOffices = userInfo.subOffices;
      }
      if (this.checkAuth(1454)) {
        rtn = this.roleList;
      } else if (this.checkAuth(55011)) {
        rtn = userSubOffices
          ? this.roleList.filter(
              (x) =>
                userSubOffices.includes(x.OfficeId) ||
                x.OfficeId == userOfficeId ||
                x.OfficeId == null
            )
          : this.roleList.filter(
              (x) => x.OfficeId == userOfficeId || x.OfficeId == null
            );
      } else {
        rtn = this.roleList.filter(
          (x) => x.OfficeId == userOfficeId || x.OfficeId == null
        );
      }
      return _orderby.orderBy(rtn, "Name", "asc");
    },
  }),

  async mounted() {
    this.roles = [...this.liveCallRoleList];

    await this.$store.dispatch(globalTypes.GET_ROLES),
      (this.userPhoneSettings = JSON.parse(
        sessionStorage.getItem("userPhoneSettings")
      ));

    this.$Emitter.on("stopLiveCallTimer", () => {
      this.stopTimer();
    });
  },
  unmounted() {
    this.stopTimer();
    this.$Emitter.off("stopLiveCallTimer");
    this.$store.commit(globalTypes.SET_USER_LISTEN_CALL, false);
  },
  methods: {

    checkIfNumeric(str){
      let rtn = /^\d+$/.test(str);
      return rtn;
    },

    addAllRoles() {
      this.roles = this.sortedRoles.slice();
    },
    clearRoles() {
      this.roles = [];
    },

    async startTimer() {
      if (this.roles && this.roles.length > 0) {
        this.pauseTimer = false;
        if (liveCallReportTimer == null) {
          liveCallReportTimer = window.setInterval(async () => {
            this.refreshData();
          }, 10000);
        }
      }
      await this.$store.commit(types.SET_LIVE_CALL_ROLE_LIST, this.roles);
    },
    async filterReport() {
      if (this.roles && this.roles.length > 0) {
        this.pauseTimer = false;
        await this.refreshData();
        if (liveCallReportTimer == null) {
          liveCallReportTimer = window.setInterval(async () => {
            this.refreshData();
          }, 10000);
        }
      } else {
        this.$swal("Warning!", "Please select at least one role.", "warning");
      }
      await this.$store.commit(types.SET_LIVE_CALL_ROLE_LIST, this.roles);
    },
    openRoles() {
      this.pauseTimer = true;
    },
    stopTimer() {
      if (liveCallReportTimer) {
        window.clearInterval(liveCallReportTimer);
        liveCallReportTimer = null;
      }
    },
    async refreshData() {
      if (this.pauseTimer) {
        return;
      }
      let RoleIds = this.roles?.map((x) => x.Id).join(",");
      if (!RoleIds) {
        this.$swal("Warning!", "Please select at least one role.", "warning");
        this.stopTimer();
        return;
      }
      if (this.isLoading == false) {
        this.isLoading = true;
        await this.$store.dispatch(types.GET_LIVE_CALL_REPORT, { RoleIds });

        this.checkListenWhisperList();
        this.isLoading = false;
      }

      this.phoneReady =
        document.getElementById("expandCollapsePhoneButton") != null;
    },
    async changeState() {
      this.InboundStatistics = !this.InboundStatistics;
      this.show = !this.show;
      this.$refs.btnToggle.innerText = this.show
        ? "Show Inbound Statistics"
        : "Hide Inbound Statistics";
      if (this.InboundStatistics) {
        await this.$store.dispatch(types.GET_INBOUND_STATISTICS_REPORT, {
          Option: "today",
          StartDate: "01/01/1970",
          EndDate: "01/01/1970",
        });
      }
    },
    getActiveAgentCount() {
      return this.agentList
        .filter((item) => item.Status == "In Call")
        .length.toString();
    },
    getIdleAgentCount() {
      return this.agentList
        .filter((item) => item.Status == "Idle")
        .length.toString();
    },
    checkDuplicateCalls() {
      let listenConferences = this.agentList.filter((x) =>
        x.ConferenceName.includes("listenxxx")
      );
      for (let i = 0; i < listenConferences.length; i++) {
        let parentCall = this.agentList.filter(
          (x) =>
            x.ConferenceName == "" &&
            x.EndpointId == listenConferences[i].EndpointId &&
            x.PhoneNumber == listenConferences[i].PhoneNumber
        );
        if (parentCall.length > 0) {
          let indx = this.agentList.findIndex(
            (x) => x.ConferenceName == listenConferences[i].ConferenceName
          );
          if (indx > -1) {
            this.agentList.splice(indx, 1);
          }
        }
      }

      let inboundFrom = this.inboundCallList.map((x) =>
        this.cleanFormatPhone(x.From)
      );
      let rtnList = this.agentList.filter(
        (x) => !inboundFrom.includes(this.cleanFormatPhone(x.PhoneNumber))
      );

      if (rtnList && rtnList.length > 0) {
        let duplicatePhones = [];
        let phones = _orderby.groupBy(rtnList, "PhoneNumber");
        let keys = Object.keys(phones);
        for (let key of keys) {
          let item = phones[key];
          let phn = this.cleanFormatPhone(key);
          if (item.length > 1 && phn && phn.length > 9 && this.checkIfNumeric(phn)) {
            duplicatePhones.push(phn);
          }
        }
        
        if (duplicatePhones.length > 0) {
          rtnList = rtnList.filter(
            (x) =>
              !duplicatePhones.includes(this.cleanFormatPhone(x.PhoneNumber))
          );
        }
      }

      return rtnList;
    },
    checkListenWhisperList() {
      for (let i = 0; i < this.listenWhisperList.length; i++) {
        if (
          !this.agentList
            .map((x) => x.CallUuid)
            .includes(this.listenWhisperList[i].CallUuid)
        ) {
          if (this.listenWhisperList[i].IsWhisper) {
            this.stopWhisper(this.listenWhisperList[i].WhisperedCall);
          }
          this.$store.commit(globalTypes.SET_USER_LISTEN_CALL, false);
          /*if (this.listenWhisperList[i].IsListen) {
            this.$Emitter.emit("hangUpListen", {

                ConferenceName: this.listenWhisperList[i].ConferenceName

            });
          }*/
          this.listenWhisperList.splice(i, 1);
        }
      }
    },
    isListenActive(callUuid) {
      let rtn = false;
      let items = this.listenWhisperList.filter((x) => x.CallUuid == callUuid);
      if (items.length > 0) {
        rtn = items[0].IsListen;
      }
      return rtn;
    },
    isWhisperActive(callUuid) {
      let rtn = false;
      let items = this.listenWhisperList.filter((x) => x.CallUuid == callUuid);
      if (items.length > 0) {
        rtn = items[0].IsWhisper;
      }
      return rtn;
    },
    async listenCall(callItem) {
      if (
        this.listenClickTime != null &&
        this.getTimeDifference(new Date(), this.listenClickTime) < 5
      ) {
        await this.$swal.fire(
          "Warning!",
          "Please wait 5 seconds to execute this operation!",
          "warning"
        );
        return;
      }
      //item.CallUuid,item.EndpointId,item.PhoneNumber
      let conferenceName = "";
      let createConference = false;
      if (callItem.ConferenceName == "") {
        conferenceName =
          "listenxxx" + callItem.EndpointId + "xxx" + callItem.PhoneNumber;
        createConference = true;
      } else {
        conferenceName = callItem.ConferenceName;
      }

      this.listenClickTime = new Date();
      let startListenCall = false;
      let items = this.listenWhisperList.filter(
        (x) => x.CallUuid == callItem.CallUuid
      );
      if (items.length > 0) {
        if (items[0].IsListen) {
          this.$Emitter.emit("stopListenCall", {
            ConferenceName: conferenceName,
          });
          items[0].IsListen = false;
          items[0].IsWhisper = false;
          this.stopWhisper(items[0].WhisperedCall);
          await this.$store.commit(globalTypes.SET_USER_LISTEN_CALL, false);
        } else {
          startListenCall = true;
          items[0].IsListen = true;
        }
      } else {
        this.listenWhisperList.push({
          ConferenceName: conferenceName,
          CallUuid: callItem.CallUuid,
          IsListen: true,
          IsWhisper: false,
          WhisperedCall: null,
        });
        startListenCall = true;
      }

      if (callItem.CallUuid != "" && startListenCall) {
        this.$Emitter.emit("listenCall", {
          CallUuid: callItem.CallUuid,
          ConferenceName: conferenceName,
          CreateConference: createConference,
          EndpointId: callItem.EndpointId,
        });
        await this.$store.commit(globalTypes.SET_USER_LISTEN_CALL, true);
      }
    },
    async whisperCall(callUuid, peerId) {
      if (
        this.whisperClickTime != null &&
        this.getTimeDifference(new Date(), this.whisperClickTime) < 5
      ) {
        await this.$swal.fire(
          "Warning!",
          "Please wait 5 seconds to execute this operation!",
          "warning"
        );
        return;
      }
      this.whisperClickTime = new Date();
      let items = this.listenWhisperList.filter((x) => x.CallUuid == callUuid);

      if (items.length > 0 && items[0].IsListen) {
        if (!items[0].IsWhisper) {
          if (peerId && peerId != "") {
            items[0].IsWhisper = true;
            navigator.mediaDevices
              .getUserMedia({ audio: true })
              .then((stream) => {
                items[0].WhisperedCall = window.MyPeer.call(peerId, stream);

                items[0].WhisperedCall.on("stream", function (remoteStream) {
                  // Show stream in some video/canvas element.
                });
              });
          }
        } else {
          items[0].IsWhisper = false;
          this.stopWhisper(items[0].WhisperedCall);
        }
      } else {
        this.$swal.fire(
          "Warning!",
          "Please listen call before whisper!",
          "warning"
        );
      }
    },
    stopWhisper(whisperedCall) {
      if (whisperedCall != null) {
        whisperedCall.close();
      }
    },
    isAvailableForListenWhisper(callUuid) {
      let rtn = true;
      if (this.listenWhisperList.length > 0) {
        if (
          this.listenWhisperList.filter(
            (x) =>
              x.CallUuid != callUuid &&
              (x.IsListen == true || x.IsWhisper == true)
          ).length > 0
        )
          rtn = false;
      }

      return rtn;
    },
    formatIfPhone(val) {
      let isNum = /^\d+$/.test(val);
      if (isNum) {
        return this.formatPhone(val);
      } else {
        return val;
      }
    },
  },
};
</script>

<style>
.liveCallReportWrapper tbody tr {
  color: #ffffff !important;
  text-shadow: 0 1px 1px #333;
}
.liveCallReportWrapper tbody tr td {
  color: #ffffff !important;
  text-shadow: 0 1px 1px #333;
  padding: 10px !important;
}
.inbound-call-table thead th {
  background-color: #fafafa;
}
.inbound-call-table tbody tr {
  border-top: 1px solid #dbdbdb;
}

.inbound-call-table tbody tr td {
  color: #6c757d !important;
  text-shadow: none;
  padding: 10px !important;
}
.liveCallReportWrapper thead th {
  padding: 10px !important;
}
.liveCallReport {
  width: 100%;
  height: 50px;
  position: absolute;
  top: -55px;
  left: 12px;
  background-color: white;
}
.pageHeader {
  float: left;
  font-size: 1.1rem;
  margin: 0;
  line-height: 2.4rem;
  color: #323a46;
}
.timerWrapper {
  width: auto;
  float: left;

  border-radius: 18px;
  font-weight: bold;
  font-size: 14px;
  padding: 9px 16px;
  height: 40px;
  border: solid 1px #e4e4e4;
  background-color: #ffffff;
}

.spinnerDiv {
  float: left;
  color: #1abc9c;
  font-size: 24px;
}
.mapsDiv {
  width: auto;
  float: left;
}
.activeCallRow .passiveButton {
  color: #999999;
}
.activeCallRow .activeButton {
  color: #ed3030;
}
</style>
