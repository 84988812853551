export default {
  GET_CUSTOMER_LANGUAGE_ID:"GET_CUSTOMER_LANGUAGE_ID",
  GET_COMPANY_POLICY_GROUPS_BY_OFFICE:"GET_COMPANY_POLICY_GROUPS_BY_OFFICE",
  GET_PORTAL_AUTO_LOGIN_LINK:"GET_PORTAL_AUTO_LOGIN_LINK",
  GET_RESTRICTED_CREDITOR_STATUSES:"GET_RESTRICTED_CREDITOR_STATUSES",
  KNOWLADGE_OWL: "KNOWLADGE_OWL",
  CHECK_CONSECUTIVE_DRAFTS: "CHECK_CONSECUTIVE_DRAFTS",
  GET_USER_ACTIVITY_HISTORY: "GET_USER_ACTIVITY_HISTORY",
  MFA_VERIFICATION_SEND: "MFA_VERIFICATION_SEND",
  MFA_VERIFICATION_VERIFY: "MFA_VERIFICATION_VERIFY",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  GET_BLOCKED_CREDITORS_AND_CREDIT_TYPES:
    "GET_BLOCKED_CREDITORS_AND_CREDIT_TYPES",
  SET_BLOCKED_CREDITORS_AND_CREDIT_TYPES:
    "SET_BLOCKED_CREDITORS_AND_CREDIT_TYPES",
  GET_DRAFT_AMOUNT: "GET_DRAFT_AMOUNT",
  GET_CSR_TASK_TRIGGER_LOG_STATUS: "GET_CSR_TASK_TRIGGER_LOG_STATUS",
  SET_CSR_TASK_TRIGGER_LOG_STATUS: "SET_CSR_TASK_TRIGGER_LOG_STATUS",
  GET_QUICK_EMAIL_TEMPLATES: "GET_QUICK_EMAIL_TEMPLATES",
  SET_QUICK_EMAIL_TEMPLATES: "SET_QUICK_EMAIL_TEMPLATES",
  INSERT_USER_ACTIVITY: "INSERT_USER_ACTIVITY",
  GET_ACTIVE_POA_CREDITOR: "GET_ACTIVE_POA_CREDITOR",
  SET_ACTIVE_POA_CREDITOR: "SET_ACTIVE_POA_CREDITOR",
  SEND_POA_EFAX: "SEND_POA_EFAX",
  GET_CUSTOMER_SETTLED_CREDITORS: "GET_CUSTOMER_SETTLED_CREDITORS",
  GET_AGENT_IP_ADDR: "GET_AGENT_IP_ADDR",
  GET_DISPOSITION_OPTIONS_FOR_ADMIN: "GET_DISPOSITION_OPTIONS_FOR_ADMIN",
  SET_DISPOSITION_OPTIONS_FOR_ADMIN: "SET_DISPOSITION_OPTIONS_FOR_ADMIN",
  GET_COMPANY_SETTINGS_STATES: "GET_COMPANY_SETTINGS_STATES",
  SET_COMPANY_SETTINGS_STATES: "SET_COMPANY_SETTINGS_STATES",
  GET_CUSTOMER_PRIMARY_PHONE: "GET_CUSTOMER_PRIMARY_PHONE",
  GET_UI_SETTINGS: "GET_UI_SETTINGS",
  SET_UI_SETTINGS: "SET_UI_SETTINGS",
  SET_USER_LISTEN_CALL: "SET_USER_LISTEN_CALL",
  GET_ALL_CREDITOR_CONTACT: "GET_ALL_CREDITOR_CONTACT",
  SET_ALL_CREDITOR_CONTACT: "SET_ALL_CREDITOR_CONTACT",
  SEND_CREDITOR_FAX: "SEND_CREDITOR_FAX",
  GET_CUSTOMER_TAG_TYPE: "GET_CUSTOMER_TAG_TYPE",
  SET_CUSTOMER_TAG_TYPE: "SET_CUSTOMER_TAG_TYPE",
  RESET_ASSIGNED_CSR_TASK_ID: "RESET_ASSIGNED_CSR_TASK_ID",
  GET_COMPANY_POLICY_GROUP_IDS: "GET_COMPANY_POLICY_GROUP_IDS",
  SEND_ERROR_LOG: "SEND_ERROR_LOG",
  RESET_STATES: "RESET_STATES",
  RESET_IDLE: "RESET_IDLE",
  GET_ONLINE_STATUS: "GET_ONLINE_STATUS",
  SET_ONLINE_STATUS: "SET_ONLINE_STATUS",
  SET_RESET_STATES: "SET_RESET_STATES",
  GET_TIMEZONES: "GET_TIMEZONES",
  SET_TIMEZONES: "SET_TIMEZONES",
  GET_STATES: "GET_STATES",
  SET_STATES: "SET_STATES",
  GET_GENDERS: "GET_GENDERS",
  SET_GENDERS: "SET_GENDERS",
  GET_ROLES: "GET_ROLES",
  SET_ROLES: "SET_ROLES",
  GET_OFFICE_NAMES: "GET_OFFICE_NAMES",
  SET_OFFICE_NAMES: "SET_OFFICE_NAMES",
  GET_PHONETYPES: "GET_PHONETYPES",
  SET_PHONETYPES: "SET_PHONETYPES",
  GET_PRIORITYTYPES: "GET_PRIORITYTYPES",
  SET_PRIORITYTYPES: "SET_PRIORITYTYPES",
  GET_STATUS_NAMES: "GET_STATUS_NAMES",
  SET_STATUS_NAMES: "SET_STATUS_NAMES",
  GET_DISPOSITIONTYPES: "GET_DISPOSITIONTYPES",
  SET_DISPOSITIONTYPES: "SET_DISPOSITIONTYPES",
  GET_CAMPAIGNTYPES: "GET_CAMPAIGNTYPES",
  SET_CAMPAIGNTYPES: "SET_CAMPAIGNTYPES",
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
  MODIFY_NOTIFICATIONS: "MODIFY_NOTIFICATIONS",
  SET_MODIFY_NOTIFICATIONS: "SET_MODIFY_NOTIFICATIONS",
  GET_ZIP_DATA: "GET_ZIP_DATA",
  GET_HISTORY: "GET_HISTORY",
  SET_HISTORY: "SET_HISTORY",
  DELETE_HISTORY: "DELETE_HISTORY",
  GET_REM: "GET_REM",
  GET5_REMINDERS: "GET5_REMINDERS",
  GET5_DISPOS: "GET5_DISPOS",
  ACCEPT_HOT_LEAD: "ACCEPT_HOT_LEAD",
  GET_SMS_TEMPLATES: "GET_SMS_TEMPLATES",
  SET_SMS_TEMPLATES: "SET_SMS_TEMPLATES",
  GET_DRIP_EMAIL_FROM_TYPE: "GET_DRIP_EMAIL_FROM_TYPE",
  SET_DRIP_EMAIL_FROM_TYPE: "SET_DRIP_EMAIL_FROM_TYPE",
  GET_PAUSE_REASON_TYPES: "GET_PAUSE_REASON_TYPES",
  SET_PAUSE_REASON_TYPES: "SET_PAUSE_REASON_TYPES",
  GET_LAST_CALL_TIME: "GET_LAST_CALL_TIME",
  SET_LAST_CALL_TIME: "SET_LAST_CALL_TIME",
  UPDATE_LAST_CALL_TIME: "UPDATE_LAST_CALL_TIME",
  UPDATE_PEER_ID: "UPDATE_PEER_ID",
  SET_INCOMING_CUSTOMER_INFO: "SET_INCOMING_CUSTOMER_INFO",
  UPDATE_INCOMING_CUSTOMER_INFO: "UPDATE_INCOMING_CUSTOMER_INFO",
  LOG_ERROR: "LOG_ERROR",
  GET_OUTBOUND_DID_LIST: "GET_OUTBOUND_DID_LIST",
  SET_OUTBOUND_DID_LIST: "SET_OUTBOUND_DID_LIST",
  GET_CAMPAIGNLIST: "GET_CAMPAIGNLIST",
  SET_CAMPAIGNLIST: "SET_CAMPAIGNLIST",
  GET_FAV: "GET_FAV",
  SET_FAV: "SET_FAV",
  DELETEFAV: "DELETEFAV",
  POSTFAV: "POSTFAV",
  UPDATEFAV: "UPDATEFAV",
  GET_CSRTASKSTATUS: "GET_CSRTASKSTATUS",
  SET_CSRTASKSTATUS: "SET_CSRTASKSTATUS",
  CHECK_ROUTE_NUMBER: "CHECK_ROUTE_NUMBER",
  GET_SALES_AGENTS: "GET_SALES_AGENTS",
  SET_SALES_AGENTS: "SET_SALES_AGENTS",
  GET_NEGOTIATORS: "GET_NEGOTIATORS",
  SET_NEGOTIATORS: "SET_NEGOTIATORS",
  GET_CSRS: "GET_CSRS",
  SET_CSRS: "SET_CSRS",
  USPS_VERIFY: "USPS_VERIFY",
  GET_TASK_TRIGGER_TYPE: "GET_TASK_TRIGGER_TYPE",
  SET_TASK_TRIGGER_TYPE: "SET_TASK_TRIGGER_TYPE",
  GET_TASK_TYPE_NAMES: "GET_TASK_TYPE_NAMES",
  SET_TASK_TYPE_NAMES: "SET_TASK_TYPE_NAMES",
  GET_TASK_TYPE_ACTIONS: "GET_TASK_TYPE_ACTIONS",
  SET_TASK_TYPE_ACTIONS: "SET_TASK_TYPE_ACTIONS",
  SEND_EMAIL_MG: "SEND_EMAIL_MG",
  GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA",
  GET_ALL_ACTIVE_EMPLOYEES: "GET_ALL_ACTIVE_EMPLOYEES",
  SET_ALL_ACTIVE_EMPLOYEES: "SET_ALL_ACTIVE_EMPLOYEES",
  UPDATE_ASSIGNED_CSR_TASK_ID: "UPDATE_ASSIGNED_CSR_TASK_ID",
  SET_ASSIGNED_CSR_TASK_ID: "SET_ASSIGNED_CSR_TASK_ID",
  GET_PENDING_CHANGE_STATUSES: "GET_PENDING_CHANGE_STATUSES",
  SET_PENDING_CHANGE_STATUSES: "SET_PENDING_CHANGE_STATUSES",
  GET_PENDING_CHANGE_TYPES: "GET_PENDING_CHANGE_TYPES",
  SET_PENDING_CHANGE_TYPES: "SET_PENDING_CHANGE_TYPES",
  INSERT_ASSIGNED_LEAD_DATA: "INSERT_ASSIGNED_LEAD_DATA",
  SET_ASSIGNED_LEAD_DATA: "SET_ASSIGNED_LEAD_DATA",
  GET_HARDSHIPS_LIST: "GET_HARDSHIPS_LIST",
  SET_HARDSHIPS_LIST: "SET_HARDSHIPS_LIST",
  GET_OCCUPATIONS_LIST: "GET_OCCUPATIONS_LIST",
  SET_OCCUPATIONS_LIST: "SET_OCCUPATIONS_LIST",
  GET_ACCOUNT_TYPES_LIST: "GET_ACCOUNT_TYPES_LIST",
  SET_ACCOUNT_TYPES_LIST: "SET_ACCOUNT_TYPES_LIST",
  GET_RESIDENCE_OWNERSHIP_TYPES: "GET_RESIDENCE_OWNERSHIP_TYPES",
  SET_RESIDENCE_OWNERSHIP_TYPES: "SET_RESIDENCE_OWNERSHIP_TYPES",
  GET_EXPENSE_TYPES_LIST: "GET_EXPENSE_TYPES_LIST",
  SET_EXPENSE_TYPES_LIST: "SET_EXPENSE_TYPES_LIST",
  GET_SCRIPTS: "GET_SCRIPTS",
  SET_SCRIPTS: "SET_SCRIPTS",
  GET_ALL_POLICY_GROUPS: "GET_ALL_POLICY_GROUPS",
  SET_ALL_POLICY_GROUPS: "SET_ALL_POLICY_GROUPS",
  GET_ALL_PAYMENT_OPTIONS: "GET_ALL_PAYMENT_OPTIONS",
  SET_ALL_PAYMENT_OPTIONS: "SET_ALL_PAYMENT_OPTIONS",
  GET_SUGGEST_CREDITOR_PATTERN: "GET_SUGGEST_CREDITOR_PATTERN",
  SET_SUGGEST_CREDITOR_PATTERN: "SET_SUGGEST_CREDITOR_PATTERN",
  GET_CREDITOR_ALIAS_LIST: "GET_CREDITOR_ALIAS_LIST",
  SET_CREDITOR_ALIAS_LIST: "SET_CREDITOR_ALIAS_LIST",
  UPDATE_CSR_TRIGGER_LOG_ANSWERED_USER: "UPDATE_CSR_TRIGGER_LOG_ANSWERED_USER",
};
