<template>
  <div class="row admin-page officePage">
    <div
      v-if="!isNegotiatorTaskPopup"
      class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 datagrid-table"
    >
      <label class="generalLabel ml-2 mt-2">Document Templates</label>

      <div class="filter form-group row">
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input
            type="text"
            class="form-control"
            v-model="nameFilter"
            placeholder="Filter Document Templates"
          />
          <div class="list col-md-12">
            <div
              class="office item row"
              v-for="(item, index) in filteredDocumentTypes"
              :key="item.Id"
              :class="{ active: activeItem === item.Id }"
              :disabled="saving || deleting"
              :style="[
                index % 2 === 0
                  ? { 'background-color': '#fafafa' }
                  : { 'background-color': '#FFFFFF' },
              ]"
            >
              <span
                :class="{ adminActiveMenuItem: activeItem === item.Id }"
                style="width: 90%"
              >
                <span style="margin-left: 2px"
                  >{{ item.DocumentName }}
                  <button
                    type="button"
                    title="Search"
                    class="btn btn-primary fas fa-download btn-sm cursor-pointer text-light rounded float-right"
                    style="background-color: #3498db"
                    @click="() => handleGenerateTemplate(item)"
                  ></button>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      :class="
        isNegotiatorTaskPopup
          ? 'col-md-12'
          : 'col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12'
      "
    >
      <div class="datagrid-table mb-2" v-if="checkAuth(4218)">
        <div class="col-md-12">
          <label class="generalLabel mt-1" for="extraDocuments"
            >Extra Documents:</label
          >
          <input
            type="checkbox"
            id="docreviewchk"
            v-model="createDocReview"
            class="checkbox-input generalCheckBox ml-3"
            style="
              border-radius: 4px !important;
              border: solid 1px #d1d1d1 !important;
            "
          />
          <label>Correspondence for Review</label>

          <input
            type="file"
            ref="uploader"
            style="display: none"
            accept="docx"
            @click="handleFileClick"
            @change="handleFileChange"
            multiple
          />
          <!-- <button
            type="button"
            id="uploadNowButton1"
            class="btn btn-sm generalButton ml-2 btn-success"
            style="background-color: #3bafda; color: #ffffff; margin-top: -1px"
            v-on:click.prevent.stop="$refs.uploader.click()"
            @change="handleFileChange"
          >
            Upload Now
          </button> -->

          <div class="row mt-2">
            <div class="col-md-12">
              <o-field class="col-md-12">
                <o-upload
                  v-model="dropFiles"
                  multiple
                  drag-drop
                  type="file"
                  @input="handleFileChange"
                >
                  <section
                    class="text-center"
                    style="
                      margin: 30px;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    "
                  >
                    <i class="fas fa-cloud-upload-alt folderIcon"> </i>
                    <label class="mt-1"
                      >Drop your files here or click to upload.
                    </label>
                  </section>
                </o-upload>
              </o-field>
            </div>
            <perfect-scrollbar
              class="col-md-12 row"
              style="
                padding-left: 20px;
                padding-right: 20px;
                max-height: 100px;
                overflow-x: hidden !important;
              "
            >
              <div
                v-for="(file, index) in dropFiles"
                :key="index"
                style="display: flex"
              >
                <h5>
                  <span
                    style="
                      border-top-right-radius: 0;
                      border-bottom-right-radius: 0;
                      font-size: 12px;
                      padding: 5px;
                    "
                    class="badge badge-primary"
                    >{{ file.name }}</span
                  >
                </h5>
                <h5>
                  <span
                    style="
                      border-top-left-radius: 0;
                      border-bottom-left-radius: 0;
                      font-size: 12px;
                      cursor: pointer;
                      padding: 3px;
                    "
                    class="mr-2 badge badge-danger"
                  >
                    <i
                      @click="deleteDropFile(index)"
                      class="ri-close-line"
                      style="margin-top: 2px"
                    ></i
                  ></span>
                </h5>
              </div>
            </perfect-scrollbar>
            <div v-if="dropFiles.length > 0" class="col-md-12">
              <button
                @click="uploadAllFiles()"
                class="col-md-12 btn btn-success"
              >
                Upload All Files
              </button>
            </div>
          </div>
        </div>
      </div>
      <perfect-scrollbar
        class="col-md-12 noPadding row"
        style="
          padding-left: 20px;
          padding-right: 20px;
          max-height: 350px;
          overflow-x: hidden !important;
        "
      >
        <div class="datagrid-table table is-striped is-hoverable">
          <o-table
            :data="sortedCustomerDocumentList"
            @click="selectDocument($event)"
          >
            <o-table-column
              v-if="isNegotiatorTaskPopup && selectedDocumentName"
              field=""
              label=""
              width="2%"
            >
              <template v-slot="props">
                <span v-if="props.row.Name == selectedDocumentName">
                  <i
                    style="font-size: 18px; color: green"
                    class="fas fa-check"
                  ></i>
                </span>
              </template>
            </o-table-column>
            <o-table-column
              field="Name"
              label="File Name"
              sortable
              searchable
              width="35%"
            >
              <template v-slot:searchable="props">
                <input
                  v-if="!sortedCustomerDocumentList.length == 0"
                  class="form-control"
                  type="text"
                  v-model="props.filters[props.column.field]"
                  placeholder="Search..."
                  icon="search"
                />
              </template>
              <template v-slot="props">
                <input
                  type="text"
                  class="generalTextBox"
                  ref="documentName"
                  v-model="selectedRow.NameOnly"
                  v-if="
                    selectedRow.isEditing && selectedRow.Name == props.row.Name
                  "
                />

                <span
                  v-if="
                    !selectedRow.isEditing || selectedRow.Name != props.row.Name
                  "
                  >{{ props.row.Name }}</span
                >
              </template>
            </o-table-column>

            <o-table-column
              field="Modified"
              label="Date"
              position="centered"
              width="15%"
              sortable
            >
              <template v-slot="props">
                {{
                  new Date(props.row.Modified).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                }}
              </template>
            </o-table-column>
            <o-table-column
              field="UserId"
              label="User"
              position="centered"
              width="25%"
              sortable
            >
              <template v-slot="props">
                <span>{{ props.row.UserId }}</span>
              </template>
            </o-table-column>
            <o-table-column
              field="icon"
              v-slot="props"
              width="25%"
              v-if="!isNegotiatorTaskPopup"
            >
              <div
                v-if="
                  !selectedRow.isEditing || selectedRow.Name != props.row.Name
                "
              >
                {{ props.row.icon }}

                <button
                  type="button"
                  title="Download Document"
                  class="btn btn-sm fas fa-download text-light ml-1 rounded"
                  style="background-color: #2c3e50"
                  :disabled="saving || deleting"
                  @click="() => handleDownloadDocument(props.row)"
                ></button>
                <button
                  v-if="checkAuth(8218)"
                  type="button"
                  title="Edit"
                  class="btn btn-sm fas fa-edit text-light ml-1 rounded"
                  style="background-color: #f39c12"
                  :disabled="saving || deleting"
                  @click="() => editRow(props.row.Name)"
                ></button>
                <button
                  v-if="checkAuth(4982)"
                  @click="OpenGCSUploadPopup(props.row.Name)"
                  @contextmenu.prevent="rightclick()"
                  type="button"
                  title="Upload to Global"
                  class="btn btn-sm fas fa-cloud-upload-alt text-light ml-1 rounded"
                  style="background-color: #00b894"
                  :disabled="saving || deleting"
                ></button>
                <button
                  v-if="checkAuth(8213)"
                  type="button"
                  title="Delete"
                  class="btn btn-sm text-light ml-1 rounded ri-delete-bin-line"
                  style="background-color: rgb(237, 48, 48)"
                  :disabled="saving || deleting"
                  @click="() => handleDeleteDocument(props.row)"
                ></button>
              </div>
              <div
                v-if="
                  selectedRow.isEditing && selectedRow.Name == props.row.Name
                "
              >
                <button
                  type="button"
                  title="Save Changes"
                  class="btn btn-sm fas fa-save text-light ml-1 rounded"
                  style="background-color: #00b894"
                  @click="() => handleUpdateDocumentName()"
                  :disabled="saving || deleting"
                ></button>
                <button
                  type="button"
                  title="Cancel Editing"
                  class="btn btn-sm text-light ml-1 rounded far fa-window-close"
                  style="background-color: rgb(237, 48, 48)"
                  :disabled="saving || deleting"
                  @click="() => cancelOperation()"
                ></button>
              </div>
            </o-table-column>
          </o-table>

          <section
            v-if="sortedCustomerDocumentList.length == 0"
            class="section"
            style="text-align: center"
          >
            <div class="content has-text-grey has-text-centered mb-4">
              <p class="noMargin">
                <i
                  style="font-size: 24px; color: #939393"
                  class="ri-question-line"
                ></i>
              </p>
              <p class="noMargin">Nothing here.</p>
            </div>
          </section>
        </div>
      </perfect-scrollbar>

      <div class="row from-group" v-if="isNegotiatorTaskPopup">
        <div class="col-md-12">
          <button
            @click="sendSelectedDocumentToNegotiatorTask"
            type="button"
            id="uploadNowButton2"
            class="btn btn-sm generalButton btn-success"
            style="width: 100%; background-color: #3bafda; color: #ffffff"
          >
            Select
          </button>
        </div>
      </div>
    </div>
    <div
      class="backgroundLock"
      v-if="isGCSUploadPopup"
      style="-webkit-box-align: center; align-items: center; display: flex"
    >
      <div
        style="position: relative; margin: 10% auto"
        class="offset-lg-12 col-xl-4 offset-lg-2 col-lg-8 col-md-12 col-sm-12"
      >
        <GCSUploadPopup
          :documentName="gcsPopUpDocumentName"
          :customerId="customerId"
          :customerOfficeId="customerOfficeId"
          @closeGCSUploadPopup="closeGCSUploadPopup"
        ></GCSUploadPopup>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import types from "./types";
import GCSUploadPopup from "@/components/GCSUploadPopup.vue";
import formatMixin from "@/mixins/formatMixin";
import _orderby from "lodash";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
import { SpanStatusCode } from "@azure/core-tracing";
import { throwStatement } from "@babel/types";
export default {
  name: "ClientDocuments",
  components: {
    GCSUploadPopup,
  },
  props: {
    customerId: {
      type: Number,
      default: () => 0,
    },
    customerOfficeId: {
      type: Number,
      default: () => 0,
    },
    tabClicked: {
      Type: Boolean,
      Default: false,
    },
    isNegotiatorTaskPopup: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    tabClicked(newVal, oldVal) {
      if (newVal != oldVal && newVal) {
        this.watcherRefresh();
      }
    },
  },
  computed: mapState({
    customerDocuments: (state) => state.clientManagement.customerDocuments,
    filteredDocumentTypes() {
      return this.customerDocuments.filter(
        (item) =>
          item.DocumentName.toLowerCase().indexOf(
            this.nameFilter.toLowerCase()
          ) > -1
      );
    },
    sortedCustomerDocumentList() {
      return this.customerDocumentList.length > 0
        ? this.customerDocumentList.sort(
            (a, b) => new Date(b.Modified) - new Date(a.Modified)
          )
        : [];
    },
  }),
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      dropFiles: [],
      selectedRow: {
        Name: "",
        isEditing: false,
        NameOnly: "",
        Extension: "",
      },
      gcsPopUpDocumentName: "",
      fileNameForUpdate: "",
      saving: false,
      deleting: false,
      activeItem: null,
      nameFilter: "",
      isGCSUploadPopup: false,
      selectedDocumentName: "",
      createDocReview: false,
      customerDocumentList: [],
    };
  },
  methods: {
    uploadAllFiles() {
      if (this.dropFiles && this.dropFiles.length > 0) {
        if (this.dropFiles.length > 10) {
          this.$swal("Warning!", "Maximum 10 files allowed.", "warning");
        } else {
          this.uploadDocuments(this.dropFiles);
        }
      }
    },
    async uploadDocuments(files) {
      if (files && files.length > 0) {
        let errors = "";
        let successCount = 0;
        this.$emit("set-loading", true);
        for (let file of files) {
          try {
            let fileExtension = "";
            fileExtension = file.name.split(".").pop();
            if (!this.isValidFileSize(file.size, 50)) {
              this.$swal(
                "Warning!",
                "Maximum allowed file size is 50 MB. Please resize your file.",
                "warning"
              );
              this.$emit("set-loading", false);
              return;
            }
            if (file) {
              if (file.size == 0) {
                this.$swal(
                  "Warning!",
                  "File is empty: " + file.name,
                  "warning"
                );
                this.$emit("set-loading", false);
                return;
              }

              const toBase64 = (file) =>
                new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = () => resolve(reader.result);
                  reader.onerror = (error) => reject(error);
                });

              let data = await toBase64(file);

              if (data.indexOf("data:") > -1) {
                data = data.split(",")[1];
              }

              if (typeof data == "undefined" || data == null) {
                this.$swal(
                  "Warning!",
                  "File data is not valid: " + file.name,
                  "warning"
                );
                this.$emit("set-loading", false);
                return;
              }

              let uploadData = {
                Name: this.cropFileNames(this.getFileNameWithoutExt(file.name)),
                Extension: "." + fileExtension,
                CustomerId: this.customerId,
                DocumentData: data,
              };

              let err, result;
              [err, result] = await this.$store.dispatch(
                types.UPLOAD_EXTRA_DOCUMENT,
                uploadData
              );

              if (result) {
                if (this.createDocReview) {
                  let dataToCreateDocReview = {
                    CustomerId: this.customerId,
                    Notes: file.name,
                  };
                  [err, result] = await this.$store.dispatch(
                    types.CREATE_DOCUMENT_REVIEW_CHANGES,
                    dataToCreateDocReview
                  );
                }
                successCount++;
              } else {
                let errMsg = this.getApiErrorMessage(err);
                this.$swal("Error!", errMsg, "error");
              }
            }
          } catch {
            this.$emit("set-loading", false);
          }
        }
        this.$emit("set-loading", false);
        if (files.length == successCount) {
          this.$swal("Success!", "Document(s) Uploaded.", "success");
        } else {
          if (errors != "") {
            if (successCount > 0) {
              this.$swal(
                "Warning!",
                "While some documents were uploaded smoothly, a handful encountered errors. " +
                  errors,
                "warning"
              );
            } else {
              this.$swal("Error!", errors, "error");
            }
          }
        }
        this.dropFiles = [];
        await this.getCustomerDocuments();
      }
    },

    deleteDropFile(index) {
      if (index > -1) {
        this.dropFiles.splice(index, 1);
      }
    },

    async watcherRefresh() {
      let globalTypeData = {
        OfficeId: this.customerOfficeId,
      };

      await this.$store.dispatch(types.GET_CUSTOMERDOCUMENTS);

      if (globalTypeData.OfficeId != 0) {
        await this.$store.dispatch(
          types.GET_GLOBALDOCUMENTTYPES,
          globalTypeData
        );
      }

      await this.getCustomerDocuments();

      this.selectedDocument = this.sortedCustomerDocumentList[2];
    },
    async getCustomerDocuments() {
      let err, result;
      let customerListData = {
        CustomerId: this.customerId,
      };
      [err, result] = await this.$store.dispatch(
        types.GET_CUSTOMERDOCUMENTLIST,
        customerListData
      );
      if (result && result.Data) {
        this.customerDocumentList = deepCopy(result.Data);
      }
    },
    emptyTheObjectsOnUnmount() {
      this.selectedRow = null;
      this.gcsPopUpDocumentName = null;
      this.fileNameForUpdate = null;
      this.saving = null;
      this.deleting = null;
      this.activeItem = null;
      this.nameFilter = null;
      this.isGCSUploadPopup = null;
      this.selectedDocumentName = null;
      this.createDocReview = null;
    },
    selectDocument(value) {
      this.selectedDocumentName = value.Name;
    },
    sendSelectedDocumentToNegotiatorTask() {
      this.$emit("documentSelected", this.selectedDocumentName);
    },
    OpenGCSUploadPopup(docName) {
      this.gcsPopUpDocumentName = docName;
      this.isGCSUploadPopup = true;
    },
    closeGCSUploadPopup() {
      this.isGCSUploadPopup = false;
    },
    closeEditing() {
      this.selectedRow.isEditing = false;
    },
    editRow(documentName) {
      this.selectedRow.isEditing = true;
      this.selectedRow.Name = documentName;
      this.selectedRow.NameOnly = this.getFileNameWithoutExt(documentName);
      this.selectedRow.Extension = this.getExtension(documentName);
    },
    cancelOperation() {
      this.selectedRow.isEditing = false;
      this.selectedRow.Name = "";
      this.selectedRow.NameOnly = "";
      this.selectedRow.Extension = "";
    },
    saveByteArray(name, byte) {
      let blob = new Blob([byte], { type: "application/docx" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      let fileName = name;
      link.download = fileName;
      link.click();
    },
    async handleGenerateTemplate(item) {
      if (item.Id > 0) {
        let err, result;
        let dataToDownload = {
          Type: "generatetemplate",
          CustomerId: this.customerId,
          TemplateId: item.Id,
        };
        [err, result] = await this.$store.dispatch(
          types.GENERATE_CUSTOMER_TEMPLATE,
          dataToDownload
        );
        if (result) {
          this.saveByteArray(item.DocumentName + ".docx", result);
        }
      }
    },
    async handleUpdateDocumentName() {
      this.saving = true;
      let err, result;
      let dataToUpdate = {
        Name: this.getFileNameWithoutExt(this.selectedRow.Name),
        NewName: this.selectedRow.NameOnly,
        Extension: this.getExtension(this.selectedRow.Name),
        CustomerId: this.customerId,
      };

      if (dataToUpdate.Name == dataToUpdate.NewName) {
        this.$swal("Warning!", "No Changes Detected!", "warning");
        this.saving = false;
        return;
      }

      let isFileNameExists =
        this.customerDocumentList.filter(
          (x) =>
            x.Name.toLowerCase() ==
            (dataToUpdate.NewName + dataToUpdate.Extension).toLowerCase()
        ).length > 0;

      /*  if(isFileNameExists){

       }
       else{

       }*/
      [err, result] = await this.$store.dispatch(
        types.RENAME_CUSTOMER_DOCUMENT,
        dataToUpdate
      );

      if (result) {
        let indx = this.customerDocumentList.findIndex(
          (x) => x.Name == this.selectedRow.Name
        );
        this.customerDocumentList[indx].Name =
          this.selectedRow.NameOnly + this.getExtension(this.selectedRow.Name);
        this.customerDocumentList.push();
        this.cancelOperation();
        this.$swal("Success!", result.Message, "success");
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.saving = false;
    },
    async handleDeleteDocument(row) {
      this.deleting = true;
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let err, result;
            let dataToDelete = {
              Name: this.getFileNameWithoutExt(row.Name),
              Extension: this.getExtension(row.Name),
              CustomerId: this.customerId,
            };

            [err, result] = await this.$store.dispatch(
              types.DELETE_CUSTOMER_DOCUMENT,
              dataToDelete
            );
            if (result) {
              let indx = this.customerDocumentList.findIndex(
                (x) => x.Name == row.Name
              );

              this.customerDocumentList.splice(indx, 1);

              this.cancelOperation();
              this.$swal("Success!", result.Message, "success");
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
        });

      this.deleting = false;
    },
    async handleDownloadDocument(item) {
      let fileExtension = "";
      fileExtension = this.getExtension(item.Name);
      if (fileExtension != "") {
        let err, result;
        let dataToDownload = {
          Name: this.getFileNameWithoutExt(item.Name),
          Extension: fileExtension,
          CustomerId: this.customerId,
        };

        [err, result] = await this.$store.dispatch(
          types.DOWNLOAD_CUSTOMER_DOCUMENT,
          dataToDownload
        );

        if (result) {
          if (fileExtension == ".pdf") {
            var file = new Blob([result], { type: "application/pdf" });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          } else {
            this.saveByteArray(item.Name, result);
          }
        }
      }
    },

    handleFileClick() {
      this.$refs.uploader.value = "";
      const input = this.$refs.uploader;
      input.type = "file";
    },
    async handleFileChange(e) {
      if (e.target.files && e.target.files.length > 0) {
        this.uploadDocuments(e.target.files);
      }
    },
    isValidFileSize(size, limitMb) {
      let rtn = true;
      if (size > 1024 * 1024 * limitMb) {
        rtn = false;
      }

      return rtn;
    },
  },
  unmounted() {
    // this.emptyTheObjectsOnUnmount();
  },
  async mounted() {
    if (this.isNegotiatorTaskPopup) {
      await this.watcherRefresh();
    }
  },
};
</script>

<style>
.o-upl {
  position: relative;
  display: inline-flex;
  width: 100%;
}
.folderIcon {
  font-size: 30px;
  color: rgb(59, 175, 218);
  color: linear-gradient(
    243deg,
    rgba(59, 175, 218, 1) 4%,
    rgba(118, 240, 255, 1) 86%
  );
}
.o-upl__draggable {
  cursor: pointer;
  width: 100%;
  padding: 0.25em;
  border: 1px dashed #78b5db !important;
  border-radius: 4px;
}
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}
</style>
