<template>
  <div style="width: 100%">
    <div
      v-if="dashboardData == null || getTimePassed <= 4"
      class="bgWrapper animate__animated animate__lightSpeedInLeft slowAnimation"
      :style="
        'width:' +
        100 +
        '%;' +
        'min-height:' +
        800 +
        'px;' +
        'height:' +
        100 +
        '%;' +
        'left:' +
        240 +
        'px;' +
        'background-color: #3bafda;'
      "
    ></div>
    <div
      v-if="dashboardData != null"
      id="dashboardBgWrapper"
      class="bgWrapper gradientBg"
      :style="
        'width:' +
        bgWidth +
        'px;' +
        'min-height:' +
        bgHeight +
        'px;' +
        'left:' +
        bgLeft +
        'px;' +
        'background-image:url(' +
        dashboardData.Background.ImageUrl +
        ');'
      "
    ></div>
    <div class="row">
      <div
        v-if="dashboardData != null"
        class="dashboard col-md-12 noPadding"
        style="margin-top: -59px"
      >
        <div
          style="width: 100%"
          :style="
            'min-height:' +
            (bgHeight - 72) +
            'px;' +
            'max-height:' +
            (bgHeight - 72) +
            'px;'
          "
        >
          <div class="dateWrapper">
            {{
              new Date().toLocaleDateString("en-us", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            }}
          </div>

          <div
            v-if="checkAuth(18654) && officePerformanceData"
            class="col-md-12 dashboardWidgets"
            style="z-index: 100"
          >
            <div class="col-md-12">
              <Widget
                componentName="TableWidget"
                title="Office Performance"
                :dateOption="dateFilter"
                :widgetData="{
                  data: officePerformanceData,
                  columns: dashboardTableColumns,
                  refresh: true,
                  timeInterval: true,
                  foot: true,
                }"
              />
            </div>
          </div>

          <div
            style="display: flex"
            class="col-md-12 row dashboardWidgets"
            v-if="checkAuth(55001) && snfDashboardData"
          >
            <div
              class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12"
              style="z-index: 100"
            >
              <Widget
                v-if="snfDashboardData.WeekByOffice"
                :dateOption="dateFilter"
                :widgetData="{
                  list: snfDashboardData,
                  name: 'Snf Dashboard',
                }"
                componentName="BarChartsWidget"
                title="Weekly Affiliate Sales"
              />
            </div>

            <div
              v-if="snfDashboardData"
              class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12"
              style="
                display: flex;
                flex-direction: column;
                gap: 10px;
                z-index: 100;
              "
            >
              <div>
                <Widget
                  :widgetData="{
                    current: snfDashboardData.DailySales,
                    rate: snfDashboardData.DailyChangeRate,
                    prev: snfDashboardData.PreviousDaySales,
                    type: 'daily',
                    refresh: true,

                    newSnf: true,
                    totalAmount: snfDashboardData.DailyEnrolledAmount,
                    salesSnf: snfDashboardData.SnfDailySales,
                    salesFlg: snfDashboardData.FlgDailySales,
                    debtAmountSnf: snfDashboardData.SnfDailyEnrolledAmount,
                    debtAmountFlg: snfDashboardData.FlgDailyEnrolledAmount,
                  }"
                  componentName="SummaryWidget"
                  title="Daily Sales"
                />
              </div>
              <div>
                <Widget
                  :widgetData="{
                    current: snfDashboardData.CurrentWeekSales,
                    rate: snfDashboardData.WeeklyChangeRate,
                    prev: snfDashboardData.PreviousWeekSales,
                    type: 'weekly',
                    refresh: true,

                    newSnf: true,
                    totalAmount: snfDashboardData.WeeklyEnrolledAmount,
                    salesSnf: snfDashboardData.SnfCurrentWeekSales,
                    salesFlg: snfDashboardData.FlgCurrentWeekSales,
                    debtAmountSnf: snfDashboardData.SnfWeeklyEnrolledAmount,
                    debtAmountFlg: snfDashboardData.FlgWeeklyEnrolledAmount,
                  }"
                  componentName="SummaryWidget"
                  title="Weekly Sales"
                />
              </div>
              <div>
                <Widget
                  :widgetData="{
                    current: snfDashboardData.CurrentMonthlySales,
                    rate: snfDashboardData.MonthlyChangeRate,
                    prev: snfDashboardData.PreviousMonthlySales,
                    type: 'monthly',
                    refresh: true,

                    newSnf: true,
                    totalAmount: snfDashboardData.MonthEnrolledAmount,
                    salesSnf: snfDashboardData.SnfCurrentMonthSales,
                    salesFlg: snfDashboardData.FlgCurrentMonthSales,
                    debtAmountSnf: snfDashboardData.SnfMonthEnrolledAmount,
                    debtAmountFlg: snfDashboardData.FlgMonthEnrolledAmount,
                  }"
                  componentName="SummaryWidget"
                  title="Monthly Sales"
                />
              </div>
            </div>
          </div>

          <div
            v-if="snfCustomerStatusDashboardList && checkAuth(55414)"
            class="col-md-12 row dashboardWidgets"
            style="z-index: 100"
          >
            <div
              class="col-xl-4"
              v-for="item in snfCustomerStatusDashboardList"
              :key="item.Status"
            >
              <Widget
                :widgetData="{
                  current: item.TotalCount,
                  refresh: true,
                  newSnfBottomBoxes: true,
                  salesSnf: item.SnfCount,
                  salesFlg: item.FlgCount,
                }"
                componentName="SummaryWidget"
                :title="item.Status"
                secondTitle="Snf Customer Status Dashboard"
              />
            </div>
          </div>

          <div
            style="display: flex"
            class="col-md-12 row dashboardWidgets"
            v-if="checkAuth(55055) && !isLoading"
          >
            <div
              class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12"
              style="z-index: 100"
            >
              <Widget
                v-if="snfSettlementsList.WeeklyResult"
                :dateOption="dateFilter"
                :widgetData="{
                  list: snfSettlementsList,
                  name: 'Settlements Dashboard',
                }"
                componentName="BarChartsWidget"
                title="Weekly Affiliate Settlements"
              />
            </div>

            <div
              v-if="snfSettlementsList"
              class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12"
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                gap: 10px;
                z-index: 100;
              "
            >
              <div>
                <Widget
                  v-if="snfSettlementsList"
                  :widgetData="{
                    current: snfSettlementsDashDailySales(),
                    rate: snfSettlementsDashDailyAmountRate(),
                    prev: formatMoney(snfSettlementsDashDailyPreviousAmount()),
                    type: 'daily',
                    refresh: true,
                    newSnf: true,
                    totalAmount: formatMoney(
                      snfSettlementsDashDailySalesDebt()
                    ),
                    salesSnf: snfSettlementsList.SnfDailyCount,
                    salesFlg: snfSettlementsList.FlgDailyCount,
                    debtAmountSnf: formatMoney(
                      snfSettlementsList.SnfDailyDebtAmount
                    ),
                    debtAmountFlg: formatMoney(
                      snfSettlementsList.FlgDailyDebtAmount
                    ),

                    settledBox: true,
                    settledBoxRate: snfSettlementsDashDailyCountRate(),
                    settledBoxPrev: snfSettlementsDashDailyPreviousCount(),
                  }"
                  componentName="SummaryWidget"
                  title="Debt Settled Today"
                />
              </div>
              <div>
                <Widget
                  v-if="snfSettlementsList"
                  :widgetData="{
                    current: snfSettlementsDashMonthlySales(),
                    rate: snfSettlementsDashMonthlyAmountRate(),
                    prev: formatMoney(
                      snfSettlementsDashMonthlyPreviousAmount()
                    ),
                    type: 'monthly',
                    refresh: true,
                    newSnf: true,
                    totalAmount: formatMoney(
                      snfSettlementsDashMonthlySalesDebt()
                    ),
                    salesSnf: snfSettlementsList.SnfMonthlyCount,
                    salesFlg: snfSettlementsList.FlgMonthlyCount,
                    debtAmountSnf: formatMoney(
                      snfSettlementsList.SnfMonthlyDebtAmount
                    ),
                    debtAmountFlg: formatMoney(
                      snfSettlementsList.FlgMonthlyDebtAmount
                    ),

                    settledBox: true,
                    settledBoxRate: snfSettlementsDashMonthlyCountRate(),
                    settledBoxPrev: snfSettlementsDashMonthlyPreviousCount(),
                  }"
                  componentName="SummaryWidget"
                  title="Monthly Settled Debt"
                />
              </div>
              <div>
                <Widget
                  v-if="snfSettlementsList"
                  :widgetData="{
                    current: snfSettlementsDashYearlySales(),
                    type: 'yearly',
                    refresh: true,
                    newSnf: true,
                    totalAmount: formatMoney(
                      snfSettlementsDashYearlySalesDebt()
                    ),
                    salesSnf: snfSettlementsList.SnfYearlyTotalCount,
                    salesFlg: snfSettlementsList.FlgYearlyTotalCoun,
                    debtAmountSnf: formatMoney(
                      snfSettlementsList.SnfYearlyDebtAmount
                    ),
                    debtAmountFlg: formatMoney(
                      snfSettlementsList.FlgYearlyDebtAmount
                    ),
                    settledBox: true,
                  }"
                  componentName="SummaryWidget"
                  title="Yearly Settled Debt"
                />
              </div>
            </div>
          </div>

          <div
            style="display: flex"
            class="col-md-12 row dashboardWidgets"
            v-if="checkAuth(55435) && affiliateEnrollmenrDashboardList"
          >
            <div
              class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12"
              style="z-index: 100"
            >
              <Widget
                v-if="affiliateEnrollmenrDashboardList.WeekByOffice"
                :dateOption="dateFilter"
                :widgetData="{
                  list: affiliateEnrollmenrDashboardList,
                  name: 'Snf Dashboard',
                }"
                componentName="BarChartsWidget"
                title="Weekly Affiliate Enrollments"
              />
            </div>

            <div
              class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12"
              style="
                display: flex;
                flex-direction: column;
                gap: 10px;
                z-index: 100;
              "
            >
              <div>
                <Widget
                  :widgetData="{
                    current: affiliateEnrollmenrDashboardList.DailySales,
                    rate: affiliateEnrollmenrDashboardList.DailyChangeRate,
                    prev: affiliateEnrollmenrDashboardList.PreviousDaySales,
                    type: 'daily',
                    refresh: true,
                    newSnf: false,
                  }"
                  componentName="SummaryWidget"
                  title="Daily Enrollments"
                  secondTitle="Weekly Affiliate Enrollments Daily Enrollments"
                />
              </div>
              <div>
                <Widget
                  :widgetData="{
                    current: affiliateEnrollmenrDashboardList.CurrentWeekSales,
                    rate: affiliateEnrollmenrDashboardList.WeeklyChangeRate,
                    prev: affiliateEnrollmenrDashboardList.PreviousWeekSales,
                    type: 'weekly',
                    refresh: true,
                    newSnf: false,
                  }"
                  componentName="SummaryWidget"
                  title="Weekly Enrollments"
                  secondTitle="Weekly Affiliate Enrollments Weekly Enrollments"
                />
              </div>
              <div>
                <Widget
                  :widgetData="{
                    current:
                      affiliateEnrollmenrDashboardList.CurrentMonthlySales,
                    rate: affiliateEnrollmenrDashboardList.MonthlyChangeRate,
                    prev: affiliateEnrollmenrDashboardList.PreviousMonthlySales,
                    type: 'monthly',
                    refresh: true,
                    newSnf: false,
                  }"
                  componentName="SummaryWidget"
                  title="Monthly Enrollments"
                  secondTitle="Weekly Affiliate Enrollments Monthly Enrollments"
                />
              </div>
            </div>
          </div>

          <div
            style="display: flex"
            class="col-md-12 row dashboardWidgets"
            v-if="
              checkAuth(55435) &&
              checkAuth(87235) &&
              migrationAffiliateEnrollmenrDashboardList
            "
          >
            <div
              class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12"
              style="z-index: 100"
            >
              <Widget
                v-if="migrationAffiliateEnrollmenrDashboardList.WeekByOffice"
                :dateOption="dateFilter"
                :widgetData="{
                  list: migrationAffiliateEnrollmenrDashboardList,
                  name: 'Snf Dashboard',
                }"
                componentName="BarChartsWidget"
                title="Weekly Enrollments (Migrated Leads)"
              />
            </div>

            <div
              class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12"
              style="
                display: flex;
                flex-direction: column;
                gap: 10px;
                z-index: 100;
              "
            >
              <div>
                <Widget
                  :widgetData="{
                    current:
                      migrationAffiliateEnrollmenrDashboardList.DailySales,
                    rate: migrationAffiliateEnrollmenrDashboardList.DailyChangeRate,
                    prev: migrationAffiliateEnrollmenrDashboardList.PreviousDaySales,
                    type: 'daily',
                    refresh: true,
                    newSnf: false,
                  }"
                  componentName="SummaryWidget"
                  title="Daily Enrollments"
                  secondTitle="Migrated Leads Daily Enrollments"
                />
              </div>
              <div>
                <Widget
                  :widgetData="{
                    current:
                      migrationAffiliateEnrollmenrDashboardList.CurrentWeekSales,
                    rate: migrationAffiliateEnrollmenrDashboardList.WeeklyChangeRate,
                    prev: migrationAffiliateEnrollmenrDashboardList.PreviousWeekSales,
                    type: 'weekly',
                    refresh: true,
                    newSnf: false,
                  }"
                  componentName="SummaryWidget"
                  title="Weekly Enrollments"
                  secondTitle="Migrated Leads Weekly Enrollments"
                />
              </div>
              <div>
                <Widget
                  :widgetData="{
                    current:
                      migrationAffiliateEnrollmenrDashboardList.CurrentMonthlySales,
                    rate: migrationAffiliateEnrollmenrDashboardList.MonthlyChangeRate,
                    prev: migrationAffiliateEnrollmenrDashboardList.PreviousMonthlySales,
                    type: 'monthly',
                    refresh: true,
                    newSnf: false,
                  }"
                  componentName="SummaryWidget"
                  title="Monthly Enrollments"
                  secondTitle="Migrated Leads Monthly Enrollments"
                />
              </div>
            </div>
          </div>

          <div
            v-if="checkAuth(55415) && welcomeCallDashboardList"
            class="col-md-12 dashboardWidgets"
            style="z-index: 100"
          >
            <div class="col-md-12">
              <Widget
                componentName="TableWidget"
                title="Pending Welcome Calls"
                :dateOption="dateFilter"
                :widgetData="{
                  data: welcomeCallDashboardList,
                  columns: welcomeCalldashboardTableColumns,
                  refresh: true,
                  prefix: true,
                  foot: true,
                }"
              />
            </div>
          </div>

          <div class="col-md-12">
            <div
              v-if="checkAuth(81452)"
              class="dashboardWidgets"
              style="
                background: #ffffff;
                margin: 10px;
                border-radius: 6px;
                padding: 12px 0px;
                position: relative;
              "
            >
              <div
                @click="getDbSalesDashboardData()"
                style="
                  min-height: 60px;
                  position: absolute;
                  top: 5px;
                  right: 5px;
                  z-index: 1000;
                  cursor: pointer;
                "
              >
                <i
                  v-if="!dbSalesDashboardLoading"
                  class="fe-refresh-cw"
                  title="refresh"
                  style="font-size: 16px; color: #3295b9"
                ></i>
              </div>
              <div class="col-md-12 row">
                <div v-if="dbSalesDashboardLoading" class="loading">
                  <div class="text-center" style="margin-top: 0px">
                    <i class="spinner-grow text-info m-2"></i>
                  </div>
                </div>
              </div>

              <div
                v-if="dbDashBoardData && !dbSalesDashboardLoading"
                class="col-md-12"
                style="display: flex"
              >
                <!-- Left chart -->
                <div
                  class="col-xl-3 col-lg-3 col-md-12 col-xs-12 col-12"
                  style="z-index: 100"
                >
                  <div style="height: 100%">
                    <Widget
                      componentName="NewDonuthChartWidget"
                      title=""
                      :dateOption="dateFilter"
                      :widgetData="{
                        header: dbDashBoardData.TotalDealsHeader,
                        value: dbDashBoardData.TotalDealsValue,
                        valueFormat: 'number',
                        subHeader: dbDashBoardData.TotalDealsProjectionHeader,
                        subValue: dbDashBoardData.TotalDealsProjectionValue,
                        bottomHeader: 'Deals',
                        bottomValue: dbDashBoardData.TotalDealsGoals,
                        bottomValueText: ' Goal',
                        bottomPerc: '% of',
                      }"
                    />
                  </div>
                </div>
                <!-- Header and and middle boxes -->
                <div
                  class="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-12"
                  style="display: flex; flex-direction: column; z-index: 100"
                >
                  <div style="display: flex; height: 30%">
                    <div class="col-md-4">
                      <Widget
                        componentName="BoxWidget"
                        title="Total Deals"
                        :dateOption="dateFilter"
                        :widgetData="{
                          header: dbDashBoardData.AgentDealsTodayHeader,
                          value: dbDashBoardData.AgentDealsTodayValue,
                          valueFormat: 'number',
                          positiveIndicator: false,
                          negativeIndicator: false,
                          subHeader: null,
                          subHeaderValue: null,
                          bottomHeader: '',
                          bottomValue: null,
                        }"
                      />
                    </div>
                    <div class="col-md-4">
                      <Widget
                        componentName="BoxWidget"
                        :dateOption="dateFilter"
                        title="Need to Meet Goal"
                        :widgetData="{
                          header: dbDashBoardData.NeedToMeetGoalHeader,
                          value: dbDashBoardData.NeedToMeetGoalValue,
                          valueFormat: 'number',
                          positiveIndicator: false,
                          negativeIndicator: false,
                          subHeader: null,
                          subHeaderValue: null,
                          bottomHeader: '',
                          bottomValue: null,
                        }"
                      />
                    </div>
                    <div class="col-md-4">
                      <Widget
                        componentName="BoxWidget"
                        title="Total Submitted"
                        :dateOption="dateFilter"
                        :widgetData="{
                          header: dbDashBoardData.AgentSubmittedDebtTodayHeader,
                          value: dbDashBoardData.AgentSubmittedDebtTodayValue,
                          valueFormat: 'money',
                          positiveIndicator: false,
                          negativeIndicator: false,
                          subHeader: null,
                          subHeaderValue: null,
                          bottomHeader: '',
                          bottomValue: null,
                        }"
                      />
                    </div>
                  </div>

                  <div style="display: flex; height: 30%">
                    <div class="col-md-6">
                      <Widget
                        componentName="BoxWidget"
                        title="Talk Time Day"
                        :dateOption="dateFilter"
                        :widgetData="{
                          header: dbDashBoardData.TalkTimeDayHeader,
                          value: dbDashBoardData.TalkTimeDayValue,
                          valueFormat: 'number',
                          positiveIndicator:
                            dbDashBoardData.TalkTimeDayComparisonValue > 0,
                          negativeIndicator:
                            dbDashBoardData.TalkTimeDayComparisonValue < 0,
                          subHeader: null,
                          subHeaderValue: null,
                          bottomHeader: 'Goal:',
                          bottomValue: dbDashBoardData.TalkTimeDayGoal,
                        }"
                      />
                    </div>
                    <div class="col-md-6">
                      <Widget
                        componentName="BoxWidget"
                        title="Talk Time Week"
                        :dateOption="dateFilter"
                        :widgetData="{
                          header: dbDashBoardData.TalkTimeWeekHeader,
                          value: dbDashBoardData.TalkTimeWeekValue,
                          valueFormat: 'number',
                          positiveIndicator:
                            dbDashBoardData.TalkTimeWeekComparisonValue > 0,
                          negativeIndicator:
                            dbDashBoardData.TalkTimeWeekComparisonValue < 0,
                          subHeader: null,
                          subHeaderValue: null,
                          bottomHeader: 'Goal:',
                          bottomValue: dbDashBoardData.TalkTimeWeekGoal,
                        }"
                      />
                    </div>
                  </div>

                  <div style="display: flex; height: 32%">
                    <div class="col-md-6">
                      <Widget
                        componentName="BoxWidget"
                        title="Leads Worked"
                        :dateOption="dateFilter"
                        :widgetData="{
                          header: dbDashBoardData.LeadsWorkedHeader,
                          value: dbDashBoardData.LeadsWorkedValue,
                          valueFormat: 'number',
                          positiveIndicator:
                            dbDashBoardData.LeadsWorkedComparisonValue > 0,
                          negativeIndicator:
                            dbDashBoardData.LeadsWorkedComparisonValue < 0,
                          subHeader: 'Last Month:',
                          subHeaderValue:
                            dbDashBoardData.LeadsWorkedPreviousMonth,
                          bottomHeader: '',
                          bottomValue: dbDashBoardData.LeadsWorkedPercentage,
                        }"
                      />
                    </div>
                    <div class="col-md-6">
                      <Widget
                        componentName="BoxWidget"
                        title="Total Submitted"
                        :dateOption="dateFilter"
                        :widgetData="{
                          header: dbDashBoardData.ContactsEnrolledHeader,
                          value: dbDashBoardData.ContactsEnrolledValue,
                          valueFormat: 'number',
                          positiveIndicator:
                            dbDashBoardData.ContactsEnrolledComparisonValue > 0,
                          negativeIndicator:
                            dbDashBoardData.ContactsEnrolledComparisonValue < 0,
                          subHeader: 'Last Month:',
                          subHeaderValue:
                            dbDashBoardData.ContactsEnrolledPreviousMonth,
                          bottomHeader: '',
                          bottomValue:
                            dbDashBoardData.ContactsEnrolledPercentage,
                        }"
                      />
                    </div>
                  </div>

                  <div style="display: flex; height: 32%">
                    <div class="col-md-12">
                      <Widget
                        componentName="BoxWidget"
                        title="Avg Debt Per Submit"
                        :dateOption="dateFilter"
                        :widgetData="{
                          header: dbDashBoardData.AverageSubmittedDebtHeader,
                          value: dbDashBoardData.AverageSubmittedDebtValue,
                          valueFormat: 'money',
                          positiveIndicator:
                            dbDashBoardData.AverageSubmittedDebtComparisonValue >
                            0,
                          negativeIndicator:
                            dbDashBoardData.AverageSubmittedDebtComparisonValue <
                            0,
                          subHeader: 'Last Month:',
                          subHeaderValue:
                            dbDashBoardData.AverageSubmittedDebtPreviousMonth,
                          bottomHeader: '',
                          bottomValue:
                            dbDashBoardData.AverageSubmittedDebtPercentage,
                        }"
                      />
                    </div>
                  </div>
                </div>
                <!-- Right chart  -->
                <div
                  class="col-xl-3 col-lg-3 col-md-12 col-xs-12 col-12"
                  style="z-index: 100"
                >
                  <div style="height: 100%">
                    <Widget
                      componentName="NewDonuthChartWidget"
                      title=""
                      :dateOption="dateFilter"
                      :widgetData="{
                        header: dbDashBoardData.TotalDebtHeader,
                        value: dbDashBoardData.TotalDebtValue,
                        valueFormat: 'money',
                        subHeader: dbDashBoardData.TotalDebtProjectionHeader,
                        subValue: dbDashBoardData.TotalDebtProjectionValue,
                        bottomHeader: 'Debt Submitted',
                        bottomValue: dbDashBoardData.TotalDebtGoals,
                        bottomValueText: ' Goal',
                        bottomPerc: '% of',
                      }"
                    />
                  </div>
                </div>
              </div>
              <!-- Bottom boxes -->
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                style="display: flex; z-index: 100"
              ></div>
            </div>
          </div>

          <div class="col-md-12">
            <div
              style="display: flex; flex-direction: column"
              class="col-md-12 row dashboardWidgets"
              v-if="checkAuth(81453)"
            >
              <div
                style="
                  min-height: 60px;
                  background-color: rgb(255, 255, 255, 1);
                  padding-top: 20px;
                  padding-bottom: 15px;
                  border-radius: 10px;
                "
              >
                <div style="display: flex; justify-content: space-between">
                  <label class="generalLabel ml-3">{{
                    csrDashboardReportList.AgentName
                  }}</label>
                  <i
                    class="fe-refresh-cw mr-3 refresh"
                    @click="refreshcsrDashboardReport()"
                  ></i>
                </div>
                <div class="col-md-12 row">
                  <div v-if="dbCsrDashboardLoading" class="loading">
                    <div class="text-center" style="margin-top: -50px">
                      <i class="spinner-grow text-info m-2"></i>
                    </div>
                  </div>
                </div>

                <div v-if="!dbCsrDashboardLoading && csrDashboardReportList">
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style="display: flex; flex-direction: row; z-index: 100"
                  >
                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current: csrDashboardReportList.InBoundCall,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Inbound Calls Taken"
                      />
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current: csrDashboardReportList.OutBoundCall,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Outbound Calls Made"
                      />
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current: csrDashboardReportList.TalkTime,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Talk Time"
                      />
                    </div>
                  </div>

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3"
                    style="display: flex; flex-direction: row; z-index: 100"
                  >
                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current: csrDashboardReportList.LoggedTime,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Logged In Time"
                      />
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current: csrDashboardReportList.ClosedTask,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Task Completed"
                      />
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current: csrDashboardReportList.AvgCallTime,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Average Call Time"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div
              style="display: flex; flex-direction: column"
              class="col-md-12 row dashboardWidgets"
              v-if="checkAuth(55007) && affiliateLeadershipDashboardData"
            >
              <div
                style="
                  min-height: 60px;
                  background-color: rgb(255, 255, 255, 1);
                  padding-top: 20px;
                  padding-bottom: 12px;
                  border-radius: 10px;
                "
              >
                <div style="display: flex; justify-content: space-between">
                  <label class="generalLabel ml-3"
                    >Sales Statistics Dashboard</label
                  >
                  <i
                    v-if="!affiliateLeadershipDashboardLoading"
                    class="fe-refresh-cw mr-3 refresh"
                    @click="refreshLeadershipDashboard()"
                  ></i>
                </div>
                <div class="col-md-12">
                  <div
                    v-if="affiliateLeadershipDashboardLoading"
                    class="loading"
                  >
                    <div class="text-center" style="margin-top: -20px">
                      <i class="spinner-grow text-info m-2"></i>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    !affiliateLeadershipDashboardLoading &&
                    affiliateLeadershipDashboardData
                  "
                >
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row"
                    style="display: flex; flex-direction: row; z-index: 100"
                  >
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current: affiliateLeadershipDashboardData.Enrollments,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Enrollments"
                      />
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current:
                            affiliateLeadershipDashboardData.InMonthCancellations,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="In-Month Cancellations"
                      />
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current:
                            affiliateLeadershipDashboardData.OneMonthRetention,
                          refresh: 'none',
                          valueFormat: 'percantage',
                        }"
                        componentName="SummaryWidget"
                        title="One-Month Retention %"
                      />
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current:
                            affiliateLeadershipDashboardData.NetEnrollments,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Net Enrollments"
                      />
                    </div>
                  </div>

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3 row"
                    style="display: flex; flex-direction: row; z-index: 100"
                  >
                    <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current: affiliateLeadershipDashboardData.TotalDebt,
                          valueFormat: 'money',
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Total Debt Enrolled"
                      />
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current:
                            affiliateLeadershipDashboardData.AverageDebtAmount,
                          valueFormat: 'money',
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Average Debt Amount"
                      />
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current:
                            affiliateLeadershipDashboardData.AverageDraftAmount,
                          valueFormat: 'money',
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Average Draft Amount"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div
              style="display: flex; flex-direction: column"
              class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row dashboardWidgets"
              v-if="checkAuth(70005) && individualSalesDashboardList"
            >
              <div
                style="
                  min-height: 60px;
                  background-color: rgb(255, 255, 255, 1);
                  padding-top: 20px;
                  padding-bottom: 12px;
                  border-radius: 10px;
                "
              >
                <div style="display: flex; justify-content: space-between">
                  <label class="generalLabel ml-3">{{
                    individualSalesDashboardList.AgentName
                  }}</label>

                  <i
                    v-if="!individualSalesLoading"
                    @click="refreshIndividualSales()"
                    class="fe-refresh-cw mr-3 refresh"
                  ></i>
                </div>
                <div class="col-md-12">
                  <div v-if="individualSalesLoading" class="loading">
                    <div class="text-center" style="margin-top: -30px">
                      <i class="spinner-grow text-info m-2"></i>
                    </div>
                  </div>
                </div>
                <div v-if="!individualSalesLoading">
                  <div
                    class="col-xl-12 col-lg-3 col-md-3 col-sm-3 col-3"
                    style="display: flex; flex-direction: row; z-index: 100"
                  >
                    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current: individualSalesDashboardList.DealsToday,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Deals Today"
                      />
                    </div>
                    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current: individualSalesDashboardList.DealsThisWeek,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Deals This Week"
                      />
                    </div>
                    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current: individualSalesDashboardList.DealsThisMonth,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Deals This Month"
                      />
                    </div>
                    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current: individualSalesDashboardList.CreditPulls,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Credit Pulls This Month"
                      />
                    </div>
                  </div>

                  <div
                    class="col-xl-12 col-lg-3 col-md-3 col-sm-3 col-3 mt-3"
                    style="display: flex; flex-direction: row; z-index: 100"
                  >
                    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current: individualSalesDashboardList.EnrolledForDay,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Enrolled For Day"
                      />
                    </div>
                    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current: individualSalesDashboardList.EnrolledForWeek,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Enrolled For Week"
                      />
                    </div>
                    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current:
                            individualSalesDashboardList.EnrolledForMonth,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Enrolled For Month"
                      />
                    </div>
                    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current: individualSalesDashboardList.ContractsIn,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Contracts In This Month"
                      />
                    </div>
                  </div>

                  <div
                    class="col-xl-12 col-lg-3 col-md-3 col-sm-3 col-3 mt-3"
                    style="display: flex; flex-direction: row; z-index: 100"
                  >
                    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current:
                            individualSalesDashboardList.TotalDebtEnrolledThisMonth,

                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Total Debt Enrolled This Month"
                      />
                    </div>
                    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current:
                            individualSalesDashboardList.TotalDebtEnrolledThisYear,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Total Debt Enrolled This Year"
                      />
                    </div>
                    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current: individualSalesDashboardList.TotalCallsToday,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Total Calls Today"
                      />
                    </div>
                    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current:
                            individualSalesDashboardList.TotalMinutesToday,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Total Talk Time"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="checkAuth(55056) && settlementFeeDashboardList"
            style="display: flex; flex-direction: column"
            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row dashboardWidgets"
          >
            <div
              style="
                min-height: 60px;
                background-color: rgb(255, 255, 255, 1);
                padding-top: 20px;
                padding-bottom: 12px;
                border-radius: 10px;
                margin-left: 10px;
                margin-right: 10px;
              "
            >
              <div>
                <label class="generalLabel ml-3"
                  >Settlements Fees Statistics</label
                >
                <i
                  v-if="!snfSettlementFeeLoading"
                  class="fe-refresh-cw mr-3 refresh"
                  @click="refreshSnfSettlementFeesDashboard()"
                ></i>
              </div>
              <div class="col-md-12">
                <div v-if="snfSettlementFeeLoading" class="loading">
                  <div class="text-center" style="margin-top: -20px">
                    <i class="spinner-grow text-info m-2"></i>
                  </div>
                </div>
              </div>
              <div
                v-if="settlementFeeDashboardList && !snfSettlementFeeLoading"
                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                style="display: flex; flex-direction: row; flex-wrap: wrap"
              >
                <div class="col-md-4">
                  <Widget
                    v-if="settlementFeeDashboardList"
                    :widgetData="{
                      current:
                        settlementFeeDashboardList.SnfCollectedDaily +
                        settlementFeeDashboardList.FlgCollectedDaily,
                      type: 'daily',
                      newSnfBottomBoxes: true,
                      amount: true,
                      salesSnf: settlementFeeDashboardList.SnfCollectedDaily,
                      salesFlg: settlementFeeDashboardList.FlgCollectedDaily,
                    }"
                    componentName="SummaryWidget"
                    title="Daily Fees Collected"
                  />
                </div>
                <div class="col-md-4">
                  <Widget
                    v-if="settlementFeeDashboardList"
                    :widgetData="{
                      current:
                        settlementFeeDashboardList.SnfCollectedMonthly +
                        settlementFeeDashboardList.FlgCollectedMonthly,
                      type: 'monthly',
                      newSnfBottomBoxes: true,
                      amount: true,
                      salesSnf: settlementFeeDashboardList.SnfCollectedMonthly,
                      salesFlg: settlementFeeDashboardList.FlgCollectedMonthly,
                    }"
                    componentName="SummaryWidget"
                    title="Monthly Fees Collected"
                  />
                </div>
                <div class="col-md-4">
                  <Widget
                    v-if="settlementFeeDashboardList"
                    :widgetData="{
                      current:
                        settlementFeeDashboardList.SnfCollectedYearly +
                        settlementFeeDashboardList.FlgCollectedYearly,
                      type: 'yearly',
                      newSnfBottomBoxes: true,
                      amount: true,
                      salesSnf: settlementFeeDashboardList.SnfCollectedYearly,
                      salesFlg: settlementFeeDashboardList.FlgCollectedYearly,
                    }"
                    componentName="SummaryWidget"
                    title="Yearly Fees Collected"
                  />
                </div>
                <div class="col-md-4">
                  <Widget
                    v-if="settlementFeeDashboardList"
                    :widgetData="{
                      current:
                        settlementFeeDashboardList.SnfScheduledDaily +
                        settlementFeeDashboardList.FlgScheduledDaily,
                      type: 'daily',
                      newSnfBottomBoxes: true,
                      amount: true,
                      salesSnf: settlementFeeDashboardList.SnfScheduledDaily,
                      salesFlg: settlementFeeDashboardList.FlgScheduledDaily,
                    }"
                    componentName="SummaryWidget"
                    title="Daily Scheduled Fees"
                  />
                </div>
                <div class="col-md-4">
                  <Widget
                    v-if="settlementFeeDashboardList"
                    :widgetData="{
                      current:
                        settlementFeeDashboardList.SnfScheduledMonthly +
                        settlementFeeDashboardList.FlgScheduledMonthly,
                      amount: true,
                      type: 'monthly',
                      newSnfBottomBoxes: true,
                      salesSnf: settlementFeeDashboardList.SnfScheduledMonthly,
                      salesFlg: settlementFeeDashboardList.FlgScheduledMonthly,
                    }"
                    componentName="SummaryWidget"
                    title="Monthly Scheduled Fees"
                  />
                </div>
                <div class="col-md-4">
                  <Widget
                    v-if="settlementFeeDashboardList"
                    :widgetData="{
                      current:
                        settlementFeeDashboardList.SnfScheduledYearly +
                        settlementFeeDashboardList.FlgScheduledYearly,
                      amount: true,
                      type: 'yearly',
                      newSnfBottomBoxes: true,
                      salesSnf: settlementFeeDashboardList.SnfScheduledYearly,
                      salesFlg: settlementFeeDashboardList.FlgScheduledYearly,
                    }"
                    componentName="SummaryWidget"
                    title="Yearly Scheduled Fees"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="checkAuth(58088) && negotiatorStatisticsList"
            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 dashboardWidgets"
          >
            <div class="col-md-12">
              <div
                class="col-md-12"
                style="
                  display: flex;
                  flex-direction: column;
                  background-color: rgb(255, 255, 255, 1);
                  padding-top: 20px;
                  padding-bottom: 12px;
                  border-radius: 10px;
                  margin-right: 10px;
                "
              >
                <div class="col-md-12">
                  <label class="generalLabel ml-2">Negotiator Statistics</label>
                  <i
                    v-if="!negotiatorStatisticsLoading"
                    class="fe-refresh-cw mr-2 refresh"
                    @click="refreshNegotiatorStatistics()"
                  ></i>
                </div>
                <div class="col-md-12">
                  <div v-if="negotiatorStatisticsLoading" class="loading">
                    <div class="text-center" style="margin-top: -20px">
                      <i class="spinner-grow text-info m-2"></i>
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    negotiatorStatisticsList && !negotiatorStatisticsLoading
                  "
                  class="col-md-12"
                  style="display: flex; flex-direction: row"
                >
                  <div class="col-md-4">
                    <div>
                      <Widget
                        :widgetData="{
                          current: snfNegotiatorStatisticsTotalCount(),
                          rate: snfNegotiatorStatisticsRate(),
                          prev: snfNegotiatorStatisticsPreviousCount(),
                          type: 'monthly',
                          newSnfBottomBoxes: true,
                          amount: false,
                          salesSnf: negotiatorStatisticsList.ThisMonthSnfCount,
                          salesFlg: negotiatorStatisticsList.ThisMonthFlgCount,
                        }"
                        componentName="SummaryWidget"
                        title="Number of Settlements"
                      />
                    </div>
                    <div class="mt-2">
                      <Widget
                        :widgetData="{
                          current: snfNegotiatorStatisticsTotalAmount(),
                          rate: snfNegotiatorStatisticsRateAmount(),
                          prev: snfNegotiatorStatisticsPreviousAmount(),
                          type: 'monthly',
                          newSnfBottomBoxes: true,
                          amount: true,
                          salesSnf: negotiatorStatisticsList.ThisMonthSnfDebt,
                          salesFlg: negotiatorStatisticsList.ThisMonthFlgDebt,
                        }"
                        componentName="SummaryWidget"
                        title="Total Settled Debt"
                      />
                    </div>
                  </div>
                  <div class="col-md-8">
                    <Widget
                      componentName="TableWidget"
                      title="Negotiator Settlement Summary"
                      :dateOption="dateFilter"
                      :widgetData="{
                        data: negotiatorStatisticsList.NegoStats,
                        columns: negotiatorStatisticsdashboardTableColumns,
                        prefix: true,
                        foot: false,
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="checkAuth(55425) && !isLoading"
            class="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12 dashboardWidgets"
          >
            <div
              style="
                background-color: rgb(255, 255, 255, 1);
                padding-top: 20px;
                padding-bottom: 20px;
                border-radius: 10px;
                margin-left: 10px;
                margin-right: 10px;
              "
            >
              <div>
                <label class="generalLabel ml-3">Negotiator Statistics</label>
              </div>
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                style="display: flex; flex-direction: row; flex-wrap: wrap"
              >
                <div class="col-md-6">
                  <Widget
                    v-if="negotiatorsDashboardList"
                    :widgetData="{
                      current:
                        negotiatorsDashboardList.SettlementCountForThisMonth,
                      rate:
                        negotiatorsDashboardList.SettlementCountPercentage * -1,
                      prev: negotiatorsDashboardList.SettlementCountForLastMonth,
                      refresh: true,
                      type: 'monthly',
                    }"
                    componentName="SummaryWidget"
                    title="Number of Settlements"
                  />
                </div>
                <div class="col-md-6">
                  <Widget
                    v-if="negotiatorsDashboardList"
                    :widgetData="{
                      current: negotiatorsDashboardList.TotalDebtForThisMonth,
                      rate: negotiatorsDashboardList.TotalDebtPercentage * -1,
                      prev: negotiatorsDashboardList.TotalDebtForLastMonth,
                      refresh: true,
                      type: 'monthly',
                      valueFormat: 'money',
                      amount: true,
                    }"
                    componentName="SummaryWidget"
                    title="Total Settled Debt"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 dashboardWidgets"
            v-if="operationHourDashboardList && checkAuth(55413)"
          >
            <div
              class="row"
              style="
                padding-bottom: 12px;
                border-radius: 10px;
                margin-left: 0;
                margin-right: 0;
              "
            >
              <div
                v-for="item in operationHourDashboardList.Hours"
                :key="item.Role"
                class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
              >
                <Widget
                  :widgetData="{
                    Role: item.Role,
                    English: item.EN,
                    Spanish: item.ES,
                  }"
                  componentName="OperationHourWidget"
                  title=""
                />
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div
              v-if="salesMonthlyOverviewData && checkAuth(81549)"
              class="dashboardWidgets"
              style="
                min-height: 60px;
                background: #ffffff;
                margin: 10px;
                border-radius: 6px;
                padding: 12px 0px;
                position: relative;
              "
            >
              <div class="col-md-12 noMargin row">
                <div class="col-md-2">
                  <label class="generalLabel">Office:</label>
                  <select
                    @change="changeOffice"
                    style="background-color: #ffffff"
                    class="form-control"
                    v-model="salesMonthlyDashboardOffice"
                  >
                    <option :value="0">All Offices</option>
                    <option
                      v-for="item in filteredOffices"
                      :key="item.Id"
                      :value="item.Id"
                    >
                      {{ item.Name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2">
                  <label class="generalLabel">Teams:</label>
                  <select
                    @change="changeTeams"
                    style="background-color: #ffffff"
                    class="form-control"
                    v-model="salesMonthlyDashboardRole"
                  >
                    <option :value="0">All Teams</option>
                    <option
                      v-for="item in salesRoles"
                      :key="item.Id"
                      :value="item.Id"
                    >
                      {{ item.Name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2">
                  <label class="generalLabel">Month:</label>
                  <select
                    style="background-color: #ffffff"
                    class="form-control"
                    v-model="salesMonthlyDashboardMonth"
                  >
                    <option
                      v-for="item in menuOptions"
                      :key="item.Key"
                      :value="item.Key"
                    >
                      {{ item.Value }}
                    </option>
                  </select>
                </div>

                <div
                  class="col-md-2"
                  style="display: flex; align-items: flex-end"
                >
                  <button
                    class="btn btn-primary"
                    @click="getSalesMonthlyOverviewData()"
                    :disabled="salesMonthlyOverviewLoading"
                  >
                    Filter
                  </button>
                </div>

                <div
                  @click="getSalesMonthlyOverviewData()"
                  style="
                    position: absolute;
                    top: 5px;
                    right: 25px;
                    z-index: 10;
                    cursor: pointer;
                  "
                >
                  <i
                    class="fe-refresh-cw"
                    title="refresh"
                    style="font-size: 16px; color: #3295b9"
                  ></i>
                </div>
              </div>

              <div class="col-md-12 row">
                <div v-if="salesMonthlyOverviewLoading" class="loading">
                  <div class="text-center" style="margin-top: -50px">
                    <i class="spinner-grow text-info m-2"></i>
                  </div>
                </div>
              </div>

              <div
                v-if="isErrorMessage && !salesMonthlyOverviewLoading"
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <i
                  class="ri-question-line"
                  style="font-size: 32px; color: rgb(147, 147, 147)"
                ></i>
                <label for="">{{ errorMessage }}</label>
              </div>

              <div v-if="!salesMonthlyOverviewLoading && !isErrorMessage">
                <div class="col-md-12" style="display: flex">
                  <!-- Left chart -->
                  <div
                    class="col-xl-3 col-lg-3 col-md-12 col-xs-12 col-12"
                    style="z-index: 100"
                  >
                    <div>
                      <Widget
                        componentName="NewDonuthChartWidget"
                        title=""
                        :dateOption="dateFilter"
                        :widgetData="{
                          header: salesMonthlyOverviewData.TotalDealsHeader,
                          value: salesMonthlyOverviewData.TotalDealsValue,
                          valueFormat: 'number',
                          subHeader:
                            salesMonthlyOverviewData.TotalDealsProjectionHeader,
                          subValue:
                            salesMonthlyOverviewData.TotalDealsProjectionValue,
                          bottomHeader: 'Deals',
                          bottomValue: salesMonthlyOverviewData.TotalDealsGoals,
                          bottomValueText: ' Goal',
                          bottomPerc: '% of',
                        }"
                      />
                    </div>
                  </div>
                  <!-- Header and and middle boxes -->
                  <div
                    class="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-12"
                    style="
                      display: flex;
                      flex-direction: column;
                      gap: 5px;
                      z-index: 100;
                    "
                  >
                    <div
                      class="mb-2"
                      style="
                        height: 30%;
                        padding-left: 12px;
                        padding-right: 12px;
                      "
                    >
                      <Widget
                        componentName="BoxWidget"
                        :dateOption="dateFilter"
                        title="Need to Meet Goal"
                        :widgetData="{
                          header: salesMonthlyOverviewData.NeedToMeetGoalHeader,
                          value: salesMonthlyOverviewData.NeedToMeetGoalValue,
                          valueFormat: 'number',
                          positiveIndicator: false,
                          negativeIndicator: false,
                          subHeader: null,
                          subHeaderValue: null,
                          bottomHeader: '',
                          bottomValue: null,
                        }"
                      />
                    </div>

                    <div class="mb-2" style="display: flex">
                      <div class="col-md-6" style="width: 100%; height: 100%">
                        <Widget
                          componentName="BoxWidget"
                          title="Total Deals"
                          :dateOption="dateFilter"
                          :widgetData="{
                            header:
                              salesMonthlyOverviewData.AgentDealsTodayHeader,
                            value:
                              salesMonthlyOverviewData.AgentDealsTodayValue,
                            valueFormat: 'number',
                            positiveIndicator: false,
                            negativeIndicator: false,
                            subHeader: null,
                            subHeaderValue: null,
                            bottomHeader: '',
                            bottomValue: null,
                          }"
                        />
                      </div>
                      <div class="col-md-6" style="width: 100%; height: 100%">
                        <Widget
                          componentName="BoxWidget"
                          title="Total Submitted"
                          :dateOption="dateFilter"
                          :widgetData="{
                            header:
                              salesMonthlyOverviewData.AgentDebtTodayHeader,
                            value:
                              salesMonthlyOverviewData.AgentSubmittedDebtTodayValue,
                            valueFormat: 'money',
                            positiveIndicator: false,
                            negativeIndicator: false,
                            subHeader: null,
                            subHeaderValue: null,
                            bottomHeader: '',
                            bottomValue: null,
                          }"
                        />
                      </div>
                    </div>

                    <div style="display: flex">
                      <div class="col-md-4">
                        <Widget
                          componentName="BoxWidget"
                          title="New Submits"
                          :dateOption="dateFilter"
                          :widgetData="{
                            header: salesMonthlyOverviewData.NewSubmitHeader,
                            value: salesMonthlyOverviewData.NewSubmitValue,
                            valueFormat: 'number',
                            positiveIndicator:
                              salesMonthlyOverviewData.NewSubmitComparisonValue >
                              0,
                            negativeIndicator:
                              salesMonthlyOverviewData.NewSubmitComparisonValue <
                              0,
                            subHeader:
                              salesMonthlyOverviewData.NewSubmitSubHeader,
                            subHeaderValue:
                              salesMonthlyOverviewData.NewSubmitSubValue,
                            bottomHeader: '',
                            bottomValue: salesMonthlyOverviewData.NewSubmitGoal,
                          }"
                        />
                      </div>

                      <div class="col-md-4">
                        <Widget
                          componentName="BoxWidget"
                          title="Contacts Enrolled"
                          :dateOption="dateFilter"
                          :widgetData="{
                            header:
                              salesMonthlyOverviewData.ContactsEnrolledHeader,
                            value:
                              salesMonthlyOverviewData.ContactsEnrolledValue,
                            valueFormat: 'number',
                            positiveIndicator:
                              salesMonthlyOverviewData.ContactsEnrolledComparisonValue >
                              0,
                            negativeIndicator:
                              salesMonthlyOverviewData.ContactsEnrolledComparisonValue <
                              0,
                            subHeader:
                              salesMonthlyOverviewData.ContactsEnrolledSubHeader,
                            subHeaderValue:
                              salesMonthlyOverviewData.ContactsEnrolledSubValue,
                            bottomHeader: '',
                            bottomValue:
                              salesMonthlyOverviewData.ContactsEnrolledGoal,
                          }"
                        />
                      </div>

                      <div class="col-md-4">
                        <Widget
                          componentName="BoxWidget"
                          title="Affiliate Deals"
                          :dateOption="dateFilter"
                          :widgetData="{
                            header:
                              salesMonthlyOverviewData.AffiliateDealsHeader,
                            value: salesMonthlyOverviewData.AffiliateDealsValue,
                            valueFormat: 'number',
                            positiveIndicator:
                              salesMonthlyOverviewData.AffiliateDealsComparisonValue >
                              0,
                            negativeIndicator:
                              salesMonthlyOverviewData.AffiliateDealsComparisonValue <
                              0,
                            subHeader:
                              salesMonthlyOverviewData.AffiliateDealsSubHeader,
                            subHeaderValue:
                              salesMonthlyOverviewData.AffiliateDealsSubValue,
                            bottomHeader: '',
                            bottomValue:
                              salesMonthlyOverviewData.AffiliateDealsGoal,
                          }"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Right chart  -->
                  <div
                    class="col-xl-3 col-lg-3 col-md-12 col-xs-12 col-12"
                    style="z-index: 100"
                  >
                    <div>
                      <Widget
                        componentName="NewDonuthChartWidget"
                        title=""
                        :dateOption="dateFilter"
                        :widgetData="{
                          header:
                            salesMonthlyOverviewData.ProjectionofSubmittalsHeader,
                          value:
                            salesMonthlyOverviewData.ProjectionofSubmittalsValue,
                          valueFormat: 'number',
                          subHeader:
                            salesMonthlyOverviewData.ProjectionofSubmittalsProjectionHeader,
                          subValue:
                            salesMonthlyOverviewData.ProjectionofSubmittalsProjectionValue,
                          bottomHeader: 'Deals',
                          bottomValue: '',
                          bottomValueText: ' Daily pace to goal',
                          bottomPerc: '%',
                          justPercantageShow: false,
                          specialPercentageShow: true,
                          specialPercentageValue:
                            salesMonthlyOverviewData.ProjectionofSubmittalsGoals,
                        }"
                      />
                    </div>
                  </div>
                </div>
                <!-- Bottom boxes -->
                <div
                  class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                  style="display: flex; z-index: 100"
                >
                  <div class="col-md-3">
                    <Widget
                      componentName="BoxWidget"
                      title="Leads Created"
                      :dateOption="dateFilter"
                      :widgetData="{
                        header: salesMonthlyOverviewData.LeadsCreatedHeader,
                        value: salesMonthlyOverviewData.LeadsCreatedValue,
                        valueFormat: 'number',
                        positiveIndicator:
                          salesMonthlyOverviewData.LeadsCreatedComparisonValue >
                          0,
                        negativeIndicator:
                          salesMonthlyOverviewData.LeadsCreatedComparisonValue <
                          0,
                        subHeader:
                          salesMonthlyOverviewData.LeadsCreatedSubHeader,
                        subHeaderValue:
                          salesMonthlyOverviewData.LeadsCreatedSubValue,
                        bottomHeader: '',
                        bottomValue: salesMonthlyOverviewData.LeadsCreatedGoal,
                      }"
                    />
                  </div>

                  <div class="col-md-3">
                    <Widget
                      componentName="BoxWidget"
                      title="Total Debt Goal"
                      :dateOption="dateFilter"
                      :widgetData="{
                        header: salesMonthlyOverviewData.DebtGoalHeader,
                        value: salesMonthlyOverviewData.DebtGoalValue,
                        valueFormat: 'percentage',
                        positiveIndicator:
                          salesMonthlyOverviewData.DebtGoalComparisonValue > 0,
                        negativeIndicator:
                          salesMonthlyOverviewData.DebtGoalComparisonValue < 0,
                        subHeader: salesMonthlyOverviewData.DebtGoalSubHeader,
                        subHeaderValue:
                          salesMonthlyOverviewData.DebtGoalSubValue,
                        bottomHeader: '',
                        bottomValue: salesMonthlyOverviewData.DebtGoalGoal,
                      }"
                    />
                  </div>

                  <div class="col-md-3">
                    <Widget
                      componentName="BoxWidget"
                      title="Total Debt Submitted"
                      :dateOption="dateFilter"
                      :widgetData="{
                        header:
                          salesMonthlyOverviewData.TotalDebtSubmittedHeader,
                        value: salesMonthlyOverviewData.TotalDebtSubmittedValue,
                        valueFormat: 'money',
                        positiveIndicator:
                          salesMonthlyOverviewData.TotalDebtSubmittedComparisonValue >
                          0,
                        negativeIndicator:
                          salesMonthlyOverviewData.TotalDebtSubmittedComparisonValue <
                          0,
                        subHeader:
                          salesMonthlyOverviewData.TotalDebtSubmittedSubHeader,
                        subHeaderValue:
                          salesMonthlyOverviewData.TotalDebtSubmittedSubValue,
                        bottomHeader: '',
                        bottomValue:
                          salesMonthlyOverviewData.TotalDebtSubmittedGoal,
                      }"
                    />
                  </div>

                  <div class="col-md-3">
                    <Widget
                      componentName="BoxWidget"
                      title="Avg Debt Per Submit"
                      :dateOption="dateFilter"
                      :widgetData="{
                        header: salesMonthlyOverviewData.AvgDebtPerSubmitHeader,
                        value: salesMonthlyOverviewData.AvgDebtPerSubmitValue,
                        valueFormat: 'money',
                        positiveIndicator:
                          salesMonthlyOverviewData.AvgDebtPerSubmitComparisonValue >
                          0,
                        negativeIndicator:
                          salesMonthlyOverviewData.AvgDebtPerSubmitComparisonValue <
                          0,
                        subHeader:
                          salesMonthlyOverviewData.AvgDebtPerSubmitSubHeader,
                        subHeaderValue:
                          salesMonthlyOverviewData.AvgDebtPerSubmitSubValue,
                        bottomHeader: '',
                        bottomValue:
                          salesMonthlyOverviewData.AvgDebtPerSubmitGoal,
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Sales Monthly Overview Dashboard END -->

          <!-- SNF Status Dashboard -->

          <!-- SNF Status Dashboard END -->

          <div class="timeAndGreetingWrapper">
            <span class="timeText">
              {{ timeInfo }}
            </span>
            <span class="greetingText">
              {{ greetingInfo }}
            </span>
          </div>

          <div class="messageWrapper">
            <span class="messageText">
              {{ dashboardData.Background.Message }}

              <span
                v-if="dashboardData.Background.MessageSource"
                class="meessageSourceText"
              >
                <sup>- {{ dashboardData.Background.MessageSource }}</sup>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import types from "./types";
import { mapState } from "vuex";
import Widget from "@/components/Widget";
import deepCopy from "@/helpers/deepCopy";
import globalTypes from "@/store/types";
import _orderby from "lodash";

export default {
  name: "Dashboard",
  components: { Widget },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      errorMessage: "",
      isErrorMessage: false,
      menuOptions: null,
      negotiatorStatisticsLoading: false,
      individualSalesLoading: false,
      snfSettlementFeeLoading: false,
      affiliateLeadershipDashboardLoading: false,
      salesMonthlyOverviewLoading: false,
      dbCsrDashboardLoading: false,
      dbSalesDashboardLoading: false,
      hasAccessOtherOffices: false,
      userOfficeId: 0,
      salesMonthlyDashboardRole: 0,
      salesMonthlyDashboardOffice: 0,
      salesMonthlyDashboardMonth: null,
      welcomeCalldashboardTableColumns: [
        {
          field: "CustomerId",
          label: "Customer Id",
          width: "15%",
          type: "text",
          showTotal: "",
        },
        {
          field: "Name",
          label: "Name",
          width: "15%",
          type: "text",
          showTotal: "",
        },
        {
          field: "SalesRep",
          label: "Sales Rep",
          width: "15%",
          type: "numeric",
          showTotal: "",
        },
        {
          field: "Status",
          label: "Status",
          width: "15%",
          type: "text",
          showTotal: "",
        },
        {
          field: "SignedDate",
          label: "Contract Signed Date",
          width: "15%",
          type: "onlyDate",
          showTotal: "",
        },
        {
          field: "Office",
          label: "Office",
          width: "15%",
          type: "text",
          showTotal: "",
        },
        {
          field: "ViewCustomer",
          label: "View Customer",
          width: "10%",
          type: "button",
          showTotal: "",
        },
      ],
      operationHourTimerActive: false,

      salesMonthlyOverviewData: {
        // LeftChart
        TotalDealsHeader: "Total Submitted",
        TotalDealsValue: 0,
        TotalDealsProjectionHeader: "",
        TotalDealsProjectionValue: "", // BOS
        TotalDealsGoals: 0,
        //

        //Header
        NeedToMeetGoalHeader: "NEEDED TODAY TO MEET GOAL",
        NeedToMeetGoalValue: 0,
        //

        // ML1
        AgentDealsTodayHeader: "DebtBlue Today (#)",
        AgentDealsTodayValue: 0,
        //

        // MR1
        AgentDebtTodayHeader: "DebtBlue Today ($)",
        AgentSubmittedDebtTodayValue: 0,
        //

        // ML2
        // AffiliatesTodayHeader: "Affiliates Today (#)",
        // AffiliatesTodayValue: 0,
        // AffiliatesTodayGoal: 0,
        // AffiliatesTodayComparisonValue: 0,
        //

        // MR2
        // AffiliatesTodayDealsHeader: "Affiliates Today ($)",
        // AffiliatesTodayDealsValue: 0,
        // AffiliatesTodayDealsGoal: 0,
        // AffiliatesTodayDealsComparisonValue: 0,
        //

        // ML3
        NewSubmitHeader: "NEW SUBMITS",
        NewSubmitValue: 0,
        NewSubmitGoal: 0,
        NewSubmitComparisonValue: 0,
        NewSubmitSubHeader: "",
        NewSubmitSubValue: 0,
        //

        // MM3
        // ReinstatementsHeader: "REINSTATEMENTS",
        // ReinstatementsValue: 0,
        // ReinstatementsGoal: 0,
        // ReinstatementsComparisonValue: 0,
        // ReinstatementsSubHeader: "",
        // ReinstatementsSubValue: 0,
        //

        // MR4
        AffiliateDealsHeader: "AFFILIATE DEALS",
        AffiliateDealsValue: 0,
        AffiliateDealsGoal: 0,
        AffiliateDealsComparisonValue: 0,
        AffiliateDealsSubHeader: "",
        AffiliateDealsSubValue: 0,
        //

        // MR3
        DebtGoalHeader: "TOTAL DEBT GOAL",
        DebtGoalValue: 0,
        DebtGoalGoal: 0,
        DebtGoalComparisonValue: 0,
        DebtGoalSubHeader: "",
        DebtGoalSubValue: 0,
        //

        // RightCharts
        ProjectionofSubmittalsHeader: "Projection of Submittals",
        ProjectionofSubmittalsValue: 0,
        ProjectionofSubmittalsProjectionHeader: "",
        ProjectionofSubmittalsProjectionValue: "",
        ProjectionofSubmittalsGoals: 0,
        //

        // BL1
        LeadsCreatedHeader: "LEADS CREATED",
        LeadsCreatedValue: 0,
        LeadsCreatedGoal: 0,
        LeadsCreatedComparisonValue: 0,
        LeadsCreatedSubHeader: "",
        LeadsCreatedSubValue: 0,

        //

        // BL2
        ContactsEnrolledHeader: "CONTACTS ENROLLED",
        ContactsEnrolledValue: 0,
        ContactsEnrolledGoal: 0,
        ContactsEnrolledComparisonValue: 0,
        ContactsEnrolledSubHeader: "",
        ContactsEnrolledSubValue: 0,
        //

        // BL3
        TotalDebtSubmittedHeader: "TOTAL DEBT SUBMITTED",
        TotalDebtSubmittedValue: 0,
        TotalDebtSubmittedGoal: 0,
        TotalDebtSubmittedComparisonValue: 0,
        TotalDebtSubmittedSubHeader: "",
        TotalDebtSubmittedSubValue: 0,
        //

        // BL4
        AvgDebtPerSubmitHeader: "AVG DEBT PER SUBMIT",
        AvgDebtPerSubmitValue: 0,
        AvgDebtPerSubmitGoal: 0,
        AvgDebtPerSubmitComparisonValue: 0,
        AvgDebtPerSubmitSubHeader: "",
        AvgDebtPerSubmitSubValue: 0,

        //
      },

      userId: null,
      dbDashBoardData: {
        TotalDealsHeader: "Total Deals Submitted",
        TotalDealsValue: 0,
        TotalDealsGoals: 0,
        TotalDealsProjectionHeader: "EOM FORECAST",
        TotalDealsProjectionValue: 0,

        TotalDebtHeader: "Total Debt Submitted",
        TotalDebtValue: 0,
        TotalDebtGoals: 0,
        TotalDebtProjectionHeader: "EOM FORECAST",
        TotalDebtProjectionValue: 0,

        NeedToMeetGoalHeader: "NEEDED TO MEET GOAL",
        NeedToMeetGoalValue: 0,
        AgentDealsTodayHeader: "Deals Today",
        AgentDealsTodayValue: 0,
        AgentSubmittedDebtTodayHeader: "Debt Today",
        AgentSubmittedDebtTodayValue: 0,

        TalkTimeDayHeader: "Talk Time Day",
        TalkTimeDayValue: 0,
        TalkTimeDayGoal: 0,
        TalkTimeDayComparisonValue: 0,

        TalkTimeWeekHeader: "Talk Time Week",
        TalkTimeWeekValue: 0,
        TalkTimeWeekGoal: 0,
        TalkTimeWeekComparisonValue: 0,

        LeadsWorkedHeader: "Leads Worked",
        LeadsWorkedValue: 0,
        LeadsWorkedPreviousMonth: 0,
        LeadsWorkedPercentage: 0,
        LeadsWorkedComparisonValue: 0,

        AverageSubmittedDebtHeader: "Average Debt Per Submission",
        AverageSubmittedDebtValue: 0,
        AverageSubmittedDebtPreviousMonth: 0,
        AverageSubmittedDebtPercentage: 0,
        AverageSubmittedDebtComparisonValue: 0,

        ContactsEnrolledHeader: "Contacts Enrolled",
        ContactsEnrolledValue: "Contacts Enrolled",
        ContactsEnrolledPreviousMonth: 0,
        ContactsEnrolledPercentage: 0,
        ContactsEnrolledComparisonValue: 0,
      },
      welcomeCallData: [],

      leftMenuOpen: true,
      isLoading: false,

      closeCall: false,
      isMobile: false,
      bgHeight: 0,
      bgWidth: 0,
      bgLeft: 240,
      timeInfo: "",
      greetingInfo: "",
      userFullName: "",
      dateFilter: "Today",
      dashboardTableColumns: [
        {
          field: "OfficeName",
          label: "Office Name",
          width: "17.7%",
          type: "text",
          showTotal: "",
        },
        {
          field: "EnrollmentCount",
          label: "Deals #",
          width: "11.6%",
          type: "numeric",
          showTotal: "total",
        },
        {
          field: "ActiveClientCount",
          label: "Active #",
          width: "11.5%",
          type: "numeric",
          showTotal: "total",
        },
        {
          field: "AverageDeals",
          label: "Avg Deals #",
          width: "12.6%",
          type: "float",
          showTotal: "total",
        },
        {
          field: "AverageDebt",
          label: "Avg Deal $",
          width: "11.7%",
          type: "decimal",
          showTotal: "total",
        },
        {
          field: "TotalCampaignInbound",
          label: "Campaign Inbound #",
          width: "11.8%",
          type: "numeric",
          showTotal: "total",
        },
        {
          field: "TotalOutbound",
          label: "Total Outbound #",
          width: "12.1%",
          type: "numeric",
          showTotal: "total",
        },
        {
          field: "PotentialRevenue",
          label: "Potential Rev $",
          width: "10%",
          type: "decimal",
          showTotal: "total",
        },
      ],
      negotiatorStatisticsdashboardTableColumns: [
        {
          field: "NegotiatorName",
          label: "Negotiator Name",
          width: "15%",
          type: "text",
          showTotal: "",
        },
        {
          field: "SettledCount",
          label: "Settlement Count",
          width: "15%",
          type: "numeric",
          showTotal: "",
        },
        {
          field: "SettledDebt",
          label: "Settled Debt",
          width: "15%",
          type: "money",
          showTotal: "",
        },
      ],
      timeNow: new Date(),
      timePassed: new Date(),
    };
  },
  computed: mapState({
    officePerformanceData: (state) =>
      state.dashboardStore.officePerformanceData,
    settlementFeeDashboardList: (state) =>
      state.dashboardStore.settlementFeeDashboardList,
    negotiatorsDashboardList: (state) =>
      state.dashboardStore.negotiatorsDashboardList,
    snfSettlementsList: (state) => state.dashboardStore.snfSettlementsList,
    dashboardData: (state) => state.dashboardStore.dashboardData,
    affiliateEnrollmenrDashboardList: (state) =>
      state.dashboardStore.affiliateEnrollmenrDashboardList,
    migrationAffiliateEnrollmenrDashboardList: (state) =>
      state.dashboardStore.migrationAffiliateEnrollmenrDashboardList,
    snfDashboardData: (state) => state.dashboardStore.snfDashboardData,
    affiliateLeadershipDashboardData: (state) =>
      state.dashboardStore.affiliateLeadershipDashboardData,
    csrDashboardReportList: (state) =>
      state.dashboardStore.csrDashboardReportList,
    individualSalesDashboardList: (state) =>
      state.dashboardStore.individualSalesDashboardList,
    operationHourDashboardList: (state) =>
      state.dashboardStore.operationHourDashboardList,
    snfCustomerStatusDashboardList: (state) =>
      state.dashboardStore.snfCustomerStatusDashboardList,
    welcomeCallDashboardList: (state) =>
      state.dashboardStore.welcomeCallDashboardList,
    roles: (state) => state.globals.roles,
    negotiatorStatisticsList: (state) =>
      state.dashboardStore.negotiatorStatisticsList,

    salesRoles() {
      return _orderby.orderBy(
        this.roles.filter((x) => x.ModuleFunctions.includes(1848)),
        "Name",
        "asc"
      );
    },

    offices: (state) => state.globals.offices,
    filteredOffices() {
      return _orderby.orderBy(
        this.offices.filter((x) =>
          this.hasAccessOtherOffices == true ? true : x.Id == this.userOfficeId
        )
      );
    },
    getTimePassed() {
      let rtn = parseInt(this.getTimeDifference(this.timePassed, this.timeNow));

      return rtn;
    },
  }),
  async mounted() {
    this.isLoading = true;
    this.setMenuOptions();

    if (this.salesMonthlyDashboardMonth == null) {
      this.salesMonthlyDashboardMonth = this.menuOptions[0].Key;
    }

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (userInfo != null && userInfo.moduleFunctions != null) {
      this.userOfficeId = userInfo.officeId;
    }
    this.hasAccessOtherOffices = this.canSeeAllOffices();

    let dashboardList = [];
    dashboardList.push(this.$store.dispatch(types.GET_SUMMARY_TILES));

    /*NF DASHBOARD START */
    if (this.checkAuth(18654)) {
      dashboardList.push(
        this.$store.dispatch(types.GET_OFFICE_PERFORMANCE_STATE, {
          Option: "today",
        })
      );
    }

    if (this.checkAuth(70005)) {
      dashboardList.push(
        this.$store.dispatch(types.GET_INDIVIDUAL_SALES_DASHBOARD, {
          Option: "today",
        })
      );
    }
    /*NF DASHBOARD END */

    /*SNF DASHBOARD START */
    if (this.checkAuth(58088)) {
      dashboardList.push(
        this.$store.dispatch(types.GET_NEGOTIATOR_STATISTICS_DASHBOARD, {
          Option: "today",
        })
      );
    }

    if (this.checkAuth(55001)) {
      dashboardList.push(
        this.$store.dispatch(types.GET_SNF_DASHBOARD, {
          Option: "today",
        })
      );
    }
    if (this.checkAuth(55414)) {
      dashboardList.push(
        this.$store.dispatch(types.GET_SNF_DASHBOARD_CUSTOMER_STATUS)
      );
    }
    if (this.checkAuth(55055)) {
      dashboardList.push(
        this.$store.dispatch(types.GET_SNF_SETTLEMENTS_DASHBOARD, {
          Option: "lastweek",
        })
      );
    }
    if (this.checkAuth(55056)) {
      dashboardList.push(
        this.$store.dispatch(types.GET_SNF_SETTLEMENT_FEE_DASHBOARD, {
          Title: "",
          Option: "",
          StartDate: "",
          EndDate: "",
        })
      );
    }
    if (this.checkAuth(55435)) {
      dashboardList.push(
        this.$store.dispatch(types.GET_AFFILIATE_ENROLLMENT_DASHBOARD, {
          Title: "",
          Option: "",
          StartDate: "",
          EndDate: "",
          AccessToMigrationLeads: false,
        })
      );
    }

    if (this.checkAuth(55435) && this.checkAuth(87235)) {
      dashboardList.push(
        this.$store.dispatch(
          types.GET_MIGRATION_AFFILIATE_ENROLLMENT_DASHBOARD,
          {
            Title: "",
            Option: "",
            StartDate: "",
            EndDate: "",
            AccessToMigrationLeads: true,
          }
        )
      );
    }

    if (this.checkAuth(55007)) {
      dashboardList.push(
        this.$store.dispatch(types.GET_AFFILIATE_LEADERSHIP_DASHBOARD)
      );
    }
    if (this.checkAuth(55415)) {
      dashboardList.push(this.$store.dispatch(types.GET_WELCOME_CALL));
    }
    if (this.checkAuth(55413)) {
      dashboardList.push(
        this.$store.dispatch(types.GET_OPERATION_HOUR_DASHBOARD)
      );
      this.operationHourTimerActive = true;
    }

    if (this.checkAuth(55425)) {
      dashboardList.push(this.$store.dispatch(types.GET_NEGOTIATORS_DASHBOARD));
    }
    /*SNF DASHBOARD END */

    await Promise.all(dashboardList);

    /*D-B DASHBOARD START */
    if (this.checkAuth(81452)) {
      if (userInfo != null) {
        this.userId = userInfo.userId;
        await this.getDbSalesDashboardData();
      }
    }
    if (this.checkAuth(81453)) {
      await this.refreshcsrDashboardReport();
    }
    if (this.checkAuth(81549)) {
      await this.$store.dispatch(globalTypes.GET_ROLES);
      await this.$store.dispatch(globalTypes.GET_OFFICE_NAMES);
      await this.getSalesMonthlyOverviewData();
    }

    /*D-B DASHBOARD END */

    this.isLoading = false;

    this.isMobile = this.isMobileDevice();
    window.addEventListener("resize", this.setDashboardSize);

    if (userInfo) {
      this.userFullName = userInfo.firstName + " " + userInfo.lastName;
    } else {
      this.userFullName = "";
    }
    this.getTime();
    this.getGreetings();
    await this.sleep(200);
    this.setDashboardSize();
    setInterval(() => {
      this.getTime();
    }, 1000);
    setInterval(async () => {
      this.getGreetings();
      if (this.operationHourTimerActive) {
        await this.refreshOperationHours();
      }
    }, 60000);

    this.$Emitter.on("resizeDashboard", (isMenuOpen) => {
      if (typeof isMenuOpen != "undefined" && isMenuOpen != null) {
        this.leftMenuOpen = isMenuOpen;
        this.setDashboardSize();
      }
    });
    this.$Emitter.on("stopOperationHourTimer", () => {
      this.operationHourTimerActive = false;
    });
  },
  unmounted() {
    window.removeEventListener("resize", this.setDashboardSize);
  },
  methods: {
    getPayload() {
      let startDate = "";
      let endDate = "";

      let month = this.salesMonthlyDashboardMonth.getMonth();
      let year = this.salesMonthlyDashboardMonth.getFullYear();
      startDate = new Date(year, month, 1).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      endDate = new Date(year, month + 1, 0).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      return {
        StartDate: startDate,
        EndDate: endDate,
      };
    },

    setMenuOptions() {
      this.menuOptions = [];
      let today = new Date();
      for (let i = 0; i < 12; i++) {
        let date = new Date(today);
        date.setMonth(today.getMonth() - i);
        let year = date.getFullYear();
        let monthShortName = date.toLocaleString("en-us", { month: "short" });
        let value = i == 0 ? "This Month" : monthShortName + " " + year;
        this.menuOptions.push({
          Key: date,
          Value: value,
        });
      }
    },

    snfNegotiatorStatisticsTotalCount() {
      let rtn = 0;
      rtn =
        this.negotiatorStatisticsList.ThisMonthSnfCount +
        this.negotiatorStatisticsList.ThisMonthFlgCount;
      return rtn;
    },

    snfNegotiatorStatisticsPreviousCount() {
      let rtn = 0;
      if (
        this.negotiatorStatisticsList &&
        this.negotiatorStatisticsList.PreviousMonthSnfCount &&
        this.negotiatorStatisticsList.PreviousMonthFlgCount
      ) {
        rtn =
          this.negotiatorStatisticsList.PreviousMonthSnfCount +
          this.negotiatorStatisticsList.PreviousMonthFlgCount;
      }
      return rtn;
    },

    snfNegotiatorStatisticsRate() {
      let rtn = 0;
      let monthlyCount = this.snfNegotiatorStatisticsTotalCount();
      let previousCount = this.snfNegotiatorStatisticsPreviousCount();
      rtn = (monthlyCount / previousCount - 1) * 100 * -1;
      return rtn;
    },

    snfNegotiatorStatisticsTotalAmount() {
      let rtn = 0;
      rtn =
        this.negotiatorStatisticsList.ThisMonthSnfDebt +
        this.negotiatorStatisticsList.ThisMonthFlgDebt;
      return rtn;
    },

    snfNegotiatorStatisticsPreviousAmount() {
      let rtn = 0;
      if (
        this.negotiatorStatisticsList &&
        this.negotiatorStatisticsList.PreviousMonthSnfDebt &&
        this.negotiatorStatisticsList.PreviousMonthFlgDebt
      ) {
        rtn =
          this.negotiatorStatisticsList.PreviousMonthSnfDebt +
          this.negotiatorStatisticsList.PreviousMonthFlgDebt;
      }
      return rtn;
    },

    snfNegotiatorStatisticsRateAmount() {
      let rtn = 0;
      let monthlyCount = this.snfNegotiatorStatisticsTotalAmount();
      let previousCount = this.snfNegotiatorStatisticsPreviousAmount();
      rtn = (monthlyCount / previousCount - 1) * 100 * -1;
      return rtn;
    },

    snfSettlementsDashDailySales() {
      let rtn = 0;

      rtn =
        this.snfSettlementsList.SnfDailyCount +
        this.snfSettlementsList.FlgDailyCount;
      return rtn;
    },

    snfSettlementsDashDailyPreviousCount() {
      let rtn = 0;
      if (
        this.snfSettlementsList.SnfYesterdayCount &&
        this.snfSettlementsList.FlgYesterdayCount
      ) {
        rtn =
          this.snfSettlementsList.SnfYesterdayCount +
          this.snfSettlementsList.FlgYesterdayCount;
      }
      return rtn;
    },

    snfSettlementsDashDailyCountRate() {
      let rtn = 0;
      let dailySales = this.snfSettlementsDashDailySales();
      let previousCount = this.snfSettlementsDashDailyPreviousCount();
      rtn = (dailySales / previousCount - 1) * 100 * -1;
      return rtn;
    },

    snfSettlementsDashDailySalesDebt() {
      let rtn = 0;
      rtn =
        this.snfSettlementsList.SnfDailyDebtAmount +
        this.snfSettlementsList.FlgDailyDebtAmount;
      return rtn;
    },

    snfSettlementsDashDailyPreviousAmount() {
      let rtn = 0;
      if (
        this.snfSettlementsList &&
        this.snfSettlementsList.SnfYesterdayDebtAmount &&
        this.snfSettlementsList.FlgYesterdayDebtAmount
      ) {
        rtn =
          this.snfSettlementsList.SnfYesterdayDebtAmount +
          this.snfSettlementsList.FlgYesterdayDebtAmount;
      }
      return rtn;
    },

    snfSettlementsDashDailyAmountRate() {
      let rtn = 0;
      let dailySales = this.snfSettlementsDashDailySalesDebt();
      let previousCount = this.snfSettlementsDashDailyPreviousAmount();
      rtn = (dailySales / previousCount - 1) * 100 * -1;
      return rtn;
    },

    /////////////////////////////////////////////

    snfSettlementsDashMonthlySales() {
      let rtn = 0;
      if (
        this.snfSettlementsList &&
        this.snfSettlementsList.SnfMonthlyCount &&
        this.snfSettlementsList.FlgMonthlyCount
      ) {
        rtn =
          this.snfSettlementsList.SnfMonthlyCount +
          this.snfSettlementsList.FlgMonthlyCount;
      }
      return rtn;
    },

    snfSettlementsDashMonthlyPreviousCount() {
      let rtn = 0;
      if (
        this.snfSettlementsList &&
        this.snfSettlementsList.SnfPrvMonthCount &&
        this.snfSettlementsList.FlgPrvMonthCount
      ) {
        rtn =
          this.snfSettlementsList.SnfPrvMonthCount +
          this.snfSettlementsList.FlgPrvMonthCount;
      }
      return rtn;
    },

    snfSettlementsDashMonthlyCountRate() {
      let rtn = 0;
      let monthlySales = this.snfSettlementsDashMonthlySales();
      let previousCount = this.snfSettlementsDashMonthlyPreviousCount();
      rtn = (monthlySales / previousCount - 1) * 100 * -1;
      return rtn;
    },

    snfSettlementsDashMonthlySalesDebt() {
      let rtn = 0;
      if (
        this.snfSettlementsList &&
        this.snfSettlementsList.SnfMonthlyDebtAmount &&
        this.snfSettlementsList.FlgMonthlyDebtAmount
      ) {
        rtn =
          this.snfSettlementsList.SnfMonthlyDebtAmount +
          this.snfSettlementsList.FlgMonthlyDebtAmount;
      }
      return rtn;
    },

    snfSettlementsDashMonthlyPreviousAmount() {
      let rtn = 0;
      if (
        this.snfSettlementsList &&
        this.snfSettlementsList.SnfPrvMonthDebtAmount &&
        this.snfSettlementsList.FlgPrvMonthDebtAmount
      ) {
        rtn =
          this.snfSettlementsList.SnfPrvMonthDebtAmount +
          this.snfSettlementsList.FlgPrvMonthDebtAmount;
      }
      return rtn;
    },

    snfSettlementsDashMonthlyAmountRate() {
      let rtn = 0;
      let monthlySales = this.snfSettlementsDashMonthlySalesDebt();
      let previousCount = this.snfSettlementsDashMonthlyPreviousAmount();
      rtn = (monthlySales / previousCount - 1) * 100 * -1;
      return rtn;
    },

    ////////////////////////////////////////////

    snfSettlementsDashYearlySales() {
      let rtn = 0;
      if (
        this.snfSettlementsList &&
        this.snfSettlementsList.SnfYearlyTotalCount &&
        this.snfSettlementsList.FlgYearlyTotalCoun
      ) {
        rtn =
          this.snfSettlementsList.SnfYearlyTotalCount +
          this.snfSettlementsList.FlgYearlyTotalCoun;
      }
      return rtn;
    },
    snfSettlementsDashYearlySalesDebt() {
      let rtn = 0;
      if (
        this.snfSettlementsList &&
        this.snfSettlementsList.SnfYearlyDebtAmount &&
        this.snfSettlementsList.FlgYearlyDebtAmount
      ) {
        rtn =
          this.snfSettlementsList.SnfYearlyDebtAmount +
          this.snfSettlementsList.FlgYearlyDebtAmount;
      }
      return rtn;
    },

    /////////////////////
    changeOffice() {
      this.salesMonthlyDashboardRole = 0;
    },
    changeTeams() {
      this.salesMonthlyDashboardOffice = 0;
    },

    async refreshWelcomeCallDashboard() {
      if (this.checkAuth(55415)) {
        this.isLoading = true;
        await this.$store.dispatch(types.GET_WELCOME_CALL);
        this.isLoading = false;
      }
    },

    async refreshOperationHours() {
      if (this.checkAuth(55413)) {
        await this.$store.dispatch(types.GET_OPERATION_HOUR_DASHBOARD);
      }
    },
    async refreshIndividualSales() {
      this.individualSalesLoading = true;
      await this.$store.dispatch(types.GET_INDIVIDUAL_SALES_DASHBOARD, {
        Option: "today",
      });
      this.individualSalesLoading = false;
    },

    async refreshLeadershipDashboard() {
      this.affiliateLeadershipDashboardLoading = true;
      await this.$store.dispatch(types.GET_AFFILIATE_LEADERSHIP_DASHBOARD);
      this.affiliateLeadershipDashboardLoading = false;
    },

    async refreshcsrDashboardReport() {
      this.dbCsrDashboardLoading = true;
      await this.$store.dispatch(types.GET_CSR_DASHBOARD_REPORT, {
        Option: "today",
      });
      this.dbCsrDashboardLoading = false;
    },
    async refreshNegotiatorStatistics() {
      this.negotiatorStatisticsLoading = true;
      await this.$store.dispatch(types.GET_NEGOTIATOR_STATISTICS_DASHBOARD, {
        Option: "today",
      });
      this.negotiatorStatisticsLoading = false;
    },

    async refreshSnfSettlementFeesDashboard() {
      this.snfSettlementFeeLoading = true;
      await this.$store.dispatch(types.GET_SNF_SETTLEMENT_FEE_DASHBOARD, {
        Title: "",
        Option: "",
        StartDate: "",
        EndDate: "",
      });
      this.snfSettlementFeeLoading = false;
    },

    setDashboardSize() {
      this.bgHeight = window.innerHeight - 70;

      let widgets = document.getElementsByClassName("dashboardWidgets");

      if (typeof widgets != "undefined" && widgets && widgets.length > 0) {
        let totalWidgetHeight = 0;
        for (let i = 0; i < widgets.length; i++) {
          totalWidgetHeight += widgets[i].clientHeight + 20;
        }

        if (totalWidgetHeight + 250 > this.bgHeight) {
          this.bgHeight = totalWidgetHeight + 350;
        }
      }

      this.bgWidth =
        this.isMobile || !this.leftMenuOpen || window.innerWidth < 768
          ? window.innerWidth
          : window.innerWidth - 240;

      this.bgLeft =
        this.isMobile || !this.leftMenuOpen || window.innerWidth < 768
          ? 0
          : 240;
    },
    getTime() {
      let date = new Date();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      this.timeInfo = hours + ":" + minutes + " " + ampm;
      this.timePassed = new Date();
    },
    getGreetings() {
      let date = new Date();
      let hours = date.getHours();
      let greetingsText;
      if (hours < 12) greetingsText = "Good Morning, ";
      else if (hours < 17) greetingsText = "Good Afternoon, ";
      else greetingsText = "Good Evening, ";

      if (this.userFullName != "") {
        this.greetingInfo = greetingsText + this.userFullName;
      }
    },
    async getDbSalesDashboardData() {
      let err, result;

      this.dbSalesDashboardLoading = true;
      try {
        [err, result] = await this.$store.dispatch(
          types.GET_DB_DASHBOARD_SALES,
          {
            UserId: this.userId,
          }
        );

        if (result && result.Data) {
          let data = deepCopy(result.Data);

          this.dbDashBoardData.TotalDealsValue = data.TotalDeals;
          this.dbDashBoardData.TotalDealsGoals = data.TotalDealsGoal;
          this.dbDashBoardData.TotalDealsProjectionValue =
            data.ProjectionOfSubmittals;

          this.dbDashBoardData.TotalDebtValue = data.TotalDebts;
          this.dbDashBoardData.TotalDebtGoals = data.TotalDebtsGoal;
          this.dbDashBoardData.TotalDebtProjectionValue =
            data.ProjectionOfSubmittalsByDebt;

          this.dbDashBoardData.TalkTimeDayValue = data.AgentTodayTalkTime;
          this.dbDashBoardData.TalkTimeDayGoal = data.AgentDailyTalkTimeGoal;
          this.dbDashBoardData.TalkTimeDayComparisonValue =
            parseInt(this.dbDashBoardData.TalkTimeDayValue) -
            parseInt(this.dbDashBoardData.TalkTimeDayGoal);

          this.dbDashBoardData.TalkTimeWeekValue = data.AgentWeekTalkTime;
          this.dbDashBoardData.TalkTimeWeekGoal = data.AgentWeekTalkTimeGoal;
          this.dbDashBoardData.TalkTimeWeekComparisonValue =
            parseInt(this.dbDashBoardData.TalkTimeWeekValue) -
            parseInt(this.dbDashBoardData.TalkTimeWeekGoal);

          this.dbDashBoardData.NeedToMeetGoalValue = parseFloat(
            data.NeededToMeetGoal
          ).toFixed(2);
          this.dbDashBoardData.AgentDealsTodayValue = data.AgentDealsToday;
          this.dbDashBoardData.AgentSubmittedDebtTodayValue =
            data.AgentDebtsSubmittedToday;

          this.dbDashBoardData.AverageSubmittedDebtValue =
            data.AvgSubmittedDebt;
          this.dbDashBoardData.AverageSubmittedDebtPreviousMonth =
            data.PreviousMonthAvgSubmittedDebt;
          let avgSubmitLastMonth = this.calculatePercentage(
            parseInt(
              this.dbDashBoardData.AverageSubmittedDebtValue -
                this.dbDashBoardData.AverageSubmittedDebtPreviousMonth
            ),
            this.dbDashBoardData.AverageSubmittedDebtPreviousMonth
          );
          this.dbDashBoardData.AverageSubmittedDebtComparisonValue =
            avgSubmitLastMonth;
          this.dbDashBoardData.AverageSubmittedDebtPercentage =
            avgSubmitLastMonth == 0
              ? "No change"
              : avgSubmitLastMonth > 0
              ? avgSubmitLastMonth.toFixed(2) + "% increase"
              : avgSubmitLastMonth.toFixed(2) + "% decrease";

          this.dbDashBoardData.LeadsWorkedValue = data.LeadsWorked;
          this.dbDashBoardData.LeadsWorkedPreviousMonth =
            data.PreviousMonthLeadsWorked;
          let leadsWorkedLastMonth = this.calculatePercentage(
            parseInt(
              this.dbDashBoardData.LeadsWorkedValue -
                this.dbDashBoardData.LeadsWorkedPreviousMonth
            ),

            this.dbDashBoardData.LeadsWorkedPreviousMonth
          );
          this.dbDashBoardData.LeadsWorkedComparisonValue =
            leadsWorkedLastMonth;

          this.dbDashBoardData.LeadsWorkedPercentage =
            leadsWorkedLastMonth == 0
              ? "No change"
              : leadsWorkedLastMonth > 0
              ? leadsWorkedLastMonth.toFixed(2) + "% increase"
              : leadsWorkedLastMonth.toFixed(2) + "% decrease";

          this.dbDashBoardData.ContactsEnrolledValue = data.ContactsEnrolled;
          this.dbDashBoardData.ContactsEnrolledPreviousMonth =
            data.PreviousMonthContactsEnrolled;
          let contactsEnrolledLastMonth = this.calculatePercentage(
            parseInt(
              this.dbDashBoardData.ContactsEnrolledValue -
                this.dbDashBoardData.ContactsEnrolledPreviousMonth
            ),
            this.dbDashBoardData.ContactsEnrolledPreviousMonth
          );
          this.dbDashBoardData.ContactsEnrolledComparisonValue =
            contactsEnrolledLastMonth;
          this.dbDashBoardData.ContactsEnrolledPercentage =
            contactsEnrolledLastMonth == 0
              ? "No change"
              : contactsEnrolledLastMonth > 0
              ? contactsEnrolledLastMonth.toFixed(2) + "% increase"
              : contactsEnrolledLastMonth.toFixed(2) + "% decrease";
        }
      } catch {
        this.dbSalesDashboardLoading = false;
      }

      this.dbSalesDashboardLoading = false;
    },

    async getSalesMonthlyOverviewData() {
      let err, result;
      let payload = this.getPayload();
      this.salesMonthlyOverviewLoading = true;
      try {
        // if (
        //   this.salesMonthlyDashboardOffice == 0 &&
        //   this.salesMonthlyDashboardRole == 0
        // ) {
        [err, result] = await this.$store.dispatch(
          types.GET_SALES_MONTHLY_DASHBOARD,
          {
            OfficeId: this.salesMonthlyDashboardOffice,
            RoleId: this.salesMonthlyDashboardRole,
            StartDate: payload.StartDate,
            EndDate: payload.EndDate,
          }
        );
        // }

        // if (this.salesMonthlyDashboardOffice > 0) {
        //   [err, result] = await this.$store.dispatch(
        //     types.GET_SALES_MONTHLY_DASHBOARD,
        //     {
        //       OfficeId: this.salesMonthlyDashboardOffice,
        //     }
        //   );
        // }

        // if (this.salesMonthlyDashboardRole > 0) {
        //   [err, result] = await this.$store.dispatch(
        //     types.GET_SALES_MONTHLY_DASHBOARD,
        //     {
        //       RoleId: this.salesMonthlyDashboardRole,
        //     }
        //   );
        // }

        // if (this.salesMonthlyDashboardMonth != null) {
        //   [err, result] = await this.$store.dispatch(
        //     types.GET_SALES_MONTHLY_DASHBOARD,
        //     payload
        //   );
        // }

        if (result && result.Data) {
          this.isErrorMessage = false;
          let data = deepCopy(result.Data);

          let currentDate = new Date();
          currentDate.setMonth(currentDate.getMonth() - 1);
          let aMonthAgo = currentDate.toLocaleString("en-US", {
            month: "short",
          });
          let currentDateYear = new Date().getFullYear();

          this.salesMonthlyOverviewData.TotalDealsValue = data.TotalSubmitted;
          this.salesMonthlyOverviewData.TotalDealsGoals = data.MonthlyGoal;

          this.salesMonthlyOverviewData.ProjectionofSubmittalsValue =
            data.ProjectionOfSubmittals;
          this.salesMonthlyOverviewData.ProjectionofSubmittalsGoals =
            data.PaceToGoal;

          this.salesMonthlyOverviewData.NeedToMeetGoalValue =
            data.NeededToMeetGoal;
          this.salesMonthlyOverviewData.AgentDealsTodayValue =
            data.DebtBlueDealsToday;
          this.salesMonthlyOverviewData.AgentSubmittedDebtTodayValue =
            data.DebtBlueDebtsToday;

          this.salesMonthlyOverviewData.LeadsCreatedValue = data.LeadsCreated;
          this.salesMonthlyOverviewData.LeadsCreatedSubValue =
            data.PrvMonthLeadsCreated;
          this.salesMonthlyOverviewData.LeadsCreatedSubHeader =
            aMonthAgo + " " + currentDateYear + ":";

          let leadCreatedLastMonth = this.calculatePercentage(
            parseInt(
              this.salesMonthlyOverviewData.LeadsCreatedValue -
                this.salesMonthlyOverviewData.LeadsCreatedSubValue
            ),

            this.salesMonthlyOverviewData.LeadsCreatedSubValue
          );
          this.salesMonthlyOverviewData.LeadsCreatedComparisonValue =
            leadCreatedLastMonth;

          this.salesMonthlyOverviewData.LeadsCreatedGoal =
            leadCreatedLastMonth == 0
              ? "No change"
              : "% of Difference From: " +
                leadCreatedLastMonth.toFixed(2) +
                "%";

          this.salesMonthlyOverviewData.AvgDebtPerSubmitValue =
            data.AvgDebtPerSubmit;
          this.salesMonthlyOverviewData.AvgDebtPerSubmitSubValue =
            data.PrvMonthAvgDebtPerSubmit;
          this.salesMonthlyOverviewData.AvgDebtPerSubmitSubHeader =
            aMonthAgo + " " + currentDateYear + ":";

          let avgDebtPerSubmitLastMonth = this.calculatePercentage(
            parseInt(
              this.salesMonthlyOverviewData.AvgDebtPerSubmitValue -
                this.salesMonthlyOverviewData.AvgDebtPerSubmitSubValue
            ),

            this.salesMonthlyOverviewData.AvgDebtPerSubmitSubValue
          );
          this.salesMonthlyOverviewData.AvgDebtPerSubmitComparisonValue =
            avgDebtPerSubmitLastMonth;

          this.salesMonthlyOverviewData.AvgDebtPerSubmitGoal =
            avgDebtPerSubmitLastMonth == 0
              ? "No change"
              : "% of Difference From: " +
                avgDebtPerSubmitLastMonth.toFixed(2) +
                "%";

          this.salesMonthlyOverviewData.NewSubmitValue = data.NewSubmits;
          this.salesMonthlyOverviewData.NewSubmitSubValue =
            data.PrvMonthNewSubmits;
          this.salesMonthlyOverviewData.NewSubmitSubHeader =
            aMonthAgo + " " + currentDateYear + ":";

          let newSubmitLastMonth = this.calculatePercentage(
            parseInt(
              this.salesMonthlyOverviewData.NewSubmitValue -
                this.salesMonthlyOverviewData.NewSubmitSubValue
            ),

            this.salesMonthlyOverviewData.NewSubmitSubValue
          );
          this.salesMonthlyOverviewData.NewSubmitComparisonValue =
            newSubmitLastMonth;

          this.salesMonthlyOverviewData.NewSubmitGoal =
            newSubmitLastMonth == 0
              ? "No change"
              : "% of Difference From: " + newSubmitLastMonth.toFixed(2) + "%";

          this.salesMonthlyOverviewData.ContactsEnrolledValue =
            data.ContactsEnrolled;
          this.salesMonthlyOverviewData.ContactsEnrolledSubValue =
            data.PrvMonthContactsEnrolled;
          this.salesMonthlyOverviewData.ContactsEnrolledSubHeader =
            aMonthAgo + " " + currentDateYear + ":";

          let contactsEnrolledLastMonth = this.calculatePercentage(
            parseInt(
              this.salesMonthlyOverviewData.ContactsEnrolledValue -
                this.salesMonthlyOverviewData.ContactsEnrolledSubValue
            ),

            this.salesMonthlyOverviewData.ContactsEnrolledSubValue
          );
          this.salesMonthlyOverviewData.ContactsEnrolledComparisonValue =
            contactsEnrolledLastMonth;

          this.salesMonthlyOverviewData.ContactsEnrolledGoal =
            contactsEnrolledLastMonth == 0
              ? "No change"
              : "% of Difference From: " +
                contactsEnrolledLastMonth.toFixed(2) +
                "%";

          this.salesMonthlyOverviewData.AffiliateDealsValue =
            data.AffiliateDeals;
          this.salesMonthlyOverviewData.AffiliateDealsSubValue =
            data.PrvAffiliateDeals;
          this.salesMonthlyOverviewData.AffiliateDealsSubHeader =
            aMonthAgo + " " + currentDateYear + ":";

          let affiliateDealsLastMonth = this.calculatePercentage(
            parseInt(
              this.salesMonthlyOverviewData.AffiliateDealsValue -
                this.salesMonthlyOverviewData.AffiliateDealsSubValue
            ),

            this.salesMonthlyOverviewData.AffiliateDealsSubValue
          );
          this.salesMonthlyOverviewData.AffiliateDealsComparisonValue =
            affiliateDealsLastMonth;

          this.salesMonthlyOverviewData.AffiliateDealsGoal =
            affiliateDealsLastMonth == 0
              ? "No change"
              : "% of Difference From: " +
                affiliateDealsLastMonth.toFixed(2) +
                "%";

          this.salesMonthlyOverviewData.TotalDebtSubmittedValue =
            data.TotalDebtSubmitted;
          this.salesMonthlyOverviewData.TotalDebtSubmittedSubValue =
            data.PrvMonthTotalDebtSubmitted;
          this.salesMonthlyOverviewData.TotalDebtSubmittedSubHeader =
            aMonthAgo + " " + currentDateYear + ":";

          let totalDebtSubmittedLastMonth = this.calculatePercentage(
            parseInt(
              this.salesMonthlyOverviewData.TotalDebtSubmittedValue -
                this.salesMonthlyOverviewData.TotalDebtSubmittedSubValue
            ),

            this.salesMonthlyOverviewData.TotalDebtSubmittedSubValue
          );
          this.salesMonthlyOverviewData.TotalDebtSubmittedComparisonValue =
            totalDebtSubmittedLastMonth;

          this.salesMonthlyOverviewData.TotalDebtSubmittedGoal =
            totalDebtSubmittedLastMonth == 0
              ? "No change"
              : "% of Difference From: " +
                totalDebtSubmittedLastMonth.toFixed(2) +
                "%";

          let debtGoalLastMonth = this.calculatePercentage(
            parseInt(data.TotalDebtSubmitted),
            data.MonthlyDebtGoal
          );
          this.salesMonthlyOverviewData.DebtGoalValue = debtGoalLastMonth;

          this.salesMonthlyOverviewData.DebtGoalGoal =
            data.MonthlyDebtGoal == 0
              ? "No change"
              : "of " + this.formatMoney(data.MonthlyDebtGoal);
        } else {
          this.isErrorMessage = true;
          this.errorMessage = result.Message;
        }
      } catch {
        this.salesMonthlyOverviewLoading = false;
      }

      this.salesMonthlyOverviewLoading = false;
    },
  },
};
</script>

<style lang="css">
.bgWrapper {
  position: fixed;
  top: 70px;
}

.gradientBg {
  /*background-image: radial-gradient(circle at 50% 52%, #ffffff, #d9f5ff 100%);*/
  /*background-image: url("https://csmdashboard.azurewebsites.net/bg/29.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
}

.dateWrapper {
  margin-left: 20px;
  width: 100%;
  font-size: 3em;
  color: white;
}

.timeAndGreetingWrapper {
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0;
}

.timeText {
  display: block;
  margin: 0 20px;
  font-size: 5em;
  line-height: 0.8em;
  color: white;
}

.greetingText {
  display: block;
  margin: 0px 28px;
  font-size: 2.5em;
  color: white;
}

.messageWrapper {
  width: 101%;
  position: absolute;
  bottom: -72px;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}

.messageText {
  display: block;
  padding: 5px 20px;
  font-size: 1.5em;
  color: #fdfdfd;
  font-style: italic;
}

.meessageSourceText {
  font-size: 0.6em;
  font-style: italic;
}

.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}

.refresh {
  height: 27px;
  float: right;
  font-size: 16px;
  font-weight: 600;
  color: #3bafda;
  cursor: pointer;
}

.slowAnimation {
  --animate-duration: 8s !important;
}
.dashboardWidgets {
  margin-bottom: 20px !important;
}
</style>
