<template>
  <div
    v-show="isCashFlowPanelVisible"
    class="col-12"
    style="width: 100%; height: 100%"
  >
    <div class="cashflowMoveablePendingChanges">
      <div class="col-md-12 d-flex text-left">
        <label class="generalLabel text-left w-100"
          >Cash Flow
          <span style="color: red">
            (You can drag and drop this panel)</span
          ></label
        >

        <div class="closeButton2" v-on:click.prevent.stop="closeCashFlowPanel">
          <i class="fas fa-window-close"></i>
        </div>
      </div>
      <div class="col-md-12 noPadding">
        <div class="d-flex ml-2">
          <div style="display: flex">
            <input
              autocomplete="off"
              type="checkbox"
              v-model="calculateWithOngoingSettlements"
              @change="ongoingSettlementChange"
              class="checkbox-input generalCheckBox mr-1"
              style="
                border-radius: 4px !important;
                border: solid 1px #d1d1d1 !important;
              "
            />
          </div>
          <div>
            <label class="generalLabel pr-2"> Include Ongoing Settlements</label>
          </div>
        </div>
        <perfect-scrollbar style="max-height: 664px; position: relative">
          <div
            class="datagrid-table"
            style="background-color: #fafafa"
            v-if="!tableLoading"
          >
            <o-table :data="cashFlowList">
              <o-table-column
                field="TransactionType"
                label="T.Type"
                v-slot="props"
              >
                <span
                  v-bind:class="{
                    textGreen:
                      props.row.IsCurrentTask && props.row.Balance >= 0,
                    textRed2: props.row.IsCurrentTask && props.row.Balance < 0,
                  }"
                >
                  {{ props.row.TransactionType }}
                </span>
              </o-table-column>
              <o-table-column field="Date" label="Date" v-slot="props">
                <span
                  v-bind:class="{
                    textGreen:
                      props.row.IsCurrentTask && props.row.Balance >= 0,
                    textRed2: props.row.IsCurrentTask && props.row.Balance < 0,
                  }"
                >
                  {{ formatShortDate(props.row.Date) }}
                </span>
              </o-table-column>

              <o-table-column field="Balance" label="Net Cash">
                <template v-slot="props">
                  <div
                    v-bind:class="{
                      textGreen:
                        props.row.IsCurrentTask && props.row.Balance >= 0,
                      textRed: props.row.Balance < 0,
                      textRed2:
                        props.row.IsCurrentTask && props.row.Balance < 0,
                    }"
                  >
                    {{ formatMoney(props.row.Balance) }}
                  </div>
                </template>
              </o-table-column>
              <o-table-column field="Memo" label="Memo" v-slot="props">
                <span
                  v-bind:class="{
                    textGreen:
                      props.row.IsCurrentTask && props.row.Balance >= 0,
                    textRed2: props.row.IsCurrentTask && props.row.Balance < 0,
                  }"
                >
                  {{ props.row.Memo }}
                </span>
              </o-table-column>
            </o-table>
            <div
              v-if="tableLoading"
              class="loadingAbsoluteBox"
              style="background: #fff"
            >
              <div class="loadingSpinner">
                <i class="spinner-border text-primary m-2"></i>
              </div>
            </div>
            <section
              v-if="!tableLoading && cashFlowList.length == 0"
              class="section"
              style="text-align: center"
            >
              <div class="content has-text-grey has-text-centered mb-4">
                <p class="noMargin">
                  <i
                    style="font-size: 24px; color: #939393"
                    class="ri-question-line"
                  ></i>
                </p>
                <p class="noMargin">Nothing here.</p>
              </div>
            </section>
          </div>
        </perfect-scrollbar>
      </div>
    </div>

    <Moveable v-bind="moveable" @drag="handleDrag" />
  </div>
  <div class="col-md-12">
    <div
      v-if="!gridReady"
      class="policyLoader noPadding"
      style="left: -12px; width: 105%"
    >
      <div class="lds-grid">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div style="margin-top: -48px; margin-left: 160px">
      <span
        style="padding: 8px; cursor: pointer"
        class="badge btn-success"
        @click="refreshData"
        >Refresh Now</span
      >
    </div>
    <div class="col-md-12 row noPadding">
      <div class="col-md-12 policy-form noPadding noMargin">
        <ul class="nav">
          <li v-for="tab in tabs" :key="tab.id">
            <a
              v-if="gridReady && checkAuth(tab.authId)"
              data-toggle="tab"
              :aria-expanded="tab.active"
              v-on:click.prevent="() => handleTabChange(tab.name)"
              class="policyTabItem"
              style="
                margin: 5px 10px 5px 0;
                padding: 12px 2px;
                font-size: 12.5px;
              "
              :class="{ activeItemShadow: tab.active }"
            >
              <span class="d-none d-sm-inline-block tab-item-label">
                {{ tab.label }}

                <span
                  style="padding: 4px 7px"
                  class="badge noti-icon-badge badge-danger ml-1"
                  >{{ getTabRowCount(tab.name) }}</span
                >
              </span>
            </a>
          </li>
        </ul>
        <div class="col-md-12 noPadding">
          <div v-show="tabs[0].active">
            <ExtraPayment
              @refreshData="refreshData"
                @showCashFlow="showCashFlow"
              :extraPaymentList="extraPaymentList"
              v-if="gridReady"
            ></ExtraPayment>
          </div>
          <div v-show="tabs[1].active">
            <DraftChanges
              @refreshData="refreshData"
              @showCashFlow="showCashFlow"
              :draftChangesList="draftChangesList"
              v-if="gridReady"
            ></DraftChanges>
          </div>
          <div v-show="tabs[2].active">
            <FeeChanges
              @refreshData="refreshData"
                 @showCashFlow="showCashFlow"
              :feeChangesList="feeChangesList"
              v-if="gridReady"
            ></FeeChanges>
          </div>
          <div v-show="tabs[3].active">
            <AdditionalComment
              @refreshData="refreshData"
              :additionalNotesList="additionalNotesList"
                @showCashFlow="showCashFlow"
              v-if="gridReady"
            ></AdditionalComment>
          </div>
          <div v-show="tabs[4].active">
            <ClientComplete
              @refreshData="refreshData"
              :statusChangesList="statusChangesList"
              v-if="gridReady"
            ></ClientComplete>
          </div>

          <div v-show="tabs[5].active">
            <DocumentReview
              @refreshData="refreshData"
              :documentReviewsList="documentReviewsList"
              v-if="gridReady"
            ></DocumentReview>
          </div>
          <div v-show="tabs[6].active">
            <SkipPayment
              @refreshData="refreshData"
               @showCashFlow="showCashFlow"
              :skipPaymentList="skipPaymentList"
              v-if="gridReady"
            ></SkipPayment>
          </div>
          <div v-show="tabs[7].active">
            <ClientInfo
              @refreshData="refreshData"
              :infoChangesList="infoChangesList"
              v-if="gridReady"
            ></ClientInfo>
          </div>
          <div v-show="tabs[8].active">
            <PaymentChanges
              @refreshData="refreshData"
                @showCashFlow="showCashFlow"
              :paymentChangesList="paymentChangesList"
              v-if="gridReady"
            ></PaymentChanges>
          </div>
          <div v-show="tabs[9].active">
            <ChargesChanges
              @refreshData="refreshData"
                   @showCashFlow="showCashFlow"
              :chargesChangesList="chargesChangesList"
              v-if="gridReady"
            ></ChargesChanges>
          </div>
          <!-- <div  v-show="tabs[8].active">
            <Amendment
              @refreshData="refreshData"
              :amendmentsList="amendmentsList"
              v-if="gridReady"
            ></Amendment>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdditionalComment from "@/views/agent/PendingChanges/AdditionalComment";
import Amendment from "@/views/agent/PendingChanges/Amendment";
import ClientComplete from "@/views/agent/PendingChanges/ClientComplete";
import ClientInfo from "@/views/agent/PendingChanges/ClientInfo";
import DocumentReview from "@/views/agent/PendingChanges/DocumentReview";
import DraftChanges from "@/views/agent/PendingChanges/DraftChanges";
import ExtraPayment from "@/views/agent/PendingChanges/ExtraPayment";
import SkipPayment from "@/views/agent/PendingChanges/SkipPayment";
import FeeChanges from "@/views/agent/PendingChanges/FeeChanges";
import PaymentChanges from "@/views/agent/PendingChanges/PaymentChanges";
import ChargesChanges from "@/views/agent/PendingChanges/ChargesChanges";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import formatMixin from "@/mixins/formatMixin";
import _orderby from "lodash";
import types from "./types";
import { mapState } from "vuex";
import Moveable from "vue3-moveable";
import deepCopy from "@/helpers/deepCopy";
export default {
  name: "PendingChanges",
  components: {
    AdditionalComment,
    // Amendment,
    ClientComplete,
    ClientInfo,
    DocumentReview,
    DraftChanges,
    ExtraPayment,
    SkipPayment,
    FeeChanges,
    PaymentChanges,
    ChargesChanges,
    Moveable,
  },
  mixins: [utilitiesMixin, formatMixin],

  data() {
    return {
      moveable: {
        target: [".cashflowMoveablePendingChanges"],
        draggable: true,
        scalable: false,
        rotatable: false,
        pinchable: false,
        origin: false,
      },

      gridReady: false,
      tabs: [
        {
          id: 1,
          name: "tab1",
          label: "Extra Payment",
          active: true,
          authId: 12401,
        },
        {
          id: 2,
          name: "tab2",
          label: "Draft Changes",
          active: false,
          authId: 12402,
        },
        {
          id: 3,
          name: "tab3",
          label: "Fee Changes",
          active: false,
          authId: 12409,
        },
        {
          id: 4,
          name: "tab4",
          label: "Additional Notes",
          active: false,
          authId: 12403,
        },
        {
          id: 5,
          name: "tab5",
          label: "Status Changes",
          active: false,
          authId: 12404,
        },

        {
          id: 6,
          name: "tab6",
          label: "Doc Reviews",
          active: false,
          authId: 12406,
        },
        {
          id: 7,
          name: "tab7",
          label: "Skip Payment",
          active: false,
          authId: 12407,
        },
        {
          id: 8,
          name: "tab8",
          label: "Info Changes",
          active: false,
          authId: 12408,
        },
        // {
        //   id: 9,
        //   name: "tab9",
        //   label: "Amendments",
        //   active: false,
        // },
        {
          id: 10,
          name: "tab10",
          label: "Payment Changes",
          active: false,
          authId: 12410,
        },
        {
          id: 11,
          name: "tab11",
          label: "Charges Changes",
          active: false,
          authId: 12412,
        },
      ],
      cashFlowList: [],
      cashFlowModifiedListBackup: [],
      ongoingSettlements: [],
      isCashFlowPanelVisible: false,
      calculateWithOngoingSettlements: false,
      tableLoading: false,
    };
  },
  async mounted() {
    await this.refreshData();
  },
  computed: mapState({
    allPendingChanges: (state) => state.pendingChanges.allPendingChanges,
    extraPaymentList() {
      let rtn = this.allPendingChanges.PendingChanges.filter(
        (item) => item.Request.Type.Id == 1
      );

      return rtn;
    },
    draftChangesList() {
      return this.allPendingChanges.PendingChanges.filter(
        (item) => item.Request.Type.Id == 2
      );
    },
    additionalNotesList() {
      return this.allPendingChanges.PendingChanges.filter(
        (item) => item.Request.Type.Id == 3
      );
    },
    statusChangesList() {
      return this.allPendingChanges.PendingChanges.filter(
        (item) => item.Request.Type.Id == 4
      );
    },
    amendmentsList() {
      return this.allPendingChanges.PendingChanges.filter(
        (item) => item.Request.Type.Id == 5
      );
    },
    documentReviewsList() {
      return this.allPendingChanges.PendingChanges.filter(
        (item) => item.Request.Type.Id == 6
      );
    },
    skipPaymentList() {
      return this.allPendingChanges.PendingChanges.filter(
        (item) => item.Request.Type.Id == 7
      );
    },
    infoChangesList() {
      return this.allPendingChanges.PendingChanges.filter(
        (item) => item.Request.Type.Id == 8
      );
    },
    feeChangesList() {
      return this.allPendingChanges.PendingChanges.filter(
        (item) => item.Request.Type.Id == 9
      );
    },
    paymentChangesList() {
      return this.allPendingChanges.PendingChanges.filter(
        (item) => item.Request.Type.Id == 10
      );
    },
    chargesChangesList() {
      return this.allPendingChanges.PendingChanges.filter(
        (item) => item.Request.Type.Id == 11
      );
    },
  }),
  methods: {
    openCashFlowPanel() {
      this.isCashFlowPanelVisible = true;
      this.calculateWithOngoingSettlements = false;
    },
    closeCashFlowPanel() {
      this.isCashFlowPanelVisible = false;
    },
    showCashFlow(data) {
      if (data) {
        this.tableLoading = true;
        this.cashFlowList = deepCopy(data.CashFlow);
        this.cashFlowModifiedListBackup = deepCopy(data.CashFlow);
        this.ongoingSettlements = deepCopy(data.OngoingSettlements);

        this.tableLoading = false;
        this.openCashFlowPanel();
      }
    },
    ongoingSettlementChange() {
      let list = deepCopy(this.cashFlowModifiedListBackup);
      this.calculateCashFlow(list);
    },
    async calculateCashFlow(list) {
      if (this.checkAuth(956782)) {
        if (this.calculateWithOngoingSettlements == true) {
          for (let i of this.ongoingSettlements) {
            list.push({
              Id: "X",
              ReferenceId: "X",
              TransactionType: "P",
              Date: i.PaymentDate,
              Amount: i.PaymentAmount + i.FeeAmount,
              Balance: 0,
              Memo:
                i.CreditorName + " - " + this.formatShortDate(i.PaymentDate),
              IsPay: true,
            });
          }
        }

        list.sort((a, b) => new Date(a.Date) - new Date(b.Date));

        for (let i = 0; i < list.length; i++) {
          if (list[i].IsPay == true) {
            if (i > 0) {
              list[i].Balance = parseFloat(
                parseFloat(list[i - 1].Balance - list[i].Amount).toFixed(2)
              );
              list[i].Id = parseInt(list[i - 1].Id + 1);
            }

            for (let j = i + 1; j < list.length; j++) {
              list[j].Balance = parseFloat(
                parseFloat(list[j].Balance - list[i].Amount).toFixed(2)
              );
              list[j].Id += 1;
            }
          } else if (list[i].IsDeletedDraft == true) {
            if (i < list.length) {
              for (let j = i; j < list.length; j++) {
                list[j].Balance = parseFloat(
                  parseFloat(list[j].Balance - list[i].Amount).toFixed(2)
                );
              }
            }
          } else if (list[i].IsDeletedPay == true) {
            if (i < list.length) {
              for (let j = i; j < list.length; j++) {
                list[j].Balance = parseFloat(
                  parseFloat(list[j].Balance + list[i].Amount).toFixed(2)
                );
              }
            }
          } else if (list[i].IsDraft == true) {
            if (i > 0) {
              list[i].Balance = parseFloat(
                parseFloat(list[i - 1].Balance + list[i].Amount).toFixed(2)
              );

              list[i].Id = parseInt(list[i - 1].Id + 1);
            }

            for (let j = i + 1; j < list.length; j++) {
              list[j].Balance = parseFloat(
                parseFloat(list[j].Balance + list[i].Amount).toFixed(2)
              );
              list[j].Id += 1;
            }
          } else if (list[i].IsModifiedDraft == true) {
            let indx = this.cashFlowListOld.findIndex(
              (x) => x.ReferenceId == list[i].ReferenceId
            );
            if (indx > -1) {
              if (i > 0) {
                list[i].Balance = parseFloat(
                  parseFloat(list[i - 1].Balance + list[i].Amount).toFixed(2)
                );

                list[i].Id = parseInt(list[i - 1].Id + 1);
              }
              for (let j = i + 1; j < list.length; j++) {
                let addedAmount = parseFloat(
                  list[i].Amount - this.cashFlowListOld[indx].Amount
                );
                list[j].Balance = parseFloat(
                  parseFloat(list[j].Balance + addedAmount).toFixed(2)
                );
                list[j].Id += 1;
              }
            }
          } else if (list[i].IsModifiedFee == true) {
            let indx = this.cashFlowListOld.findIndex(
              (x) => x.Id == list[i].Id
            );
            if (indx > -1) {
              list[i].Balance = parseFloat(
                parseFloat(list[i - 1].Balance - parseFloat(list[i].Amount))
              );
              list[i].Id = parseInt(list[i - 1].Id + 1);

              for (let j = i + 1; j < list.length; j++) {
                list[j].Balance = parseFloat(
                  parseFloat(list[j].Balance - list[i].Amount).toFixed(2)
                );
                list[j].Id += 1;
              }
            }
          }
        }

        let deletedList = list.filter(
          (x) => x.IsDeletedDraft || x.IsDeletedPay
        );
        if (deletedList && deletedList.length > 0) {
          for (let item of deletedList) {
            let dIndex = list.indexOf(item);

            if (dIndex > -1) {
              list.splice(dIndex, 1);
            }
          }
        }

        this.tableLoading = true;
        this.cashFlowList = deepCopy(list);
        this.tableLoading = false;
      }
    },
    handleDrag({ target, transform }) {
      if (this.isTouchDevice) {
        return;
      }
      try {
        //matrix(1, 0, 0, 1, 0, 0) translate(-308px, -701px)
        if (target && transform.includes("translate")) {
          let index = transform.indexOf("translate(");
          let str = transform.slice(index, transform.length);
          str = str.replace("translate(", "");
          str = str.replace(")", "");

          let maxHeight = window.innerHeight;
          let customerPanel = document.getElementById("draftChagesSection");
          if (typeof customerPanel != "undefined" && customerPanel != null) {
            maxHeight = customerPanel.clientHeight;
          }

          if (str.length > 0) {
            let positions = str.split(",");
            if (positions.length > 0) {
              let posX = parseInt(positions[0].replace("px", ""));
              let posY = parseInt(positions[1].replace("px", ""));
              let winWidth = window.innerWidth;
              let controlWidth = target.offsetWidth;
              let controlHeight = target.offsetHeight;
              let minX = -controlWidth + 50;
              let minY = -controlHeight + 50;
              if (
                posX > minX &&
                posY > minY &&
                posX < parseInt(winWidth - controlWidth) &&
                posY < parseInt(maxHeight)
              ) {
                target.style.transform = transform;
              }
            }
          }
        }
      } catch {
        //todo:log errors
      }
    },
    getTabRowCount(tabName) {
      let rtn = 0;
      if (tabName == "tab1") {
        rtn = this.extraPaymentList.length;
      } else if (tabName == "tab2") {
        rtn = this.draftChangesList.length;
      } else if (tabName == "tab3") {
        rtn = this.feeChangesList.length;
      } else if (tabName == "tab4") {
        rtn = this.additionalNotesList.length;
      } else if (tabName == "tab5") {
        rtn = this.statusChangesList.length;
      } else if (tabName == "tab6") {
        rtn = this.documentReviewsList.length;
      } else if (tabName == "tab7") {
        rtn = this.skipPaymentList.length;
      } else if (tabName == "tab8") {
        rtn = this.infoChangesList.length;
      } else if (tabName == "tab9") {
        rtn = this.amendmentsList.length;
      } else if (tabName == "tab10") {
        rtn = this.paymentChangesList.length;
      } else if (tabName == "tab11") {
        rtn = this.chargesChangesList.length;
      }
      return rtn.toString();
    },
    async refreshData() {
      this.gridReady = false;
      await this.$store.dispatch(types.GET_ALL_PENDING_CHANGES).catch();

      this.gridReady = true;
    },
    handleTabChange(tabName) {
      let temp = [...this.tabs];
      temp = temp.map((item) => {
        item.active = item.name === tabName ? true : false;
        return item;
      });
      this.tabs = [...temp];
    },
  },
};
</script>

<style>
.activeItemShadow {
  box-shadow: 0 0 2px 0 #00bbff;
  border: solid 2px #3bb0dacc;
}
.cashflowMoveablePendingChanges {
  position: absolute;
  min-width: 480px;
  height: auto;
  margin: 0 auto;
  padding: 0px;
  border-radius: 8px;
  box-shadow: 0 0 5px 2px #b7b3b3;
  background-color: #ffffff;
  cursor: pointer;
  z-index: 2000;
}
</style>
