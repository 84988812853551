import types from "./types";
import api from "@/services/client/customerApi";
import adminApi from "@/services/admin/api";
import to from "@/helpers/to";
import downloadApi from "@/services/fileServices/downloadService";

export default {
  state: {},
  actions: {
    GET_CHARGES: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getChargesInfo(payload));
      return [err, result];
    },
    GET_NEW_CASHFLOW: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getNewGcsTabCashflow(payload));
      return [err, result];
    },

    GET_INFO: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getGcsTabInfo(payload));
      return [err, result];
    },
    GET_GLOBAL_INFO_DATA: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getGcsTabInfo(payload));
      return [err, result];
    },
    GET_CASHFLOW: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getGcsTabCashflow(payload));
      return [err, result];
    },

    GET_CASHFLOW_DATA: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getNewGcsTabCashflow(payload));
      return [err, result];
    },
    GET_GCS_NOTES: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getGcsTabNotes(payload));
      return [err, result];
    },
    GET_PAYMENTS: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getGcsTabPayments(payload));
      return [err, result];
    },
    GET_DOCUMENTS: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getGcsTabDocuments(payload));
      return [err, result];
    },
    GET_DEPOSITS: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getGcsTabDeposits(payload));
      return [err, result];
    },
    GET_FEES: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getGcsTabFees(payload));
      return [err, result];
    },
    GET_TRANSACTIONS: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getGcsTabTransactions(payload));
      return [err, result];
    },
    GET_DRAFTS: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getGcsTabDrafts(payload));
      return [err, result];
    },

    GET_STATEMENT: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(downloadApi.downloadStatement(payload));
      return [err, result];
    },

    PHONE_PAY_INFO: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.phonePayInfo(payload));
      return [err, result];
    },
  },
  mutations: {},
};
