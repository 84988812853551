<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'ActiveEnrollmentsReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @refreshReport="refreshReport"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      id="active-enrollment-report"
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredActiveEnrollmentsReport"
        :paginated="isPaginated && filteredActiveEnrollmentsReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
        :scrollable="true"
      >
        <o-table-column
          field="CustomerId"
          label="Customer Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                style="width: 90px"
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>
          <template v-slot="props">
            <span
              @click="
                viewCustomer(
                  props.row.CustomerId,
                  props.row.CustomerFirstName + ' ' + props.row.CustomerLastName
                )
              "
              :class="
                getTagReportHyperLinkClass(
                  props.row.CustomerId &&
                    props.row.CustomerFirstName &&
                    props.row.CustomerLastName
                )
              "
            >
              <strong v-if="props.row.IsSummary">
                {{ props.row.CustomerId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ addPrefixToCustomerId(props.row.CustomerId) }}
              </span>
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerFirstName"
          label="Customer First Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerFirstNameFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>{{ props.row.CustomerFirstName }}</div>
          </template>
        </o-table-column>
        <o-table-column
          field="CustomerLastName"
          label="Customer Last Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerLastNameFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>{{ props.row.CustomerLastName }}</div>
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerFullAdress"
          label="Customer Address"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerFullAdressFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>{{ props.row.CustomerFullAdress }}</div>
          </template>
        </o-table-column>

        <o-table-column field="Line1" label="Address Line1" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="Line1Filter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>{{ props.row.Line1 }}</div>
          </template>
        </o-table-column>

        <o-table-column field="Line2" label="Address Line2" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="Line2Filter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>{{ props.row.Line2 }}</div>
          </template>
        </o-table-column>

        <o-table-column field="City" label="City" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CityFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>{{ props.row.City }}</div>
          </template>
        </o-table-column>

        <o-table-column field="State" label="State" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>{{ props.row.State }}</div>
          </template>
        </o-table-column>

        <o-table-column field="Zip" label="Zip" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ZipFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>{{ props.row.Zip }}</div>
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerStatus"
          label="Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerStatusFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>{{ props.row.CustomerStatus }}</div>
          </template>
        </o-table-column>

        <o-table-column
          field="ClearedDraftCount"
          label="Cleared Draft Count"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ClearedDraftCountFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>{{ props.row.ClearedDraftCount }}</div>
          </template>
        </o-table-column>

        <o-table-column
          field="GlobalBalance"
          label="Global Balance"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="GlobalBalanceFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary">
              {{ props.row.GlobalBalance }}
            </strong>
            <div v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.GlobalBalance) }}
            </div>
          </template>
        </o-table-column>

        <o-table-column field="Office" label="Office" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="officeFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>
              {{ props.row.Office }}
            </div>
          </template>
        </o-table-column>

        <o-table-column
          field="PolicyGroup"
          label="Policy Group"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PolicyGroupFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>
              {{ props.row.PolicyGroup }}
            </div>
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerSSN"
          label="Customer SSN"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="customerSSNFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>
              {{ formatSSN(props.row.CustomerSSN) }}
            </div>
          </template>
        </o-table-column>
        <o-table-column
          field="CoCustomerFirstName"
          label="CoCustomer First Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CoCustomerFirstNameFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>
              {{ props.row.CoCustomerFirstName }}
            </div>
          </template>
        </o-table-column>

        <o-table-column
          field="CoCustomerLastName"
          label="CoCustomer Last Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CoCustomerLastNameFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>
              {{ props.row.CoCustomerLastName }}
            </div>
          </template>
        </o-table-column>

        <o-table-column
          field="CoCustomerSSN"
          label="CoCustomer SSN"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="coCustomerSSNFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>
              {{ formatSSN(props.row.CoCustomerSSN) }}
            </div>
          </template>
        </o-table-column>

        <o-table-column
          v-if="checkAuth(3592)"
          field="CustomerDob"
          label="Customer DoB"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerDobFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>
              {{ formatShortDate(props.row.CustomerDob) }}
            </div>
          </template>
        </o-table-column>

        <o-table-column
          v-if="checkAuth(3592)"
          field="CoCustomerDob"
          label="CoCustomer DoB"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CoCustomerDobFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>
              {{ formatShortDate(props.row.CoCustomerDob) }}
            </div>
          </template>
        </o-table-column>

        <o-table-column
          v-if="checkAuth(3592)"
          field="CreditScore"
          label="Last Credit Score"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreditScoreFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>
              {{ props.row.CreditScore }}
            </div>
          </template>
        </o-table-column>

        <o-table-column
          v-if="checkAuth(3592)"
          field="TotalMonthlyIncome"
          label="Monthly Income (total)"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TotalMonthlyIncomeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary">
              {{ props.row.TotalMonthlyIncome }}
            </strong>
            <div v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.TotalMonthlyIncome) }}
            </div>
          </template>
        </o-table-column>

        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="
              viewCustomer(
                props.row.CustomerId,
                props.row.CustomerFirstName,
                props.row.CustomerLastName
              )
            "
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <div v-if="loadingSpinner" class="text-center" style="margin: 6em 0">
        <i class="spinner-border avatar-lg text-primary m-2"></i>
      </div>
      <section
        v-if="filteredActiveEnrollmentsReport.length == 0 && !loadingSpinner"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "ActiveEnrollmentsReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 0,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,

      recordCount: 0,
      reportLoading: false,
      reportData: [],

      activeEnrollmentsReportInitSummary: {
        CustomerId: "",
        CustomerFirstName: "",
        CustomerLastName: "",
        CustomerSSN: "",
        CoCustomerFirstName: "",
        CoCustomerLastName: "",
        CoCustomerSSN: "",
        Office: "",
        PolicyGroup: "",
        CustomerStatus: "",
        CustomerDob: "",
        CoCustomerDob: "",
        CreditScore: "",
        TotalMonthlyIncome: "",
        ClearedDraftCount: "",
        GlobalBalance: "",
        CustomerFullAdress: "",
      },
      CustomerIdFilter: "",
      CustomerFirstNameFilter: "",
      CustomerLastNameFilter: "",
      customerSSNFilter: "",
      CoCustomerFirstNameFilter: "",
      CoCustomerLastNameFilter: "",
      coCustomerSSNFilter: "",
      officeFilter: "",
      PolicyGroupFilter: "",
      CustomerStatusFilter: "",
      CustomerDobFilter: "",
      CoCustomerDobFilter: "",
      CreditScoreFilter: "",
      TotalMonthlyIncomeFilter: "",
      ClearedDraftCountFilter: "",
      GlobalBalanceFilter: "",
      CustomerFullAdressFilter: "",
      Line1Filter: "",
      Line2Filter: "",
      CityFilter: "",
      StateFilter: "",
      ZipFilter: "",
    };
  },
  computed: mapState({
    documentsActiveEnrollmentsList: (state) =>
      state.reports.activeEnrollmentsList,

    filteredActiveEnrollmentsReport() {
      let rtn = [];

      let tmpList =
        this.documentsActiveEnrollmentsList.length > 0
          ? this.documentsActiveEnrollmentsList.filter((item) => {
              return (
                this.filterString(item.Line1, this.Line1Filter) &&
                this.filterString(item.Line2, this.Line2Filter) &&
                this.filterString(item.City, this.CityFilter) &&
                this.filterString(item.State, this.StateFilter) &&
                this.filterString(item.Zip, this.ZipFilter) &&
                this.filterString(
                  item.CustomerFirstName,
                  this.CustomerFirstNameFilter
                ) &&
                this.filterString(
                  item.CustomerLastName,
                  this.CustomerLastNameFilter
                ) &&
                this.filterString(
                  item.CustomerFullAdress,
                  this.CustomerFullAdressFilter
                ) &&
                this.filterString(
                  item.ClearedDraftCount,
                  this.ClearedDraftCountFilter
                ) &&
                this.filterString(
                  item.GlobalBalance,
                  this.GlobalBalanceFilter
                ) &&
                this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                this.filterString(
                  item.CustomerStatus,
                  this.CustomerStatusFilter
                ) &&
                this.filterString(item.Office, this.officeFilter) &&
                this.filterString(item.PolicyGroup, this.PolicyGroupFilter) &&
                this.filterString(item.CustomerSSN, this.customerSSNFilter) &&
                this.filterString(
                  item.CoCustomerFirstName,
                  this.CoCustomerFirstNameFilter
                ) &&
                this.filterString(
                  item.CoCustomerLastName,
                  this.CoCustomerLastNameFilter
                ) &&
                this.filterString(
                  item.CoCustomerSSN,
                  this.coCustomerSSNFilter
                ) &&
                this.filterString(item.CreditScore, this.CreditScoreFilter) &&
                this.filterString(
                  item.TotalMonthlyIncome,
                  this.TotalMonthlyIncomeFilter
                ) &&
                new Date(item.CustomerDob)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.CustomerDobFilter) > -1 &&
                new Date(item.CoCustomerDob)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.CoCustomerDobFilter) > -1
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.activeEnrollmentsReportInitSummary.CustomerId =
        "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.activeEnrollmentsReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.activeEnrollmentsReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      userInfo != null &&
      userInfo.customerIdPrefix != null &&
      userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = userInfo.customerIdPrefix;
    }
    await this.sleep(1000);
    this.fixStickyHeaders();
  },

  methods: {
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },

    setPagination(data) {
      this.isPaginated = data;
    },

    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async refreshReport(value) {
      this.loadingSpinner = true;
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_ACTIVE_ENROLLMENTS_REPORT,
        value
      );
      if (result && result.Data) {
        let apiResult = result.Data;
        for (let item of apiResult) {
          if (item.CustomerSSN != "") {
            item.CustomerSSN = await this.AES256_GCM_decrypt(item.CustomerSSN);
          }
          if (item.CoCustomerSSN != "") {
            item.CoCustomerSSN = await this.AES256_GCM_decrypt(
              item.CoCustomerSSN
            );
          }
        }
        await this.$store.dispatch(
          types.MODIFY_ACTIVE_ENROLLMENTS_REPORT,
          apiResult
        );
      }
      this.loadingSpinner = false;
      await this.sleep(1000);
      this.fixStickyHeaders();
    },
    async viewCustomer(id, firstName, lastName) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: firstName + " " + lastName,
      });
    },
  },
};
</script>

<style scoped></style>
