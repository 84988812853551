<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'SettlementsFeeReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredSettlementsFeeReport"
        :paginated="isPaginated && filteredSettlementsFeeReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="CustomerId"
          label="Customer Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span
              @click="
                viewCustomer(props.row.CustomerId, props.row.CustomerName)
              "
              :class="
                getTagReportHyperLinkClass(
                  props.row.CustomerId && props.row.CustomerName
                )
              "
            >
              <strong v-if="props.row.IsSummary"
                >{{ props.row.CustomerId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ addPrefixToCustomerId(props.row.CustomerId) }}
              </span>
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerName"
          label="Customer Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CustomerName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.CustomerName }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerStatus"
          label="Customer Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerStatusFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CustomerStatus }}
          </template>
        </o-table-column>
        <o-table-column
          field="GlobalStatus"
          label="Global Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="GlobalStatusFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.GlobalStatus }}
          </template>
        </o-table-column>

        <o-table-column field="Office" label="Office" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OfficeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Office }}
          </template>
        </o-table-column>

        <o-table-column field="State" label="State" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StateFilter"
                placeholder=""
                style="width: 75px"
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.State }}
          </template>
        </o-table-column>
        <o-table-column
          field="DebtAmountAtEnrollment"
          label="Debt Amount At Enrollment"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DebtAmountAtEnrollmentFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.DebtAmountAtEnrollment }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.DebtAmountAtEnrollment) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CreditorBalance"
          label="Creditor Balance"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreditorBalanceFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CreditorBalance }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.CreditorBalance) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="SettlementFeePercentage"
          label="Settlement Fee Percentage"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SettlementFeePercentageFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.SettlementFeePercentage }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatPercantage(props.row.SettlementFeePercentage) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="Creditor" label="Creditor" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreditorFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">{{ props.row.Creditor }} </template>
        </o-table-column>

        <o-table-column
          field="CreditorAccountNumber"
          label="Creditor Account Number"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreditorAccountNumberFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CreditorAccountNumber }}
          </template>
        </o-table-column>

        <o-table-column
          field="SettledDate"
          label="Settled Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SettledDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatGridDate(props.row.SettledDate) }}
          </template>
        </o-table-column>

        <o-table-column
          v-if="checkAuth(55015)"
          field="NewPaymentPlanner"
          label="New Payment Planner"
          sortable
        >
          <template v-slot:searchable>
            <div class="datagrid-table creditorTable"></div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.NewPaymentPlanner }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.NewPaymentPlanner == true ? "Yes" : "No" }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="FeeTotal" label="Fee Total" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FeeTotalFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.FeeTotal }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.FeeTotal) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="FeeCollected"
          label="Fee Collected"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FeeCollectedFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.FeeCollected }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.FeeCollected) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="FeeRemain"
          label="Fee Remain"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FeeRemainFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.FeeRemain }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.FeeRemain) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="FeeTerms" label="Fee Terms" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FeeTermsFilter"
                placeholder=""
                style="width: 75px"
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.FeeTerms }}
          </template>
        </o-table-column>

        <o-table-column
          field="AddedAfterEnrollment"
          label="Added After Enrollment"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AddedAfterEnrollmentFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.AddedAfterEnrollment }}
          </template>
        </o-table-column>

        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="viewCustomer(props.row.CustomerId, props.row.CustomerName)"
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredSettlementsFeeReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "SettlementsFeeReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      recordCount: 0,
      reportLoading: false,
      reportData: [],

      settlementsFeeReportInitSummary: {
        CustomerId: "",
        CustomerName: "",
        Office: "",
        State: "",
        DebtAmountAtEnrollment: "",
        SettlementFeePercentage: "",
        Creditor: "",
        CreditorAccountNumber: "",
        SettledDate: "",
        NewPaymentPlanner: "",
        FeeTotal: "",
        FeeCollected: "",
        FeeRemain: "",
        FeeTerms: "",
        CustomerStatus: "",
        GlobalStatus: "",
        CreditorBalance: "",
        AddedAfterEnrollment: "",
      },
      CustomerIdFilter: "",
      CustomerNameFilter: "",
      OfficeFilter: "",
      StateFilter: "",
      DebtAmountAtEnrollmentFilter: "",
      SettlementFeePercentageFilter: "",
      CreditorFilter: "",
      CreditorAccountNumberFilter: "",
      SettledDateFilter: "",
      NewPaymentPlannerFilter: "",
      FeeTotalFilter: "",
      FeeCollectedFilter: "",
      FeeRemainFilter: "",
      FeeTermsFilter: "",
      CustomerStatusFilter: "",
      GlobalStatusFilter: "",
      CreditorBalanceFilter: "",
      AddedAfterEnrollmentFilter: "",
    };
  },
  computed: mapState({
    settlementsFeeList: (state) => state.reports.settlementsFeeList,

    filteredSettlementsFeeReport() {
      let rtn = [];

      let tmpList =
        this.settlementsFeeList.length > 0
          ? this.settlementsFeeList.filter((item) => {
              return (
                this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                this.filterString(
                  item.AddedAfterEnrollment,
                  this.AddedAfterEnrollmentFilter
                ) &&
                this.filterString(
                  item.CreditorBalance,
                  this.CreditorBalanceFilter
                ) &&
                this.filterString(
                  item.DebtAmountAtEnrollment,
                  this.DebtAmountAtEnrollmentFilter
                ) &&
                this.filterString(item.CustomerName, this.CustomerNameFilter) &&
                this.filterString(
                  item.CustomerStatus,
                  this.CustomerStatusFilter
                ) &&
                this.filterString(item.GlobalStatus, this.GlobalStatusFilter) &&
                this.filterString(item.Office, this.OfficeFilter) &&
                this.filterString(item.State, this.StateFilter) &&
                this.filterString(
                  item.SettlementFeePercentage,
                  this.SettlementFeePercentageFilter
                ) &&
                this.filterString(item.Creditor, this.CreditorFilter) &&
                this.filterString(
                  item.CreditorAccountNumber,
                  this.CreditorAccountNumberFilter
                ) &&
                new Date(item.SettledDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.SettledDateFilter) > -1 &&
                this.filterString(
                  item.NewPaymentPlanner,
                  this.NewPaymentPlannerFilter
                ) &&
                this.filterString(item.FeeTotal, this.FeeTotalFilter) &&
                this.filterString(item.FeeCollected, this.FeeCollectedFilter) &&
                this.filterString(item.FeeRemain, this.FeeRemainFilter) &&
                this.filterString(item.FeeTerms, this.FeeTermsFilter)
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();
      let totalFee = 0;
      let totalFeeCollected = 0;
      let totalFeeRemain = 0;

      for (let item of tmpList) {
        totalFee += item.FeeTotal;
        totalFeeCollected += item.FeeCollected;
        totalFeeRemain += item.FeeRemain;
      }

      this.settlementsFeeReportInitSummary.CustomerId =
        "Total: " + totalCustomer;
      this.settlementsFeeReportInitSummary.FeeTotal =
        "Total: " + this.formatMoney(totalFee);
      this.settlementsFeeReportInitSummary.FeeCollected =
        "Total: " + this.formatMoney(totalFeeCollected);
      this.settlementsFeeReportInitSummary.FeeRemain =
        "Total: " + this.formatMoney(totalFeeRemain);

      this.recordCount = tmpList.length;
      this.settlementsFeeReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.settlementsFeeReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }

    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_SETTLEMENTS_FEE_REPORT, value);
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, name) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: name,
      });
    },
  },
};
</script>

<style scoped></style>
