<template>
  <div
    class="col-md-12 newPanelWrapper noMargin pcrp-mediaScreen"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="requestPopUpHeader">
      <label style="font-size: 15px">{{ getServiceName() }}</label>
    </div>
    <div>
      <div class="closeButton" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
    </div>
    <perfect-scrollbar
      style="width: 100%; max-height: 500px; overflow-x: hidden !important"
    >
      <div class="col-md-12" v-if="!isArrayAdditionalAuth">
        <div class="col-md-12 row">
          <div class="col-md-6" v-if="sourceId > 0">
            <label for="pcr-requestFor" class="generalLabel">Request For</label>
            <select
              id="pcr-requestFor"
              class="form-control"
              v-model="coCustId"
              @change="fillCustomerValues()"
            >
              <i class="fas fa-angle-down" aria-hidden="true"></i>

              <option
                v-for="item in getCustomerData()"
                :key="item.coCustId"
                :value="item.coCustId"
              >
                {{ item.firstName + " " + item.lastName }}
              </option>
              <option
              v-if="(this.sourceId == 1 || this.sourceId == 4 )&& getCustomerData().length > 1"
                value="joint"
              >
                Joint
              </option>
            </select>
          </div>
          <div class="col-md-6" v-show="sourceId == 1 || sourceId == 4">
            <label for="pcr-dateofBirth" class="generalLabel"
              >Date of Birth</label
            >
            <div class="col-md-12 noPadding noMargin">
              <DatePicker
                mode="date"
                :locale="'en-US'"
                v-model="currentObj.dateofBirth"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    id="pcr-dateofBirth"
                    class="form-control"
                    :value="inputValue"
                    v-on="inputEvents"
                    placeholder="Date of Birth"
                    :class="v$.currentObj.dateofBirth.$error ? 'has-error' : ''"
                    @blur="v$.currentObj.dateofBirth.$touch"
                  />
                </template>
              </DatePicker>
            </div>
          </div>
        </div>
        <div class="col-md-12 row" v-if="!(sourceId == 5 || sourceId == 6)">
          <div class="col-md-6">
            <label for="pcr-firstName" class="generalLabel">First Name</label>
            <input
              type="text"
              id="pcr-firstName"
              class="form-control"
              autocomplete="none"
              placeholder="First Name"
              v-model="currentObj.firstName"
              :class="v$.currentObj.firstName.$error ? 'has-error' : ''"
              @blur="v$.currentObj.firstName.$touch"
            />
          </div>
          <div class="col-md-6">
            <label for="pcr-lastName" class="generalLabel">Last Name</label>
            <input
              type="text"
              id="pcr-lastName"
              class="form-control"
              autocomplete="none"
              placeholder="Last Name"
              v-model="currentObj.lastName"
              :class="v$.currentObj.lastName.$error ? 'has-error' : ''"
              @blur="v$.currentObj.lastName.$touch"
            />
          </div>
        </div>
        <div class="col-md-12 row" v-if="!(sourceId == 5 || sourceId == 6)">
          <div class="col-md-6">
            <label for="pcr-ssn" class="generalLabel">SSN</label>
            <input
              type="text"
              id="pcr-ssn"
              class="form-control"
              autocomplete="none"
              placeholder="SSN"
              v-myMask="{ mask: ['999-99-9999'] }"
              v-model="currentObj.ssn"
              :class="v$.currentObj.ssn.$error ? 'has-error' : ''"
              @blur="v$.currentObj.ssn.$touch"
            />
          </div>
          <div class="col-md-6">
            <label for="pcr-city" class="generalLabel">City</label>
            <input
              type="text"
              id="pcr-city"
              class="form-control"
              autocomplete="none"
              placeholder="City"
              v-model="currentObj.city"
              :class="v$.currentObj.city.$error ? 'has-error' : ''"
              @blur="v$.currentObj.city.$touch"
            />
          </div>
        </div>
        <div class="col-md-12 row" v-if="!(sourceId == 5 || sourceId == 6)">
          <div class="col-md-12">
            <label for="pcr-street" class="generalLabel">Street</label>
            <input
              type="text"
              id="pcr-street"
              class="form-control"
              autocomplete="none"
              placeholder="Street"
              v-model="currentObj.street"
              :class="v$.currentObj.street.$error ? 'has-error' : ''"
              @blur="v$.currentObj.street.$touch"
            />
          </div>
        </div>
        <div class="col-md-12 row" v-if="!(sourceId == 5 || sourceId == 6)">
          <div class="col-md-6">
            <label for="pcr-state" class="generalLabel">State</label>
            <select
              v-model="currentObj.state"
              id="pcr-state"
              class="form-control"
              :class="v$.currentObj.state.$error ? 'has-error' : ''"
              @blur="v$.currentObj.state.$touch"
            >
              <option :value="null">Select State</option>
              <option v-for="item in states" :key="item.Id" :value="item.Id">
                {{ item.Name }}
              </option>
            </select>
          </div>
          <div class="col-md-6">
            <label for="pcr-zip" class="generalLabel">Zip Code</label>
            <input
              type="text"
              id="pcr-zip"
              class="form-control"
              autocomplete="none"
              v-myMask="{ mask: ['99999'] }"
              placeholder="Zip Code"
              v-model="currentObj.zipCode"
              :class="v$.currentObj.zipCode.$error ? 'has-error' : ''"
              @blur="v$.currentObj.zipCode.$touch"
            />
          </div>
        </div>

        <div
          class="col-md-12 noPadding"
          v-if="(this.sourceId == 1 || this.sourceId == 4) && coCustId == 'joint'"
        >
          <div class="col-md-12">
            <hr />
            <label class="mb-2 ml-2">Co-Customer Information</label>
          </div>
          <div class="col-md-12">
            <div class="col-md-6">
              <label for="co-pcr-dateofBirth" class="generalLabel"
                >Date of Birth</label
              >
              <div class="col-md-12 noPadding noMargin">
                <DatePicker
                  mode="date"
                  :locale="'en-US'"
                  v-model="coCustCurrentObj.dateofBirth"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      id="co-pcr-dateofBirth"
                      class="form-control"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="Date of Birth"
                      :class="
                        v$.coCustCurrentObj.dateofBirth.$error
                          ? 'has-error'
                          : ''
                      "
                      @blur="v$.coCustCurrentObj.dateofBirth.$touch"
                    />
                  </template>
                </DatePicker>
              </div>
            </div>
          </div>
          <div class="col-md-12 row">
            <div class="col-md-6">
              <label for="co-pcr-firstName" class="generalLabel"
                >First Name</label
              >
              <input
                type="text"
                id="co-pcr-firstName"
                class="form-control"
                autocomplete="none"
                placeholder="First Name"
                v-model="coCustCurrentObj.firstName"
                :class="v$.coCustCurrentObj.firstName.$error ? 'has-error' : ''"
                @blur="v$.coCustCurrentObj.firstName.$touch"
              />
            </div>
            <div class="col-md-6">
              <label for="co-pcr-lastName" class="generalLabel"
                >Last Name</label
              >
              <input
                type="text"
                id="co-pcr-lastName"
                class="form-control"
                autocomplete="none"
                placeholder="Last Name"
                v-model="coCustCurrentObj.lastName"
                :class="v$.coCustCurrentObj.lastName.$error ? 'has-error' : ''"
                @blur="v$.coCustCurrentObj.lastName.$touch"
              />
            </div>
          </div>
          <div class="col-md-12 row">
            <div class="col-md-6">
              <label for="co-pcr-ssn" class="generalLabel">SSN</label>
              <input
                type="text"
                id="co-pcr-ssn"
                class="form-control"
                autocomplete="none"
                placeholder="SSN"
                v-myMask="{ mask: ['999-99-9999'] }"
                v-model="coCustCurrentObj.ssn"
                :class="v$.coCustCurrentObj.ssn.$error ? 'has-error' : ''"
                @blur="v$.coCustCurrentObj.ssn.$touch"
              />
            </div>
            <div class="col-md-6">
              <label for="co-pcr-city" class="generalLabel">City</label>
              <input
                type="text"
                id="co-pcr-city"
                class="form-control"
                autocomplete="none"
                placeholder="City"
                v-model="coCustCurrentObj.city"
                :class="v$.coCustCurrentObj.city.$error ? 'has-error' : ''"
                @blur="v$.coCustCurrentObj.city.$touch"
              />
            </div>
          </div>
          <div class="col-md-12 row">
            <div class="col-md-12">
              <label for="co-pcr-street" class="generalLabel">Street</label>
              <input
                type="text"
                id="co-pcr-street"
                class="form-control"
                autocomplete="none"
                placeholder="Street"
                v-model="coCustCurrentObj.street"
                :class="v$.coCustCurrentObj.street.$error ? 'has-error' : ''"
                @blur="v$.coCustCurrentObj.street.$touch"
              />
            </div>
          </div>
          <div class="col-md-12 row">
            <div class="col-md-6">
              <label for="pcr-state" class="generalLabel">State</label>
              <select
                v-model="coCustCurrentObj.state"
                id="pcr-state"
                class="form-control"
                :class="v$.coCustCurrentObj.state.$error ? 'has-error' : ''"
                @blur="v$.coCustCurrentObj.state.$touch"
              >
                <option :value="null">Select State</option>
                <option v-for="item in states" :key="item.Id" :value="item.Id">
                  {{ item.Name }}
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <label for="co-pcr-zip" class="generalLabel">Zip Code</label>
              <input
                type="text"
                id="co-pcr-zip"
                class="form-control"
                autocomplete="none"
                v-myMask="{ mask: ['99999'] }"
                placeholder="Zip Code"
                v-model="coCustCurrentObj.zipCode"
                :class="v$.coCustCurrentObj.zipCode.$error ? 'has-error' : ''"
                @blur="v$.coCustCurrentObj.zipCode.$touch"
              />
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="col-md-12">
            <button
              type="button"
              class="btn btn-success col-md-12"
              :disabled="!(sourceId == 5 || sourceId == 6) && v$.$invalid"
              @click="handleSendRequest()"
            >
              Request
            </button>
          </div>
        </div>
      </div>
      <div
        class="col-md-12"
        style="margin-top: -50px"
        v-if="isArrayAdditionalAuth"
      >
        <array-authentication-kba
          :appKey="arrayCredentials.appKey"
          :userId="arrayCredentials.clientKey"
          :tui="true"
          :exp="true"
          :efx="true"
          showResultPages="true"
          :sandbox="arrayCredentials.isTestMode"
        >
        </array-authentication-kba>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { DatePicker } from "v-calendar";
import { mapState } from "vuex";
import globalTypes from "@/store/types";
import types from "./types";
import deepCopy from "@/helpers/deepCopy";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import utilitiesMixin from "@/mixins/utilitiesMixin";
let form;
export default {
  name: "PullCreditRequestPopup",
  components: { DatePicker },
  mixins: [utilitiesMixin],
  props: {
    pullCreditCustomerArr: {
      type: Array,
      default: () => [],
    },
    meridianCustomerArr: {
      type: Array,
      default: () => [],
    },
    trustedCreditCustomerArr: {
      type: Array,
      default: () => [],
    },
    xActusCustomerArr: {
      type: Array,
      default: () => [],
    },
    spinWheelCustomerArr: {
      type: Array,
      default: () => [],
    },
    sourceId: {
      type: Number,
      default: () => 0,
    },
  },
  unmounted() {
    window.removeEventListener("array-event", this.arrayEvent);
  },
  async mounted() {
    await this.$store.dispatch(globalTypes.GET_STATES);
    this.fillCustomerValues();
    if(this.sourceId == 4 && this.xActusCustomerArr && this.xActusCustomerArr.length > 1)
        {
          this.coCustId = "joint";
          this.currentObj = deepCopy(this.xActusCustomerArr[0]);
          this.coCustCurrentObj = deepCopy(this.xActusCustomerArr[1]);
        }
       

    form = this;
    if (this.sourceId == 1) {
      if(this.pullCreditCustomerArr.length > 1)
          {
            this.coCustId = "joint";
            this.currentObj = deepCopy(this.pullCreditCustomerArr[0]);
            this.coCustCurrentObj = deepCopy(this.pullCreditCustomerArr[1]);
          }
      window.addEventListener("array-event", this.arrayEvent);
    }
  },
  data() {
    return {
      isLoading: false,
      v$: useValidate(),
      coCustId: 0,
      saving: false,
      currentObj: {
        coCustId: 0,
        customerId: 0,
        firstName: "",
        lastName: "",
        dateofBirth: "",
        ssn: "",
        street: "",
        city: "",
        state: null,
        zipCode: "",
        phone: "",
      },
      coCustCurrentObj: {
        coCustId: 0,
        customerId: 0,
        firstName: "",
        lastName: "",
        dateofBirth: "",
        ssn: "",
        street: "",
        city: "",
        state: null,
        zipCode: "",
        phone: "",
      },
      isArrayAdditionalAuth: false,
      arrayCredentials: {
        logId: 0,
        appKey: "",
        clientKey: "",
        isTestMode: true,
        providerName: "",
      },
    };
  },
  computed: mapState({
    states: (state) => state.globals.states,
  }),
  methods: {
    async arrayEvent(arrayEvent) {
      const {
        tagName, // the name of the component that emitted the event
        event, // the name of the user's action
        metadata = {}, // component-specific data
      } = arrayEvent.detail;
      if (tagName == "array-authentication-kba" && event == "loaded") {
        await form.sendAuthQuestionPulled(true);
      }
      if (tagName == "array-authentication-kba" && event == "success") {
        await form.sendAuthenticationToken();
      } else if (tagName == "array-authentication-kba" && event == "failure") {
        form.$emit("closePullCreditRequestPopup", {
          Text: "Authentication failed!",
          Type: "Error",
        });
      }
    },
    getServiceName() {
      let rtn = "";
      if (this.sourceId == 1) rtn = "Pull Credit Array Request";
      else if (this.sourceId == 2) rtn = "Pull Credit Meridian Link Request";
      else if (this.sourceId == 3) rtn = "Trusted Credit Request";
      else if (this.sourceId == 4) rtn = "XActus Request";
      else if (this.sourceId == 5) rtn = "Spin Wheel Request";
      else if (this.sourceId == 6) rtn = "Spin Wheel Request (Manual)";
      return rtn;
    },
    getCustomerData() {
      if (this.sourceId == 1) {
        return this.pullCreditCustomerArr;
      } else if (this.sourceId == 2) {
        return this.meridianCustomerArr;
      } else if (this.sourceId == 3) {
        return this.trustedCreditCustomerArr;
      } else if (this.sourceId == 4) {
        return this.xActusCustomerArr;
      } else if (this.sourceId == 5) {
        return this.spinWheelCustomerArr;
      }
      else if (this.sourceId == 6) {
        return this.spinWheelCustomerArr;
      } else {
        return [];
      }
    },
    emptyTheObjectsOnUnmount() {
      this.coCustId = null;
      this.saving = null;
      this.currentObj = null;
      this.coCustCurrentObj = null;
    },
    fillCustomerValues() {
      if(this.sourceId == 1 && this.coCustId == "joint")
      {
        if (this.pullCreditCustomerArr.length > 1) {
          this.currentObj = deepCopy(this.pullCreditCustomerArr[0]);
          this.coCustCurrentObj = deepCopy(this.pullCreditCustomerArr[1]);
        }
      }
      else if (this.sourceId == 1) {
        if (this.pullCreditCustomerArr.length > 0) {

          let indx = this.pullCreditCustomerArr.findIndex(
            (x) => x.coCustId == this.coCustId
          );
          if(indx > -1)
          {
          this.currentObj = deepCopy(this.pullCreditCustomerArr[indx]);
          }
        }
      } else if (this.sourceId == 2) {
        if (this.meridianCustomerArr.length > 0) {
          let indx = this.meridianCustomerArr.findIndex(
            (x) => x.coCustId == this.coCustId
          );
          this.currentObj = deepCopy(this.meridianCustomerArr[indx]);
          this.currentObj.dateofBirth = "none";
        }
      } else if (this.sourceId == 3) {
        if (this.trustedCreditCustomerArr.length > 0) {
          let indx = this.trustedCreditCustomerArr.findIndex(
            (x) => x.coCustId == this.coCustId
          );
          this.currentObj = deepCopy(this.trustedCreditCustomerArr[indx]);
          this.currentObj.dateofBirth = "none";
        }
      } else if (this.sourceId == 4 && this.coCustId == "joint") {
        if (this.xActusCustomerArr.length > 0) {
          this.currentObj = deepCopy(this.xActusCustomerArr[0]);
        }
        if (this.xActusCustomerArr.length > 1) {
          this.coCustCurrentObj = deepCopy(this.xActusCustomerArr[1]);
        }
      } else if (this.sourceId == 4) {
          if (this.xActusCustomerArr.length > 0) {
          let indx = this.xActusCustomerArr.findIndex(
            (x) => x.coCustId == this.coCustId
          );
          if(indx > -1)
          {
            this.currentObj = deepCopy(this.xActusCustomerArr[indx]);
          }
         
        }
      } else if (this.sourceId == 5 || this.sourceId==6) {
        if (this.spinWheelCustomerArr.length > 0) {
          let indx = this.spinWheelCustomerArr.findIndex(
            (x) => x.coCustId == this.coCustId
          );
          this.currentObj = deepCopy(this.spinWheelCustomerArr[indx]);
        }
      }
    },
    async closePanel() {
      if (this.isArrayAdditionalAuth == true) {
        await this.$swal({
          title: "Confirm",
          text: "Are you sure you want to leave without submitting the answers? You can continue submitting the answers from the credit pull history without putting a new order.",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.$emit("closePullCreditRequestPopup");
          }
        });
      } else {
        this.$emit("closePullCreditRequestPopup");
      }
    },
    async handleSendRequest() {
      let err, result;
      let ssnVal = "";
      let coSsnVal="";

      if (this.sourceId == 5) {
        if (
          !(
            this.currentObj.dateofBirth != null &&
            this.currentObj.dateofBirth != "" &&
            this.currentObj.phone != null &&
            this.currentObj.phone != ""
          )
        ) {
          this.$swal(
            "Warning",
            "Date of Birth and Phone Number required for this request!",
            "warning"
          );
          return;
        }
      }

      if (this.currentObj.ssn && this.currentObj.ssn.length > 0) {
        ssnVal = await this.AES256_GCM_ENCRYPT(this.currentObj.ssn);
      }
      //bura
      let dataToPost;
      // joint post...
      if(( this.sourceId == 1 || this.sourceId == 4) && this.coCustId == "joint" && this.coCustCurrentObj != null){
        if (this.coCustCurrentObj.ssn && this.coCustCurrentObj.ssn.length > 0) {
          coSsnVal = await this.AES256_GCM_ENCRYPT(this.coCustCurrentObj.ssn);
        }
        dataToPost = {
          CustomerId: this.currentObj.customerId,
          Ssn: ssnVal,
          FirstName: this.currentObj.firstName,
          LastName: this.currentObj.lastName,
          Dob: this.currentObj.dateofBirth,
          Address: {
            Street: this.currentObj.street,
            City: this.currentObj.city,
            State: this.currentObj.state,
            Zip: this.currentObj.zipCode,
          },
          CoCustomerId: this.coCustCurrentObj.coCustId,
          CoCustomerFirstName: this.coCustCurrentObj.firstName,
          CoCustomerLastName: this.coCustCurrentObj.lastName,
          CoCustomerDob: this.coCustCurrentObj.dateofBirth,
          CoCustomerSsn: coSsnVal,
          CoCustomerAddress: {
            Street: this.coCustCurrentObj.street,
            City: this.coCustCurrentObj.city,
            State: this.coCustCurrentObj.state,
            Zip: this.coCustCurrentObj.zipCode,
          },

          IsCoCustomer: false,
          ServiceId: this.sourceId == 6 ? 5 : this.sourceId,
        };

      }
      else{
        dataToPost = {
          CustomerId: this.currentObj.customerId,
          Ssn: ssnVal,
          FirstName: this.currentObj.firstName,
          LastName: this.currentObj.lastName,
          Dob: this.currentObj.dateofBirth,
          Address: {
            Street: this.currentObj.street,
            City: this.currentObj.city,
            State: this.currentObj.state,
            Zip: this.currentObj.zipCode,
          },
          IsCoCustomer: this.currentObj.coCustId == 0 ? false : true,
          ServiceId: this.sourceId == 6 ? 5 : this.sourceId,
        };
      }


      this.isLoading = true;
      [err, result] = await this.$store.dispatch(
        types.REQUEST_CREDIT_PULL_REPORT,
        dataToPost
      );
      this.isLoading = false;
      if (result && result.Data) {
        if (this.sourceId == 1) {
          if (result.Data.IsExtraAuth == true) {
            this.resetArrayCredentials();
            this.arrayCredentials.appKey = result.Data.AppKey;
            this.arrayCredentials.clientKey = result.Data.ClientKey;
            this.arrayCredentials.logId = result.Data.Id;
            this.arrayCredentials.isTestMode = result.Data.IsTest;
            this.arrayCredentials.providerName = result.Data.ProductCode;

            if (
              this.arrayCredentials.providerName &&
              this.arrayCredentials.providerName.length > 3
            ) {
              this.arrayCredentials.providerName =
                this.arrayCredentials.providerName.substr(0, 3);
            }

            this.isLoading = true;
            let arrayMainScript = document.createElement("script");
            arrayMainScript.setAttribute(
                    "src",
                    "https://embed.array.io/cms/array-web-component.js?appKey=" +
                    this.arrayCredentials.appKey
            );
            arrayMainScript.setAttribute("id","arrayScript")
            document.head.appendChild(arrayMainScript);
            await this.sleep(1000);
            let arrayKBAScript = document.createElement("script");
            arrayKBAScript.setAttribute(
                    "src",
                    "https://embed.array.io/cms/array-authentication-kba.js?appKey=" +
                    this.arrayCredentials.appKey
            );
            arrayKBAScript.setAttribute("id","arrayKbaScript");
            document.head.appendChild(arrayKBAScript);

            await this.sleep(1000);
            this.isArrayAdditionalAuth = true;
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.$swal(
              "Success",
              "Credit pull requested, please check the status from pull history shortly.",
              "success"
            );
            this.$emit("closePullCreditRequestPopup");
          }

          //       if(typeof result.Data.Questions != "undefined" && result.Data.Questions && result.Data.Questions.questions)
          // {

          //         this.$emit("openArrayAuthPopup", { Questions: result.Data.Questions.questions, Id:result.Data.Id  });
          // }
          // else
          // {
          //       this.$swal(
          //   "Success",
          //   "Credit pull requested, please check the status from pull history shortly.",
          //   "success"
          //     );
          //     this.$emit("closePullCreditRequestPopup");
          // }
        } else if (this.sourceId == 5) {
          this.$emit("openSpinWheelAuthPopup", {
            appKey: result.Data.AppKey,
            clientKey: result.Data.ClientKey,
            logId: result.Data.Id,
            dob: this.currentObj.dateofBirth,
            phone: this.currentObj.phone,
            isManualRequest: false
          });
        }
        else if (this.sourceId == 6) {
          this.$emit("openSpinWheelAuthPopup", {
            appKey: result.Data.AppKey,
            clientKey: result.Data.ClientKey,
            logId: result.Data.Id,
            dob: this.currentObj.dateofBirth,
            phone: this.currentObj.phone,
            isManualRequest: true
          });
        } else {
          this.$swal(
            "Success",
            "Credit pull requested, please check the status from pull history shortly.",
            "success"
          );
          this.$emit("closePullCreditRequestPopup");
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
    async sendAuthenticationToken() {
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.SEND_ARRAY_AUTH_ANSWERS,
        {
          ServiceId: 1,
          CreditPullLogId: this.arrayCredentials.logId,
        }
      );

      if (result) {
        await this.sendAuthQuestionPulled(false);
        this.$swal(
          "Success",
          "Credit pull requested, please check the status from pull history shortly.",
          "success"
        );
        this.$emit("closePullCreditRequestPopup");
      } else {
        this.$swal(
          "Error",
          "An error occured. Please try again later.",
          "error"
        );
        this.$emit("closePullCreditRequestPopup");
      }
    },
    async sendAuthQuestionPulled(pulled) {
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.SET_AUTH_QUESTION_PULLED,
        {
          Id: this.arrayCredentials.logId,
          IsPulled: pulled,
        }
      );
    },

    resetArrayCredentials() {
      this.arrayCredentials = {
        logId: 0,
        appKey: "",
        clientKey: "",
        isTestMode: true,
        providerName: "",
      };
    },
  },
  validations() {
    let commonValidation = {
      firstName: { required },
      lastName: { required },
      dateofBirth: { required },
      ssn: { required },
      street: { required },
      city: { required },
      state: { required },
      zipCode: { required },
    };

    if (this.coCustId == "joint") {
      return {
        currentObj: commonValidation,
        coCustCurrentObj: commonValidation,
      };
    } else {
      return {
        currentObj: commonValidation,
      };
    }
  },
};
</script>

<style>
.has-error {
  border: 2px solid red !important;
}
.requestPopUpHeader {
  padding-left: 35px;
  margin-top: -5px;
  padding-bottom: 10px;
}

@media screen and (max-height: 540px) {
  .pcrp-mediaScreen {
    max-height: 400px !important;
    overflow-y: scroll;
  }
}
@media screen and (max-height: 313px) {
  .pcrp-mediaScreen {
    max-height: 200px !important;
    overflow-y: scroll;
  }
}
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}
</style>
