<template>
  <div
    class="col-md-12 newPanelWrapper noMargin pchp-mediaScreen"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div class="settlementFeePopupHeader generalLabel">
      <label style="font-size: 15px">Program Adjustments</label>
    </div>

    <div>
      <div class="closeButton" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
    </div>

    <perfect-scrollbar
      style="width: 100%; max-height: 480px; overflow-x: hidden !important"
    >
      <div class="col-md-12">
        <div class="col-md-12" style="display: flex; flex-direction: column">
          <div v-if="checkAuth(50002)" style="display: flex">
            <input
              type="checkbox"
              class="generalCheckBox"
              v-model="settings.removedFeeCBox"
            />
            <label class="form-label ml-1">Remove EG Fees</label>
          </div>

          <div v-if="checkAuth(81405)">
            <div
              v-for="item in settings.convertedData"
              :key="item"
              style="display: flex"
            >
              <input
                type="checkbox"
                class="generalCheckBox"
                v-model="item.Selected"
              />
              <label class="form-label ml-1">{{ item.Id }}</label>
            </div>
          </div>
        </div>

        <div v-if="checkAuth(100051)" class="col-md-12 row noPadding">
          <div class="col-md-12">
            <label
              >Override (min:
              {{
                attorneyName != ""
                  ? (
                      this.maxServiceFeePercentvalue * 100 -
                      this.getFeeAdjustmentAttorney()
                    ).toFixed()
                  : (
                      this.maxServiceFeePercentvalue * 100 -
                      this.getFeeAdjustment()
                    ).toFixed()
              }}% - max:
              {{ (this.maxServiceFeePercentvalue * 100).toFixed() }}%)</label
            >
            <div style="display: flex">
              <input
                maxlength="6"
                class="form-control"
                type="text"
                v-model="settings.settlementFeePercentage"
                v-myMask="{
                  alias: 'numeric',
                  min: 0,
                  rightAlign: false,
                  suffix: '%',
                  allowMinus: false,
                }"
              />
              <button
                @click="resetFee()"
                type="button"
                class="btn btn-danger btn-sm ml-1"
              >
                Clear
              </button>
            </div>
          </div>
        </div>

        <div class="col-md-12 row noPadding" v-if="checkAuth(100053)">
          <div class="col-md-12">
            <label>Min Draft Amount</label>
            <div style="display: flex">
              <input
                class="form-control"
                type="text"
                v-myMask="{
                  alias: 'currency',
                  prefix: '$',
                  rightAlign: false,
                  min: 0,
                }"
                v-model="settings.minDraftAmount"
              />
              <button
                @click="resetMinDraftAmount()"
                type="button"
                class="btn btn-danger btn-sm ml-1"
              >
                Clear
              </button>
            </div>
          </div>

          <div class="col-md-12">
            <label>Min Debt Amount</label>
            <div style="display: flex">
              <input
                class="form-control"
                type="text"
                v-myMask="{
                  alias: 'currency',
                  prefix: '$',
                  rightAlign: false,
                  min: 0,
                }"
                v-model="settings.minDebtAmount"
              />
              <button
                @click="resetMinDebtAmount()"
                type="button"
                class="btn btn-danger btn-sm ml-1"
              >
                Clear
              </button>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <button
            @click="saveFee()"
            type="button"
            class="btn btn-success col-md-12 mt-2"
          >
            Save
          </button>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import { mapState } from "vuex";
import types from "./types";

export default {
  name: "SettlementFeePercentagePopup",
  mixins: [utilitiesMixin, formatMixin],
  components: {},
  props: {
    policyGroupId: {
      type: Number,
      default: () => 0,
    },
    officeId: {
      type: Number,
      default: () => 0,
    },
    stateId: {
      type: Number,
      default: () => 0,
    },
    customerId: {
      type: Number,
      default: () => 0,
    },
    percantageValue: {
      type: Number,
      default: () => 0,
    },
    maxServiceFeePercentvalue: {
      type: Number,
      default: () => 0,
    },

    removedFeeGroup: {
      type: String,
      default: "",
    },
    attorneyName: {
      Type: String,
      default: "",
    },
    minDebtAmountValue: {
      type: Number,
      default: () => null,
    },
    minDebtAmountDefaultValue: {
      type: Number,
      default: () => null,
    },
    minDraftAmountValue: {
      type: Number,
      default: () => null,
    },
    serviceFeeAdjustment: {
      type: Number,
      default: () => 0,
    },
    serviceFeeAdjustmentAttorney: {
      type: Number,
      default: () => 0,
    },
    createdDate: {
      Type: String,
      default: "",
    },
  },
  computed: mapState({
    docusignValidationSettings: (state) =>
      state.clientManagement.docusignValidationSettings,
  }),
  data() {
    return {
      settings: {
        settlementFeePercentage: 0,
        removedFeeCBox: false,
        minDraftAmount: 0,
        minDebtAmount: 0,
        convertedData: [],
      },
      oldSettings: "",
      minDraftAmountDefault: 0,
      minDebtAmountDefault: 0,
    };
  },
  unmounted() {},
  async mounted() {
    this.settings.settlementFeePercentage = this.percantageValue * 100;

    if (this.checkAuth(50002)) {
      if (this.removedFeeGroup == null) {
        this.settings.removedFeeCBox = false;
      } else {
        this.settings.removedFeeCBox = this.removedFeeGroup.length > 0;
      }
    } else if (this.checkAuth(81405)) {
      await this.convertData();
      if (this.removedFeeGroup == null) {
        this.settings.convertedData.forEach((x) => (x.Selected = true));
      } else if (this.removedFeeGroup == "") {
        this.settings.convertedData.forEach((x) => (x.Selected = true));
      } else {
        let groups = this.removedFeeGroup.split(",");
        if (groups && groups.length > 0) {
          this.settings.convertedData.forEach(
            (x) => (x.Selected = groups.includes(x.Id) ? false : true)
          );
        }
      }
    }
    //min debt amount value set
    if (this.minDebtAmountValue > 0) {
      this.settings.minDebtAmount = this.minDebtAmountValue;
    } else {
      if (this.minDebtAmountDefaultValue) {
        this.settings.minDebtAmount = this.minDebtAmountDefaultValue;
      }
    }

    //min debt amount default value (reset value)
    if (this.minDebtAmountDefaultValue) {
      this.minDebtAmountDefault = this.minDebtAmountDefaultValue;
    }

    //min draft amount value set
    if (this.minDraftAmountValue > 0) {
      this.settings.minDraftAmount = this.minDraftAmountValue;
    }

    if (
      this.docusignValidationSettings &&
      this.docusignValidationSettings.length > 0
    ) {
      let draftAmountFields = this.docusignValidationSettings.filter(
        (x) =>
          x.ObjectType == "Customer" &&
          x.Id == "CustomerGlobalInformation.DraftAmount"
      );
      if (draftAmountFields.length > 0) {
        let daDefault = parseFloat(draftAmountFields[0].MinCharLimit);
        if (daDefault > 0) {
          this.minDraftAmountDefault = daDefault;
          if (
            this.minDraftAmountValue == null ||
            this.minDraftAmountValue == 0
          ) {
            //min draft amount default value (reset value)
            this.settings.minDraftAmount = daDefault;
          }
        }
      }
    }

    this.oldSettings = JSON.stringify(this.settings);
  },

  methods: {
    async convertData() {
      let err, result;

      let payload = {
        PolicyGroupId: this.policyGroupId,
        OfficeId: this.officeId,
        StateId: this.stateId,
        CreatedDate: this.createdDate,
      };
      [err, result] = await this.$store.dispatch(
        types.GET_ALL_OPTIONAL_PRODUCT_TYPES,
        payload
      );

      if (result && result.Data) {

        this.settings.convertedData = result.Data.map((item) => {
          return { Id: item.Name,ProductType:item.ProductType, Selected: false };
        });
      }
    },

    getFeeAdjustment() {
      let rtn =
        this.serviceFeeAdjustment > 0
          ? parseInt(this.serviceFeeAdjustment * 100)
          : 0;
      return rtn;
    },
    getFeeAdjustmentAttorney() {
      let rtn =
        this.serviceFeeAdjustmentAttorney > 0
          ? parseInt(this.serviceFeeAdjustmentAttorney * 100)
          : 0;
      return rtn;
    },
    saveFee() {
      this.settings.minDebtAmount = parseFloat(this.settings.minDebtAmount);
      this.settings.minDraftAmount = parseFloat(this.settings.minDraftAmount);

      let minPercent = 0;

      let feeAdjustment = this.getFeeAdjustment();
      let feeAdjustmentAttorney = this.getFeeAdjustmentAttorney();
      if (this.attorneyName != "") {
        minPercent = parseFloat(
          parseFloat(this.maxServiceFeePercentvalue * 100) -
            feeAdjustmentAttorney
        );
      } else {
        minPercent = parseFloat(
          parseFloat(this.maxServiceFeePercentvalue * 100) - feeAdjustment
        );
      }

      let maxPercent = parseFloat(this.maxServiceFeePercentvalue * 100);
      let settlementFee = parseFloat(this.settings.settlementFeePercentage);

      if (settlementFee < minPercent) {
        this.$swal(
          "Warning!",
          "Service fee percentage cannot be less than " + minPercent + "%",
          "warning"
        );
        return;
      } else if (settlementFee > maxPercent) {
        this.$swal(
          "Warning!",
          "Service Fee % cannot be greater than " + maxPercent,
          "warning"
        );
        return;
      }

      if (this.checkAuth(100053)) {
        if (this.settings.minDebtAmount <= 0) {
          this.$swal(
            "Warning!",
            "Min Debt Amount must be greater than 0",
            "warning"
          );
          return;
        }

        if (this.settings.minDraftAmount <= 0) {
          this.$swal(
            "Warning!",
            "Min Draft Amount must be greater than 0",
            "warning"
          );
          return;
        }
      }

      if (
        this.oldSettings == JSON.stringify(this.settings) &&
        this.checkAuth(81405) &&
        this.removedFeeGroup != null
      ) {
        this.$swal("Warning!", "No changes detected.", "warning");
        return;
      }

      if (settlementFee == maxPercent) {
        this.settings.settlementFeePercentage = null;
      }
      let removedFees = null;

      if (this.checkAuth(81405)) {
        removedFees = "";
        if (this.settings.convertedData) {
          let optionalGroups = this.settings.convertedData.filter(
            (x) => x.Selected == false
          );
          if (optionalGroups && optionalGroups.length > 0) {
            removedFees = optionalGroups.map((x) => {return{Id:x.Id,ProductType:x.ProductType}});
          }
        }
      } else if (this.checkAuth(50002)) {
        removedFees =
          this.settings.removedFeeCBox == false ? null : [{Id:"Evergreen",ProductType:"Fee"}];
      }



      this.$emit("saveServiceFeePercantage", {
        FeePercentage: settlementFee,
        RemovedFeeGroup: removedFees,
        MinDebtAmount: this.settings.minDebtAmount,
        MinDraftAmount: this.settings.minDraftAmount,
      });
    },
    resetFee() {
      this.settings.settlementFeePercentage = parseFloat(
        this.maxServiceFeePercentvalue * 100
      );
      this.settings.removedFeeCBox = false;
    },
    resetMinDebtAmount() {
      this.settings.minDebtAmount = this.minDebtAmountDefault;
    },
    resetMinDraftAmount() {
      this.settings.minDraftAmount = this.minDraftAmountDefault;
    },

    closePanel() {
      this.$emit("closeSettlementFeePercentagePopup");
    },
  },
};
</script>

<style scope>
.has-error {
  border: 2px solid red !important;
}

.settlementFeePopupHeader {
  margin-left: 23px;
  margin-top: -5px;
  padding-bottom: 10px;
}
</style>
