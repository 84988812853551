<template>
  <div class="col-md-12">
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 275px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-12">
        <form class="newPanelWrapper">
          <div class="row">
            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <label class="generalLabel">Start Date</label>
              <DatePicker mode="date" :locale="'en-US'" v-model="date1">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    autocomplete="off"
                    class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                    :value="inputValue"
                    v-on="inputEvents"
                    placeholder="Start Date"
                  />
                </template>
              </DatePicker>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <label class="generalLabel">End Date</label>
              <DatePicker
                mode="date"
                :locale="'en-US'"
                v-model="date2"
                :available-dates="disabledEndDates"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    autocomplete="off"
                    class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                    :value="inputValue"
                    v-on="inputEvents"
                    placeholder="End Date"
                  />
                </template>
              </DatePicker>
            </div>

            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12 noMargin">
              <button
                type="button"
                class="generalButton btn-sm btn-primary"
                @click="downloadReport()"
                style="
                  width: 75%;
                  background-color: #ff7979;
                  color: #ffffff;
                  margin-top: 27px;
                "
              >
                Download
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import { DatePicker } from "v-calendar";

export default {
  name: "EnrolledCreditorsReport",
  components: { DatePicker },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      isLoading: false,
      date1: "",
      date2: "",
    };
  },
  async mounted() {},
  computed: {
    disabledEndDates() {
      let startDate = new Date(this.date1);
      let endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 30);

      return {
        start: startDate,
        end: endDate,
      };
    },
  },
  methods: {
    async downloadReport() {
      let err, result;
      this.isLoading = true;

      let payload = {
        Option: "customdaterange",
        StartDate: new Date(this.date1).toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
        EndDate: new Date(this.date2).toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
      };

      [err, result] = await this.$store.dispatch(
        types.GET_ENROLLED_CREDITORS_REPORT,
        payload
      );
      this.isLoading = false;
      if (result) {
        let tableElement = document.createElement("div");
        tableElement.innerHTML = result;
        this.exportExcel(
          tableElement,
          "EnrolledCreditorsReport-" + new Date().getTime()
        );
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
  },
  watch: {
    date1(newValue, oldValue) {
      if (newValue && this.date2) {
        let startDate = new Date(newValue);
        let endDate = new Date(this.date2);
        let endDate30DaysAfterStartDate = new Date(startDate);
        endDate30DaysAfterStartDate.setDate(startDate.getDate() + 30);
        if (endDate > endDate30DaysAfterStartDate) {
          this.date2 = "";
        }
      }
    },
  },
};
</script>

<style scoped></style>
