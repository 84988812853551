<template>
  <div class="col-md-12" v-if="isValidations">
    <ReportSummary
      :exportData="reportData"
      :allData="snfPayoutList"
      :reportName="'SnfPayoutReport'"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />
    <div class="col-md-12">
      <label class="text-danger">
        The payout automation feature provided by the system is for testing purposes only. Global Zenith makes no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of the feature or information on this system. Upon completion of your testing of this feature, Customer will acknowledge and represent that the feature is acceptable to “go live” before implementation. Under no circumstance, prior to or after the go live date will Global Zenith have any liability to Customer for any loss or damage of any kind incurred as a result of the use of this feature or reliance on the information provided therefrom.
      </label>
    </div>
    <div class="datagrid-wrapper">
      <div>
        <div class="datagrid-header" @click="isOpen(1)">
          <span v-if="toggleDivs[1]">
            <i class="mdi mdi-chevron-up mdi-24px"></i>
          </span>
          <span v-else>
            <i class="mdi mdi-chevron-down mdi-24px"></i>
          </span>
          <span style="color: #2c3e50">1st Enrollment Payout </span>
        </div>

        <div class="datagrid-table gcsTabsTableScroll">
          <div v-if="toggleDivs[1]">
            <o-table :data="filteredFirstEnrollmentPayout">
              <o-table-column
                field="ClientId"
                label="Client ID"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="firstEnrollmentPayout.ClientIdFilter"
                      placeholder=""
                      v-myMask="{
                        alias: 'numeric',
                        rightAlign: false,
                      }"
                    />
                  </div>
                </template>

                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.ClientId }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ addPrefixToCustomerId(props.row.ClientId) }}
                  </span>
                </template>
              </o-table-column>

              <o-table-column
                field="ClientName"
                label="Client Name"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="firstEnrollmentPayout.ClientNameFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.ClientName }}
                </template>
              </o-table-column>

              <o-table-column
                field="OfficeName"
                label="Office"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="firstEnrollmentPayout.OfficeNameFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.OfficeName }}
                </template>
              </o-table-column>

              <o-table-column
                field="FirstClearedDraftDate"
                label="1st Cleared Draft Date"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="
                        firstEnrollmentPayout.FirstClearedDraftDateFilter
                      "
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ formatShortDate(props.row.FirstClearedDraftDate) }}
                </template>
              </o-table-column>

              <o-table-column
                field="EnrolledDebt"
                label="Enrolled Debt"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="firstEnrollmentPayout.EnrolledDebtFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.EnrolledDebt }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ formatMoney(props.row.EnrolledDebt) }}
                  </span>
                </template>
              </o-table-column>

              <o-table-column
                field="PayoutPercentage"
                label="Payout Percantage"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="firstEnrollmentPayout.PayoutPercentageFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.PayoutPercentage }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ props.row.PayoutPercentage + "%" }}
                  </span>
                </template>
              </o-table-column>

              <o-table-column
                field="PayoutAmount"
                label="Payout Amount"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="firstEnrollmentPayout.PayoutAmountFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.PayoutAmount }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ formatMoney(props.row.PayoutAmount) }}
                  </span>
                </template>
              </o-table-column>

              <o-table-column
                field="CatchUpAmount"
                label="Catch Up Amount"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="firstEnrollmentPayout.CatchUpAmountFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.CatchUpAmount }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ formatMoney(props.row.CatchUpAmount) }}
                  </span>
                </template>
              </o-table-column>
            </o-table>
            <section
              v-if="filteredFirstEnrollmentPayout.length == 0"
              class="section"
              style="text-align: center"
            >
              <div class="content has-text-grey has-text-centered mb-4">
                <p class="noMargin">
                  <i
                    style="font-size: 24px; color: #939393"
                    class="ri-question-line"
                  ></i>
                </p>
                <p class="noMargin">Nothing here.</p>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div>
        <div class="datagrid-header" @click="isOpen(2)">
          <span v-if="toggleDivs[2]">
            <i class="mdi mdi-chevron-up mdi-24px"></i>
          </span>
          <span v-else>
            <i class="mdi mdi-chevron-down mdi-24px"></i>
          </span>
          <span style="color: #2c3e50">Settlement Fee Payout</span>
        </div>

        <div class="datagrid-table gcsTabsTableScroll">
          <div v-if="toggleDivs[2]">
            <o-table :data="filteredSettlementFeePayout">
              <o-table-column
                field="ClientId"
                label="Client ID"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="settlementFeePayout.ClientIdFilter"
                      placeholder=""
                      v-myMask="{
                        alias: 'numeric',
                        rightAlign: false,
                      }"
                    />
                  </div>
                </template>

                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.ClientId }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ addPrefixToCustomerId(props.row.ClientId) }}
                  </span>
                </template>
              </o-table-column>

              <o-table-column
                field="ClientName"
                label="Client Name"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="settlementFeePayout.ClientNameFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.ClientName }}
                </template>
              </o-table-column>

              <o-table-column
                field="OfficeName"
                label="Office"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="settlementFeePayout.OfficeNameFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.OfficeName }}
                </template>
              </o-table-column>

              <o-table-column
                field="SettlementFeeClearedDate "
                label="Sett. Fee Cleared Date "
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="
                        settlementFeePayout.SettlementFeeClearedDateFilter
                      "
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ formatShortDate(props.row.SettlementFeeClearedDate) }}
                </template>
              </o-table-column>

              <o-table-column
                field="CreditorName"
                label="Creditor"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="settlementFeePayout.CreditorNameFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.CreditorName }}
                </template>
              </o-table-column>

              <o-table-column
                field="SettlementPayoutPercentage"
                label="Sett. Payout Percentage"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="
                        settlementFeePayout.SettlementPayoutPercentageFilter
                      "
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.SettlementPayoutPercentage }}
                </template>
              </o-table-column>

              <o-table-column
                field="FeeAmount"
                label="Fee Amount"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="settlementFeePayout.FeeAmountFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.FeeAmount }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ formatMoney(props.row.FeeAmount) }}
                  </span>
                </template>
              </o-table-column>

              <o-table-column
                field="TotalCatchUpAmount"
                label="Total Catch Up Amount"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="settlementFeePayout.TotalCatchUpAmountFilter"
                      placeholder=""
                    />
                  </div>
                </template>
                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.TotalCatchUpAmount }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ formatMoney(props.row.TotalCatchUpAmount) }}
                  </span>
                </template>
              </o-table-column>

              <o-table-column
                field="CatchUpRemaining"
                label="Catch Up Remaining"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="settlementFeePayout.CatchUpRemainingFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.CatchUpRemaining }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ formatMoney(props.row.CatchUpRemaining) }}
                  </span>
                </template>
              </o-table-column>

              <o-table-column
                field="SettlementFeePayout"
                label="Sett. Fee Payout"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="settlementFeePayout.SettlementFeePayoutFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.SettlementFeePayout }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ formatMoney(props.row.SettlementFeePayout) }}
                  </span>
                </template>
              </o-table-column>
            </o-table>
            <section
              v-if="filteredSettlementFeePayout.length == 0"
              class="section"
              style="text-align: center"
            >
              <div class="content has-text-grey has-text-centered mb-4">
                <p class="noMargin">
                  <i
                    style="font-size: 24px; color: #939393"
                    class="ri-question-line"
                  ></i>
                </p>
                <p class="noMargin">Nothing here.</p>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div>
        <div class="datagrid-header" @click="isOpen(3)">
          <span v-if="toggleDivs[3]">
            <i class="mdi mdi-chevron-up mdi-24px"></i>
          </span>
          <span v-else>
            <i class="mdi mdi-chevron-down mdi-24px"></i>
          </span>
          <span style="color: #2c3e50">Cancelled Customers</span>
        </div>

        <div class="datagrid-table gcsTabsTableScroll">
          <div v-if="toggleDivs[3]">
            <o-table :data="filteredCancelledCustomers">
              <o-table-column
                field="ClientId"
                label="Client ID"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="cancelledCustomers.ClientIdFilter"
                      placeholder=""
                      v-myMask="{
                        alias: 'numeric',
                        rightAlign: false,
                      }"
                    />
                  </div>
                </template>

                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.ClientId }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ addPrefixToCustomerId(props.row.ClientId) }}
                  </span>
                </template>
              </o-table-column>

              <o-table-column
                field="ClientName"
                label="Client Name"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="cancelledCustomers.ClientNameFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.ClientName }}
                </template>
              </o-table-column>

              <o-table-column field="Office" label="Office" sortable searchable>
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="cancelledCustomers.OfficeFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.Office }}
                </template>
              </o-table-column>

              <o-table-column
                field="CancelledDate"
                label="Cancelled Date"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="cancelledCustomers.CancelledDate"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ formatShortDate(props.row.CancelledDate) }}
                </template>
              </o-table-column>

              <o-table-column
                field="OriginalEnrolledDebtAmount"
                label="Original Enrolled Debt Amount"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="
                        cancelledCustomers.OriginalEnrolledDebtAmountFilter
                      "
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.OriginalEnrolledDebtAmount }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ formatMoney(props.row.OriginalEnrolledDebtAmount) }}
                  </span>
                </template>
              </o-table-column>

              <o-table-column
                field="PayoutPercantage"
                label="Payout Percantage"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="cancelledCustomers.PayoutPercentageFilter"
                      placeholder=""
                    />
                  </div>
                </template>
                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.PayoutPercantage }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ props.row.PayoutPercantage }} %
                  </span>
                </template>
              </o-table-column>

              <o-table-column
                field="AmounttobeRecouped"
                label="Amount to be Recouped"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="cancelledCustomers.AmounttobeRecoupedFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.AmounttobeRecouped }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ formatMoney(props.row.AmounttobeRecouped) }}
                  </span>
                </template>
              </o-table-column>
            </o-table>
            <section
              v-if="filteredCancelledCustomers.length == 0"
              class="section"
              style="text-align: center"
            >
              <div class="content has-text-grey has-text-centered mb-4">
                <p class="noMargin">
                  <i
                    style="font-size: 24px; color: #939393"
                    class="ri-question-line"
                  ></i>
                </p>
                <p class="noMargin">Nothing here.</p>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div>
        <div class="datagrid-header" @click="isOpen(4)">
          <span v-if="toggleDivs[4]">
            <i class="mdi mdi-chevron-up mdi-24px"></i>
          </span>

          <span v-else>
            <i class="mdi mdi-chevron-down mdi-24px"></i>
          </span>
          <span style="color: #2c3e50">Withdrawn Debt </span>
        </div>

        <div class="datagrid-table gcsTabsTableScroll">
          <div v-if="toggleDivs[4]">
            <o-table :data="filteredWithdrawnDebt">
              <o-table-column
                field="ClientId"
                label="Client ID"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="withdrawnDebt.ClientIdFilter"
                      placeholder=""
                      v-myMask="{
                        alias: 'numeric',
                        rightAlign: false,
                      }"
                    />
                  </div>
                </template>

                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.ClientId }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ addPrefixToCustomerId(props.row.ClientId) }}
                  </span>
                </template>
              </o-table-column>

              <o-table-column
                field="ClientName"
                label="Client Name"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="withdrawnDebt.ClientNameFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.ClientName }}
                </template>
              </o-table-column>

              <o-table-column field="Office" label="Office" sortable searchable>
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="withdrawnDebt.OfficeFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.Office }}
                </template>
              </o-table-column>

              <o-table-column
                field="DateDebtwasRemoved"
                label="Date Debt was Removed"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="withdrawnDebt.DateDebtwasRemovedFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ formatShortDate(props.row.DateDebtwasRemoved) }}
                </template>
              </o-table-column>

              <o-table-column
                field="CreditorName"
                label="Creditor Name"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="withdrawnDebt.CreditorName"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.CreditorName }}
                </template>
              </o-table-column>

              <o-table-column
                field="OriginalBalanceAmount"
                label="Original Balance Amount"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="withdrawnDebt.OriginalBalanceAmount"
                      placeholder=""
                    />
                  </div>
                </template>
                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.OriginalBalanceAmount }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ formatMoney(props.row.OriginalBalanceAmount) }}
                  </span>
                </template>
              </o-table-column>

              <o-table-column
                field="PayoutPercentage"
                label="Payout Percentage"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="withdrawnDebt.PayoutPercentage"
                      placeholder=""
                    />
                  </div>
                </template>
                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.PayoutPercentage }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ props.row.PayoutPercentage }} %
                  </span>
                </template>
              </o-table-column>

              <o-table-column
                field="AmounttobeRecouped"
                label="Amount to be Recouped"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="withdrawnDebt.AmounttobeRecoupedFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.AmounttobeRecouped }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ formatMoney(props.row.AmounttobeRecouped) }}
                  </span>
                </template>
              </o-table-column>
            </o-table>
            <section
              v-if="filteredWithdrawnDebt.length == 0"
              class="section"
              style="text-align: center"
            >
              <div class="content has-text-grey has-text-centered mb-4">
                <p class="noMargin">
                  <i
                    style="font-size: 24px; color: #939393"
                    class="ri-question-line"
                  ></i>
                </p>
                <p class="noMargin">Nothing here.</p>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div>
        <div class="datagrid-header" @click="isOpen(5)">
          <span v-if="toggleDivs[5]">
            <i class="mdi mdi-chevron-up mdi-24px"></i>
          </span>
          <span v-else>
            <i class="mdi mdi-chevron-down mdi-24px"></i>
          </span>
          <span style="color: #2c3e50">New Debt </span>
        </div>

        <div class="datagrid-table gcsTabsTableScroll">
          <div v-if="toggleDivs[5]">
            <o-table :data="filteredCustomerAddsaDebt">
              <o-table-column
                field="ClientId"
                label="Client ID"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="customerAddsaDebt.ClientIdFilter"
                      placeholder=""
                      v-myMask="{
                        alias: 'numeric',
                        rightAlign: false,
                      }"
                    />
                  </div>
                </template>

                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.ClientId }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ addPrefixToCustomerId(props.row.ClientId) }}
                  </span>
                </template>
              </o-table-column>

              <o-table-column
                field="ClientName"
                label="Client Name"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="customerAddsaDebt.ClientNameFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.ClientName }}
                </template>
              </o-table-column>

              <o-table-column
                field="OfficeName"
                label="Office"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="customerAddsaDebt.OfficeNameFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.OfficeName }}
                </template>
              </o-table-column>

              <o-table-column
                field="DateDebtwasAdded"
                label="Date Debt was Added"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="customerAddsaDebt.DateDebtwasAddedFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ formatShortDate(props.row.DateDebtwasAdded) }}
                </template>
              </o-table-column>

              <o-table-column
                field="CreditorName"
                label="Creditor Name"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="customerAddsaDebt.CreditorNameFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.CreditorName }}
                </template>
              </o-table-column>

              <o-table-column
                field="CreditorBalance"
                label="Original Balance Amount"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="customerAddsaDebt.CreditorBalanceFilter"
                      placeholder=""
                    />
                  </div>
                </template>
                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.CreditorBalance }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ formatMoney(props.row.CreditorBalance) }}
                  </span>
                </template>
              </o-table-column>

              <o-table-column
                field="PayoutPercentage"
                label="Payout Percentage"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="customerAddsaDebt.PayoutPercentageFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.PayoutPercentage }}
                </template>
              </o-table-column>

              <o-table-column
                field="PayoutAmount"
                label="Bonus Amount"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="customerAddsaDebt.PayoutAmountFilter"
                      placeholder=""
                    />
                  </div>
                </template>
                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.PayoutAmount }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ formatMoney(props.row.PayoutAmount) }}
                  </span>
                </template>
              </o-table-column>
            </o-table>
            <section
              v-if="filteredCustomerAddsaDebt.length == 0"
              class="section"
              style="text-align: center"
            >
              <div class="content has-text-grey has-text-centered mb-4">
                <p class="noMargin">
                  <i
                    style="font-size: 24px; color: #939393"
                    class="ri-question-line"
                  ></i>
                </p>
                <p class="noMargin">Nothing here.</p>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div>
        <div class="datagrid-header" @click="isOpen(6)">
          <span v-if="toggleDivs[6]">
            <i class="mdi mdi-chevron-up mdi-24px"></i>
          </span>
          <span v-else>
            <i class="mdi mdi-chevron-down mdi-24px"></i>
          </span>
          <span style="color: #2c3e50">Debt Increased</span>
        </div>

        <div class="datagrid-table gcsTabsTableScroll">
          <div v-if="toggleDivs[6]">
            <o-table :data="filteredIncreasedDebt">
              <o-table-column
                field="ClientId"
                label="Client ID"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="increasedDebt.ClientIdFilter"
                      placeholder=""
                      v-myMask="{
                        alias: 'numeric',
                        rightAlign: false,
                      }"
                    />
                  </div>
                </template>

                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.ClientId }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ addPrefixToCustomerId(props.row.ClientId) }}
                  </span>
                </template>
              </o-table-column>

              <o-table-column
                field="ClientName"
                label="Client Name"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="increasedDebt.ClientNameFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.ClientName }}
                </template>
              </o-table-column>

              <o-table-column
                field="OfficeName"
                label="Office"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="increasedDebt.OfficeNameFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.OfficeName }}
                </template>
              </o-table-column>

              <o-table-column
                field="DateDebtwasIncreased"
                label="Date Debt was Increased"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="increasedDebt.DateDebtwasIncreasedFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ formatShortDate(props.row.DateDebtwasIncreased) }}
                </template>
              </o-table-column>

              <o-table-column
                field="CreditorName"
                label="Creditor Name"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="increasedDebt.CreditorNameFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.CreditorName }}
                </template>
              </o-table-column>

              <o-table-column
                field="IncreasedDebtAmount"
                label="Increased Debt Amount"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="increasedDebt.IncreasedDebtAmountFilter"
                      placeholder=""
                    />
                  </div>
                </template>
                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.IncreasedDebtAmount }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ formatMoney(props.row.IncreasedDebtAmount) }}
                  </span>
                </template>
              </o-table-column>

              <o-table-column
                field="PayoutPercentage"
                label="Payout Percentage"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="increasedDebt.PayoutPercentageFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.PayoutPercentage }}
                </template>
              </o-table-column>

              <o-table-column
                field="BonusAmount"
                label="Bonus Amount"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="increasedDebt.BonusAmountFilter"
                      placeholder=""
                    />
                  </div>
                </template>
                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.BonusAmount }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ formatMoney(props.row.BonusAmount) }}
                  </span>
                </template>
              </o-table-column>
            </o-table>
            <section
              v-if="filteredIncreasedDebt.length == 0"
              class="section"
              style="text-align: center"
            >
              <div class="content has-text-grey has-text-centered mb-4">
                <p class="noMargin">
                  <i
                    style="font-size: 24px; color: #939393"
                    class="ri-question-line"
                  ></i>
                </p>
                <p class="noMargin">Nothing here.</p>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div>
        <div class="datagrid-header" @click="isOpen(7)">
          <span v-if="toggleDivs[7]">
            <i class="mdi mdi-chevron-up mdi-24px"></i>
          </span>
          <span v-else>
            <i class="mdi mdi-chevron-down mdi-24px"></i>
          </span>
          <span style="color: #2c3e50">Debt Decreased</span>
        </div>
        <div class="datagrid-table gcsTabsTableScroll">
          <div v-if="toggleDivs[7]">
            <o-table :data="filteredDecreasedDebt">
              <o-table-column
                field="ClientId"
                label="Client ID"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="decreasedDebt.ClientIdFilter"
                      placeholder=""
                      v-myMask="{
                        alias: 'numeric',
                        rightAlign: false,
                      }"
                    />
                  </div>
                </template>

                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.ClientId }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ addPrefixToCustomerId(props.row.ClientId) }}
                  </span>
                </template>
              </o-table-column>

              <o-table-column
                field="ClientName"
                label="Client Name"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="decreasedDebt.ClientNameFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.ClientName }}
                </template>
              </o-table-column>

              <o-table-column
                field="OfficeName"
                label="Office"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="decreasedDebt.OfficeNameFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.OfficeName }}
                </template>
              </o-table-column>

              <o-table-column
                field="DateDebtwasDecreased"
                label="Date Debt was Decreased"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="decreasedDebt.DateDebtwasDecreasedFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ formatShortDate(props.row.DateDebtwasDecreased) }}
                </template>
              </o-table-column>

              <o-table-column
                field="CreditorName"
                label="Creditor Name"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="decreasedDebt.CreditorNameFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.CreditorName }}
                </template>
              </o-table-column>

              <o-table-column
                field="DecreasedDebtAmount"
                label="Decreased Debt Amount"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="decreasedDebt.DecreasedDebtAmountFilter"
                      placeholder=""
                    />
                  </div>
                </template>
                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.CreditorBalance }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ formatMoney(props.row.DecreasedDebtAmount) }}
                  </span>
                </template>
              </o-table-column>

              <o-table-column
                field="PayoutPercentage"
                label="Payout Percentage"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="decreasedDebt.PayoutPercentageFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.PayoutPercentage }}
                </template>
              </o-table-column>

              <o-table-column
                field="PayoutAmount"
                label="Amount to be Recouped"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="decreasedDebt.PayoutAmountFilter"
                      placeholder=""
                    />
                  </div>
                </template>
                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.PayoutAmount }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ formatMoney(props.row.PayoutAmount) }}
                  </span>
                </template>
              </o-table-column>
            </o-table>
            <section
              v-if="filteredDecreasedDebt.length == 0"
              class="section"
              style="text-align: center"
            >
              <div class="content has-text-grey has-text-centered mb-4">
                <p class="noMargin">
                  <i
                    style="font-size: 24px; color: #939393"
                    class="ri-question-line"
                  ></i>
                </p>
                <p class="noMargin">Nothing here.</p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import globalTypes from "@/store/types";

export default {
  name: "SnfPayoutReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      isValidations: false,
      toggleDivs: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
      },
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      reportLoading: false,
      reportData: [],

      ////////////
      firstEnrollmentPayoutInitSummary: {
        ClientId: "",
        ClientName: "",
        OfficeName: "",
        FirstClearedDraftDate: "",
        EnrolledDebt: "",
        PayoutPercentage: "",
        PayoutAmount: "",
        CatchUpAmount: "",
      },
      firstEnrollmentPayout: {
        ClientIdFilter: "",
        ClientNameFilter: "",
        OfficeNameFilter: "",
        FirstClearedDraftDateFilter: "",
        EnrolledDebtFilter: "",
        PayoutPercentageFilter: "",
        PayoutAmountFilter: "",
        CatchUpAmountFilter: "",
      },
      ////////////
      settlementFeePayoutInitSummary: {
        ClientId: "",
        ClientName: "",
        OfficeName: "",
        SettlementFeeClearedDate: "",
        CreditorName: "",
        SettlementPayoutPercentage: "",
        FeeAmount: "",
        TotalCatchUpAmount: "",
        CatchUpRemaining: "",
        SettlementFeePayout: "",
      },
      settlementFeePayout: {
        ClientIdFilter: "",
        ClientNameFilter: "",
        OfficeNameFilter: "",
        SettlementFeeClearedDateFilter: "",
        CreditorNameFilter: "",
        SettlementPayoutPercentageFilter: "",
        FeeAmountFilter: "",
        TotalCatchUpAmountFilter: "",
        CatchUpRemainingFilter: "",
        SettlementFeePayoutFilter: "",
      },
      ////////////
      cancelledCustomersInitSummary: {
        ClientId: "",
        ClientName: "",
        OfficeName: "",
        CancelledDate: "",
        OriginalEnrolledDebtAmount: "",
        PayoutPercentage: "",
        AmounttobeRecouped: "",
      },
      cancelledCustomers: {
        ClientIdFilter: "",
        ClientNameFilter: "",
        OfficeNameFilter: "",
        CancelledDateFilter: "",
        OriginalEnrolledDebtAmountFilter: "",
        PayoutPercentageFilter: "",
        AmounttobeRecoupedFilter: "",
      },
      ////////////
      withdrawnDebtInitSummary: {
        ClientId: "",
        ClientName: "",
        OfficeName: "",
        DateDebtwasRemoved: "",
        CreditorName: "",
        OriginalBalanceAmount: "",
        PayoutPercentage: "",
        AmounttobeRecouped: "",
      },
      withdrawnDebt: {
        ClientIdFilter: "",
        ClientNameFilter: "",
        OfficeNameFilter: "",
        DateDebtwasRemovedFilter: "",
        CreditorNameFilter: "",
        OriginalBalanceAmountFilter: "",
        PayoutPercentageFilter: "",
        AmounttobeRecoupedFilter: "",
      },
      ////////////
      customerAddsaDebtSummary: {
        ClientId: "",
        ClientName: "",
        OfficeName: "",
        DateDebtwasAdded: "",
        CreditorName: "",
        CreditorBalance: "",
        PayoutPercentage: "",
        PayoutAmount: "",
      },
      customerAddsaDebt: {
        ClientIdFilter: "",
        ClientNameFilter: "",
        OfficeNameFilter: "",
        DateDebtwasAddedFilter: "",
        CreditorNameFilter: "",
        CreditorBalanceFilter: "",
        PayoutPercentageFilter: "",
        PayoutAmountFilter: "",
      },
      ////////////
      increasedDebtSummary: {
        ClientId: "",
        ClientName: "",
        OfficeName: "",
        DateDebtwasIncreased: "",
        CreditorName: "",
        IncreasedDebtAmount: "",
        PayoutPercentage: "",
        BonusAmount: "",
      },
      increasedDebt: {
        ClientIdFilter: "",
        ClientNameFilter: "",
        OfficeNameFilter: "",
        DateDebtwasIncreasedFilter: "",
        CreditorNameFilter: "",
        IncreasedDebtAmountFilter: "",
        PayoutPercentageFilter: "",
        BonusAmountFilter: "",
      },
      ////////////
      decreasedDebtSummary: {
        ClientId: "",
        ClientName: "",
        OfficeName: "",
        DateDebtwasDecreased: "",
        CreditorName: "",
        DecreasedDebtAmount: "",
        PayoutPercentage: "",
        PayoutAmount: "",
      },
      decreasedDebt: {
        ClientIdFilter: "",
        ClientNameFilter: "",
        OfficeNameFilter: "",
        DateDebtwasDecreasedFilter: "",
        CreditorNameFilter: "",
        DecreasedDebtAmountFilter: "",
        PayoutPercentageFilter: "",
        PayoutAmountFilter: "",
      },
      ////////////
    };
  },
  computed: mapState({
    snfPayoutList: (state) => state.reports.snfPayoutList,

    filteredFirstEnrollmentPayout() {
      let rtn = [];
      if (
        !this.snfPayoutList ||
        !Array.isArray(this.snfPayoutList.FirstEnrollmentPayout)
      ) {
        this.reportData = [];
        return rtn;
      }
      let tmpList =
        this.snfPayoutList.FirstEnrollmentPayout.length > 0
          ? this.snfPayoutList.FirstEnrollmentPayout.filter((item) => {
              return (
                this.filterString(
                  item.ClientId,
                  this.firstEnrollmentPayout.ClientIdFilter
                ) &&
                this.filterString(
                  item.ClientName,
                  this.firstEnrollmentPayout.ClientNameFilter
                ) &&
                this.filterString(
                  item.OfficeName,
                  this.firstEnrollmentPayout.OfficeNameFilter
                ) &&
                new Date(item.FirstClearedDraftDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(
                    this.firstEnrollmentPayout.FirstClearedDraftDateFilter
                  ) > -1 &&
                this.filterString(
                  item.EnrolledDebt,
                  this.firstEnrollmentPayout.EnrolledDebtFilter
                ) &&
                this.filterString(
                  item.PayoutPercentage,
                  this.firstEnrollmentPayout.PayoutPercentageFilter
                ) &&
                this.filterString(
                  item.PayoutAmount,
                  this.firstEnrollmentPayout.PayoutAmountFilter
                ) &&
                this.filterString(
                  item.CatchUpAmount,
                  this.firstEnrollmentPayout.CatchUpAmountFilter
                )
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      let totalPayoutAmount = 0.0;
      for (let item of tmpList) {
        totalPayoutAmount += parseFloat(item.PayoutAmount);
      }
      let totalAmountStr = totalPayoutAmount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });

      this.firstEnrollmentPayoutInitSummary.ClientId =
        "Total: " + totalCustomer;
      this.firstEnrollmentPayoutInitSummary.PayoutAmount = totalAmountStr;

      this.firstEnrollmentPayoutInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.firstEnrollmentPayoutInitSummary);
      this.reportData = rtn;

      return rtn;
    },

    filteredSettlementFeePayout() {
      let rtn = [];
      if (
        !this.snfPayoutList ||
        !Array.isArray(this.snfPayoutList.SettlementFeePayout)
      ) {
        this.reportData = [];
        return rtn;
      }
      let tmpList =
        this.snfPayoutList.SettlementFeePayout.length > 0
          ? this.snfPayoutList.SettlementFeePayout.filter((item) => {
              return (
                this.filterString(
                  item.ClientId,
                  this.settlementFeePayout.ClientIdFilter
                ) &&
                this.filterString(
                  item.ClientName,
                  this.settlementFeePayout.ClientNameFilter
                ) &&
                this.filterString(
                  item.OfficeName,
                  this.settlementFeePayout.OfficeNameFilter
                ) &&
                new Date(item.SettlementFeeClearedDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(
                    this.settlementFeePayout.SettlementFeeClearedDateFilter
                  ) > -1 &&
                this.filterString(
                  item.CreditorName,
                  this.settlementFeePayout.CreditorNameFilter
                ) &&
                this.filterString(
                  item.SettlementPayoutPercentage,
                  this.settlementFeePayout.SettlementPayoutPercentageFilter
                ) &&
                this.filterString(
                  item.FeeAmount,
                  this.settlementFeePayout.FeeAmountFilter
                ) &&
                this.filterString(
                  item.TotalCatchUpAmount,
                  this.settlementFeePayout.TotalCatchUpAmountFilter
                ) &&
                this.filterString(
                  item.CatchUpRemaining,
                  this.settlementFeePayout.CatchUpRemainingFilter
                ) &&
                this.filterString(
                  item.SettlementFeePayout,
                  this.settlementFeePayout.SettlementFeePayoutFilter
                )
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      let totalFeeAmount = 0.0;
      for (let item of tmpList) {
        totalFeeAmount += parseFloat(item.FeeAmount);
      }
      let totalAmountStr = totalFeeAmount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });

      this.settlementFeePayoutInitSummary.ClientId = "Total: " + totalCustomer;
      this.settlementFeePayoutInitSummary.FeeAmount = totalAmountStr;

      this.settlementFeePayoutInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.settlementFeePayoutInitSummary);
      this.reportData = rtn;

      return rtn;
    },

    filteredCancelledCustomers() {
      let rtn = [];
      if (
        !this.snfPayoutList ||
        !Array.isArray(this.snfPayoutList.CancelledCustomers)
      ) {
        this.reportData = [];
        return rtn;
      }
      let tmpList =
        this.snfPayoutList.CancelledCustomers.length > 0
          ? this.snfPayoutList.CancelledCustomers.filter((item) => {
              return (
                this.filterString(
                  item.ClientId,
                  this.cancelledCustomers.ClientIdFilter
                ) &&
                this.filterString(
                  item.ClientName,
                  this.cancelledCustomers.ClientNameFilter
                ) &&
                this.filterString(
                  item.OfficeName,
                  this.cancelledCustomers.OfficeNameFilter
                ) &&
                new Date(item.CancelledDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.cancelledCustomers.CancelledDateFilter) > -1 &&
                this.filterString(
                  item.OriginalEnrolledDebtAmount,
                  this.cancelledCustomers.OriginalEnrolledDebtAmountFilter
                ) &&
                this.filterString(
                  item.PayoutPercentage,
                  this.cancelledCustomers.PayoutPercentageFilter
                ) &&
                this.filterString(
                  item.AmounttobeRecouped,
                  this.cancelledCustomers.AmounttobeRecoupedFilter
                )
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      let totalAmounttobeRecouped = 0.0;
      for (let item of tmpList) {
        totalAmounttobeRecouped += parseFloat(item.AmounttobeRecouped);
      }
      let totalAmountStr = totalAmounttobeRecouped.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });

      this.cancelledCustomersInitSummary.ClientId = "Total: " + totalCustomer;
      this.cancelledCustomersInitSummary.AmounttobeRecouped = totalAmountStr;

      this.cancelledCustomersInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.cancelledCustomersInitSummary);
      this.reportData = rtn;

      return rtn;
    },

    filteredWithdrawnDebt() {
      let rtn = [];
      if (
        !this.snfPayoutList ||
        !Array.isArray(this.snfPayoutList.WithdrawnDebt)
      ) {
        this.reportData = [];
        return rtn;
      }
      let tmpList =
        this.snfPayoutList.WithdrawnDebt.length > 0
          ? this.snfPayoutList.WithdrawnDebt.filter((item) => {
              return (
                this.filterString(
                  item.ClientId,
                  this.withdrawnDebt.ClientIdFilter
                ) &&
                this.filterString(
                  item.ClientName,
                  this.withdrawnDebt.ClientNameFilter
                ) &&
                this.filterString(
                  item.OfficeName,
                  this.withdrawnDebt.OfficeNameFilter
                ) &&
                this.filterString(
                  item.DateDebtwasRemoved,
                  this.withdrawnDebt.DateDebtwasRemovedFilter
                ) &&
                this.filterString(
                  item.CreditorName,
                  this.withdrawnDebt.CreditorNameFilter
                ) &&
                this.filterString(
                  item.OriginalBalanceAmount,
                  this.withdrawnDebt.OriginalBalanceAmountFilter
                ) &&
                this.filterString(
                  item.PayoutPercentage,
                  this.withdrawnDebt.PayoutPercentageFilter
                ) &&
                this.filterString(
                  item.AmounttobeRecouped,
                  this.withdrawnDebt.AmounttobeRecoupedFilter
                )
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      let totalAmounttobeRecouped = 0.0;
      for (let item of tmpList) {
        totalAmounttobeRecouped += parseFloat(item.AmounttobeRecouped);
      }
      let totalAmountStr = totalAmounttobeRecouped.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });

      this.withdrawnDebtInitSummary.ClientId = "Total: " + totalCustomer;
      this.withdrawnDebtInitSummary.AmounttobeRecouped = totalAmountStr;

      this.withdrawnDebtInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.withdrawnDebtInitSummary);
      this.reportData = rtn;

      return rtn;
    },

    filteredCustomerAddsaDebt() {
      let rtn = [];
      if (
        !this.snfPayoutList ||
        !Array.isArray(this.snfPayoutList.CustomerAddsaDebt)
      ) {
        this.reportData = [];
        return rtn;
      }
      let tmpList =
        this.snfPayoutList.CustomerAddsaDebt.length > 0
          ? this.snfPayoutList.CustomerAddsaDebt.filter((item) => {
              return (
                this.filterString(
                  item.ClientId,
                  this.customerAddsaDebt.ClientIdFilter
                ) &&
                this.filterString(
                  item.ClientName,
                  this.customerAddsaDebt.ClientNameFilter
                ) &&
                this.filterString(
                  item.OfficeName,
                  this.customerAddsaDebt.OfficeNameFilter
                ) &&
                this.filterString(
                  item.DateDebtwasAdded,
                  this.customerAddsaDebt.DateDebtwasAddedFilter
                ) &&
                this.filterString(
                  item.CreditorName,
                  this.customerAddsaDebt.CreditorNameFilter
                ) &&
                this.filterString(
                  item.CreditorBalance,
                  this.customerAddsaDebt.CreditorBalanceFilter
                ) &&
                this.filterString(
                  item.PayoutPercentage,
                  this.customerAddsaDebt.PayoutPercentageFilter
                ) &&
                this.filterString(
                  item.PayoutAmount,
                  this.customerAddsaDebt.PayoutAmountFilter
                )
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      let totalPayoutAmount = 0.0;
      for (let item of tmpList) {
        totalPayoutAmount += parseFloat(item.PayoutAmount);
      }
      let totalAmountStr = totalPayoutAmount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });

      this.customerAddsaDebtSummary.ClientId = "Total: " + totalCustomer;
      this.customerAddsaDebtSummary.PayoutAmount = totalAmountStr;

      this.customerAddsaDebtSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.customerAddsaDebtSummary);
      this.reportData = rtn;

      return rtn;
    },

    filteredIncreasedDebt() {
      let rtn = [];
      if (
        !this.snfPayoutList ||
        !Array.isArray(this.snfPayoutList.IncreasedDebt)
      ) {
        this.reportData = [];
        return rtn;
      }
      let tmpList =
        this.snfPayoutList.IncreasedDebt.length > 0
          ? this.snfPayoutList.IncreasedDebt.filter((item) => {
              return (
                this.filterString(
                  item.ClientId,
                  this.increasedDebt.ClientIdFilter
                ) &&
                this.filterString(
                  item.ClientName,
                  this.increasedDebt.ClientNameFilter
                ) &&
                this.filterString(
                  item.OfficeName,
                  this.increasedDebt.OfficeNameFilter
                ) &&
                this.filterString(
                  item.DateDebtwasIncreased,
                  this.increasedDebt.DateDebtwasIncreasedFilter
                ) &&
                this.filterString(
                  item.CreditorName,
                  this.increasedDebt.CreditorNameFilter
                ) &&
                this.filterString(
                  item.IncreasedDebtAmount,
                  this.increasedDebt.IncreasedDebtAmountFilter
                ) &&
                this.filterString(
                  item.PayoutPercentage,
                  this.increasedDebt.PayoutPercentageFilter
                ) &&
                this.filterString(
                  item.BonusAmount,
                  this.increasedDebt.BonusAmountFilter
                )
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      let totalBonusAmount = 0.0;
      for (let item of tmpList) {
        totalBonusAmount += parseFloat(item.BonusAmount);
      }
      let totalAmountStr = totalBonusAmount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });

      this.increasedDebtSummary.ClientId = "Total: " + totalCustomer;
      this.increasedDebtSummary.BonusAmount = totalAmountStr;

      this.increasedDebtSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.increasedDebtSummary);
      this.reportData = rtn;

      return rtn;
    },

    filteredDecreasedDebt() {
      let rtn = [];
      if (
        !this.snfPayoutList ||
        !Array.isArray(this.snfPayoutList.DecreasedDebt)
      ) {
        this.reportData = [];
        return rtn;
      }
      let tmpList =
        this.snfPayoutList.DecreasedDebt.length > 0
          ? this.snfPayoutList.DecreasedDebt.filter((item) => {
              return (
                this.filterString(
                  item.ClientId,
                  this.decreasedDebt.ClientIdFilter
                ) &&
                this.filterString(
                  item.ClientName,
                  this.decreasedDebt.ClientNameFilter
                ) &&
                this.filterString(
                  item.OfficeName,
                  this.decreasedDebt.OfficeNameFilter
                ) &&
                this.filterString(
                  item.DateDebtwasDecreased,
                  this.decreasedDebt.DateDebtwasDecreasedFilter
                ) &&
                this.filterString(
                  item.CreditorName,
                  this.decreasedDebt.CreditorNameFilter
                ) &&
                this.filterString(
                  item.DecreasedDebtAmount,
                  this.decreasedDebt.DecreasedDebtAmountFilter
                ) &&
                this.filterString(
                  item.PayoutPercentage,
                  this.decreasedDebt.PayoutPercentageFilter
                ) &&
                this.filterString(
                  item.PayoutAmount,
                  this.decreasedDebt.PayoutAmountFilter
                )
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      let totalPayoutAmount = 0.0;
      for (let item of tmpList) {
        totalPayoutAmount += parseFloat(item.PayoutAmount);
      }
      let totalAmountStr = totalPayoutAmount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });

      this.decreasedDebtSummary.ClientId = "Total: " + totalCustomer;
      this.decreasedDebtSummary.PayoutAmount = totalAmountStr;

      this.decreasedDebtSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.decreasedDebtSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    await this.$swal
      .fire({
        title: "Are you sure?",
        text: "The payout automation feature provided by the system is for testing purposes only. Global Zenith makes no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of the feature or information on this system.  Upon completion of your testing of this feature, Customer will acknowledge and represent that the feature is acceptable to “go live” before implementation.  Under no circumstance, prior to or after the go live date will Global Zenith have any liability to Customer for any loss or damage of any kind incurred as a result of the use of this feature or reliance on the information provided therefrom.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Agree",
        cancelButtonText: "Decline"
      })
      .then(async (dialog) => {
        if (dialog.value) {
          this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
        Message:"{module: 'snf payout report', action: 'Agree'}"
        });
          this.isValidations = true;
          this.reportsPanelHeight = this.setReportGridHeight(
            window.innerHeight,
            document.getElementById("reportsSummary")
          );
          this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

          if (
            this.userInfo != null &&
            this.userInfo.customerIdPrefix != null &&
            this.userInfo.customerIdPrefix.length > 0
          ) {
            this.customerPrefix = this.userInfo.customerIdPrefix;
          }

          await this.sleep(1000);
          this.fixStickyHeaders();
        }
        else{
          this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
        Message:"{module: 'snf payout report', action: 'Decline'}"
        });
        }
      });
  },
  methods: {
    isOpen(divNumber) {
      this.toggleDivs[divNumber] = !this.toggleDivs[divNumber];
    },
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_SNF_PAYOUT_REPORT, value);
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
  },
};
</script>

<style scoped>
.datagrid-wrapper {
  display: flex;
  flex-direction: column;
}

.datagrid-header {
  background-color: #cde5f1;
  padding: 10px;
  display: flex;
  align-items: center;
  font-weight: 600;
  border-bottom: 1px #f7f7f7 solid;
  cursor: pointer;
  width: 100%;
}

.gcsTabsTableScroll {
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
}

.datagrid-table {
  border: 1px solid #e4e4e4;
  border-collapse: collapse;
  border-radius: 8px;
}

.datagrid-table thead {
  background-color: #ffffff;
  color: #2a404c;
}

.datagrid-table th {
  padding: 15px;
  border-top-color: #ffffff;
  border-bottom: 1px solid #f3eded;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.datagrid-table tbody tr {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #2a404c;
}

.datagrid-table tbody td {
  padding: 11px 15px;
  border-top: none;
  vertical-align: middle;
}

.datagrid-table tbody tr:hover,
.datagrid-table tbody tr:active {
  background-color: #daffe3;
}
</style>
