<template>
  <div
    class="col-md-12 newPanelWrapper noMargin cprp-mediaScreen"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 15px;
      padding-right: 5px;
    "
  >
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 30%">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="popupNameLabel" v-touch:drag="dragPanel">
      <label style="font-size: 15px">Credit Report</label>
    </div>

    <div
      v-touch:tap="onTapItem"
      class="closeButton"
      v-on:click.prevent.stop="closePanel"
    >
      <i class="fas fa-window-close"></i>
    </div>

    <div class="col-md-12 noPadding" v-touch:drag="dragPanel">
      <span
        v-if="creditScore != ''"
        style="padding: 10px; font-size: 1.2em"
        class="badge btn-info"
        >Credit Score: {{ creditScore }}</span
      >

      <span
        v-if="sourceId == 1"
        style="padding: 10px; font-size: 1.2em"
        class="badge btn-info ml-2"
      >
        Utilization: {{ utilization }}</span
      >

      <span
        v-if="revolvingCreditUtilization != ''"
        style="padding: 10px; font-size: 1.2em"
        class="badge btn-info ml-2"
        >Revolving Credit Utilization: {{ revolvingCreditUtilization }}</span
      >

      <span
        v-if="this.creditReportData.CustomerId"
        class="badge btn-info ml-2"
        style="
          background-color: rgb(235, 235, 235) !important;
          padding: 10px;
          font-size: 1.2em;
          color: #000;
        "
      >
        Customer ID: {{ this.creditReportData.CustomerId }}
      </span>
      <button
        v-if="checkAuth(6367) && creditorList && creditorList.length > 0"
        type="button"
        class="btn btn-md generalButton mt-1 ml-1 mr-1 addAllButton"
        @click="handleAddAllCreditors()"
      >
        Add All Creditors
      </button>
    </div>

    <perfect-scrollbar
      style="width: 100%; max-height: 60vh; overflow-x: hidden !important"
      v-touch:press="swipePanel"
      v-touch:swipe="swipePanel"
      v-touch:hold="holdPanel"
    >
      <div class="col-md-12" style="margin-left: -12px">
        <div v-if="borrowerInfo">
          <label
            class="generalLabel"
            style="font-size: 1.6em; margin: 0 !important"
            >Personal Information</label
          >

          <div class="col-md-12 row noPadding">
            <div class="col-md-5 noPadding" v-if="borrowerInfo._UnparsedName">
              <label class="valueLabel"
                >Full Name: {{ borrowerInfo._UnparsedName }}</label
              >
            </div>
            <div class="col-md-3 noPadding" v-if="borrowerInfo._BirthDate">
              <label class="valueLabel"
                >Birth Date: {{ borrowerInfo._BirthDate }}</label
              >
            </div>
            <div class="col-md-3 noPadding" v-if="borrowerInfo._SSN">
              <label class="valueLabel">SSN: {{ borrowerInfo._SSN }}</label>
            </div>
          </div>
          <div class="col-md-12 row noPadding" v-if="borrowerInfo._RESIDENCE">
            <div class="col-md-12 noPadding noMargin">
              <label class="headerLabel">Addresses:</label>
              <br />
              <div
                v-for="item in borrowerInfo._RESIDENCE"
                :key="item.BorrowerID"
              >
                <label class="valueLabel">
                  {{
                    parseInt(borrowerInfo._RESIDENCE?.indexOf(item) + 1) +
                    ") " +
                    getPersonelAdresses(item)
                  }}
                </label>
              </div>
            </div>
            <div
              class="col-md-12"
              style="margin-left: -12px; border-bottom: 2px solid #555"
            ></div>
          </div>
        </div>

        <div v-if="employerInfo && employerInfo.length > 0">
          <label class="generalLabel" style="font-size: 1.6em"
            >Employer Info</label
          >

          <div class="col-md-12 row noPadding noMargin">
            <div class="col-md-3 noPadding">
              <label
                v-if="isExistsInList(employerInfo, '_Name')"
                class="headerLabel"
                >Name</label
              >
              <br />
            </div>
            <div class="col-md-3 noPadding">
              <label
                v-if="isExistsInList(employerInfo, 'EmploymentReportedDate')"
                class="headerLabel"
                >Reported Date</label
              >
              <br />
            </div>
            <div class="col-md-3 noPadding">
              <label
                v-if="
                  isExistsInList(employerInfo, 'EmploymentPositionDescription')
                "
                class="headerLabel"
                >Position</label
              >
              <br />
            </div>
            <div class="col-md-3 noPadding">
              <label
                v-if="
                  isExistsInList(employerInfo, 'EmploymentCurrentIndicator')
                "
                class="headerLabel"
                >Status</label
              >
              <br />
            </div>
          </div>
          <div
            v-for="item in employerInfo"
            :key="item._Name + item.EmploymentReportedDate"
            class="col-md-12 row noPadding noMargin"
          >
            <div class="col-md-3 noPadding noMargin">
              <label v-if="item._Name" class="valueLabel">{{
                item._Name
              }}</label>
              <br />
            </div>
            <div class="col-md-3 noPadding noMargin">
              <label v-if="item.EmploymentReportedDate" class="valueLabel">{{
                item.EmploymentReportedDate
              }}</label>
              <br />
            </div>
            <div class="col-md-3 noPadding noMargin">
              <label
                v-if="item.EmploymentPositionDescription"
                class="valueLabel"
                >{{ item.EmploymentPositionDescription }}</label
              >
              <br />
            </div>
            <div class="col-md-3 noPadding noMargin">
              <label
                v-if="item.EmploymentCurrentIndicator"
                class="valueLabel"
                >{{
                  item.EmploymentCurrentIndicator == "Y" ? "Current" : "Prior"
                }}</label
              >
              <br />
            </div>
          </div>
          <div
            class="col-md-12"
            style="margin-left: -12px; border-bottom: 2px solid #555"
          ></div>
        </div>
      </div>
      <div
        v-for="item in creditorList"
        class="col-md-12 mb-2"
        :key="item.CreditLiabilityID"
      >
        <div class="col-md-12" style="margin-left: -12px">
          <div class="col-md-12 noPadding d-flex">
            <label
              v-if="creditorList && creditorList.length > 0"
              class="generalLabel"
              style="font-size: 1.6em; margin-left: -12px"
              >{{
                creditorList.indexOf(item) +
                1 +
                ". " +
                (item._CREDITOR._Name && item._CREDITOR._Name != ""
                  ? item._CREDITOR._Name
                  : "N/A")
              }}</label
            >

            <label
              v-if="
                item &&
                item._OriginalCreditorName &&
                item._OriginalCreditorName.length > 0
              "
              class="generalLabel ml-3"
              style="font-size: 1.4em; color: #e71a33"
              >Original: {{ item._OriginalCreditorName }}</label
            >
            <div v-if="checkAuth(6354)">
              <button
                v-if="showButton && showAddCreditorButton(item)"
                type="button"
                :disabled="isInserting"
                class="btn generalButton ml-3 mr-1 creditorsAddButton"
                @click="handleCreditorInsert(item)"
                v-touch:tap="onTapAddCreditor(item)"
              >
                Add to Creditors
              </button>
            </div>
          </div>

          <div class="row" style="margin: 0 0 0 -12px">
            <div
              v-if="
                item._CURRENT_RATING &&
                (item._CURRENT_RATING._Code || item._CURRENT_RATING._Type)
              "
              class="d-flex mr-2"
              style="flex-direction: column; align-items: center"
            >
              <span
                style="
                  padding: 5px 10px;
                  margin: 5px 0;
                  background-color: #ebebeb !important;
                "
                class="badge"
              >
                <label class="generalLabel" style="font-size: 1.3em"
                  >Current Rating:
                </label>
                <label
                  class="generalLabel"
                  style="margin: 0 5px; font-size: 1.8em; font-weight: bold"
                  >{{ item._CURRENT_RATING._Code }}</label
                >
                <label class="generalLabel" style="font-size: 1.3em">
                  {{ item._CURRENT_RATING._Type }}</label
                >
              </span>
            </div>
            <div
              v-if="
                item._HIGHEST_ADVERSE_RATING &&
                (item._HIGHEST_ADVERSE_RATING._Code ||
                  item._HIGHEST_ADVERSE_RATING._Type)
              "
              class="d-flex mr-3"
              style="flex-direction: column; align-items: center"
            >
              <span
                style="
                  padding: 5px 10px;
                  margin: 5px 0;
                  background-color: #ebebeb !important;
                "
                class="badge"
              >
                <label class="generalLabel" style="font-size: 1.3em"
                  >Highest Adverse Rating:
                </label>
                <label
                  class="generalLabel"
                  style="margin: 0 5px; font-size: 1.8em; font-weight: bold"
                  >{{ item._HIGHEST_ADVERSE_RATING._Code }}</label
                >
                <label class="generalLabel" style="font-size: 1.3em">
                  {{ item._HIGHEST_ADVERSE_RATING._Type }}</label
                >
              </span>
            </div>
            <div
              v-if="
                item._MOST_RECENT_ADVERSE_RATING &&
                (item._MOST_RECENT_ADVERSE_RATING._Code ||
                  item._MOST_RECENT_ADVERSE_RATING._Type)
              "
              class="d-flex mr-3"
              style="flex-direction: column; align-items: center"
            >
              <span
                style="
                  padding: 5px 10px;
                  margin: 5px 0;
                  background-color: #ebebeb !important;
                "
                class="badge"
              >
                <label class="generalLabel" style="font-size: 1.3em"
                  >Most Recent Adverse Rating:
                </label>
                <label
                  class="generalLabel"
                  style="margin: 0 5px; font-size: 1.8em; font-weight: bold"
                  >{{ item._MOST_RECENT_ADVERSE_RATING._Code }}</label
                >
                <label class="generalLabel" style="font-size: 1.3em">
                  {{ item._MOST_RECENT_ADVERSE_RATING._Type }}</label
                >
              </span>
            </div>
          </div>

          <div class="row" v-if="item && item._CREDITOR">
            <div class="col-md-3 noPadding">
              <label class="headerLabel">Creditor Name</label>
              <br />
              <label v-if="item._CREDITOR._Name" class="valueLabel"
                >{{ item._CREDITOR._Name }}
              </label>
            </div>
            <div v-if="item._CREDITOR._Phone" class="col-md-3 noPadding">
              <label class="headerLabel">Phone Number</label>
              <br />
              <label class="valueLabel">{{
                item._CREDITOR._Phone && !isNaN(item._CREDITOR._Phone)
                  ? tryFormatPhone(item._CREDITOR._Phone)
                  : ""
              }}</label>
            </div>
            <div
              v-if="
                getCreditorAdress(Array.isArray(item) ? item[0] : item) != ''
              "
              class="col-md-6 noPadding"
            >
              <label class="headerLabel">Adress</label>
              <br />
              <label v-if="item" class="valueLabel">{{
                getCreditorAdress(Array.isArray(item) ? item[0] : item)
              }}</label>
            </div>
          </div>

          <div class="row">
            <div v-if="item.CreditBusinessType" class="col-md-3 noPadding">
              <label class="headerLabel">Credit Business Type</label>
              <br />
              <label :title="item.CreditBusinessType" c class="valueLabel"
                >{{ item.CreditBusinessType }}
              </label>
            </div>
            <div v-if="item.CreditLoanType" class="col-md-3 noPadding">
              <label class="headerLabel">Credit Loan Type</label>
              <br />
              <label :title="item.CreditLoanType" class="valueLabel">{{
                item.CreditLoanType
              }}</label>
            </div>
            <div v-if="item._AccountStatusType" class="col-md-3 noPadding">
              <label class="headerLabel">Status Type</label>
              <br />
              <label :title="item._AccountStatusType" class="valueLabel">{{
                item._AccountStatusType
              }}</label>
            </div>
            <div v-if="item.IsClosedIndicator" class="col-md-3 noPadding">
              <label class="headerLabel">Closed?</label>
              <br />
              <label class="valueLabel">{{ item.IsClosedIndicator }}</label>
            </div>

            <div v-if="item._AccountIdentifier" class="col-md-3 noPadding">
              <label class="headerLabel">Account ID</label>
              <br />
              <label class="valueLabel">{{
                item._AccountIdentifier.slice(0, 50)
              }}</label>
            </div>
            <div v-if="item._AccountOwnershipType" class="col-md-3 noPadding">
              <label class="headerLabel">Acct. Ownership</label>
              <br />
              <label :title="item._AccountOwnershipType" class="valueLabel">{{
                item._AccountOwnershipType
              }}</label>
            </div>
            <div v-if="item._AccountOpenedDate" class="col-md-3 noPadding">
              <label class="headerLabel">Opened Date</label>
              <br />
              <label class="valueLabel">{{ item._AccountOpenedDate }}</label>
            </div>
            <div v-if="item._AccountReportedDate" class="col-md-3 noPadding">
              <label class="headerLabel">Reported Date</label>
              <br />
              <label class="valueLabel">{{ item._AccountReportedDate }}</label>
            </div>

            <div v-if="item._LastActivityDate" class="col-md-3 noPadding">
              <label class="headerLabel">Last Activity Date</label>
              <br />
              <label class="valueLabel">{{ item._LastActivityDate }}</label>
            </div>
            <div v-if="item.LastPaymentDate" class="col-md-3 noPadding">
              <label class="headerLabel">Last Payment Date</label>
              <br />
              <label class="valueLabel">{{ item.LastPaymentDate }}</label>
            </div>
            <div v-if="item._PastDueAmount" class="col-md-3 noPadding">
              <label class="headerLabel">Past Due Amount</label>
              <br />
              <label class="valueLabel">{{
                !isNaN(item._PastDueAmount)
                  ? formatMoney(item._PastDueAmount)
                  : ""
              }}</label>
            </div>
            <div v-if="item._AccountType" class="col-md-3 noPadding">
              <label class="headerLabel">Account Type</label>
              <br />
              <label :title="item._AccountType" class="valueLabel">{{
                item._AccountType
              }}</label>
            </div>

            <div
              v-if="
                item._DerogatoryDataIndicator &&
                item._DerogatoryDataIndicator != ''
              "
              class="col-md-3 noPadding"
            >
              <label class="headerLabel">Deragotary Data?</label>
              <br />
              <label class="valueLabel">{{
                item._DerogatoryDataIndicator
              }}</label>
            </div>
            <div
              v-if="item.IsMortgageIndicator && item.IsMortgageIndicator != ''"
              class="col-md-3 noPadding"
            >
              <label class="headerLabel">Mortgage?</label>
              <br />
              <label class="valueLabel">{{ item.IsMortgageIndicator }}</label>
            </div>
            <div
              v-if="
                item.IsChargeoffIndicator && item.IsChargeoffIndicator != ''
              "
              class="col-md-3 noPadding"
            >
              <label class="headerLabel">Charge Off?</label>
              <br />
              <label class="valueLabel">{{ item.IsChargeoffIndicator }}</label>
            </div>
            <div v-if="item._OriginalBalanceAmount" class="col-md-3 noPadding">
              <label class="headerLabel">Original Amount</label>
              <br />
              <label class="valueLabel">{{
                item._OriginalBalanceAmount &&
                !isNaN(item._OriginalBalanceAmount)
                  ? formatMoney(item._OriginalBalanceAmount)
                  : ""
              }}</label>
            </div>

            <div v-if="item._MonthlyPaymentAmount" class="col-md-3 noPadding">
              <label class="headerLabel">Monthly Pmt. Amount</label>
              <br />
              <label class="valueLabel">{{
                item._MonthlyPaymentAmount && !isNaN(item._MonthlyPaymentAmount)
                  ? formatMoney(item._MonthlyPaymentAmount)
                  : ""
              }}</label>
            </div>
            <div v-if="item._UnpaidBalanceAmount" class="col-md-3 noPadding">
              <label class="headerLabel">Unpaid Balance</label>
              <br />
              <label class="valueLabel">{{
                item._UnpaidBalanceAmount && !isNaN(item._UnpaidBalanceAmount)
                  ? formatMoney(item._UnpaidBalanceAmount)
                  : ""
              }}</label>
            </div>
            <div v-if="item._HighBalanceAmount" class="col-md-3 noPadding">
              <label class="headerLabel">High Balance Amount</label>
              <br />
              <label class="valueLabel">{{
                item._HighBalanceAmount && !isNaN(item._HighBalanceAmount)
                  ? formatMoney(item._HighBalanceAmount)
                  : ""
              }}</label>
            </div>
            <div v-if="item._MonthsReviewedCount" class="col-md-3 noPadding">
              <label class="headerLabel">Months Reviewed</label>
              <br />
              <label class="valueLabel">{{ item._MonthsReviewedCount }}</label>
            </div>

            <div v-if="item._TermsMonthsCount" class="col-md-3 noPadding">
              <label class="headerLabel">Term Months</label>
              <br />
              <label class="valueLabel">{{ item._TermsMonthsCount }}</label>
            </div>
            <div v-if="item._TermsDescription" class="col-md-3 noPadding">
              <label class="headerLabel">Term Description</label>
              <br />
              <label :title="item._TermsDescription" class="valueLabel">{{
                item._TermsDescription
              }}</label>
            </div>
            <div v-if="item._TermsSourceType" class="col-md-3 noPadding">
              <label class="headerLabel">Term Source Type</label>
              <br />
              <label class="valueLabel">{{ item._TermsSourceType }}</label>
            </div>

            <div
              v-if="item._LATE_COUNT && item._LATE_COUNT._30Days"
              class="col-md-3 noPadding"
            >
              <label class="headerLabel">Late Count 30 days</label>
              <br />
              <label class="valueLabel">{{ item._LATE_COUNT._30Days }}</label>
            </div>
            <div
              v-if="item._LATE_COUNT && item._LATE_COUNT._60Days"
              class="col-md-3 noPadding"
            >
              <label class="headerLabel">60 Days</label>
              <br />
              <label class="valueLabel">{{ item._LATE_COUNT._60Days }}</label>
            </div>
            <div
              v-if="item._LATE_COUNT && item._LATE_COUNT._60Days"
              class="col-md-3 noPadding"
            >
              <label class="headerLabel">90 Days</label>
              <br />
              <label class="valueLabel">{{ item._LATE_COUNT._90Days }}</label>
            </div>
          </div>
          <!-- <div v-if="item._PAYMENT_PATTERN" class="row">
            <div v-if="item._PAYMENT_PATTERN._Data" class="col-md-6 noPadding">
              <label class="headerLabel">Payment Pattern</label>
              <br />
              <label class="valueLabel">{{ item._PAYMENT_PATTERN._Data }}</label>
            </div>
            <div v-if="item._PAYMENT_PATTERN._StartDate" class="col-md-6 noPadding">
              <label class="headerLabel">Start Date</label>
              <br />
              <label class="valueLabel">{{ item._PAYMENT_PATTERN._StartDate }}</label>
            </div>
          </div> -->
        </div>
        <div
          class="col-md-12"
          style="margin-left: -12px; border-bottom: 2px solid #555"
        ></div>
      </div>
      <div v-if="creditorList.length == 0" class="col-md-12 noPadding">
        <h1 class="generalLabel" style="font-size: 1.6em; margin: 0 !important">
          Creditors
        </h1>
        <label class="generalLabel mt-2"
          >No creditor records found. Customer may have their credit reports
          frozen.</label
        >
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import deepCopy from "@/helpers/deepCopy";
import creditorTypes from "./ClientCreditors/types";
import formatMixin from "@/mixins/formatMixin";
import globalTypes from "@/store/types";
import { mapState } from "vuex";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import { id } from "rhea";

export default {
  name: "CreditPullReportPopup",
  mixins: [utilitiesMixin, formatMixin],

  props: {
    creditReportData: {
      Type: Object,
      Default: null,
    },
    blockMakingChange: {
      Type: Boolean,
      Default: false,
    },
    customerId: {
      Type: Number,
      Default: 0,
    },
    sourceId: {
      Type: Number,
      Default: 0,
    },
  },
  data() {
    return {
      isLoading: false,
      existingCreditorList: [],
      creditorList: [],
      creditScore: "",
      utilization: "",
      revolvingCreditUtilization: "",
      isCoCustomer: false,
      isInserting: false,
      showButton: false,
      borrowerInfo: null,
      employerInfo: null,
    };
  },
  computed: mapState({
    creditorWithAlias: (state) => state.globals.creditorWithAlias,
    blockedCreditorInfo: (state) => state.globals.blockedCreditorInfo,
    blockedCreditorList() {
      return this.blockedCreditorInfo
        ? this.blockedCreditorInfo.BlockedCreditors
        : [];
    },
    blockedCreditTypes() {
      return this.blockedCreditorInfo
        ? this.blockedCreditorInfo.BlockedCreditTypes
        : [];
    },
  }),
  unmounted() {
    // this.emptyTheObjectsOnUnmount();
  },
  async mounted() {
    await Promise.all([
      this.$store.dispatch(globalTypes.GET_CREDITOR_ALIAS_LIST),
      this.$store.dispatch(globalTypes.GET_BLOCKED_CREDITORS_AND_CREDIT_TYPES),
      this.getCurrentCreditors(),
    ]);

    if (this.creditReportData != null) {
      if (this.creditReportData.ReportData != null) {
        let cs = Array.isArray(this.creditReportData.ReportData.CreditScore)
          ? this.creditReportData.ReportData.CreditScore
          : [this.creditReportData.ReportData.CreditScore];

        for (let i = 0; i < cs.length; i++) {
          if (i == 0) {
            this.creditScore += parseFloat(cs[i]._Value);
            if (
              typeof cs[i].RevolvingCreditUtilization != "undefined" &&
              cs[i].RevolvingCreditUtilization  &&
              cs[i].RevolvingCreditUtilization != ""
            ) {
              this.revolvingCreditUtilization +=
                cs[i].RevolvingCreditUtilization;
            }
          } else {
            this.creditScore += " / " + parseFloat(cs[i]._Value);
            if (
              typeof cs[i].RevolvingCreditUtilization != "undefined" &&
              cs[i].RevolvingCreditUtilization  &&
              cs[i].RevolvingCreditUtilization != ""
            ) {
              this.revolvingCreditUtilization +=
                "/" + cs[i].RevolvingCreditUtilization;
            }
          }
        }

        if (this.creditReportData.ReportData.Creditors) {
          let creditors = this.creditReportData.ReportData.Creditors;

          if (creditors != null) {
            let allCreditors = Array.isArray(creditors)
              ? creditors
              : [creditors];

            if (allCreditors && allCreditors.length > 0) {
              let validCreditors = allCreditors.filter((x) =>
                this.showAddCreditorButton(x)
              );
              let otherCreditors = allCreditors.filter(
                (x) => !this.showAddCreditorButton(x)
              );

              if (validCreditors.length > 0) {
                for (let item of validCreditors) {
                  this.creditorList.push(item);
                }
              }

              if (otherCreditors.length > 0) {
                for (let item of otherCreditors) {
                  this.creditorList.push(item);
                }
              }
            }
          }
        }

        if (this.creditReportData.ReportData.Borrower) {
          this.borrowerInfo = Array.isArray(
            this.creditReportData.ReportData.Borrower
          )
            ? this.creditReportData.ReportData.Borrower
            : [this.creditReportData.ReportData.Borrower];

          for (let item of this.borrowerInfo) {
            if (item._RESIDENCE != null) {
              item._RESIDENCE = Array.isArray(item._RESIDENCE)
                ? item._RESIDENCE
                : [item._RESIDENCE];
            }
          }

          if (this.borrowerInfo.length > 0) {
            this.borrowerInfo = this.borrowerInfo[0];
          }
        }

        if (this.creditReportData.ReportData.Employers) {
          this.employerInfo = Array.isArray(
            this.creditReportData.ReportData.Employers
          )
            ? this.creditReportData.ReportData.Employers
            : [this.creditReportData.ReportData.Employers];
        }

        this.showButton = true;
      }

      if (this.creditReportData.IsCoCustomer != null) {
        this.isCoCustomer = this.creditReportData.IsCoCustomer;
      }
    }


    if (
      this.creditReportData &&
      this.creditReportData.ReportData &&
      this.creditReportData.ReportData.Summaries
    ) {
      let summaries = Array.isArray(this.creditReportData.ReportData.Summaries)
        ? this.creditReportData.ReportData.Summaries
        : [this.creditReportData.ReportData.Summaries];
   
      for (let i = 0; i < summaries.length; i++) {
        if (i == 0) {
          if (typeof summaries[i]._Value != "undefined" && summaries[i]._Value ) {
            this.utilization += parseFloat(summaries[i]._Value) + "%";
          }
        } else {
          if (typeof summaries[i]._Value != "undefined" && summaries[i]._Value ) {
            this.utilization += " / " + parseFloat(summaries[i]._Value) + "%";
          }
        }
      }

  
    }
  },
  methods: {
    async getCurrentCreditors() {
      let err, result;

      [err, result] = await this.$store.dispatch(creditorTypes.GET_CREDITORS, {
        CustomerId: this.creditReportData.CustomerId,
      });

      if (result && result.Data) {
        let customerCreditors = deepCopy(result.Data);
        if (customerCreditors && customerCreditors.length > 0) {
          for (let item of customerCreditors) {
            this.existingCreditorList.push({
              CreditorName: item.Name,
              AccountId: item.AccountNumber,
            });
          }
        }
      }
    },
    async handleAddAllCreditors() {
      this.isLoading = true;
      let processedCount = 0;
      try {
        for (let item of this.creditorList) {
          if (this.showAddCreditorButton(item)) {
            let payload = this.getCreditorObjectForInserting(item, true);

            if (payload != null) {
              let err, result;
              [err, result] = await this.$store.dispatch(
                creditorTypes.INSERT_CREDITOR,
                payload
              );
              if (result) {
                processedCount += 1;
              }
            }
          }
        }
      } catch {
        //don't show error messages.
      }

      this.isLoading = false;

      if (processedCount > 0) {
        this.$swal(
          "Success",
          processedCount + " creditor(s) added.",
          "success"
        );
        this.$Emitter.emit(
          "refreshCreditorGrid",
          this.creditReportData.CustomerId
        );
      } else {
        this.$swal(
          "Warning",
          "There are no valid creditors in the credit report.",
          "warning"
        );
      }
    },
    showAddCreditorButton(item) {
      return (
        item &&
        item._CREDITOR &&
        item._CREDITOR._Name &&
        item._CREDITOR._Name.length > 0 &&
        item._AccountIdentifier &&
        item._AccountIdentifier.length > 0 &&
        item._UnpaidBalanceAmount &&
        parseInt(item._UnpaidBalanceAmount) > 0 &&
        !this.isCreditorExists(item._CREDITOR._Name, item._AccountIdentifier)
      );
    },
    onTapItem() {
      this.$emit("popupOpen", false);
    },

    onTapAddCreditor(item) {
      return async () => {
        await this.handleCreditorInsert(item);
      };
    },
    swipePanel() {
      this.$emit("disableDragging", true);
    },
    dragPanel() {
      this.$emit("disableDragging", false);
    },
    holdPanel() {
      this.$emit("disableDragging", false);
    },
    isExistsInList(item, prop) {
      let rtn = false;
      rtn = item.some((x) => typeof x[prop] != "undefined" && x[prop] != "");
      return rtn;
    },
    getCreditorAdress(item) {
      let rtn = "";

      if (
        item &&
        item._CREDITOR &&
        item._CREDITOR._Address &&
        item._CREDITOR._Address != ""
      ) {
        return item._CREDITOR._Address;
      }

      if (
        item &&
        item._CREDITOR &&
        item._CREDITOR._StreetAddress &&
        item._CREDITOR._StreetAddress != ""
      )
        rtn += item._CREDITOR._StreetAddress;

      if (
        item &&
        item._CREDITOR &&
        item._CREDITOR._City &&
        item._CREDITOR._City != ""
      )
        rtn += ", " + item._CREDITOR._City;

      if (
        item &&
        item._CREDITOR &&
        item._CREDITOR._State &&
        item._CREDITOR._State != ""
      )
        rtn += ", " + item._CREDITOR._State;

      if (
        item &&
        item._CREDITOR &&
        item._CREDITOR._PostalCode &&
        item._CREDITOR._PostalCode != ""
      )
        rtn += ", " + item._CREDITOR._PostalCode;

      return rtn;
    },
    getPersonelAdresses(item) {
      let rtn = "";

      if (item._StreetAddress && item._StreetAddress != "")
        rtn += item._StreetAddress;

      if (item._City && item._City != "") rtn += ", " + item._City;

      if (item._State && item._State != "") rtn += ", " + item._State;

      if (item._PostalCode && item._PostalCode != "")
        rtn += ", " + item._PostalCode;

      if (item.BorrowerResidencyType && item.BorrowerResidencyType != "")
        rtn += " (" + item.BorrowerResidencyType + ")";

      return rtn;
    },

    emptyTheObjectsOnUnmount() {
      this.existingCreditorList = null;
      this.creditorList = null;
      this.creditScore = null;
      this.isCoCustomer = null;
      this.isInserting = null;
      this.showButton = null;
    },
    isCreditorExists(creditorName, creditorAccountId) {
      let rtn = false;
      if (
        this.existingCreditorList.filter(
          (x) =>
            x.CreditorName == creditorName && x.AccountId == creditorAccountId
        ).length > 0
      ) {
        rtn = true;
      }

      return rtn;
    },
    async handleCreditorInsert(creditorObj) {
      if (this.isInserting) {
        return;
      }
      if (this.blockMakingChange) {
        this.$swal(
          "Warning!",
          "You are not authorized to make changes!",
          "warning"
        );
        return;
      }

      this.isInserting = true;

      let payload = this.getCreditorObjectForInserting(creditorObj, false);

      if (payload != null) {
        let err, result;

        [err, result] = await this.$store.dispatch(
          creditorTypes.INSERT_CREDITOR,
          payload
        );

        if (result) {
          this.existingCreditorList.push({
            CreditorName: result.Data.Name,
            AccountId: result.Data.AccountNumber,
          });
          this.$swal("Success!", "Creditor added successfully.", "success");
          this.$Emitter.emit(
            "refreshCreditorGrid",
            this.creditReportData.CustomerId
          );
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }

      this.isInserting = false;
    },

    getCreditorObjectForInserting(item, isAddAll) {
      let objToInsert = {
        Id: 0,
        Name: "",
        AccountTypeName: item.CreditLoanType,
        IsAddAll: isAddAll,
        CustomerId: 0,
        Comments: "",
        AccountNumber: "",
        OriginalBalance: 0,
        CurrentBalance: 0,
        CreditorStatusId: 0,
        AccountHolderId: 0,
        NegotiationPhase: { Id: 0, Name: "" },
        DateAdded: "",
        IsPOA: false,
        DateUpdated: "",
        IsLawSuit: false,
        IsSettlementSetup: false,
        IsSettlementApproved: false,
        SettlementStatusId: 0,
        SettlementRequestDate: "",
        CurrentCreditorName: "",
        CurrentCreditorBalance: 0,
        CurrentCreditorAccountNumber: "",
        SettlementRequestedUser: {
          Id: 0,
          Name: "",
        },
        AccountTypeId: 0,
        MonthlyPayment: 0,
        CreatedUserId: 0,
        SettledUserId: 0,
        Delinquent3Years: false,
        LastPaymentDate: null,
        isEditing: false,
      };

      if (this.creditReportData && this.creditReportData.CustomerId) {
        objToInsert.CustomerId = this.creditReportData.CustomerId;
      }

      if (item._UnpaidBalanceAmount) {
        objToInsert.OriginalBalance = parseFloat(
          item._UnpaidBalanceAmount
        ).toFixed(2);
      }

      if (item._OriginalCreditorName && item._OriginalCreditorName.length > 0) {
        objToInsert.Name = item._OriginalCreditorName;
        objToInsert.CurrentCreditorName = item._CREDITOR._Name;
      } else {
        objToInsert.Name = item._CREDITOR._Name;
        objToInsert.CurrentCreditorName = "";
      }

      let creditorName = item._CREDITOR._Name;

      if (creditorName != "") {
        creditorName = creditorName.toLowerCase();
      }

      let creditType = "";
      if (item.CreditLoanType != null && item.CreditLoanType != "") {
        creditType = item.CreditLoanType;
      }

      if (creditType != "") {
        creditType = creditType.toLowerCase();
      }

      if (isAddAll == false) {
        if (
          ((creditorName != "" &&
            this.blockedCreditorList.includes(creditorName)) ||
            this.blockedCreditTypes.includes(creditType)) &&
          !this.checkAuth(6356)
        ) {
          this.isInserting = false;

          this.$swal(
            "Error!",
            "Creditor Name or Credit Type is in the blocked list.",
            "error"
          );
          return null;
        }
      }

      let acctHolderId = 1; //default Applicant

      if (item.CreditorApplicantType) {
        let acctType = parseInt(item.CreditorApplicantType);
        if (acctType > 0) {
          acctHolderId = acctType;
        }

        if (
          item._AccountOwnershipType &&
          item._AccountOwnershipType
            .toLowerCase()
            .replace(" ", "")
            .includes("authorized")
        ) {
          acctHolderId = 4;
        }
      } else {
        if (this.isCoCustomer) {
          acctHolderId = 2;
        } else {
          acctHolderId = 1;
          if (this.sourceId == 1) {
            if (
              item.BorrowerID &&
              item.BorrowerID != "" &&
              item.BorrowerID.includes("02")
            ) {
              acctHolderId = 2;
            }
          }
        }

        if (
          item._AccountOwnershipType &&
          item._AccountOwnershipType.length > 0 &&
          !item._AccountOwnershipType
            .toLowerCase()
            .replace(" ", "")
            .includes("individual")
        ) {
          if (
            item._AccountOwnershipType
              .toLowerCase()
              .replace(" ", "")
              .includes("authorized")
          ) {
            acctHolderId = 4;
          } else if (
            item._AccountOwnershipType
              .toLowerCase()
              .replace(" ", "")
              .includes("joint")
          ) {
            acctHolderId = 3;
          }
        }
      }

      objToInsert.AccountHolderId = acctHolderId;

      if (item._AccountIdentifier) {
        objToInsert.AccountNumber = item._AccountIdentifier.slice(0, 50);
      } else {
        objToInsert.AccountNumber = "";
      }

      objToInsert.AccountTypeId = this.getAccountTypeId(
        item.CreditLoanType,
        item.CreditBusinessType
      );

      objToInsert.CreditorStatusId = 1;
      let currentRatingCode = parseInt(item._CURRENT_RATING._Code);
      if (
        currentRatingCode == 7 ||
        currentRatingCode == 8 ||
        currentRatingCode == 9
      ) {
        objToInsert.MonthlyPayment = 0;
      } else {
        objToInsert.MonthlyPayment = parseFloat(item._MonthlyPaymentAmount);
      }

      if (item.LastPaymentDate) {
        objToInsert.LastPaymentDate = new Date(item.LastPaymentDate);
      }

      return objToInsert;
    },
    closePanel() {
      this.$emit("popupOpen", false);
      //this.$emit("closeCreditReportPopup");
    },
    getAccountTypeId(item, creditBusinessType) {
      let rtn = 12;

      if (creditBusinessType == "CollectionServices") {
        return 19;
      }

      let type = "";
      if (typeof item != "undefined" && item != null) {
        type = item.replace("/ /g", "");
      }
      if (
        type == "AutoLoan" ||
        type == "AutoLease" ||
        type == "AutoLoanEquityTransfer" ||
        type == "AutoRefinance" ||
        type == "Automobile" ||
        type == "Title1Loan"
      ) {
        // auto loans=7
        rtn = 7;
      } else if (
        type == "BiMonthlyMortgageTermInYears" ||
        type == "CommercialMortgage" ||
        type == "ConventionalRealEstateMortgage" ||
        type == "FHAComakerNotBorrower" ||
        type == "FHAHomeImprovement" ||
        type == "FHARealEstateMortgage" ||
        type == "FarmersHomeAdministrationFHMA" ||
        type == "HomeEquity" ||
        type == "HomeEquityLineOfCredit" ||
        type == "HomeImprovement" ||
        type == "LenderPlacedInsurance" ||
        type == "ManualMortgage" ||
        type == "ManufacturedHome" ||
        type == "MobileHome" ||
        type == "Mortgage" ||
        type == "NoteLoan" ||
        type == "NoteLoanWithComaker" ||
        type == "RealEstateJuniorLiens" ||
        type == "RealEstateLoanEquityTransfer" ||
        type == "RealEstateMortgageWithoutOtherCollateral" ||
        type == "RealEstateSpecificTypeUnknown" ||
        type == "Refinance" ||
        type == "ResidentialLoan" ||
        type == "SecondMortgage" ||
        type == "SemiMonthlyMortgagePayment" ||
        type == "TimeSharedLoan" ||
        type == "VeteransAdministrationRealEstateMortgage" ||
        type == "Home Loans"
      ) {
        // mortgage
        rtn = 8;
      } else if (
        type == "Boat" ||
        type == "Camper" ||
        type == "CreditLineSecured" ||
        type == "InstallmentLoan" ||
        type == "PartiallySecured" ||
        type == "Recreational" ||
        type == "RecreationalVehicle" ||
        type == "Secured" ||
        type == "SecuredByCosigner" ||
        type == "SecuredByCosigner" ||
        type == "SecuredHomeImprovement"
      ) {
        //Secure
        rtn = 20;
      } else if (
        type == "Business" ||
        type == "CommercialCreditObligation" ||
        type == "CommercialLineOfCredit" ||
        type == "RevolvingBusinessLines"
      ) {
        //Business Loan
        rtn = 15;
      } else if (
        type == "BusinessCreditCard" ||
        type == "ChargeAccount" ||
        type == "CreditCard" ||
        type == "SecuredCreditCard"
      ) {
        //Credit Card
        rtn = 1;
      } else if (
        type == "ChildSupport" ||
        type == "FamilySupport" ||
        type == "SpouseSupport"
      ) {
        //Family Support
        rtn = 21;
      } else if (type == "Collection" || type == "CollectionAttorney") {
        //Collection
        rtn = 19;
      } else if (
        type == "Consolidation" ||
        type == "PersonalLoan" ||
        type == "SinglePaymentLoan" ||
        type == "FinanceStatement" ||
        type == "CheckCreditOrLineOfCredit"
      ) {
        // Personal Loans
        rtn = 3;
      } else if (type == "DeferredStudentLoan" || type == "Educational") {
        //Student Loan
        rtn = 14;
      } else if (type == "FederalConsolidatedLoan") {
        //Credit Union
        rtn = 13;
      } else if (type == "InsuranceClaims") {
        //Insurance
        rtn = 18;
      } else if (type == "Lease" || type == "RentalAgreement") {
        //Back Rent
        rtn = 17;
      } else if (type == "MedicalDebt") {
        // Medical Debt
        rtn = 11;
      } else if (type == "MobilePhone") {
        // Cell Phone Bill
        rtn = 10;
      } else if (type == "Unsecured") {
        //Unsecured
        rtn = 9;
      } else if (type == "UtilityCompany") {
        //Utility
        rtn = 16;
      }

      return rtn;
    },
  },
};
</script>

<style>
.popupNameLabel {
  margin: -10px 0 0 0px;
}
.headerLabel {
  width: 100%;
  padding: 4px;
  background: #ebebeb;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.valueLabel {
  width: 100%;
  padding: 0 4px;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.has-error {
  border: 2px solid red !important;
}

.creditorsAddButton {
  height: 30px !important;
  margin-top: 2px;
  padding: 0 10px !important;
  background-color: #3bafda !important;
  font-size: 12px !important;
  color: #ffffff;
}
.addAllButton {
  height: 40px !important;
  margin-top: -4px;
  padding: 0 10px !important;
  background-color: #3bafda !important;
  font-size: 12px !important;
  color: #ffffff;
}
@media screen and (max-height: 742px) {
  .cprp-mediaScreen {
    max-height: 600px !important;
    overflow-y: scroll;
  }
}
@media screen and (max-height: 637px) {
  .cprp-mediaScreen {
    max-height: 400px !important;
    overflow-y: scroll;
  }
}
</style>
