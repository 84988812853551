<template>
  <div
    class="col-md-12 newPanelWrapper noMargin pchp-mediaScreen"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div v-if="pchpSpinner" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="historyPopUpHeader">
      <label style="font-size: 15px">Pull Credit History</label>
    </div>

    <div>
      <div class="closeButton" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
    </div>

    <div class="col-md-12 d-flex" style="justify-content: space-between">
      <div class="col-md-2">
        <label class="generalLabel pt-1">Request By</label>
      </div>
      <div class="col-md-2">
        <label class="generalLabel pt-1">Request Date</label>
      </div>
      <div class="col-md-2">
        <label class="generalLabel pt-1">Pull Type</label>
      </div>
      <div class="col-md-3">
        <label class="generalLabel pt-1">Customer</label>
      </div>
      <div class="col-md-3"></div>
    </div>

    <hr />
    <perfect-scrollbar
      style="width: 100%; max-height: 250px; overflow-x: hidden !important"
    >
      <div
        class="col-md-12 d-flex"
        style="justify-content: space-between"
        v-for="item in creditPullHistoryList"
        :key="item.Id"
      >
        <div class="col-md-2 mb-2">
          <label class="generalLabel">{{ item.RequestedBy }}</label>
        </div>
        <div class="col-md-2">
          <label class="generalLabel">{{
            formatGridDate(item.RequestDate)
          }}</label>
        </div>
        <div class="col-md-2 mb-2">
          <label class="generalLabel">{{ item.PullType }}</label>
        </div>
        <div class="col-md-3 mb-2">
          <label class="generalLabel">{{ item.Name }}</label>
        </div>
        <div class="col-md-3 mb-2" style="display: flex">
          <button
            title="Show Report"
            class="btn btn-primary btn-xs mr-2"
            @click="
              showReport(item.ResponseData, item.IsCoCustomer, item.ServiceId)
            "
            v-if="
              item.ResponseData &&
              typeof item.ResponseData.questions == 'undefined'
            "
          >
            <i class="fas fa-search"></i>
            Show Report
          </button>
          <button
            title="Download"
            class="btn btn-success btn-xs"
            @click="downloadReport(item)"
            v-if="
              !item.ResponseData &&
              item.AuthQuestionPulled != true &&
              !(item.IsExpired && item.IsExpired == true) &&
              showDownload
            "
          >
            <i class="fas fa-download"></i>
            Download
          </button>

          <button
            v-if="item.BlobName"
            type="button"
            title="Download Document"
            class="btn btn-sm fas fa-download"
            style="background-color: rgb(44, 62, 80)"
            @click="downloadMeridianLinkReport(item)"
          ></button>

          <button
            title="Authorization Questions"
            class="btn btn-danger btn-xs"
            @click="showAuthQuestions(item)"
            v-if="
              item.ServiceId == 1 &&
              item.AuthQuestionPulled == true &&
              !(item.IsExpired && item.IsExpired == true) &&
              item.IsQuestionAuth == true &&
              item.ClientKey != '' &&
              item.ResponseData == null
            "
          >
            <i class="fas fa-question-circle"></i>
            Show Auth. Questions
          </button>
          <label
            v-if="
              !item.ResponseData && item.IsExpired && item.IsExpired == true
            "
            >Expired</label
          >
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import types from "./types";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
import { get } from "lodash";
export default {
  name: "PullCreditHistoryPopup",
  mixins: [utilitiesMixin, formatMixin],
  components: {},
  props: {
    customerId: {
      type: Number,
      default: () => 0,
    },
  },
  unmounted() {
    // this.emptyTheObjectsOnUnmount();
  },
  async mounted() {
    await this.getHistory();
    this.pchpSpinner = true;
    let downloadRequired = this.creditPullHistoryList.filter(
      (x) => x.IsExpired != true && x.ResponseData == null
    );
    if (downloadRequired.length > 0) {
      //start initial download
      for (let item of downloadRequired) {
        await this.downloadReportInMounted(item);
      }
      await this.getHistory();
    }

    this.showDownload = true;
    this.pchpSpinner = false;
  },
  data() {
    return {
      creditPullHistoryList: [],
      showDownload: false,
      pchpSpinner: false,
    };
  },

  methods: {
    async getHistory() {
      let err, result;

      [err, result] = await this.$store.dispatch(
        types.GET_CREDIT_PULL_HISTORY,
        { CustomerId: this.customerId }
      );

      if (result && result.Data) {
        this.creditPullHistoryList = deepCopy(
          result.Data.sort(function (a, b) {
            return new Date(b.RequestDate) - new Date(a.RequestDate);
          })
        );
      }
    },

    async downloadMeridianLinkReport(item) {
      let fileExtension = "";
      fileExtension = this.getExtension(item.BlobName);
      if (fileExtension != "") {
        let err, result;
        let dataToDownload = {
          Name: this.getFileNameWithoutExt(item.BlobName),
          Extension: fileExtension,
          CustomerId: this.customerId,
        };

        [err, result] = await this.$store.dispatch(
          types.DOWNLOAD_CUSTOMER_DOCUMENT,
          dataToDownload
        );

        if (result) {
          if (fileExtension == ".pdf") {
            var file = new Blob([result], { type: "application/pdf" });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          } else {
            this.saveByteArray(item.BlobName, result);
          }
        }
      }
    },
    saveByteArray(name, byte) {
      let blob = new Blob([byte], { type: "application/docx" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      let fileName = name;
      link.download = fileName;
      link.click();
    },
    emptyTheObjectsOnUnmount() {
      this.creditPullHistoryList = null;
      this.showDownload = null;
    },
    showAuthQuestions(item) {
      if (item.ServiceId == 1 && item.AuthQuestionPulled) {
        this.$emit("openArrayAuthPopup", {
          appKey: item.AppKey,
          clientKey: item.ClientKey,
          clientKey2: item.ClientKey2,
          isTestMode: item.IsTestMode,
          providerName: item.Provider,
          logId: item.Id,
          isJointRequest: item.PullTypeId == 3 && item.ClientKey2 != "" ? true :false

        });
        this.$emit("closePullCreditHistoryPopup");
      }
    },
    async showReport(reportContent, isCoCustomer, serviceId) {
      this.reportContentToShow = "";
      let contentStr = JSON.stringify(reportContent);
      contentStr = contentStr.replace(/@/g, "");
      let jsonContent = JSON.parse(contentStr);

      let data = {
        ReportData: jsonContent,
        IsCoCustomer: isCoCustomer,
        CustomerId: this.customerId,
        ServiceId: serviceId
      };

      this.$emit("showCreditReport", data);
    },
    async downloadReport(item) {
      let dataToPost = {
        CreditPullLogId: item.Id,
        ServiceId: item.ServiceId,
      };
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.DOWNLOAD_CREDIT_PULL_HISTORY,
        dataToPost
      );

      if (result && result.Data) {
        let indx = this.creditPullHistoryList.findIndex((x) => x.Id == item.Id);
        this.creditPullHistoryList[indx].ResponseData = result.Data;

        if (result.Message && result.Message.includes("Report is expired")) {
          let indx = this.creditPullHistoryList.findIndex(
            (x) => x.Id == item.Id
          );
          this.creditPullHistoryList[indx].IsExpired = true;
          this.$swal("Error!", result.Message, "error");
        }
      } else {
        let errMsg = "An Error Occured!";
        if (result.Message && result.Message.length > 0) {
          errMsg = result.Message;
        }
        this.$swal("Error!", errMsg, "error");
      }
      this.creditPullHistoryList.push();
    },
    async downloadReportInMounted(item) {
      let dataToPost = {
        CreditPullLogId: item.Id,
        ServiceId: item.ServiceId,
      };
      let err, result;

      [err, result] = await this.$store.dispatch(
        types.DOWNLOAD_CREDIT_PULL_HISTORY,
        dataToPost
      );

      if (result && result.Data) {
        let indx = this.creditPullHistoryList.findIndex((x) => x.Id == item.Id);
        this.creditPullHistoryList[indx].ResponseData = result.Data;

        if (result.Message && result.Message.includes("Report is expired")) {
          let indx = this.creditPullHistoryList.findIndex(
            (x) => x.Id == item.Id
          );
          this.creditPullHistoryList[indx].IsExpired = true;
        }
      }
      this.creditPullHistoryList.push();
    },
    closePanel() {
      this.$emit("closePullCreditHistoryPopup");
    },
  },
};
</script>

<style scope>
.has-error {
  border: 2px solid red !important;
}
.historyPopUpHeader {
  margin-left: 23px;
  margin-top: -5px;
  padding-bottom: 10px;
}
@media screen and (max-height: 500px) {
  .pchp-mediaScreen {
    max-height: 300px !important;
    overflow-y: scroll;
  }
}
@media screen and (max-height: 400px) {
  .pchp-mediaScreen {
    max-height: 200px !important;
    overflow-y: scroll;
  }
}
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}
</style>
