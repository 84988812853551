<template>
  <div id="wrapper">
    <Navigation
      ref="navigationCtrl"
      @openCloseMenu="openCloseMenu"
      @authenticated="authenticated($event)"
    />

    <div class="content-page" :style="'margin-left:' + contentMargin + 'px'">
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12" style="margin: 0">
              <div class="page-title-box">
                <div class="page-title-right"></div>
                <h4 class="page-title">{{ setTitle() }}</h4>
              </div>
            </div>
          </div>
          <div class="row d-flex">
            <router-view
              v-show="!(showCustomer || showNegotiatorTasks)"
            ></router-view>
            <Customers v-show="showCustomer" ref="customerCtrl"></Customers>
            <NegotiatorTask
              ref="negotiatorTaskCtrl"
              v-show="showNegotiatorTasks"
            ></NegotiatorTask>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "@/Navigation";

import Customers from "@/views/agent/ClientGrid/Clients";
import NegotiatorTask from "@/views/agent/NegotiatorTasks/NegotiatorTasks";
import globalTypes from "@/store/types";
import plivoTypes from "@/components/PlivoParts/types";
import { mapState } from "vuex";
import utilitiesMixin from "@/mixins/utilitiesMixin";
const {
  ServiceBusClient,
  ServiceBusAdministrationClient,
} = require("@azure/service-bus");

export default {
  components: {
    Customers,
    Navigation,
    NegotiatorTask,
  },
  mixins: [utilitiesMixin],
  data() {
    return {
      userId: null,
      userOfficeId: 0,
      userPhoneSettings: null,
      showCustomer: false,
      showNegotiatorTasks: false,
      showPhone: false,
      companyId: 0,
      loginCheckTopic: "",
      leadDistroTopic: "",
      conferenceTopic: "",
      autoAcceptCallTopic: "",
      dataSyncTopic: "",
      ticketTopic: "",
      subscriptionName: "",
      xChangeTopic: "",
      tabGuid: "",
      contentMargin: 240,
    };
  },
  computed: mapState({
    isUserOnline: (state) => state.globals.isUserOnline,
  }),
  async mounted() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (this.userInfo) {
      this.subscriptionName = "agent-" + this.userInfo.userId;
      this.tabGuid = new Date().getTime();

      this.userId = this.userInfo.userId;
      this.userOfficeId = this.userInfo.officeId;
      let azureBusKey = this.userInfo.busKey;
      this.companyId = this.userInfo.companyId;

      this.userPhoneSettings = JSON.parse(
        sessionStorage.getItem("userPhoneSettings")
      );

      this.loginCheckTopic = `company.${this.companyId}.logincheck`;
      this.leadDistroTopic = `company.${this.companyId}.leaddistro`;
      this.conferenceTopic = `company.${this.companyId}.conference`;
      this.autoAcceptCallTopic = `company.${this.companyId}.autoacceptcall`;
      this.dataSyncTopic = `company.${this.companyId}.datasync`;
      this.ticketTopic = `company.${this.companyId}.csrticket`;
      this.xChangeTopic = `company.${this.companyId}.xchange`;
      if (azureBusKey != "") {
        this.$ServiceBus = new ServiceBusClient(azureBusKey);
        this.$ServiceBusAdmin = new ServiceBusAdministrationClient(azureBusKey);
      }

      if (this.$ServiceBus != null && this.$ServiceBusAdmin != null) {
        if (this.companyId > 0) {
          await this.subscribeTopic(
            this.loginCheckTopic,
            this.subscriptionName
          );

          if (this.checkAuth(1848)) {
            // Can take leads/Sales
            await this.subscribeTopic(
              this.leadDistroTopic,
              this.subscriptionName
            );
          }

          if (this.userPhoneSettings != null) {
            await this.subscribeTopic(
              this.conferenceTopic,
              this.subscriptionName
            );

            await this.subscribeTopic(
              this.autoAcceptCallTopic,
              this.subscriptionName
            );
          }

          if (this.checkAuth(200010)) {
            // Can access tickets
            await this.subscribeTopic(this.ticketTopic, this.subscriptionName);
          }

          await this.subscribeTopic(this.dataSyncTopic, this.subscriptionName);

          if (this.checkAuth(1094)) {
            await this.subscribeTopic(this.xChangeTopic, this.subscriptionName);
          }

          const sender = this.$ServiceBus.createSender(this.loginCheckTopic);
          let messages = [
            {
              body: { UserId: this.userId, Token: this.tabGuid },
              contentType: "application/json",
            },
          ];
          await sender.sendMessages(messages[0]);
          await sender.close();
        }
      }

      this.$Emitter.on("SendLeadDistro", (data) => {
        if (data) {
          const sender = this.$ServiceBus.createSender(this.leadDistroTopic);
          let messages = [
            {
              body: {
                Event: data.Event,
                CustomerId: data.CustomerId,
              },
              contentType: "Object",
            },
          ];
          sender.sendMessages(messages[0]);
        }
      });
    }
  },
  untmounted() {
    this.$Emitter.off("SendLoginMessage");
    this.$Emitter.off("SendLeadDistro");
  },
  methods: {
    async checkAndSubscribeTopic(topicName) {
      await this.subscribeTopic(topicName, this.subscriptionName);
    },
    openCloseMenu(isOpen) {
      this.contentMargin = isOpen ? 240 : 0;
    },
    customersLink() {
      this.showNegotiatorTasks = false;
      this.showCustomer = true;
    },
    negotiatorTasksLink() {
      this.showCustomer = false;
      this.showNegotiatorTasks = true;
    },
    customersOutsideLink(customerId, customerName) {
      this.showNegotiatorTasks = false;
      this.showCustomer = true;
      

      if (
        this.$refs &&
        this.$refs.customerCtrl &&
        typeof this.$refs.customerCtrl !== "undefined"
      ) {
        this.$refs.customerCtrl.handleEdit(customerId, customerName, true);
      }
    },
    editNegotiatorTaskOutsideLink(taskId, customerName, negotiatorId) {
      this.showNegotiatorTasks = true;
      this.showCustomer = false;
      

      if (
        this.$refs &&
        this.$refs.negotiatorTaskCtrl &&
        typeof this.$refs.negotiatorTaskCtrl !== "undefined"
      ) {
        this.$refs.negotiatorTaskCtrl.openEditNegotiatorTask(
          taskId,
          customerName,
          negotiatorId
        );
      }
    },
    resetLink() {
      this.showCustomer = false;
      this.showNegotiatorTasks = false;
    },
    setTitle() {
      if (this.showCustomer) return "Customers";
      else return this.$route.meta.title;
    },
    async authenticated(status) {
      if (status == false) {
        await Promise.all([
          this.$store.dispatch(globalTypes.RESET_STATES),
          this.$store.dispatch(plivoTypes.RESET_STATES),
        ]);
        window.location.href = "/#/login";
      }
    },
    async subscribeTopic(topicName, subscriptionName) {
      let isExists = await this.$ServiceBusAdmin.subscriptionExists(
        topicName,
        subscriptionName
      );

      if (isExists) {
        await this.crateReceiver(topicName, subscriptionName);
      } else {
        let subscriptionOptions = {
          maxDeliveryCount: 1,
          defaultMessageTimeToLive: "PT5S",
        };

        await this.$ServiceBusAdmin
          .createSubscription(topicName, subscriptionName, subscriptionOptions)
          .then(async () => {
            await this.sleep(500);
            this.crateReceiver(topicName, subscriptionName);
          })
          .catch((err) => {});
      }
    },
    async crateReceiver(topicName, subscriptionName) {
      let receiver = this.$ServiceBus.createReceiver(
        topicName,
        subscriptionName
      );

      let myMessageHandler = async (message) => {
        if (topicName == this.loginCheckTopic) {
          if (message) {
            if (
              this.userId == message.body.UserId &&
              this.tabGuid != message.body.Token
            ) {
              this.$Emitter.emit("forceLeavePage");
              this.authenticated(false);
            }
          }
        } else if (topicName == this.leadDistroTopic) {
          let msgObj = message.body;

          // let logMessage =  "1. Service bus message received (lead distro) Event: "+msgObj.Event + "  Customers: " + msgObj.Customers.map(x=> x.CustomerId).join(",") + " Offices: " +  msgObj.Offices;

          // this.$Emitter.emit("sendServiceBusLogs", logMessage);

          if (msgObj.Event == "New2") {
            let customers = msgObj.Customers
              ? msgObj.Customers.filter(
                  (x) =>
                    (x.UserList.length == 0 ||
                      x.UserList.includes(this.userId)) &&
                    (x.OfficeId == null || x.OfficeId == this.userOfficeId)
                )
              : [];

            if (
              this.isUserOnline &&
              msgObj.Offices.includes(this.userOfficeId) &&
              customers.length > 0
            ) {
              this.$Emitter.emit("hotLeadReceived", {
                Message: msgObj,
                Customers: customers,
              });
            } else {
              this.$Emitter.emit("hotLeadCancel", {
                CustomerId: customers.length > 0 ? customers[0].CustomerId : 0,
              });
            }
          } else if (msgObj.Event == "Cancel") {
            this.$Emitter.emit("hotLeadCancel", {
              CustomerId: msgObj.CustomerId,
            });
          } else if (msgObj.Event == "bluerock") {
            if (this.userId == parseInt(msgObj.UserId)) {
              this.$Emitter.emit("brCallReceived", {
                CustomerId: msgObj.CustomerId,
                CustomerName: msgObj.CustomerName,
              });
            }
          }
          else if(msgObj.Event == "monevo")
          {
            if (this.userId == parseInt(msgObj.UserId))
            {
              this.$Emitter.emit("openMonevoLink", {
              CustomerId: msgObj.CustomerId,
              CustomerName: msgObj.CustomerName,
              Url: msgObj.Url
              });
            }
           
          }
        } else if (topicName == this.conferenceTopic) {
          let msgObj = message.body;
          if (msgObj.EventName && msgObj.EventName == "MuteOperation") {
            if (this.userPhoneSettings.endPointId) {
              this.$Emitter.emit("unmuteCall", {
                CustomerId: msgObj.CustomerId,
                Mute: msgObj.Mute,
                Hangup3rdParty: msgObj.IfConfHangup3rdP,
              });
            }
          } else {
            if (msgObj.EndpointId == this.userPhoneSettings.endPointId) {
              this.$Emitter.emit("SignAsConference", msgObj.ConferenceName);
            }
          }
        } else if (topicName == this.autoAcceptCallTopic) {
          if (
            this.userPhoneSettings &&
            this.userPhoneSettings.isAutoAcceptCall
          ) {
            let msgObj = message.body;

            if (msgObj) {
              let users = msgObj.EndpointList;

              let indx = users.findIndex(
                (x) => x == this.userPhoneSettings.endPointName
              );

              if (indx > -1) {
                this.$Emitter.emit("autoAcceptCallPush", {
                  Index: indx,
                  From: msgObj.From,
                });
              }
            }
          }
        } else if (topicName == this.dataSyncTopic) {
          let msgObj = message.body;

          if (msgObj.ObjectType == "Customer") {
            if (this.userId != msgObj.UserId) {
              if (
                this.showCustomer == true &&
                this.$refs &&
                this.$refs.customerCtrl &&
                typeof this.$refs.customerCtrl !== "undefined"
              ) {
                this.$refs.customerCtrl.handleDataSync(msgObj);
              }
            }
          } else if (msgObj.ObjectType == "Creditor") {
            if (this.userId != msgObj.UserId) {
              if (
                this.showCustomer == true &&
                this.$refs &&
                this.$refs.customerCtrl &&
                typeof this.$refs.customerCtrl !== "undefined"
              ) {
                this.$refs.customerCtrl.handleDataSync(msgObj);
              }

              if (
                (this.checkAuth(7470) ||
                  this.checkAuth(9127) ||
                  this.checkAuth(8593)) &&
                this.$refs &&
                this.$refs.negotiatorTaskCtrl &&
                typeof this.$refs.negotiatorTaskCtrl !== "undefined" &&
                this.showNegotiatorTasks == true
              ) {
                this.$refs.negotiatorTaskCtrl.handleCreditorSync(msgObj);
              }
            }
          }
        } else if (topicName == this.ticketTopic) {
          let msgObj = message.body;
          if (this.userId != msgObj.UserId) {
            let taskId = msgObj.CsrTaskId;
            let userId = msgObj.UserId;
            let roles = msgObj.RoleIdList;
            this.$Emitter.emit("updateTicketDashboard", {
              TicketId: taskId,
              UserId: userId,
              Roles: roles,
            });
          }
        } else if (topicName == this.xChangeTopic) {
          let msgObj = message.body;
          this.$Emitter.emit("refreshXChangeGrid", {
            CustomerId: msgObj.CustomerId,
          });
        }
      };
      let myErrorHandler = async (args) => {};

      receiver.subscribe({
        processMessage: myMessageHandler,
        processError: myErrorHandler,
      });
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>
<style lang="scss" scoped>
.menuAnimationLeft {
  animation-name: marginLeftAnimation;
  animation-duration: 1s;
}

@keyframes marginLeftAnimation {
  from {
    margin-left: 240px;
  }
  to {
    margin-left: 0px;
  }
}

.menuAnimationRight {
  animation-name: marginRightAnimation;
  animation-duration: 1s;
}

@keyframes marginRightAnimation {
  from {
    margin-left: 0;
  }
  to {
    margin-left: 240px;
  }
}

@media only screen and (max-width: 430px) {
  .content-page {
    padding-left: 6px;
    padding-right: 6px;
  }
  .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.pPanelWrapper {
  width: calc(100% - 240px);
  position: fixed;
  bottom: 0;
  padding: 16px 22px;
  left: 240px;

  z-index: 999;

  .row {
    &.active {
      background-color: #fff;
      box-shadow: none;
    }
  }
  .active-row {
    padding: 16px;
    background-color: #f5f5f5;
    border: solid 1px #e4e4e4;
    border-radius: 8px;
    &.disposition-enabled {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .contact-wrapper {
    .contact-details {
      display: flex;
      flex-flow: column;
      padding-left: 10px;
      span {
        color: #676666;
        font-size: 14px;
      }
      .bold {
        font-weight: 600;
      }
    }
    & > span {
      display: flex;
    }
  }
  .duration {
    display: block;
    border-radius: 18px;
    font-weight: bold;
    font-size: 14px;
    padding: 9px 16px;
    height: 40px;
    border: solid 1px #e4e4e4;
    background-color: #ffffff;
  }
  .no-calls {
    display: inline-block;
    height: 40px;
    border-radius: 6px;
    padding: 0 16px;
    line-height: 40px;
    font-size: 14px;
    border: solid 1px #e6e6e6;
    color: #2a404c;
    background-color: #ffffff;
    cursor: pointer;
  }

  .on-hold {
    display: inline-block;
    height: 40px;
    border-radius: 6px;
    padding: 0 16px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    background-color: #d7a505;
    cursor: pointer;
  }

  .connected {
    display: inline-block;
    height: 40px;
    border-radius: 6px;
    padding: 0 16px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    background-color: #24d15f;
    cursor: pointer;
  }

  .hung-up {
    display: inline-block;
    height: 40px;
    border-radius: 6px;
    padding: 0 16px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    background-color: #ed3030;
    cursor: pointer;
  }
  .incoming {
    display: inline-block;
    height: 40px;
    border-radius: 6px;
    padding: 0 16px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    background-color: #6080f7;
  }
  .row {
    background-color: #f5f5f5;
    border-radius: 10px;
    margin: 5px 0;
  }
  .texts {
    width: 100%;
    float: left;
    font-size: 20px;
    margin: 15px 0;
    .icons {
      float: left;
      padding: 0 10px;
    }
  }
  .button {
    float: right;
    margin: 0 5px;
    cursor: pointer;
    .icons {
      font-size: 32px;
      padding: 5px;
    }
  }
  .hangup {
    .icons {
      background-color: #ed3030;
      cursor: pointer;
      i {
        color: #fff;
        font-size: 18px;
        position: relative;
        top: -8px;
      }
    }
  }
  .callline {
    .icons {
      i {
        color: #fff;
        font-size: 20px;
        position: relative;
        top: -8px;
      }
    }
  }
  .pauseline {
    .icons {
      background-color: #d7a505;
      i {
        color: #fff;
        font-size: 20px;
        position: relative;
        top: -5px;
      }
    }
  }

  .moreline {
    .icons {
      background-color: #e4e4e4;
      i {
        color: #000;
        font-size: 20px;
        position: relative;
        top: -5px;
      }
    }
  }

  .recordline {
    .icons {
      background-color: #e4e4e4;
      i {
        color: #000;
        font-size: 20px;
        position: relative;
        top: -5px;
      }
    }
  }
  .forwardline {
    .icons {
      background-color: #e4e4e4;
      i {
        color: #000;
        font-size: 20px;
        position: relative;
        top: -8px;
        left: -1px;
      }
    }
  }
  .userline {
    .icons {
      background-color: #e4e4e4;
      i {
        color: #000;
        font-size: 20px;
        position: relative;
        top: -8px;
        left: -1px;
      }
    }
  }
  .playline {
    .icons {
      background-color: #24d15f;

      i {
        color: #fff;
        font-size: 25px;
        position: relative;
        top: -3px;
      }
    }
  }
  .dialnext {
    height: 40px;
    border-radius: 6px;
    background-color: #3bafda;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 13px;
    padding: 0 16px;
  }
  .action-button {
    position: relative;
  }

  .disposition {
    display: flex;
    align-items: center;
    flex-grow: 2;
    justify-content: flex-start;
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #e4e4e4;
    float: left;
    width: 100%;
    padding: 10px 10px;
    margin: 0 0 5px 0;
    clear: both;
    .align-right {
      display: flex;
      justify-content: flex-end;
    }
    .green-button {
      background-color: #24d171;
      color: #fff;
      font-size: 13px;
      padding: 3px 7px;
      border-radius: 4px;
      cursor: pointer;
    }
    .blue-button {
      background-color: #72afe1;
      color: #fff;
      font-size: 13px;
      padding: 3px 7px;
      border-radius: 4px;
      cursor: pointer;
    }
    .col-md-2 {
      display: flex;
    }
    .col-md-8 {
      display: flex;
    }
    .col-md-2 {
      display: flex;
    }
    button {
      margin-left: 16px;
      background-color: #ea9807;
      padding: 9px 16px;
      border-radius: 8px;
    }
  }
  .dispoLogPanel {
    position: absolute;
    width: 500px !important;
    bottom: 40px;

    right: 0;
    z-index: 9999;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    padding: 0.25rem;
  }
  .dispoButtons:hover {
    color: #007bff;
  }
}
</style>
