<template>
  <div v-if="isDeclinePopupOpen" class="backgroundLock">
    <div
      style="position: relative; margin: 10% auto"
      class="offset-xl-4 col-xl-3 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
    >
      <DeclineReasonPopup
        :parentName="'extraPayment'"
        @closeDeclineReasonPopup="closeDeclineReasonPopup"
        @extraPaymentDecline="extraPaymentDecline"
      ></DeclineReasonPopup>
    </div>
  </div>
  <div class="datagrid-table gcsTabsTableScroll creditorTable">
    <o-table :data="feeChangesList" detailed detail-key="Id">
      <o-table-column
        field="Customer.Name"
        label="Customer Name"
        sortable
        searchable
      >
        <template v-slot="props">
          {{ props.row.Customer.Name }}
        </template>
      </o-table-column>
      <o-table-column
        field="Request.RequestedBy.Name"
        label="Requested By"
        sortable
        searchable
      >
        <template v-slot="props">
          {{ props.row.Request.RequestedBy.Name }}
        </template>
      </o-table-column>

      <o-table-column
        field="Request.RequestDate"
        label="Request Date"
        sortable
        searchable
      >
        <template v-slot="props">
          {{ formatGridDate(props.row.Request.RequestDate) }}
        </template>
      </o-table-column>
      <o-table-column field="Notes" label="Notes" sortable searchable>
        <template v-slot="props">
          <div style="max-width: 400px">
            {{ makeEmptyIfNull(props.row.Notes) }}
          </div>
        </template>
      </o-table-column>
      <o-table-column field="Request.IsEmergency" label="Is Emergency" sortable>
        <template v-slot="props">
          <input
            type="checkbox"
            v-model="props.row.Request.IsEmergency"
            class="checkbox-input generalCheckBox"
            disabled="true"
            style="border-radius: 4px; border: solid 1px #d1d1d1"
          />
        </template>
      </o-table-column>
      <o-table-column field="" label="Negative Cashflow" width="10">
        <template v-slot="props">
          <div class="text-center">
            <button
            v-if="isCashFlowNegative(props.row.Customer.Id)"
              class="btn btn-danger btn-xs"
              @click="
                showCashFlow(props.row.Customer.Id)
              "
            >
              <i class="ri-error-warning-line"></i>
            </button>
          </div>
        </template>
      </o-table-column>
      <o-table-column field="ViewClients" label="View Clients" width="10">
        <template v-slot="props">
          <div class="text-center">
            <button
              class="btn btn-primary btn-xs"
              @click="
                openClient(props.row.Customer.Id, props.row.Customer.Name)
              "
            >
              <i class="ri-search-line"></i>
            </button>
          </div>
        </template>
      </o-table-column>
      <o-table-column field="ViewNotes" label="View Notes" width="10">
        <template v-slot="props">
          <div class="text-center">
            <button
              class="btn btn-xs"
              style="background-color: #f7b84b"
              @click="openNotes(props.row.Customer.Id)"
            >
              <i class="ri-file-edit-line"></i>
            </button>
          </div>
        </template>
      </o-table-column>

      <o-table-column field="Approve" label="Approve" width="10">
        <template v-slot="props">
          <div class="text-center">
            <button
              class="btn btn-info btn-xs"
              @click="handleApprove(props.row.Id)"
              :disabled="isApproveLoading"
            >
              <i v-if="isApproveLoading == false" class="ri-check-line"></i>
              <i
                v-if="isApproveLoading"
                class="spinner-border spinner-border-sm text-light"
                style="margin-bottom: 3px; margin-left: 1px"
              ></i>
            </button>
          </div>
        </template>
      </o-table-column>

      <o-table-column field="Decline" label="Decline" width="10">
        <template v-slot="props">
          <div class="text-center">
            <button
              class="btn btn-danger btn-xs"
              @click="handleDecline(props.row.Id)"
              :disabled="isLoading"
            >
              <i class="ri-close-line"></i>
            </button>
          </div>
        </template>
      </o-table-column>

      <template v-slot:detail="props">
        <perfect-scrollbar
          style="
            max-height: 350px;
            width: 521px;
            overflow-y: auto;
            overflow-x: hidden;
          "
        >
          <table
            v-if="props.row.ChangeDetails && props.row.ChangeDetails.length > 0"
            class="details-table"
          >
            <h5 class="generalLabel pl-2" style="color: #3bafda">
              Change Details
            </h5>
            <tr>
              <td align="center"><strong>Original Date</strong></td>
              <td align="center"><strong>Fee Date</strong></td>
              <td align="center"><strong>Fee Type</strong></td>
              <td align="center"><strong>Debit Id</strong></td>
              <td align="center"><strong>Amount</strong></td>
            </tr>

            <tr v-for="(item, index) in props.row.ChangeDetails" :key="index">
              <td align="OriginalDate">
                {{ formatShortDate(item.OriginalDate) }}
              </td>
              <td align="center">
                {{ formatShortDate(item.FeeDate) }}
              </td>
              <td align="center">
                {{ item.GlobalFeeType }}
              </td>
              <td align="center">
                {{ item.GlobalDebitId }}
              </td>
              <td align="center">
                {{ formatMoney(item.FeeAmount) }}
              </td>
            </tr>
          </table>
        </perfect-scrollbar>
        <section
          v-if="
            props.row.ChangeDetails == null ||
            props.row.ChangeDetails.length == 0
          "
        >
          <div style="display: flex; align-items: center">
            <p class="noMargin">
              <i
                style="font-size: 24px; color: #939393"
                class="ri-question-line mr-1"
              ></i>
            </p>
            <p class="noMargin">Nothing Change Details.</p>
          </div>
        </section>

        <perfect-scrollbar
          style="
            max-height: 350px;
            width: 521px;
            overflow-y: auto;
            overflow-x: hidden;
          "
        >
          <table
            v-if="
              props.row.ApplicableFees && props.row.ApplicableFees.length > 0
            "
            class="details-table mt-2"
          >
            <h5 class="generalLabel pl-2" style="color: #3bafda">
              Applicable Fees
            </h5>
            <tr>
              <td align="center"><strong>Fee Amount</strong></td>
              <td align="center"><strong>Global Fee Type</strong></td>
              <td align="center"><strong>Fee Date</strong></td>
            </tr>

            <tr v-for="(item, index) in props.row.ApplicableFees" :key="index">
              <td align="center">
                {{ formatMoney(item.FeeAmount) }}
              </td>
              <td align="center">
                {{ item.GlobalFeeType }}
              </td>
              <td align="center">
                {{ formatShortDate(item.FeeDate) }}
              </td>
            </tr>
          </table>
        </perfect-scrollbar>

        <section
          v-if="
            props.row.ApplicableFees == null ||
            props.row.ApplicableFees.length == 0
          "
        >
          <div style="display: flex; align-items: center">
            <p class="noMargin">
              <i
                style="font-size: 24px; color: #939393"
                class="ri-question-line mr-1"
              ></i>
            </p>
            <p class="noMargin">No Applicable Fees.</p>
          </div>
        </section>
      </template>
    </o-table>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import DeclineReasonPopup from "./DeclineReasonPopup";
import deepCopy from "@/helpers/deepCopy";
import _orderby from "lodash";
export default {
  name: "FeeChanges",
  components: { DeclineReasonPopup },
  mixins: [formatMixin, utilitiesMixin],
  props: {
    feeChangesList: {
      Type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedId: false,
      isDeclinePopupOpen: false,
      isLoading: false,
      isApproveLoading: false,
      customerCashFlowList:[]
    };
  },
  computed: mapState({}),
  async mounted() {
    if (this.checkAuth(956782)) {
    this.isLoading= true;
    if(this.feeChangesList && this.feeChangesList.length>0)
    {
        let groupedCustomers = _orderby.groupBy(this.feeChangesList,"Customer.Id");
        let customerIds = Object.keys(groupedCustomers);
        for(let item of customerIds)
        {   
          let list = groupedCustomers[item];
          let officeId=0;
          if(typeof list != "undefined" && list && list.length> 0)
          {
            officeId= parseInt(list[0].Customer.OfficeId);
          }
       
            await this.bindCashFlow(item,officeId);   
        }
    }
    this.isLoading= false;
  }
  },
  methods: {
    async bindCashFlow(customerId,officeId){
          let err, result;
          [err, result] = await this.$store.dispatch(
            types.GET_CASHFLOW_FOR_PENDING_CHANGES,
            {
              CustomerId: customerId,
              OfficeId: officeId,
            }
          );
          if (result && result.Data) {
            if (result.Data.CashFlows && result.Data.CashFlows.length > 0) {

              let ongoingSettlementsData= [];
              if (result.Data.OngoingSettlements) {
                ongoingSettlementsData = deepCopy(
                result.Data.OngoingSettlements
              );
            }

              this.addCustomerCashFlowData(customerId,result.Data.CashFlows,ongoingSettlementsData);


             
            }

            
          }
    },
    addCustomerCashFlowData(customerId, cashFlowData, ongoingSettlementsData){

      let indx = this.customerCashFlowList.findIndex(x=> x.CustomerId == customerId);
      if(indx > -1)
      {
        this.customerCashFlowList[indx].CashFlowData = deepCopy(cashFlowData);
        this.customerCashFlowList[indx].OngoingSettlementsData = deepCopy(ongoingSettlementsData);
      }
      else
      {
        this.customerCashFlowList.push({
                CustomerId: customerId,
                CashFlowData: deepCopy(cashFlowData),
                OngoingSettlementsData: deepCopy(ongoingSettlementsData)

              }) ;
      }

    },
    isCashFlowNegative(customerId)
    {
      let rtn = false;
      if(customerId > 0 && this.customerCashFlowList.length > 0)
      {
         let indx = this.customerCashFlowList.findIndex(x=> x.CustomerId== customerId);
         if(indx> -1)
         {
          rtn = this.customerCashFlowList[indx].CashFlowData.filter((x) => x.Balance < 0 && new Date(x.Date) >= new Date())
          .length > 0;
         }
      }

      return rtn;
    },
    showCashFlow(customerId){

      if(customerId > 0 && this.customerCashFlowList.length > 0)
      {
         let indx = this.customerCashFlowList.findIndex(x=> x.CustomerId== customerId);
         if(indx> -1)
         {
            this.$emit("showCashFlow",{ CashFlow: this.customerCashFlowList[indx].CashFlowData, OngoingSettlements: this.customerCashFlowList[indx].OngoingSettlementsData  });
         }
      }

    },
    makeEmptyIfNull(val) {
      let rtn = "";
      if (val) {
        rtn = val;
      }
      return rtn;
    },
    refreshData() {
      this.$emit("refreshData");
    },
    openClient(customerId, customerName) {
      this.$Emitter.emit("viewCustomer", {
        customerId: customerId,
        customerName: customerName,
      });
    },
    openNotes(customerId) {
      this.$Emitter.emit("viewNotePanel", { CustomerId: customerId });
    },
    async handleApprove(dataId) {
      this.isApproveLoading = true;
      let err, result;
      let dataToSend = {
        Id: dataId,
      };

      [err, result] = await this.$store.dispatch(
        types.PC_APPROVE_FEECHANGES,
        dataToSend
      );

      if (result) {
        this.refreshData();
        this.$swal("Success!", result.Message, "success");
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.isApproveLoading = false;
    },
    async extraPaymentDecline(reason, isRevert) {
      this.isLoading = true;
      let err, result;
      let dataToSend = {
        Id: this.selectedId,
        DeclineReason: reason,
      };

      [err, result] = await this.$store.dispatch(
        types.PC_DECLINE_FEECHANGES,
        dataToSend
      );

      if (result) {
        this.refreshData();
        this.$swal("Success!", result.Message, "success");
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.isLoading = false;
    },
    async handleDecline(dataId) {
      this.selectedId = dataId;
      this.isDeclinePopupOpen = true;
    },
    closeDeclineReasonPopup() {
      this.isDeclinePopupOpen = false;
    },
  },
};
</script>

<style lang="scss">
.detail td:hover {
  background-color: #fafafa !important;
}
.details-table tr:hover {
  background-color: #fafafa !important;
}
.detail {
  background: #fafafa !important;
}
</style>
