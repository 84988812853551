<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'SnfDaaEnrollmentReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredSnfDaaEnrollmentReport"
        :paginated="isPaginated && filteredSnfDaaEnrollmentReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="SalesUser"
          label="Sales Agent"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SalesUserFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.SalesUser }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.SalesUser }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerId"
          label="Client ID "
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span @click="viewCustomer(props.row.CustomerId, props.row.CustomerName)"
              :class="getTagReportHyperLinkClass(props.row.CustomerId && props.row.CustomerName)">
              <strong v-if="props.row.IsSummary"
                >{{ props.row.CustomerId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ addPrefixToCustomerId(props.row.CustomerId) }}
              </span>
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerName"
          label="Customer Full Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerNameFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.CustomerName }}
          </template>
        </o-table-column>

        <o-table-column
          field="Status"
          label="Client Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StatusFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.Status }}
          </template>
        </o-table-column>

        <o-table-column
          field="PolicyGroup"
          label="Policy Group"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PolicyGroupFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.PolicyGroup }}
          </template>
        </o-table-column>

        <o-table-column field="Office" label="Office" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OfficeFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.Office }}
          </template>
        </o-table-column>

        <o-table-column
          field="EnrolledDate"
          label="Enrolled Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EnrolledDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.EnrolledDate) }}
          </template>
        </o-table-column>

        <o-table-column field="DropDate" label="Drop Date" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DropDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.DropDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="DebtAmountAtEnrollment"
          label="Enrolled Debt"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DebtAmountAtEnrollmentFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <strong v-if="props.row.IsSummary">
              {{ props.row.DebtAmountAtEnrollment }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.DebtAmountAtEnrollment) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="Email" label="Client Email" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EmailFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">{{ props.row.Email }} </template>
        </o-table-column>

        <o-table-column field="State" label="Client State" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">{{ props.row.State }} </template>
        </o-table-column>

        <o-table-column
          field="SettlementFeePercentage"
          label="Settlement Fee %"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SettlementFeePercentageFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <span v-if="!props.row.IsSummary">
              {{
                formatFloatingNumber(props.row.SettlementFeePercentage * 100) +
                "%"
              }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="FirstDraftDt"
          label="1st Draft Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FirstDraftDtFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props"
            >{{ formatShortDate(props.row.FirstDraftDt) }}
          </template>
        </o-table-column>

        <o-table-column
          field="FirstDraftPostedDate"
          label="1st Draft Posted Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FirstDraftPostedDateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ formatShortDate(props.row.FirstDraftPostedDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="ClearedDraftCount"
          label="Cleared Draft Count"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ClearedDraftCountFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.ClearedDraftCount }}
          </template>
        </o-table-column>

        <o-table-column
          field="DraftFrequency"
          label="Draft Frequency"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DraftFrequencyFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.DraftFrequency }}
          </template>
        </o-table-column>

        <o-table-column field="Source" label="Source" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SourceFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Source }}
          </template>
        </o-table-column>

        <o-table-column
          field="NoticeNumber"
          label="Notice Number"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="NoticeNumberFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.NoticeNumber }}
          </template>
        </o-table-column>

        <o-table-column field="Tier" label="Tier" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TierFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Tier }}
          </template>
        </o-table-column>

        <o-table-column
          field="CreatedDate"
          label="Created Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreatedDateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ formatShortDate(props.row.CreatedDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="viewCustomer(props.row.CustomerId, props.row.CustomerName)"
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredSnfDaaEnrollmentReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "SnfDaaEnrollmentReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      userInfo:null,
      customerPrefix: null,

      recordCount: 0,
      reportLoading: false,
      reportData: [],

      snfDaaEnrollmentReportInitSummary: {
        SalesUser: "",
        CustomerId: "",
        CustomerName: "",
        Status: "",
        PolicyGroup: "",
        Office: "",
        EnrolledDate: "",
        DebtAmountAtEnrollment: "",
        Email: "",
        State: "",
        SettlementFeePercentage: "",
        FirstDraftDt: "",
        FirstDraftPostedDate: "",
        Source: "",
        NoticeNumber: "",
        ClearedDraftCount: "",
        DraftFrequency: "",
        DropDate: "",
        CreatedDate: "",
        Tier: "",
      },

      SalesUserFilter: "",
      CustomerIdFilter: "",
      CustomerNameFilter: "",
      StatusFilter: "",
      PolicyGroupFilter: "",
      OfficeFilter: "",
      EnrolledDateFilter: "",
      DebtAmountAtEnrollmentFilter: "",
      EmailFilter: "",
      StateFilter: "",
      SettlementFeePercentageFilter: "",
      FirstDraftDtFilter: "",
      FirstDraftPostedDateFilter: "",
      SourceFilter: "",
      NoticeNumberFilter: "",
      ClearedDraftCountFilter: "",
      DraftFrequencyFilter: "",
      DropDateFilter: "",
      CreatedDateFilter: "",
      TierFilter: "",
    };
  },
  computed: mapState({
    snfDaaEnrollmentList: (state) => state.reports.snfDaaEnrollmentList,

    filteredSnfDaaEnrollmentReport() {
      let rtn = [];

      let tmpList =
        this.snfDaaEnrollmentList.length > 0
          ? this.snfDaaEnrollmentList.filter((item) => {
              return (
                this.filterString(
                  item.DraftFrequency,
                  this.DraftFrequencyFilter
                ) &&
                this.filterString(item.Source, this.SourceFilter) &&
                this.filterString(item.NoticeNumber, this.NoticeNumberFilter) &&
                this.filterString(item.SalesUser, this.SalesUserFilter) &&
                this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                this.filterString(item.CustomerName, this.CustomerNameFilter) &&
                this.filterString(item.Status, this.StatusFilter) &&
                this.filterString(item.PolicyGroup, this.PolicyGroupFilter) &&
                this.filterString(item.Office, this.OfficeFilter) &&
                this.filterString(
                  item.DebtAmountAtEnrollment,
                  this.DebtAmountAtEnrollmentFilter
                ) &&
                this.filterString(item.Email, this.EmailFilter) &&
                this.filterString(item.State, this.StateFilter) &&
                this.filterString(
                  item.ClearedDraftCount,
                  this.ClearedDraftCountFilter
                ) &&
                this.filterString(
                  item.SettlementFeePercentage,
                  this.SettlementFeePercentageFilter
                ) &&
                new Date(item.FirstDraftDt)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.FirstDraftDtFilter) > -1 &&
                new Date(item.DropDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.DropDateFilter) > -1 &&
                new Date(item.EnrolledDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.EnrolledDateFilter) > -1 &&
                new Date(item.FirstDraftPostedDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.FirstDraftPostedDateFilter) > -1 &&
                new Date(item.CreatedDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.CreatedDateFilter) > -1 &&
                this.filterString(item.Tier, this.TierFilter)
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalSalesUser = tmpList.length.toString();

      this.snfDaaEnrollmentReportInitSummary.SalesUser =
        "Total: " + totalSalesUser;
      this.recordCount = tmpList.length;
      this.snfDaaEnrollmentReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.snfDaaEnrollmentReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );

    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }
    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_SNF_DAA_ENROLLMENT_REPORT, value);
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, name) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: name,
      });
    },
  },
};
</script>

<style scoped></style>
