<template>
  <div class="row admin-page">
    <div v-if="saving" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 panelWrapper">
      <div class="filter form-group row">
        <div class="col-sm-12 noPadding">
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            v-model="uiSettingsFilter"
            placeholder="Filter by Ui Settings"
          />
        </div>
      </div>
      <div class="list col-md-12">
        <div
          class="role item row"
          v-for="(item, index) in filteredSettings"
          :key="item.Id"
          :class="{ active: activeItem === item.Id }"
          :style="[
            index % 2 === 0
              ? { 'background-color': '#F1EFEF' }
              : { 'background-color': '#FFFFFF' },
          ]"
          :disabled="saving"
          @click="() => handleListSelection(item, index)"
        >
          <span :class="{ adminActiveMenuItem: activeItem === item.Id }">
            <i class="ri-file-settings-fill"></i>
            <span style="margin-left: 8px"> {{ item.SettingName }}</span>
          </span>
        </div>
      </div>
      <div
        v-if="isMobileDevice()"
        style="
          border-top: 1px solid rgb(0, 0, 0, 0);
          margin: 20px 0 0 9px;
          padding-bottom: 20px;
        "
        class="col-md-12 scrollToSelectedItem"
      ></div>
    </div>

    <div
      class="col-xl-9 col-lg-8 col-md-12 col-sm-12 noMargin"
      v-if="baseItems.length > 0"
    >
      <form
        class="form-horizontal col-md-12 float-right panelWrapper"
        role="form"
      >
        <div class="row">
          <div class="offset-md-4 col-4">
            <span class="badge btn-danger" style="font-size: 11px; padding: 8px"
              >You can use drag-drop to sort pinned items.</span
            >
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <h3>Items</h3>
            <input
              autocomplete="off"
              type="text"
              class="form-control mb-2"
              v-model="filterItems"
              placeholder="Filter "
            />
            <div style="max-height: 421px; overflow-y: auto">
              <draggable
                class="dragArea list-group"
                :list="filteredBaseItems"
                :group="{ name: 'uiOrderList', pull: pullFunction }"
                @start="start"
                item-key="Id"
                :disabled="IsSectionName"
                :style="IsSectionName == true ? 'opacity: 0.5;' : ''"
              >
                <template #item="{ element }">
                  <div class="list-group-item">
                    {{ element.Name }}
                  </div>
                </template>
              </draggable>
            </div>
          </div>

          <div class="col-4">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <h3>Pinned Items</h3>
              <div
                v-if="IsCustomerTags == 'Customer Tags'"
                class="badge badge-primary"
              >
                <i
                  style="font-size: 15px; cursor: pointer"
                  class="fas fa-folder-plus"
                  @click="tagSectionPopUpOpen"
                ></i>
              </div>
            </div>
            <div style="max-height: 421px; overflow-y: auto">
              <draggable
                class="dragArea list-group"
                :list="pinnedItems"
                group="uiOrderList"
                item-key="Id"
                style="
                  min-height: 421px;
                  border: 1px dotted rgba(0, 0, 0, 0.125);
                "
                @start="moveItem"
                @end="onDragEnd"
              >
                <template v-if="pinnedItems.length < 1" #footer>
                  <a style="text-align: center; margin-top: 50%"
                    >Drag and drop item here.
                  </a>
                </template>

                <template #item="{ element }">
                  <div
                    class="list-group-item"
                    :class="element.IsSection ? 'sectionBg' : ''"
                  >
                    <div style="display: flex; justify-content: space-between">
                      <div>
                        {{
                          element.IsSection
                            ? element.Name
                            : pinnedItems.indexOf(element) +
                              1 +
                              ". " +
                              element.Name
                        }}
                      </div>
                      <div v-if="element.IsSection">
                        <i
                          @click="deleteSection(element)"
                          class="fas fa-times"
                          style="cursor: pointer"
                        ></i>
                      </div>
                    </div>
                  </div>
                </template>
              </draggable>
            </div>

            <div
              class="col-12 noPadding"
              style="width: 100%; display: flex; justify-content: flex-end"
            >
              <div
                style="width: 100%; cursor: pointer"
                @click="saveSetting()"
                class="btn btn-success"
                :disabled="saving"
              >
                Save Setting
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="row">
      <div class="backgroundLock" v-if="isTagSectionPopupOpen">
        <div
          style="position: relative; margin: 5% auto"
          class="offset-xl-4 offset-lg-3 col-xl-3 col-lg-4 col-md-5 col-sm-12 col-xs-12"
        >
          <TagSectionPopUp
            @closeTagSectionPopUp="closeTagSectionPopUp"
            @addSection="addSection"
            :pinnedItems="pinnedItems"
          >
          </TagSectionPopUp>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import deepCopy from "@/helpers/deepCopy";
import _orderby from "lodash";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import globalTypes from "@/store/types";
import _orderBy from "lodash";
import draggable from "vuedraggable";
import TagSectionPopUp from "./TagSectionPopUp";

export default {
  name: "UISettingsManagement",
  display: "Clone on Control",
  instruction: "Press Ctrl to clone element from list 1",
  order: 4,
  components: {
    draggable,
    TagSectionPopUp,
  },
  data() {
    return {
      isTagSectionPopupOpen: false,
      selectedRow: Object.assign({}, this.settingInit()),
      baseItems: [],
      pinnedItems: [],
      saving: false,
      uiSettingsFilter: "",
      permissionFilter: "",
      activeItem: null,
      oldValue: "",
      isCancelledBeforeSave: false,
      valueToRevert: Object.assign({}, this.settingInit()),
      controlOnStart: true,
      filterItems: "",
      IsSectionName: false,
      IsCustomerTags: "",
    };
  },
  mixins: [formatMixin, utilitiesMixin],
  computed: mapState({
    allSettings: (state) => state.uiSettingsManagement.companyUiSettings,
    filteredSettings() {
      return this.allSettings.filter(
        (item) =>
          item.SettingName.toLowerCase().indexOf(
            this.uiSettingsFilter.toLowerCase()
          ) > -1
      );
    },
    expenseTypeList: (state) => state.globals.expenseTypeList,
    customerTagList: (state) => state.globals.customerTagTypes,
    filteredBaseItems() {
      return this.baseItems.length > 0
        ? _orderby.orderBy(
            this.baseItems.filter(
              (item) =>
                item.Name.toLowerCase().indexOf(
                  this.filterItems.toLowerCase()
                ) > -1
            )
          )
        : [];
    },
  }),
  async mounted() {
    await Promise.all([
      this.$store.dispatch(globalTypes.GET_EXPENSE_TYPES_LIST),
      this.$store.dispatch(globalTypes.GET_CUSTOMER_TAG_TYPE),
      this.$store.dispatch(types.GET_ALL_UI_SETTINGS),
    ]);

  },

  methods: {
    onDragEnd() {
      this.IsSectionName = false;
    },
    async moveItem(evt) {
      const draggedItem = this.pinnedItems[evt.oldIndex];
      if (draggedItem.IsSection) {
        this.IsSectionName = true;
      }
    },

    deleteSection(element) {
      let index = this.pinnedItems.indexOf(element);
      if (index !== -1) {
        this.pinnedItems.splice(index, 1);
      }
    },

    tagSectionPopUpOpen() {
      this.isTagSectionPopupOpen = true;
    },
    closeTagSectionPopUp() {
      this.isTagSectionPopupOpen = false;
    },

    addSection(data) {
      this.isTagSectionPopupOpen = false;
      let newSelectionId = this.getNewUserId();
      let newSelection = {
        Id: newSelectionId,
        Name: data,
        IsSection: true,
        UiOrder: this.getUiOrder(),
        IsOpen: false,
      };
      this.pinnedItems.push(newSelection);
    },

    getNewUserId() {
      let maxId = 1000;
      for (let item of this.pinnedItems) {
        if (item.Id > maxId) {
          maxId = item.Id;
        }
      }
      return maxId + 1;
    },
    getUiOrder() {
      let maxId = 0;
      for (let item of this.pinnedItems) {
        if (item.UiOrder > maxId) {
          maxId = item.UiOrder;
        }
      }
      return maxId + 1;
    },

    settingInit() {
      return {
        Id: null,
        SettingName: "",
        Data: "",
      };
    },
    addNewSetting() {},
    bindSetting() {
      if (
        this.selectedRow.SettingName &&
        this.selectedRow.SettingName.toLowerCase() == "customer expenses"
      ) {
        let list = deepCopy(this.expenseTypeList);
        if (list.length > 0) {
          this.baseItems = list.map((x) => {
            return { Id: x.Id, Name: x.Name };
          });
          this.filterItems = "";
          this.pinnedItems = [];
          try {
            let jsonData = JSON.parse(this.selectedRow.Data);
            if (
              typeof jsonData != "undefined" &&
              jsonData != null &&
              Array.isArray(jsonData)
            ) {
              this.pinnedItems = deepCopy(jsonData);
              if (this.pinnedItems.length > 0) {
                let idList = this.pinnedItems.map((x) => x.Id);
                this.baseItems = this.baseItems.filter(
                  (x) => !idList.includes(x.Id)
                );
              }
            }
          } catch {
            //err
          }
        }
      } else if (
        this.selectedRow.SettingName &&
        this.selectedRow.SettingName.toLowerCase() == "customer tags"
      ) {
        let list = deepCopy(this.customerTagList);
        if (list.length > 0) {
          this.baseItems = list.map((x) => {
            return { Id: x.Id, Name: x.TagName };
          });
          this.filterItems = "";
          this.pinnedItems = [];
          try {
            let jsonData = JSON.parse(this.selectedRow.Data);
            if (
              typeof jsonData != "undefined" &&
              jsonData != null &&
              Array.isArray(jsonData)
            ) {
              this.pinnedItems = deepCopy(jsonData);
              if (this.pinnedItems.length > 0) {
                let idList = this.pinnedItems.map((x) => x.Id);
                this.baseItems = this.baseItems.filter(
                  (x) => !idList.includes(x.Id)
                );
              }
            }
          } catch {
            //err
          }
        }
      } else {
        this.baseItems = [];
        this.pinnedItems = [];
        this.$swal.fire("Warning!", "No setting found.", "warning");
      }
    },
    clone({ Id, Name }) {
      return { Name, Id };
    },
    pullFunction() {
      return this.controlOnStart ? "clone" : true;
    },
    start({ originalEvent }) {
      this.controlOnStart = originalEvent.ctrlKey;
    },

    async saveSetting() {
      let orderList = [];

      for (let i = 0; i < this.pinnedItems.length; i++) {
        let sections = this.pinnedItems.filter((x) => x.IsSection);
        let sectionIndex = -1;

        if (sections.length > 0 && !this.pinnedItems[i].IsSection) {
          for (let item of sections) {
            let arrayIndex = this.pinnedItems.findIndex((x) => x.Id == item.Id);
            if (arrayIndex > -1 && i > arrayIndex) {
              sectionIndex = arrayIndex;
            }
          }
        }
        let section = this.pinnedItems[sectionIndex];
        let sectionId = -1;
        if (typeof section != "undefined" && section && section.Id > 0) {
          sectionId = section.Id;
        }

        orderList.push({
          UiOrder: i + 1,
          Id: this.pinnedItems[i].Id,
          Name: this.pinnedItems[i].Name,
          IsSection: this.pinnedItems[i].IsSection,
          SectionId: sectionId,
          IsOpen: this.pinnedItems[i].IsOpen,
        });
      }

      this.selectedRow.Data = JSON.stringify(orderList);

      if (this.oldValue == JSON.stringify(this.selectedRow)) {
        this.$swal.fire("Warning!", "No changes detected!", "warning");
      } else {
        this.saving = true;
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.UPDATE_UI_SETTING,
          this.selectedRow
        );
        if (result) {
          let oldId = this.selectedRow.Id;
          let settingData = deepCopy(result.Data);
          this.selectedRow = settingData;
          this.oldValue = JSON.stringify(result.Data);
          this.$swal.fire("Success!", result.Message, "success");
          this.activeItem = settingData.Id;

          this.$store.dispatch(globalTypes.GET_UI_SETTINGS, {
            ForceRefresh: true,
          });

          let indx = this.allSettings.findIndex((x) => x.Id == oldId);
          if (indx >= 0) {
            this.allSettings[indx] = settingData;
            this.allSettings.push();
          }
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }

        this.saving = false;
      }
    },
    async handleListSelection(row, index) {
      if (
        this.selectedRow &&
        JSON.stringify(this.selectedRow) != this.oldValue &&
        JSON.stringify(this.selectedRow) != JSON.stringify(this.settingInit())
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.isCancelledBeforeSave = true;
            } else {
              this.isCancelledBeforeSave = false;
              return;
            }
          });
      } else {
        this.selectedRow = deepCopy(row);
        this.oldValue = JSON.stringify(row);
        this.activeItem = row.Id;
        this.IsCustomerTags = row.SettingName;
        this.bindSetting();

      }
      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(row);
        this.oldValue = JSON.stringify(row);
        this.activeItem = row.Id;
        this.bindSetting();
      }
      this.isCancelledBeforeSave = false;
    },
  },
};
</script>

<style scoped>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.not-draggable {
  cursor: no-drop;
}
.sectionBg {
  background-color: #3bafda;
  border-color: #3bafda;
  color: white;
}
.disabled-item {
  pointer-events: none;
  opacity: 0.6;
}
</style>
