export default {
  GET_MIGRATION_AFFILIATE_ENROLLMENT_DASHBOARD:"GET_MIGRATION_AFFILIATE_ENROLLMENT_DASHBOARD",
  SET_MIGRATION_AFFILIATE_ENROLLMENT_DASHBOARD:"SET_MIGRATION_AFFILIATE_ENROLLMENT_DASHBOARD",
  GET_NEGOTIATOR_STATISTICS_DASHBOARD: "GET_NEGOTIATOR_STATISTICS_DASHBOARD",
  SET_NEGOTIATOR_STATISTICS_DASHBOARD: "SET_NEGOTIATOR_STATISTICS_DASHBOARD",
  GET_OFFICE_PERFORMANCE: "GET_OFFICE_PERFORMANCE",
  GET_OFFICE_PERFORMANCE_STATE: "GET_OFFICE_PERFORMANCE_STATE",
  SET_OFFICE_PERFORMANCE_STATE: "SET_OFFICE_PERFORMANCE_STATE",
  GET_SUMMARY_TILES: "GET_SUMMARY_TILES",
  SET_SUMMARY_TILES: "SET_SUMMARY_TILES",
  REFRESH_DASHBOARD_DATA: "REFRESH_DASHBOARD_DATA",
  RESET: "RESET",
  GET_SNF_DASHBOARD: "GET_SNF_DASHBOARD",
  SET_SNF_DASHBOARD: "SET_SNF_DASHBOARD",
  GET_SNF_DASHBOARD_SALES: "GET_SNF_DASHBOARD_SALES",
  GET_DB_DASHBOARD_SALES: "GET_DB_DASHBOARD_SALES",
  GET_AFFILIATE_LEADERSHIP_DASHBOARD: "GET_AFFILIATE_LEADERSHIP_DASHBOARD",
  SET_AFFILIATE_LEADERSHIP_DASHBOARD: "SET_AFFILIATE_LEADERSHIP_DASHBOARD",
  GET_CSR_DASHBOARD_REPORT: "GET_CSR_DASHBOARD_REPORT",
  SET_CSR_DASHBOARD_REPORT: "SET_CSR_DASHBOARD_REPORT",
  GET_INDIVIDUAL_SALES_DASHBOARD: "GET_INDIVIDUAL_SALES_DASHBOARD",
  SET_INDIVIDUAL_SALES_DASHBOARD: "SET_INDIVIDUAL_SALES_DASHBOARD",
  GET_OPERATION_HOUR_DASHBOARD: "GET_OPERATION_HOUR_DASHBOARD",
  SET_OPERATION_HOUR_DASHBOARD: "SET_OPERATION_HOUR_DASHBOARD",
  GET_SNF_DASHBOARD_CUSTOMER_STATUS: "GET_SNF_DASHBOARD_CUSTOMER_STATUS",
  SET_SNF_DASHBOARD_CUSTOMER_STATUS: "SET_SNF_DASHBOARD_CUSTOMER_STATUS",
  GET_WELCOME_CALL: "GET_WELCOME_CALL",
  SET_WELCOME_CALL: "SET_WELCOME_CALL",
  GET_SALES_MONTHLY_DASHBOARD: "GET_SALES_MONTHLY_DASHBOARD",
  GET_SNF_SETTLEMENTS_DASHBOARD: "GET_SNF_SETTLEMENTS_DASHBOARD",
  SET_SNF_SETTLEMENTS_DASHBOARD: "SET_SNF_SETTLEMENTS_DASHBOARD",
  GET_NEGOTIATORS_DASHBOARD: "GET_NEGOTIATORS_DASHBOARD",
  SET_NEGOTIATORS_DASHBOARD: "SET_NEGOTIATORS_DASHBOARD",
  GET_SNF_SETTLEMENT_FEE_DASHBOARD: "GET_SNF_SETTLEMENT_FEE_DASHBOARD",
  SET_SNF_SETTLEMENT_FEE_DASHBOARD: "SET_SNF_SETTLEMENT_FEE_DASHBOARD",
  GET_AFFILIATE_ENROLLMENT_DASHBOARD: "GET_AFFILIATE_ENROLLMENT_DASHBOARD",
  SET_AFFILIATE_ENROLLMENT_DASHBOARD: "SET_AFFILIATE_ENROLLMENT_DASHBOARD",
};
